import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AllocateLeadsForm from './AllocateLeadsForm'
import ChangeStateOfLeadsForm from './ChangeStateOfLeadsForm'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  }
}));

export default function BulkLeadActionsContainer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box mb={2}>
        <AllocateLeadsForm />
      </Box>
      <ChangeStateOfLeadsForm />
    </div>
  );
}