import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Formik, Form, Field} from "formik";
import AddIcon from "@material-ui/icons/Add";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LeadsFilter from "../../LeadsFilter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../../../shared/TableHeadersSort";
import {DEFAULT_PAGE_SIZE} from "../../../../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import FileUploadInput from "../../../../shared/FileUploadInput";
import OtpTabController from "./OTPTabController"
import { useParams } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {},

    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    stepPaddingButton: {
        marginTop: "1rem",
        marginLeft: "1rem",
    },
    inputPadding: {
        paddingLeft: "2rem",
        paddingRight: "2rem",
        paddingTop: "2rem",
    },
}));

export default function OTPTabContainer() {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const { state, tab, id } = useParams();
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("title");
    const [elementRef, setElementRef] = useState("");
    const { loading, error, data, total, types, reloadData, uploadDocument } = OtpTabController(id);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const headCells = [
        {id: "open", numeric: false, label: "Open", sort: true},
        {id: "otpNumber", numeric: false, label: "OTP Number", sort: true},
        {id: "document", numeric: false, label: "Document", sort: true},
        {id: "created", numeric: false, label: "Created", sort: true},
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const values = {
        fileUpload: "",
        otpNumber: "",
    };

    return (
        <div className={classes.stepPadding}>
            <Formik
                initialValues={values}
                isInitialValid={false}
                onSubmit={(values, actions) => {

                    uploadDocument(values);

                    setTimeout(() => {
                        console.log(values);
                        actions.setSubmitting(true);
                    }, 100);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6" style={{marginBottom: "2.5rem"}}>
                                        Add an OTP
                                    </Typography>
                                    <Grid container direction="row">
                                        <Grid item className={classes.stepPadding} xs={12} md={6}>
                                            <FileUploadInput
                                                title='Document'
                                                fieldName={"fileUpload"}
                                                error={
                                                    props.errors.fileUpload && props.touched.fileUpload
                                                }
                                            />
                                        </Grid>
                                        <Grid item className={classes.inputPadding} xs={12} md={4}>
                                            <TextField
                                                id="otpNumber"
                                                name="otpNumber"
                                                label="OTP Number"
                                                value={props.values.otpNumber}
                                                helperText={
                                                    props.touched.otpNumber ? props.errors.otpNumber : ""
                                                }
                                                error={
                                                    props.touched.otpNumber &&
                                                    Boolean(props.errors.otpNumber)
                                                }
                                                onChange={(event) => {
                                                    props.handleChange(event);
                                                    props.setFieldTouched("otpNumber", true, false);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.stepPaddingButton}>
                                        <Button
                                            variant="contained"
                                            style={{margin: "1rem"}}
                                            color="primary"
                                            type="submit"
                                            disabled={!props.isValid}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{margin: "1rem"}}
                                            color="primary"
                                            onClick={props.resetForm}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    );
                }}
            </Formik>
            <Divider
                variant="fullWidth"
                style={{marginTop: "1rem", marginBottom: "1rem"}}
            />
            <Card className={classes.card}>
                <CardContent>
                    <TableContainer ref={(el) => setElementRef(el)}>
                        <Table aria-label="ranges table">
                            <TableHeadersSort
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell><a href={row.fileUrl}>Download</a></TableCell>
                                        <TableCell>{row.note}</TableCell>
                                        <TableCell>{row.fileName}</TableCell>
                                        <TableCell>{moment(row.createDate).format('l')}</TableCell>
                                    </TableRow>
                                ))}
                                {(!data.length || data.length === 0) && !loading && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{borderBottom: 0}}
                                            className="py-3"
                                        >
                                            <Typography variant="h6" color="textSecondary">
                                                No OTP's uploaded
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {loading && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{borderBottom: 0}}
                                        >
                                            <CircularProgress/>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={total}
                            page={page}
                            rowsPerPageOptions={[5, 10, 25]}
                            onChangePage={handleChangePage}
                            rowsPerPage={pageSize}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </CardContent>
            </Card>
        </div>
    );
}
