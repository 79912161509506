import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumbs, Button, CircularProgress, Grid, Link, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { Table, TableContainer, TableBody, TablePagination } from "@material-ui/core";
import { ArrowBackIos, Clear, Edit, OpenInBrowser } from '@material-ui/icons';
import axios from "axios";
import { useSnackbar } from "notistack";
import { BASE_URI } from "../../shared/Constants";
import { useStyles, StyledTableRow, StyledTableCell } from '../../shared/CommonStyles';
import { GetErrorMessage } from '../../shared/CommonFunctions';
import TableHeadersSort from "../../shared/TableHeadersSort";

export default function VehicleLibraryModels() {
    
    const classes = useStyles({ stickyHeaderTableHeight: window.innerHeight });
    const history = useHistory();
    const { makeId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [ loading, setLoading ] = useState(true);
    const [ make, setMake ] = useState({ id: makeId });
    const [ models, setModels ] = useState([]);
    const [ searchString, setSearchString ] = useState("");

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    
    const headCells = [
        { id: "name", label: "Model Name", sort: true },
        { id: "actions", label: "Actions", sort: false, align: "center", width: "250px" }
    ];

    useEffect(() => {
        
        Promise.allSettled([
            LoadMakeDetails(),
            LoadModels()
        ])
        .catch((error) => {

			enqueueSnackbar(GetErrorMessage(error, "An unexpected error occurred while loading the master models"), { variant: 'error' });

        }).finally(() => {

            setLoading(false);

        });
        
    }, []);

    function LoadMakeDetails() {

        return new Promise((res, rej) => {

            axios({
                method: 'GET',
                url: `${BASE_URI}/MasterMake/${make.id}`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                if (!response?.data) {

                    enqueueSnackbar("No master make details could be found", { variant: 'warning' });
                    res(null);
                    return;

                }

                let makeDetails = {
                    id: make.id,
                    name: response?.data?.name
                };
                
                setMake(makeDetails);
                res(makeDetails);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the master make details"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function LoadModels() {

        return new Promise((res, rej) => {

            let params = {
                masterMakeId: makeId
            };

            axios({
                method: 'GET',
                url: `${BASE_URI}/MasterModel`,
                params,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                let data = [
                    ...response?.data
                ];
                
                setModels(data);
                res(data);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the master models"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function GoBack() {
        
        history.goBack();

    }

    function SelectModel(id) {

        history.push(`/VehicleLibrary/Makes/${make.id}/Models/${id}/Variants`);

    }

    function EditModel(id) {
        
        history.push(`/VehicleLibrary/Makes/${make.id}/Model/${id}/edit`);

    }

    function GetDisplayModels() {

        try {
            
            // Filter, slice and sort the models list
            let filteredModels = !searchString 
                ? [ ...models ]
                : models.filter(m => m.name?.trim().toLowerCase().includes(searchString?.trim().toLowerCase()));

            let sortedModels = filteredModels.sort(GetComparator());

            let startIndex = page * pageSize;
            let slicedModels = sortedModels.slice(startIndex, startIndex + pageSize);

            return slicedModels;

        } catch (error) {
            
            enqueueSnackbar(GetErrorMessage(error, "Failed to filter and sort the list"), { variant: 'error' });
            return models;

        }   

    }

    function ClearSearchString() {

        setSearchString("");

    }

    function HandleChangeSearchString(event) {
        
        setSearchString(event?.target?.value);

    }

    function GetComparator() {

        return order === "asc"
            ? (a, b) => AscendingComparator(a, b)
            : (a, b) => -AscendingComparator(a, b);

    }

    function AscendingComparator(a, b) {

        return (a[orderBy] > b[orderBy]) ? 1 : -1;

    }

    function HandleRequestSort(event, property) {

        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);

    }

    function HandleChangePage(event, newPage) {

        setPage(newPage);

    }

    function HandleChangeRowsPerPage(event) {

        setPageSize(parseInt(event?.target?.value || 25, 10));
        setPage(0);

    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Link underline="hover" onClick={GoBack}>
                            {make?.name || "Makes"}
                        </Link>
                    </Breadcrumbs>
                </Grid>

                <Grid item xs={12} container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={12} md={8} container spacing={1} direction="row" alignItems="center">
                        <Grid item className={classes.backArrowIcon}>
                            <ArrowBackIos color="primary" onClick={GoBack}/>
                        </Grid>

                        <Grid item>
                            <Typography variant="h4">
                                Master Models
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="modelsFilter"
                            label="Search Models"
                            disabled={loading}
                            value={searchString}
                            onChange={HandleChangeSearchString}
                            InputProps={{
                                endAdornment: 
                                    searchString?.length > 0 &&
                                    <InputAdornment position="end">
                                        <IconButton size="small" disableFocusRipple disableRipple onClick={() => ClearSearchString()}>
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>

                {
                    loading &&
                    <Grid item xs={12} container direction="row" justify="center">
                        <CircularProgress/>
                    </Grid>
                }
                {
                    !loading &&
                    <Grid item xs={12}>
                        <TableContainer className={classes.stickyHeaderTable}>
                            <Table stickyHeader aria-label="Master Models">
                                <TableHeadersSort
                                    order={order}
                                    orderBy={orderBy}
                                    headCells={headCells}
                                    onRequestSort={HandleRequestSort}
                                />
                                <TableBody>
                                    {
                                        (!models?.length || models.length === 0) && 
                                        <StyledTableRow hover>
                                            <StyledTableCell
                                                colSpan={headCells.length}
                                                align="center"
                                                style={{ borderBottom: 0 }}
                                                className="py-3"
                                            >
                                                <Typography variant="h6" color="textSecondary">
                                                    No Master Models Available
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }
                                    {
                                        GetDisplayModels().map((row) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell 
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => SelectModel(row.id)}
                                                >
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Grid container spacing={2} direction="row" justify="center">
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                style={{ margin: "1rem" }}
                                                                onClick={() => SelectModel(row.id)}
                                                            >
                                                                <OpenInBrowser />
                                                            </Button>
                                                        </Grid>
                                                        {/*
                                                        Removed for the time-being
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                style={{ margin: "1rem" }}
                                                                onClick={() => EditModel(row.id)}
                                                            >
                                                                <Edit />
                                                            </Button>
                                                        </Grid>
                                                        */}
                                                    </Grid>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                            <TablePagination
                                component="div"
                                count={models?.length}
                                page={page}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onChangePage={HandleChangePage}
                                rowsPerPage={pageSize}
                                onChangeRowsPerPage={HandleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Grid>
                }

            </Grid>
        </Paper>
    );
}