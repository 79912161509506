import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import copy from "copy-to-clipboard";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Parser } from "json2csv";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  button: {
    display: "inline",
    margin: "5px",
  }
}));

export default function ExportContainer({values, headers, fileName, useDataHeaders}) {
  const classes = useStyles();
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(values);
    copy(csv) ? setCopySuccess(true) : setCopySuccess(false);
  };

  const exportToPDF = () => {
    var doc = new jsPDF();
    let tableColumn =[];

    tableColumn = headers.map((header) => {
      return header.label ? header.label : header
    })

    if (useDataHeaders) {
      const parseDataHeaders = Object.fromEntries(
        Object.entries(values[0]).filter(
          ([key]) => key !== 'id'
        ))

      tableColumn =Object.entries(parseDataHeaders).map(([key]) => {return key})
    }

    const tableRows = [];
    let data = values;

    data.forEach((row) => {
      const tableData = Object.fromEntries(
        Object.entries(row).filter(
          ([key]) => key !== 'id'
        ))
      tableRows.push(Object.values(tableData));
    });
    doc.autoTable(tableColumn, tableRows);

    doc.save(`${fileName}.pdf`);
  };

  const printPage = () => {
    var win = window.open();
    var html = `
    <!DOCTYPE html>
    <html>
    <head>
    <style>
    table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }

    td, th {
    border: 1px solid #ddd;
    padding: 8px;
    }

    tr:nth-child(even){background-color: #f2f2f2;}

    tr:hover {background-color: #ddd;}

    th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
    }
    </style>
    </head>
    <body>`;
    html += `<table><tr>`;
    const tableHeaders = Object.fromEntries(
      Object.entries(values[0]).filter(
        ([key]) => key !== 'id'
      ))
      useDataHeaders && Object.entries(tableHeaders).forEach(([key]) => {
        html += "<th>";
        html += key
        html += "</th>";
      })
      !useDataHeaders &&
      headers.forEach((header) => {
        html += "<th>";
        html += header.label ? header.label: header
        html += "</th>";
      })
    html += `</tr>`;
    values.forEach((row) => {
      const tableData = Object.fromEntries(
        Object.entries(row).filter(
          ([key]) => key !== 'id'
        ))
      html += "<tr>";
      Object.values(tableData).forEach((val) => {
        html += "<td>"
        html += (val !== undefined && val !== null) ? val: ''
        html += "</td>"
      })
      html += "</tr>";
    });
    html += `</table>
            </body>
            </html>`;

    win.document.write(html);
    win.document.close();

    var document_focus = false;

    win.onload = () => {
      win.print();
      document_focus = true;
    };
    setInterval(() => {
      if (document_focus === true) {
        win.close();
      }
    }, 0.01);
  };
  return (
    <React.Fragment>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={copyToClipboard}
          >
            Copy
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <CSVLink
              filename={`${fileName}.csv`}
              className="primary button group"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
              data={values}
            >
              CSV
            </CSVLink>
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={exportToPDF}
          >
            PDF
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={printPage}
          >
            Print
          </Button>
        {/* </ButtonGroup> */}
        {/* TODO Makes this Better?  */}
        {copySuccess ? <div style={{ color: "green" }}>Success!</div> : null}
    </React.Fragment>
  );
}
