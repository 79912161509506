import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AuthContextProvider} from './shared/context/AuthContext'
import {FACEBOOK_APP_ID,FACEBOOK_API_VERSON} from './shared/Constants';
import axios from "axios";

export function initFacebookSdk() {
  return new Promise(resolve => {
      // wait for facebook sdk to initialize before starting the react app
      window.fbAsyncInit = function () {
          window.FB.init({
              appId: FACEBOOK_APP_ID,
              cookie: true,
              xfbml: true,
              version: FACEBOOK_API_VERSON
          });

          // auto authenticate with the api if already logged in with facebook
          window.FB.getLoginStatus(({ authResponse }) => {
              if (authResponse) {
                 resolve();
              } else {
                  resolve();
              }
          });
      };

      // load facebook sdk script
      (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));    
  });
}
function StartApp(){
  ReactDOM.render(
    <React.StrictMode>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
    
  );
  // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
}

initFacebookSdk()
  .then(StartApp);


