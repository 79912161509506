import React, { useContext } from "react";
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DealerContext } from './DealerContext'

const useStyles = makeStyles((theme) => ({
    white: {
        color: 'white !important'
    },
    cssOutlinedInput: {
        '&$notchedOutline': {
            borderColor: `white !important`,
        }
    },
    icon: {
        fill: 'white !important'
    },
    notchedOutline: {
        borderColor: 'white !important'
    },
    select: {
        minWidth: "300px",
        maxWidth: "850px",
        width: "40%"
    }
}));

export const DealerSelect = ({ label, fullWidth, className, secondary }) => {

    const classes = useStyles();
    const { dealerArr, selectedMotorGroup, updateSelectedMotorGroup } = useContext(DealerContext);

    const secondaryInputProps = {
        classes: {
            root: classes.cssOutlinedInput,
            notchedOutline: classes.notchedOutline,
            input: classes.white
        }
    }

    const secondaryLabelProps = {
        classes: {
            root: classes.white
        }
    }

    function handleDealerSelection(motorgroup) {

        if (!motorgroup) return;

        updateSelectedMotorGroup(motorgroup, motorgroup.id);

    }

    function getOptionLabel(option) {

        return (!option.motorGroupName)
            ? `No group - ${option.name}`
            : `${option.motorGroupName} - ${option.name}`;

    }

    return (
        <React.Fragment>
            <Autocomplete
                id="dealerSelect"
                name="dealerSelect"
                className={classes.select}
                fullWidth={fullWidth}
                value={selectedMotorGroup}
                options={dealerArr}
                getOptionLabel={getOptionLabel}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(e, child) => handleDealerSelection(child)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={className}
                        label="Dealer"
                        variant={secondary ? "outlined" : "standard"}
                        InputLabelProps={secondary ? secondaryLabelProps : ''}
                        InputProps={{
                            ...params.InputProps,
                            classes: (secondary ? secondaryInputProps.classes : ""),
                            endAdornment: (
                                <React.Fragment>
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            />
        </React.Fragment>
    );
};
