import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
// import moment from 'moment'

export default function DealerListFilter(searchString, pageNumber, pageSize, order, orderBy) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [dealers, setDealers] = useState([])
  const [total, setTotal] = useState(0)

  const sampleData = [
    {
      id: 0,
      name: 'Click Car',
      status: 'inActive',
      motorgroup: 'Click Car',
      pqLow: 510,
      pqHigh: 550,
      PIN: 'CCAR'
    },
    {
      id: 1,
      name: 'CSP Online',
      status: 'active',
      motorgroup: 'Car Sales Portal Online',
      pqLow: 400,
      pqHigh: 550,
      PIN: 'CSPO'
    },
    {
      id: 2,
      name: 'Hyundai Menlyn',
      status: 'active',
      motorgroup: 'Motus Hyundai',
      pqLow: 122,
      pqHigh: 890,
      PIN: 'HMEN'
    },
    {
      id: 3,
      name: 'KIA Durban',
      status: 'inActive',
      motorgroup: 'Motus Kia',
      pqLow: 300,
      pqHigh: 1200,
      PIN: 'KIAD'
    },
    {
      id: 4,
      name: 'Volo Car',
      status: 'active',
      motorgroup: 'Test cars',
      pqLow: 900,
      pqHigh: 1300,
      PIN: 'MHVG'
    }
  ];

  useEffect(() => {

    let cancel;

    setLoading(true);
    setError(false);

    const params = {
      pageNumber: pageNumber + 1,
      pageSize
    }

    if (searchString) {
      params.searchString = searchString;
    }

    if (order) {
      params.order = order.toUpperCase();
    }

    if (orderBy) {
      params.orderBy = orderBy
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/dealers`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {

      setLoading(false);
      setDealers(response?.data?.list || [])
      setTotal(response?.data?.list?.length || 0)

    }).catch(error => {

      setLoading(false);

      if (axios.isCancel(error))
        return;

      if (error.response) {
        setError(true);
      }

    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, order, orderBy])

  return { loading, error, dealers, total }
}