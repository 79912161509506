import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
import moment from 'moment'
import { DealerContext } from '../../shared/DealerContext'

const tmpOffers = [
    {
        id: 0,
        image: 'https://source.unsplash.com/hb8fkAWx9gc/150x150',
        headline: 'Renault Kwid',
        startDate: moment(new Date()).format('l'),
        endDate: moment(new Date()).format('l'),
        price: 637800,
        monthlyInstallment: 8799,
        term: 72,
        rate: 6.38,
        deposit: 30,
        balloon: 10,
        variant: 'KWID MY19 1.0 DYNAMIQUE (ABS)',
        active: true
    },
    {
        id: 1,
        image: 'https://source.unsplash.com/hb8fkAWx9gc/150x150',
        headline: 'Renault Triber',
        startDate: moment(new Date()).format('l'),
        endDate: moment(new Date()).format('l'),
        price: 66000,
        monthlyInstallment: 7000,
        term: 35,
        rate: 6.18,
        deposit: 10,
        balloon: 10,
        variant: 'TRIBER 1.0 EXPRESSION',
        active: true
    },
    {
        id: 2,
        image: 'https://source.unsplash.com/hb8fkAWx9gc/150x150',
        headline: 'Renault Sandero',
        startDate: moment(new Date()).format('l'),
        endDate: moment(new Date()).format('l'),
        price: 80000,
        monthlyInstallment: 2233,
        term: 72,
        rate: 4.18,
        deposit: 22,
        balloon: 13,
        variant: 'SANDERO 0.9 TURBO EXPRESSION A/C',
        active: false
    }
]

export default function OfferFilter(searchString, offerStatus, pageNumber, pageSize, order, orderBy) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [offers, setOffers] = useState([])
    const [total, setTotal] = useState(0)
    const { dealerId } = useContext(DealerContext);

    useEffect(() => {
        setOffers([])
    }, [searchString, offerStatus, pageNumber, pageSize, order, orderBy, dealerId])

    useEffect(() => {
        let cancel
        setLoading(true)
        setError(false)
        const params = {
            pageNumber: pageNumber + 1,
            pageSize,
            dealerId: dealerId
        }
        if (searchString) { params.searchHeadline = searchString }
        if (offerStatus) { params.status = offerStatus }
        // if (order) {params.order = order.toUpperCase()}
        // if (orderBy) {params.orderBy = orderBy}
        axios({
            method: 'GET',
            url: `${BASE_URI}/Offers`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setOffers(response.data?.list)
                setTotal(response.data?.total)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })
        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, offerStatus, pageNumber, pageSize, order, orderBy, dealerId])

    return { loading, error, offers, offerStatus, total }
}