import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, CircularProgress, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from 'yup';
import { useSnackbar } from "notistack";
import { BASE_URI } from "../../../shared/Constants";
import { useStyles } from '../../../shared/CommonStyles';
import { GetErrorMessage } from '../../../shared/CommonFunctions';

export default function VehicleLibraryMakeForm() {
    
    const classes = useStyles();
    const history = useHistory();
    const { id, action } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const isNewForm = (id <= 0 || action.trim().toLowerCase() === "add");
    const newFormValues = {
        name: ""
    };
    const validationSchema = Yup.object({
        name: Yup.string().required("Make name is required")
    });

    const [ loading, setLoading ] = useState(true);
    const [ initialFormValues, setInitialFormValues ] = useState(newFormValues);

    useEffect(() => {
    
        if (isNewForm) {
            setLoading(false);
            return;
        }

        Promise.allSettled([
            LoadMakeDetails()
        ])
        .catch((error) => {

			enqueueSnackbar(GetErrorMessage(error, "An unexpected error occurred while loading the master make details"), { variant: 'error' });

        }).finally(() => {

            setLoading(false);

        });
        
        
    }, []);

    function LoadMakeDetails() {

        return new Promise((res, rej) => {

            axios({
                method: 'GET',
                url: `${BASE_URI}/MasterMake/${id}`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                if (!response?.data) {

                    enqueueSnackbar("No master make details could be found", { variant: 'warning' });
                    res(null);
                    return;

                }

                let makeDetails = {
                    name: response?.data?.name
                };
                
                setInitialFormValues(makeDetails);
                res(makeDetails);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the master make details"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function GoBack() {

        history.replace("/VehicleLibrary/Makes");

    }

    function SelectMake(make) {

        history.replace(`/VehicleLibrary/Makes/${make.id}/Models`, { makeName: make.name });

    }

    function SubmitForm(formValues, formActions) {
        
        let make = {
            name: formValues.name
        }

        if (isNewForm) {
            AddNewMake(make, formActions.setSubmitting);
        } else {
            EditMake(make, formActions.setSubmitting);
        }

    }

    function AddNewMake(make, setSubmitting) {
        
        axios({
            method: 'POST',
            url: `${BASE_URI}/MasterMake`,
            data: make,
            cancelToken: axios.CancelToken.source().token
        }).then(newMakeResponse => {
            
            let newMake = newMakeResponse?.data;

            if (!newMake?.id || newMake.id <= 0) {

                enqueueSnackbar(`The new make did not save correctly, please try again`, { variant: 'error' });
                DelayNavigation(GoBack);

            } else {

                enqueueSnackbar(`Successfully saved the new make. Selecting it now`, { variant: 'success' });
                DelayNavigation(() => {
                    SelectMake(newMake);
                });

            }

        }).catch(error => {

            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar(GetErrorMessage(error, "Failed to save the new make"), { variant: 'error' });
            
        }).finally(() => {

            setSubmitting(false);

        });

    }
    
    function EditMake(make, setSubmitting) {

        axios({
            method: 'PUT',
            url: `${BASE_URI}/MasterMake/${id}`,
            data: make,
            cancelToken: axios.CancelToken.source().token
        }).then(makeResponse => {

            let make = makeResponse?.data;

            if (!make || make.name === initialFormValues.name) {

                enqueueSnackbar(`The make did not save correctly, please try again`, { variant: 'error' });
                DelayNavigation(GoBack);

            } else {

                enqueueSnackbar(`Successfully saved the make. Selecting it now`, { variant: 'success' });
                DelayNavigation(() => {
                    SelectMake(make);
                });

            }

        }).catch(error => {

            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar(GetErrorMessage(error, "Failed to save the make"), { variant: 'error' });
            
        }).finally(() => {

            setSubmitting(false);

        });

    }
    
    function DelayNavigation(callback) {

        setTimeout(() => {
            callback();
        }, 1000);

    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                <Grid item xs={12}>
                    <Typography variant="h4">
                        {isNewForm ? "New" : "Edit"} Master Make
                    </Typography>
                </Grid>

                {
                    loading &&
                    <Grid item xs={12} container direction="row" justify="center">
                        <CircularProgress/>
                    </Grid>
                }
                {
                    !loading &&
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initialFormValues}
                            isInitialValid={false}
                            enableReinitialize={true}
                            onSubmit={SubmitForm}
                            validationSchema={validationSchema}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    isSubmitting
                                } = props;
                                return (
                                    <Form noValidate>
                                        <Grid container spacing={2} direction="row">
                
                                            <Grid item xs={12} md={6} lg={3}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="name"
                                                    label="Make Name"
                                                    disabled={isSubmitting}
                                                    value={values.name}
                                                    helperText={errors.name && touched.name ? errors.name : ''}
                                                    error={errors.name && touched.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Grid>
                
                                            <Grid item xs={12} container spacing={2} direction="row" justify='flex-end'>
                                                <Grid item xs={12} sm={2}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={GoBack}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={12} sm={2}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Grid>
                }

            </Grid>
        </Paper>
    );
}