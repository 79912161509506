import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    Divider,
    Paper,
    Fab
} from '@material-ui/core'
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from "react-router-dom";
import { BASE_URI, LeadTypeEnum } from '../../shared/Constants'
import { useSnackbar } from "notistack";
import ImageUploadInput from '../../shared/ImageUploadFormInput'
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    featureInputContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    title: {
        background: '#bdbfc1',
        color: 'white',
        padding: "5px 30px",

        width: "fit-content",
        borderBottom: '2px red solid',

    },
    tr: {


        padding: "5px 30px",


    },
    td: {


        padding: "5px 10px",


    },

    btn: {
        color: "#fff",
        background: '#3f51b5',
        padding: "8px 20px",
        border: "none",
        margin: '5px',
        borderRadius: '3px'
    }
}));

export default function DealerSettingsAndFunctions({ history, props }) {

    const initialValues = {
        primaryColor: '',
        secondaryColor: '',
        accentColor: '',
        favIcon: '',
        logo: '',
        mobileLogo: '',
    }


    const { id } = useParams();
    const [formFields, setFormFields] = useState(initialValues);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    function onSubmit(fields) {

        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            axios.put(
                `${BASE_URI}/DealerWebsites/UpdateColors/${id}`,
                {
                    ...fields
                },
                {
                    cancelToken: source.token,
                }
            ).then(result => {
                enqueueSnackbar("Updated Website Settings", { variant: 'success' });
            });

        } catch (error) {
            enqueueSnackbar("Update failed", { variant: 'error' });
        }
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const getWebsiteColors = async () => {
            try {
                const result = await axios({
                    method: 'GET',
                    url: `${BASE_URI}/DealerWebsites/Colors/${id}`,
                    cancelToken: source.token
                })

                if (result?.data) {
                    const colorsObject = result?.data
                    setFormFields({
                        ...formFields, primaryColor: colorsObject?.primaryColor,
                        favIcon: colorsObject?.favIcon,
                        logo: colorsObject?.logo,
                        mobileLogo: colorsObject?.mobileLogo,
                        secondaryColor: colorsObject?.secondaryColor,
                        accentColor: colorsObject?.accentColor
                    })
                }

            } catch (error) {

            }
        }

        getWebsiteColors();

    }, []);


    const handleChangebaseColor = (value) => {
        setFormFields({ ...formFields, primaryColor: value });
    }

    const handleChangesecondaryColor = (value) => {
        setFormFields({ ...formFields, secondaryColor: value });
    }
    const handleChangeaccentColor = (value) => {
        setFormFields({ ...formFields, accentColor: value });
    }
    return (
        <Paper className={classes.paper}>
            <Formik
                initialValues={formFields}
                onSubmit={onSubmit}
                enableReinitialize={true}
                /*validationSchema={Yup.object().shape({
                    dealerId: Yup.string().required("Dealership Name is required"),
                    importType: Yup.number().required("Importer is required")
                })}*/>
                {(props) => {
                    const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset } = props;

                    //showErrors(touched)

                    return (
                        <Form>
                            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                                <Grid item xs={12} md={6}>
                                    <Grid item xs={12}>

                                        <Typography variant="h6" gutterBottom>
                                            WEBSITE COLORS
                                        </Typography>
                                    </Grid>
                                    <Typography variant="h6" gutterBottom>
                                        Hex
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="primaryColor"
                                        //required
                                        id="primaryColor"
                                        label="Primary Color"
                                        type="text"
                                        value={formFields?.primaryColor}
                                        onChange={(e) => handleChangebaseColor(e.target.value)}
                                    >


                                    </TextField>
                                    <Typography variant="h6" gutterBottom>
                                        Hex
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="secondaryColor"
                                        //required
                                        id="secondaryColor"
                                        label="Secondary Color"
                                        type="text"
                                        value={formFields?.secondaryColor}
                                        onChange={(e) => handleChangesecondaryColor(e.target.value)}
                                    >


                                    </TextField>
                                    <Typography variant="h6" gutterBottom>
                                        Hex
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        name="accentColor"
                                        //required
                                        id="accentColor"
                                        label="Accent Color"
                                        type="text"
                                        value={formFields?.accentColor}
                                        onChange={(e) => handleChangeaccentColor(e.target.value)}
                                    >


                                    </TextField>


                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ImageUploadInput title='FavIcon'
                                        fieldName={'favIcon'}
                                        value={values.favIcon}
                                        error={errors.favIcon && touched.favicon} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ImageUploadInput title='Logo'
                                        fieldName={'logo'}
                                        value={values.logo}
                                        error={errors.logo && touched.logo} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ImageUploadInput title='Mobile Logo'
                                        fieldName={'mobileLogo'}
                                        value={values.mobileLogo}
                                        error={errors.mobileLogo && touched.mobileLogo} />
                                </Grid>

                                <Grid item container
                                    justify='flex-end' xs={12}>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                </Grid>


                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }
                }
            </Formik>
        </Paper>
    )
}