import React, { useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SMSController from "./SMSController";
import Divider from "@material-ui/core/Divider";
import EmailIcon from "@material-ui/icons/Email";
import ClearIcon from "@material-ui/icons/Clear";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    marginTop: "1rem",
  },
  stepPadding: {
    marginTop: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  stepPaddingButton: {
    marginLeft: "2rem",
  },
  delivered: {
    color: "#8bc34a",
    marginRight: 10,
  },
  failed: {
    color: "#ff5722",
    marginRight: 10,
  },
  card: {
    marginBottom: 10,
  },
}));

export default function SMSTabContainer() {
  const classes = useStyles();
  const { state, tab, id } = useParams();
  const { data } = SMSController(id);

  return (
    <React.Fragment>
      <Card className={classes.stepPadding}>
        <CardContent>
          {data.map((el) => (
            <div>
              <Card className={classes.heading}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.heading}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {el.isSuccess && (
                        <div className={classes.delivered}>
                          <EmailIcon color="inherit" />
                        </div>
                      )}
                      {!el.isSuccess && (
                        <div className={classes.failed}>
                          <ClearIcon color="inherit" />
                        </div>
                      )}

                      {el.subject}
                    </div>

                    <Typography variant="subtitle1" gutterBottom>
                      <Divider variant="inset" />
                      {el.body}
                    </Typography>
                  </Typography>
                </CardContent>
              </Card>
              <Divider variant="inset" />
            </div>
          ))}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
