import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useParams, useHistory} from "react-router-dom";
import AgentWorkBreakdownContainer from "./AgentWorkBreakdownContainer";
import AgentAppsSubmittedContainer from "./AgentAppsSubmittedContainer";
import AgentMonitorContainer from "./AgentMonitorContainer";
import LeadAnalysisContainer from "./LeadAnalysisContainer";
import LeadSourcesContainer from "./LeadSourcesContainer";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#3378af",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export default function ReportingContainer() {
    const classes = useStyles();
    const history = useHistory();

    const [selectedTab, setSelectedTab] = React.useState(0);

    const tabNameToIndex = {
        0: "agentWorkBreakDown",
        1: "leadAnalysis",
        2: "leadSources",
        3: "agentAppsSubmitted",
        4: "agentMonitor"
    };

    const indexToTabName = {
        agentWorkBreakDown: 0,
        leadAnalysis: 1,
        leadSources: 2,
        agentAppsSubmitted: 3,
        agentMonitor: 4
    };

    const handleChange = (event, newValue) => {
        history.push(
            `/reporting/${tabNameToIndex[newValue]}`
        );
        setSelectedTab(newValue);
    };

    return (
        <>
            <AppBar position="relative" className={classes.root}>
                <Tabs
                    variant="scrollable"
                    value={selectedTab != null ? selectedTab : setSelectedTab(0)}
                    onChange={handleChange}
                    textColor="inherit"
                >
                    <Tab label="Agent Work Breakdown"/>
                    <Tab label="Lead Analysis"/>
                    <Tab label="Lead Sources"/>
                    <Tab label="Agent Apps Submitted" />
                    <Tab label="Agent Monitor" />
                </Tabs>
            </AppBar>
            {selectedTab === 0 && <AgentWorkBreakdownContainer/>}
            {selectedTab === 1 && <LeadAnalysisContainer/>}
            {selectedTab === 2 && <LeadSourcesContainer/>}
            {selectedTab === 3 && <AgentAppsSubmittedContainer />}
            {selectedTab === 4 && <AgentMonitorContainer />}

        </>
    );
}
