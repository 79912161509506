import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form, Field} from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SelectInput from "../../../AutocompleteContainer/AutoCompleteComponent";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Typography} from "@material-ui/core";
import {COUNTRIES} from "../../../../shared/SelectorValues";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
}));

export default function PassportTabContainer() {
    const classes = useStyles();

    const values = {
        passportNumber: "",
        country: "",
        issueDate: "",
        expiryDate: "",
        permitType: "",
        permitNumber: "",
        permitIssueDate: "",
        permitExpiryDate: "",
    };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={values}
        isInitialValid={false}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            console.log(values);
            actions.setSubmitting(true);
          }, 100);
        }}
      >
        {(props) => {
          return (
            <Form>
              <Card className={classes.stepPadding}>
                <CardContent>
                  <Typography variant="h6" style={{ marginBottom: "2.5rem" }}>
                    Passport Details
                  </Typography>
                  <Grid container direction="row">
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <Field
                        component={SelectInput}
                        props={props}
                        Label={"Country"}
                        fieldName={"country"}
                        data={COUNTRIES}
                        helperText={
                          props.touched.country ? props.errors.country : "Start Typing"
                        }
                        error={
                          props.touched.country && Boolean(props.errors.country)
                        }
                        value={{
                          name: props.values.country.toString(),
                          id: props.values.country.toString(),
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <TextField
                        id="passportNumber"
                        name="passportNumber"
                        label="Passport Number"
                        value={props.values.passportNumber}
                        helperText={
                          props.touched.passportNumber
                            ? props.errors.passportNumber
                            : ""
                        }
                        error={
                          props.touched.passportNumber &&
                          Boolean(props.errors.passportNumber)
                        }
                        onChange={(event) => {
                          props.handleChange(event);
                          props.setFieldTouched("passportNumber", true, false);
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <TextField
                        id="issueDate"
                        name="issueDate"
                        label="Issue Date"
                        value={props.values.issueDate}
                        helperText={
                          props.touched.issueDate ? props.errors.issueDate : ""
                        }
                        error={
                          props.touched.issueDate &&
                          Boolean(props.errors.issueDate)
                        }
                        onChange={(event) => {
                          props.handleChange(event);
                          props.setFieldTouched("issueDate", true, false);
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <TextField
                        id="expiryDate"
                        name="expiryDate"
                        label="Expiry Date"
                        value={props.values.expiryDate}
                        helperText={
                          props.touched.expiryDate
                            ? props.errors.expiryDate
                            : ""
                        }
                        error={
                          props.touched.expiryDate &&
                          Boolean(props.errors.expiryDate)
                        }
                        onChange={(event) => {
                          props.handleChange(event);
                          props.setFieldTouched("expiryDate", true, false);
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <TextField
                        id="permitType"
                        name="permitType"
                        label="Permit Type "
                        value={props.values.permitType}
                        helperText={
                          props.touched.permitType
                            ? props.errors.permitType
                            : ""
                        }
                        error={
                          props.touched.permitType &&
                          Boolean(props.errors.permitType)
                        }
                        onChange={(event) => {
                          props.handleChange(event);
                          props.setFieldTouched("permitType", true, false);
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <TextField
                        id="permitNumber"
                        name="permitNumber"
                        label="Permit Number "
                        value={props.values.permitNumber}
                        helperText={
                          props.touched.permitNumber
                            ? props.errors.permitNumber
                            : ""
                        }
                        error={
                          props.touched.permitNumber &&
                          Boolean(props.errors.permitNumber)
                        }
                        onChange={(event) => {
                          props.handleChange(event);
                          props.setFieldTouched("permitNumber", true, false);
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <TextField
                        id="permitIssueDate"
                        name="permitIssueDate"
                        label="Permit Issue Date "
                        value={props.values.permitIssueDate}
                        helperText={
                          props.touched.permitIssueDate
                            ? props.errors.permitIssueDate
                            : ""
                        }
                        error={
                          props.touched.permitIssueDate &&
                          Boolean(props.errors.permitIssueDate)
                        }
                        onChange={(event) => {
                          props.handleChange(event);
                          props.setFieldTouched("permitIssueDate", true, false);
                        }}
                      />
                    </Grid>
                    <Grid item className={classes.stepPadding} xs={12} md={2}>
                      <TextField
                        id="permitExpiryDate"
                        name="permitExpiryDate"
                        label="Permit Expiry Date "
                        value={props.values.permitExpiryDate}
                        helperText={
                          props.touched.permitExpiryDate
                            ? props.errors.permitExpiryDate
                            : ""
                        }
                        error={
                          props.touched.permitExpiryDate &&
                          Boolean(props.errors.permitExpiryDate)
                        }
                        onChange={(event) => {
                          props.handleChange(event);
                          props.setFieldTouched(
                            "permitExpiryDate",
                            true,
                            false
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Grid item className={classes.stepPadding}>
                <Button
                  variant="contained"
                  style={{ margin: "1rem" }}
                  startIcon={<AddIcon />}
                  color="primary"
                  type="submit"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: "1rem" }}
                  startIcon={<AddIcon />}
                  color="primary"
                  onClick={props.resetForm}
                >
                  Reset
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
