import React, {useState, createContext,} from 'react';
import VoiceMessageContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/VoiceMessageContainer";
import AppointmentContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/AppointmentContainer";
import ReminderContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/ReminderContainer";
import MarkAsLostContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/MarkAsLostContainer";
import MarkAsNewContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/MarkAsNewContainer";
import MarkAsContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/MarkAsContainer";
import IDContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/IDContainer";
import PreQualifiedEditContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/PreQualifiedEditContainer";
import NewFinanceHouseContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/NewFinanceHouseContainer";
import LeadClosedContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/LeadClosedContainer";
import ApplicationApprovedContainer
    from "../../components/LeadsContainer/LeadDetails/ActionTab/ApplicationApprovedContainer";
import DocumentsInContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/DocumentsInContainer";
import BankValidatedContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/BankValidatedContainer";
import OfferToPurchaseContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/OfferToPurchaseContainer";
import InvoicedContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/InvoicedContainer";
import DeliveredContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/DeliveredContainer";
import BankSubmittedContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/BankSubmittedContainer";
import FinanceLinkContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/FinanceLinkContainer";

export const ActionContext = createContext();

export const ActionProvider = ({children}) => {

    const [tabContainerList, setTabContainerList] = useState([
        "VoiceMessageContainer",
        "AppointmentContainer",
        "ReminderContainer",
        "MarkAsLostContainer",
        "MarkAsContainer",
    ]);

    const getTabs = (name) => {
        switch (name) {
            case("VoiceMessageContainer"):
                return <VoiceMessageContainer/>
                break;
            case("AppointmentContainer"):
                return <AppointmentContainer/>
                break;
            case ("ReminderContainer"):
                return <ReminderContainer />
                break;
            case("MarkAsLostContainer"):
                return <MarkAsLostContainer/>
                break;
            case ("MarkAsNewContainer"):
                return <MarkAsNewContainer />
                break;
            case("MarkAsContainer"):
                return <MarkAsContainer/>
                break;
            case ("FinanceLinkContainer"):
                return <FinanceLinkContainer />
                break;
            case("IDContainer"):
                return <IDContainer/>
                break;
            case("PreQualifiedEditContainer"):
                return <PreQualifiedEditContainer/>
                break;
            case("NewFinanceHouseContainer"):
                return <></> // <NewFinanceHouseContainer/>
                break;
            case("LeadClosedContainer"):
                return <LeadClosedContainer/>
                break;
            case("ApplicationApprovedContainer"):
                return <ApplicationApprovedContainer/>
                break;
            case("DocumentsInContainer"):
                return <DocumentsInContainer/>
                break;
            case("BankValidatedContainer"):
                return <BankValidatedContainer/>
                break;
            case("OfferToPurchaseContainer"):
                return <OfferToPurchaseContainer/>
                break;
            case("InvoicedContainer"):
                return <InvoicedContainer/>
                break;
            case("DeliveredContainer"):
                return <DeliveredContainer/>
                break;
            case("BankSubmittedContainer"):
                return <BankSubmittedContainer/>
                break;
            default:
                return <VoiceMessageContainer/>
        }
    }

    const getNewLead = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "FinanceLinkContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "MarkAsLostContainer",
            "IDContainer",
        ]);
    }

    const getPrequalifiedSubmitted = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "FinanceLinkContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "MarkAsLostContainer",
            "MarkAsNewContainer",
            "IDContainer",
        ]);
    }

    const getPrequalifiedApproved = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "FinanceLinkContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "PreQualifiedEditContainer",
            "NewFinanceHouseContainer",
            "MarkAsLostContainer",
        ]);
    }

    const getPrequalifiedDeclined = () => {
        setTabContainerList([
            "LeadClosedContainer",
        ]);
    }

    const getApplicationSubmitted = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "MarkAsLostContainer",
            "MarkAsContainer",
        ]);
    }

    const getApplicationApproved = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "MarkAsLostContainer",
            "ApplicationApprovedContainer"

        ]);
    }

    const getBankSubmitted = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "MarkAsLostContainer",
            "BankSubmittedContainer",
        ]);
    }

    const getDocumentsIn = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "MarkAsLostContainer",
            "DocumentsInContainer",
        ]);
    }

    const getBankValidated = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "MarkAsLostContainer",
            "BankValidatedContainer",
            "BankSubmittedContainer"
        ]);
    }

    const getOfferToPurchase = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "MarkAsLostContainer",
            "OfferToPurchaseContainer",
            "ApplicationApprovedContainer"
        ]);
    }

    const getInvoiced = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "InvoicedContainer",
        ]);
    }

    const getDelivered = () => {
        setTabContainerList([
            "VoiceMessageContainer",
            "AppointmentContainer",
            "ReminderContainer",
            "DeliveredContainer",
        ]);
    }

    const getPaidOut = () => {
        setTabContainerList([

        ]);
    }

    const getLost = () => {
        setTabContainerList([
            "IDContainer",
            "LeadClosedContainer",
        ]);
    }

    return (
        <ActionContext.Provider value={{
            tabContainerList,
            getTabs,
            getNewLead,
            getPrequalifiedApproved,
            getPrequalifiedDeclined,
            getApplicationSubmitted,
            getApplicationApproved,
            getDocumentsIn,
            getBankValidated,
            getOfferToPurchase,
            getInvoiced,
            getDelivered,
            getLost,
            getBankSubmitted,
            getPaidOut,
            getPrequalifiedSubmitted
        }}>
            {children}
        </ActionContext.Provider>
    )
}