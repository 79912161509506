import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import axios from 'axios'
import { useSnackbar } from "notistack";
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext";
import { BASE_URI } from '../../../../shared/Constants'

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    card: {
        background: "lightblue",
    },
}));

export default function BookServiceTabContainer() {
    
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [service, setService] = useState({})

    let { lead } = useContext(LeadMainContext);

    useEffect(() => {
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        
        axios({
            method: 'GET',
            url: `${BASE_URI}/Leads/ServiceInfo/${lead.id}`,
            cancelToken: source.token
        }).then((response) => {

            let serviceDate = new Date(response.data.date);
            if (serviceDate.getFullYear() < new Date().getFullYear()) {
                response.data.date = null;
            }

            setService(response.data);

        }).catch(error => {

            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar("Failed to get the service information: " + error, { variant: 'error' });

        });
    }, []);

    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="h6">
                                    Current Service Date:
                                </Typography>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography variant="caption">
                                            {service.Date ?? "None Selected"}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                            <Typography variant="h6">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    Current Vehicle Registration:

                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography variant="caption">
                                                {service.regNo}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
