import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { DealerContext } from '../../../../shared/DealerContext'
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"
import axios from 'axios';
import { BASE_URI } from "../../../../shared/Constants";
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "1rem",
    },
    buttonPre: {
        marginTop: "2rem",
        backgroundColor: "#e7af18"
    },
    buttonCash: {
        marginTop: "2rem",
        backgroundColor: "green",
        color: "white"
    },
}));

export default function IDContainer() {

    const classes = useStyles();
    const { id } = useParams();
    const { reloadState } = useContext(LeadMainContext);
    const { enqueueSnackbar } = useSnackbar();

    const [ pqResult, setPqResult ] = useState(null);
    const [ isPqSubmitting, setIsPqSubmitting ] = useState(false);

    const values = {
        idNumber: "",
    };

    const idRegex = `(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`
    
    const validationSchema = Yup.object({
        idNumber: Yup.string("Enter your SA Id Number")
            .required("ID number is required")
            .matches(idRegex, "ID number is not valid"),
    });

    const preQualify = (idNumber, setSubmitting) => {

        let params = {
			idNumber: idNumber,
			leadId: parseInt(id),
			netSalary: 0,
			totalExpenses: 0,
			desiredAmount: 0,
			triggeredByAgent: true
		};

        axios({
            method: 'POST',
            url: `${BASE_URI}/applications/pq`,
            data: params,
            cancelToken: axios.CancelToken.source().token
        }).then((response) => {
            
            if (!response?.data || response.data.includes("decline")) {
                setPqResult(false);
            } else {
                setPqResult(true);
            }
        
            setSubmitting(false);
            reloadState();

            enqueueSnackbar(`PQ submission complete`, { variant: 'success' });

        }).catch(error => {

            setSubmitting(false);

            if (axios.isCancel(error)) return

            enqueueSnackbar(`Unable to complete request. ${error}`, { variant: 'error' });

        })
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={values}
                isInitialValid={false}
                onSubmit={(values, actions) => {
                    
                    if (isPqSubmitting) {
                        preQualify(values.idNumber, actions.setSubmitting);
                    }
                    
                }}
                validationSchema={validationSchema}
            >
                {(props) => {
                    return (
                        <Form>
                            <Card className={classes.stepPadding}>
                                <CardContent>
                                    <Grid container spacing={4} >

                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                ID Number
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={4} lg={3}>
                                            <TextField
                                                fullWidth
                                                id="idNumber"
                                                name="idNumber"
                                                label="ID Number"
                                                disabled={props.isSubmitting}
                                                value={props.values.idNumber}
                                                helperText={props.touched.idNumber ? props.errors.idNumber : ""}
                                                error={props.touched.idNumber && Boolean(props.errors.idNumber)}
                                                onChange={(event) => {
                                                    props.handleChange(event);
                                                    props.setFieldTouched("idNumber", true, false);
                                                }}
                                            />

                                            <Typography variant="body1">
                                                {
                                                    pqResult === true &&
                                                    <span style={{ color: 'green' }}>PQ Approved</span>
                                                }
                                                {
                                                    pqResult === false &&
                                                    <span style={{ color: 'red' }}>PQ Declined</span>
                                                }
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={4} lg={3}>
                                            <Button
                                                onClick={() => { setIsPqSubmitting(true) }}
                                                className={classes.buttonPre}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={!props.isValid || props.isSubmitting}
                                            >
                                                Pre-Qualify
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12} md={4} lg={3}>
                                            <Button
                                                className={classes.buttonCash}
                                                variant="contained"
                                                color="secondary"
                                                type="submit"
                                                disabled={!props.isValid || props.isSubmitting}
                                            >
                                                Cash Deal
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
