import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Toolbar,
    MenuItem
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import { Switch } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { BASE_URI } from '../../shared/Constants'
import axios from 'axios'
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { CheckboxWithLabel } from 'formik-material-ui';

import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext'
import Hidden from '@material-ui/core/Hidden';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';


const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    chipsHolder:{
        background:'#e0e0e0',
        margin: 2,
        borderRadius: '30px',
    padding: '0px 14px',
},
chipsHolderRemove:{
    color:'red',

}
}));


function getStyles(arrayItem, selectArray, theme) {
    return {
        fontWeight:
            selectArray.indexOf(arrayItem) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function UserAdminForm({ history, match }) {
    const classes = useStyles();
    const { id } = match.params;
    const isAddMode = !id;
    const theme = useTheme();
    const mountedRef = useRef(true)
    const { enqueueSnackbar } = useSnackbar();
    const { showLoading, hideLoading } = useContext(LoadingContext)
    const [dealerArrSelected, setDealerArrSelected] = useState([])
    
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        active: false,
        password: '',
        confirmPassword: '',
        roles: [],
        dealers: dealerArrSelected || [],
        fiNumber: '',
        isAddMode: isAddMode
    }
    const [formFields, setFormFields] = useState(initialValues)
    const [roleArr, setRoleArr] = useState([])
    const [dealerArr, setDealerArr] = useState([])

   
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getUser = async () => {
            try {
                showLoading()
                const result = await axios.get(`${BASE_URI}/UserConfig/${id}`, {
                    cancelToken: source.token
                })
                let formData = result.data
                formData.roles = result.data.roles.map((role) => role.name)
                formData.isAddMode = isAddMode
                setDealerArrSelected(formData.dealers)
             
                setFormFields(formData)
            } catch (error) {
                enqueueSnackbar("Unable to get user details", { variant: 'error' });
                //history.push('..')
            }
        }

        const getDealers = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/dealers `, {
                    cancelToken: source.token
                })

                setDealerArr(result.data.list)
                return result.data.list
            } catch (error) {
                if (axios.isCancel(error)) return
                enqueueSnackbar("Unable to load dealerships", { variant: 'error' });
            }
        }

        getDealers().then(() => {
            if (!mountedRef.current) return null
        })


        const getRoles = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/Admin/Roles`, {
                    cancelToken: source.token
                })
                setRoleArr(result.data)
            } catch (error) {
                enqueueSnackbar('Unable to retrieve user roles', { variant: 'error' });
            }
        }

        getRoles().then(() => {
            if (!mountedRef.current) return null
        })

        if (!isAddMode) {
            getUser().then(() => {
                hideLoading()
                if (!mountedRef.current) return null
            })
        }
        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const editUser = async (id, fields, setSubmitting) => {
        console.log("tmpFields",fields)
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            let tmpFields = { ...fields }
            tmpFields.userId = parseInt(id, 10)
            const result = await axios.put(`${BASE_URI}/UserConfig`, tmpFields, {
                cancelToken: source.token
            })
            enqueueSnackbar(`Successfully updated ${fields.firstName} ${fields.lastName}`, { variant: 'success' });
            return result;
        } catch (error) {
            enqueueSnackbar(`Unable to update ${fields.firstName} ${fields.lastName}`, { variant: 'error' });
        } finally {
            hideLoading()
            setSubmitting(false)
        }
    }

    const createUser = async (fields, setSubmitting, resetForm) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.post(` ${BASE_URI}/UserConfig`, fields, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                },
                cancelToken: source.token
            })
            enqueueSnackbar(`Successfully added ${fields.firstName} ${fields.lastName}`, { variant: 'success' });
            resetForm()
            history.push('.');
            return result;
        } catch (error) {
            if (error.response && error.response.data.errors) {
                let errorObj = error.response.data.errors
                enqueueSnackbar(`${errorObj[Object.keys(errorObj)[0]]}`, { variant: 'error' });
            } else {
                enqueueSnackbar(`Unable to add ${fields.firstName} ${fields.lastName}`, { variant: 'error' });
            }
        } finally {
            hideLoading()
            setSubmitting(false)
        }
    }

    const resetPassword = async () => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            const result = await axios.get(`${BASE_URI}/user/ResetPassword/${formFields.email}/true`, {
                cancelToken: source.token
            })

            enqueueSnackbar("Reset password link sent", { variant: 'success' });
            
        } catch (error) {
            enqueueSnackbar('Unable to reset password', { variant: 'error' });
        }
    }

    function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
        let tmpFields = { ...fields }
        tmpFields.roles = roleArr.filter(role => tmpFields.roles.includes(role.name));
        tmpFields.dealers = fields.dealers.map((dealer) => ({ id: dealer.id, name: dealer.name }))
        setStatus();
        showLoading()
        if (isAddMode) {
            createUser(tmpFields, setSubmitting, resetForm);
        } else {
            editUser(id, tmpFields, setSubmitting);
        }
    }

    // const showErrors = (errors) => {
    //   console.log("Errors")
    //   console.log(errors)
    // }

    const handleDealerRemove = (val,values)=>{
        setDealerArrSelected(dealerArrSelected.filter((d)=> d.id !== val.id))
        values.dealers = values.dealers.filter((d)=> d.id !== val.id)
       
 
    }

    
    
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">
                <Grid item xs={12}>
                    <Formik
                        initialValues={formFields}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                            firstName: Yup.string().required('First Name is required'),
                            lastName: Yup.string().required('Last Name is required'),
                            email: Yup.string()
                                .email('Email is invalid')
                                .required('Email is required'),
                            password: Yup.string()
                                .when('isAddMode', {
                                    is: true,
                                    then: Yup.string().required('Password is required')
                                        .min(8, 'Password must be at least 8 characters')
                                        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                            'Password requires at least one lowercase, one uppercase letter and one non-alphanumeric letter')
                                }),
                            confirmPassword: Yup.string()
                                .when('password', (password, schema) => {
                                    if (password) return schema.required('Confirm Password is required');
                                })
                                .oneOf([Yup.ref('password')], 'Passwords must match'),
                            dealers: Yup.array().min(1, 'User must belong to atleast one dealership'),
                            roles: Yup.array().min(1, 'Atleast one role is required'),
                            fiNumber: Yup.string().notRequired().test('oneOfRequired', 'A user with the role Finance MUST have an F&I Number', function (value) {
                                return ((this.parent.fiNumber === undefined && !this.parent.roles.includes("Finance")) || this.parent.fiNumber !== undefined);
                            }),
                        })}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                handleReset
                            } = props

                            // showErrors(errors)

                            return (
                                <Form>
                                    <Toolbar disableGutters>
                                        <Typography variant="h6" style={{ flex: '1 1' }} component="div" gutterBottom>
                                            {isAddMode ? 'Add' : 'Edit'} User
                    </Typography>
                                        <FormControlLabel
                                            control={
                                                <Field component={Switch}
                                                    color="primary"
                                                    type="checkbox"
                                                    name="active" />
                                            }
                                            label="Active"
                                        />
                                    </Toolbar>
                                    <Grid spacing={4} container direction="row">
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="firstName"
                                                id="firstName"
                                                required
                                                label="First Name"
                                                value={values.firstName}
                                                type="text"
                                                helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                                                error={errors.firstName && touched.firstName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="lastName"
                                                id="lastName"
                                                required
                                                label="Last Name"
                                                value={values.lastName}
                                                type="text"
                                                helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                                                error={errors.lastName && touched.lastName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="email"
                                                id="email"
                                                required
                                                label="Email"
                                                value={values.email}
                                                type="email"
                                                helperText={errors.email && touched.email ? errors.email : ''}
                                                error={errors.email && touched.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>

                                        <Hidden xsDown>
                                            <Grid item md={6}></Grid>
                                        </Hidden>

                                        {
                                            isAddMode &&
                                            <React.Fragment>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        name="password"
                                                        id="password"
                                                        required
                                                        label="Password"
                                                        value={values.password}
                                                        type="password"
                                                        helperText={errors.password && touched.password ? errors.password : ''}
                                                        error={errors.password && touched.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        name="confirmPassword"
                                                        id="confirmPassword"
                                                        required
                                                        label="Confirm Password"
                                                        value={values.confirmPassword}
                                                        type="password"
                                                        helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ''}
                                                        error={errors.confirmPassword && touched.confirmPassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        }

                                        <Grid item xs={12} md={6}>
                                            <FormControl component="fieldset"
                                                style={{ width: '100%' }}>
                                                <FormLabel component="legend">Roles</FormLabel>
                                                <FormGroup style={{ width: '100%' }}>
                                                    <GridList cellHeight={30} cols={3}>
                                                        {roleArr.map((role) => (
                                                            <GridListTile key={role.id} cols={1}>
                                                                <Field
                                                                    key={role.id}
                                                                    component={CheckboxWithLabel} type="checkbox" name="roles"
                                                                    value={role.name}
                                                                    Label={{ label: role.name }} />
                                                            </GridListTile>
                                                        ))}
                                                    </GridList>
                                                </FormGroup>
                                                <FormHelperText error id="component-error-text">{(errors.roles && touched.roles) && errors.roles}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth style={{ marginBottom: '2rem' }}>
                                                <InputLabel id="dealers">Active dealers for this user</InputLabel>
                                          
                                                <Select
                                                    labelId="dealers-label"
                                                    id="dealers"
                                                    multiple
                                                    required
                                                    value={values.dealers}
                                                    onChange={handleChange("dealers")}
                                                    input={<Input id="dealers" />}
                                                  
                                                    MenuProps={MenuProps}
                                                >
                                                    {dealerArr.map((dealer) => (
                                                        <MenuItem key={dealer.id} value={dealer} style={getStyles(dealer, dealerArr, theme)}>
                                                            {dealer.name} 
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                               
                                                <FormHelperText error id="component-error-text">{(errors.dealers && touched.dealers) && errors.dealers}</FormHelperText>
                                            </FormControl>
                                            
                                         
                                            <div className={classes.chips}>
                                                            {values.dealers.map((value) => (
                                                                <div className={classes.chipsHolder}>
                                                                <Chip key={value.id} label={value.name} className={classes.chip} /> 
                                                                <span className={classes.chipsHolderRemove} onClick={()=>{handleDealerRemove(value,values)}}>X</span>
                                                                </div>
                                                            ))}
                                                           
                                                        </div>
                        
                                            {
                                                values.roles.includes("Finance") &&
                                                <TextField
                                                    fullWidth
                                                    name="fiNumber"
                                                    id="fiNumber"
                                                    label="F&I Number"
                                                    value={values.fiNumber}
                                                    type="text"
                                                    helperText={errors.fiNumber && touched.fiNumber ? errors.fiNumber : ''}
                                                    error={errors.fiNumber && touched.fiNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            }

                                        </Grid>

                                        <Grid item container
                                            justify='flex-end' xs={12}>
                                            <Box mr={3}>
                                                <Button
                                                    onClick={() => {
                                                        handleReset()
                                                        history.push('/admin/users')
                                                    }}>
                                                    Cancel
                                                </Button>
                                            </Box>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                            {!isAddMode && <Button
                                                onClick={() => {
                                                    resetPassword()
                                                }}>
                                                Reset Password
                                            </Button>}
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </Paper>
    )
}