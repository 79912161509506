import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { BASE_URI } from "../../../shared/Constants";
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import { red, blue, green, pink, yellow } from '@material-ui/core/colors';
import Grid from "@material-ui/core/Grid";
import { useHistory, Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import LeadMain from "./LeadMainFilter";
import { LeadMainContext } from "../../../shared/contexts/LeadMainContext";
import purple from "@material-ui/core/colors/purple";
import { DealerContext } from "../../../shared/DealerContext";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
            width: "45ch",
        },
    },
    content: {
        display: 'flex !important',
        flexDirection: 'column',
        height: "8.5vh"
    },
    addLeadButton: {
        marginLeft: "60%",
        textDecoration: 'none'
    },
    importLeadsButton: {
        marginLeft: "1%",
        textDecoration: 'none'
    },
    AS: {
        backgroundColor: blue[500],
    },
    AC: {
        backgroundColor: blue[500],
    },
    OTP: {
        backgroundColor: green[500],
    },
    BV: {
        backgroundColor: green[500],
    },
    INV: {
        backgroundColor: green[400],
    },
    CONTR: {
        backgroundColor: green[400],
    },
    DLVRD: {
        backgroundColor: green[400],
    },
    PO: {
        backgroundColor: pink[500],
    },
    AA: {
        backgroundColor: blue[400],
    },
    DI: {
        backgroundColor: blue[600],
    },
    BS: {
        backgroundColor: blue[600],
    },
    PD: {
        backgroundColor: red[500],
    },
    L: {
        backgroundColor: red[500],
    },
    AD: {
        backgroundColor: red[500],

    },
    NTU: {
        backgroundColor: red[500],
    },
    BIV: {
        backgroundColor: red[500],
    },
    BTD: {
        backgroundColor: purple[500],
    },
    RTCD: {
        backgroundColor: yellow[700],
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    stepPadding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    hr: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    }
}));

export default function LeadMainContainer() {
    const classes = useStyles();
    let { ...context } = useContext(LeadMainContext);
    let { ...dealerContext } = useContext(DealerContext);

    const [states, setStates] = useState([]);

    const [selectedDate, setSelectedDate] = React.useState(context.dates);
    const [error, setError] = useState(false);

    const history = useHistory();

    const handleDateChange = (date) => {
        setSelectedDate(new Date(date));
        context.updateDate(date);
    };

    const possibleStates = [
        {
            acronymn: "NL", name: "NEW LEAD", count: 0
        },
        { acronymn: "PA", name: "PREQUALIFIED APPROVED", count: 0 },
        { acronymn: "AC", name: "APPLICATION COMPLETED", count: 0 },
        { acronymn: "PS", name: "PREQUALIFIED SUBMITTED", count: 0 },
        { acronymn: "PD", name: "PREQUALIFIED DECLINED", count: 0 },
        { acronymn: "AS", name: "APPLICATION SUBMITTED", count: 0 },
        { acronymn: "AA", name: "APPLICATION APPROVED", count: 0 },
        { acronymn: "DI", name: "DOCUMENTS IN", count: 0 },
        { acronymn: "BS", name: "BANK SUBMITTED", count: 0 },
        { acronymn: "AD", name: "APPLICATION DECLINED", count: 0 },
        { acronymn: "NTU", name: "NOT TAKEN UP", count: 0 },
        { acronymn: "BV", name: "BANK VALIDATED", count: 0 },
        { acronymn: "OtP", name: "OFFER TO PURCHASE", count: 0 },
        { acronymn: "I", name: "INVOICED", count: 0 },
        { acronymn: "D", name: "DELIVERED", count: 0 },
        { acronymn: "PO", name: "PAID OUT", count: 0 },
        { acronymn: "BI", name: "BANK INVALIDATED", count: 0 },
        { acronymn: "L", name: "LOST", count: 0 }
    ];

    let { loading, ranges, total } = LeadMain(
        selectedDate,
    );

    const changeHistory = (name, id, acronymn) => {
        let path = `/leads/${id}`;
        history.push(path);
    }

    const cardShower = (name, id, acronymn, count, stateId) => {
        return (
            <Card elevation={4} className={classes.content}>
                <CardHeader
                    onClick={() => {
                        changeHistory(name, stateId, acronymn); // TODO Change this to use leadID
                    }}
                    avatar={
                        <Avatar aria-label="acronymn"
                            className={classes[acronymn]}>
                            {acronymn}
                        </Avatar>
                    }
                    title={name}
                    subheader={count}
                />
            </Card>
        );
    }

    const getCards = () => {

        var date = moment(selectedDate).format('YYYY-MM-DD');
        var endpoint = dealerContext.isMasterDealer
            ? `motorgroup/${dealerContext.selectedMotorGroup.motorgroupID}`
            : `dealer/${dealerContext.dealerId}`;

        axios.get(`${BASE_URI}/leadstatus/cards/${date}/${endpoint}`).then((results) => {

            var finalItems = [];
            var items = results.data;

            for (var idx in possibleStates) {
                var acronymn = possibleStates[idx].acronymn;

                var existingItems = items.filter(itm => itm.acronymn == acronymn);

                if (existingItems.length > 0) {
                    finalItems = finalItems.concat(existingItems);
                }
                else {
                    finalItems.push(possibleStates[idx]);
                }
            }

            setStates(finalItems);
        });

    }

    useEffect(() => {
        getCards();
    }, [dealerContext.selectedMotorGroup, selectedDate]);

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                    label="Only show Leads (updated) since"
                    clearable
                    fullWidth
                    disableFuture
                    format="MMMM Do YYYY"
                    value={selectedDate}
                    onChange={handleDateChange}
                    animateYearScrolling
                />
            </MuiPickersUtilsProvider>
            <Button
                component={Link}
                to="/addlead"
                variant="contained"
                color="primary"
                className={classes.addLeadButton}
                startIcon={<AddIcon />}
            >
                Add Lead
            </Button>
            <Button
                component={Link}
                to={`/importLeads/dealer/${dealerContext.dealerId}`}
                variant="contained"
                color="primary"
                className={classes.importLeadsButton}
                startIcon={<AddIcon />}
            >
                Import Leads
            </Button>
            <Divider variant="fullWidth"
                style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <Grid container>
                {(states.map((options) => {
                       if (options.acronymn == "NL") {
                        return (
                            <>
                                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                                    {cardShower("All Leads", options.id, "AL", options.count, options.leadStatusId)}
                                </Grid> 
                            </>
                        );
                    }
                    if (options.acronymn == "AC") {
                        return (
                            <>
                                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} className={classes.stepPadding}>
                                </Grid>
                            </>
                        );
                    } else if (options.acronymn == "PD") {
                        return (
                            <>
                                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                            </>
                        )
                    } else if (options.acronymn == "AD") {
                        return (
                            <>
                                <Grid item xs={12} md={6} lg={6} className={classes.stepPadding}>
                                </Grid>
                                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                            </>
                        )
                    } else if (options.acronymn == "DI") {
                        return (
                            <>
                                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                            </>
                        )
                    } else if (options.acronymn == "BS") {
                        return (
                            <>
                                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                            </>
                        )
                    } else if (options.acronymn == "BV") {
                        return (
                            <>
                                <Grid item xs={12} md={4} lg={12} className={classes.stepPadding}>
                                    <hr className={classes.hr} />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                            </>
                        )
                    } else if (options.acronymn == "RTCD") {
                        return (
                            <>
                                <Grid item xs={12} md={4} lg={12} className={classes.stepPadding}>
                                    <hr className={classes.hr} />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                            </>
                        )
                    } else if (options.acronymn == "L") {
                        return (
                            <>
                                <Grid item xs={12} md={4} lg={12} className={classes.stepPadding}>
                                    <hr className={classes.hr} />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                                    {cardShower(options.name, options.id, options.acronymn, options.count, options.leadStatusId)}
                                </Grid>
                            </>
                        )
                    }
                }
                ))}
            </Grid>
        </div>
    );
}