import React , { createContext, useState } from 'react';
import ManagementTabContainer from "../../components/LeadsContainer/LeadDetails/MangementTab/ManagementTabContainer";
import OTPTabContainer from "../../components/LeadsContainer/LeadDetails/FileUploadTabs/OTPTabContainer";
import InvoiceTabContainer from "../../components/LeadsContainer/LeadDetails/FileUploadTabs/InvoiceTabContainer";
import EmailTabContainer from "../../components/LeadsContainer/LeadDetails/EmailTab/EmailTabContainer";
import DocumentsTabContainer from "../../components/LeadsContainer/LeadDetails/FileUploadTabs/DocumentsTabContainer";
import FinanceOfferTabContainer from "../../components/LeadsContainer/LeadDetails/FinanceOfferTab/FinanceOfferTabContainer";
import SMSTabContainer from "../../components/LeadsContainer/LeadDetails/SMSTab/SMSTabContainer";
import VehicleTabContainer from "../../components/LeadsContainer/LeadDetails/VehicleTabs/VehicleTabContainer";
import NotesTabContainer from "../../components/LeadsContainer/LeadDetails/NotesTab/NotesTabContainer";
import EventsTabContainer from "../../components/LeadsContainer/LeadDetails/EventsTab/EventsTabContainer";
import VoiceMailTabContainer from "../../components/LeadsContainer/LeadDetails/VoiceMailTab/VoiceMailTabContainer";
import ActionTabContainer from "../../components/LeadsContainer/LeadDetails/ActionTab/ActionTabContainer";
import TradeInTabContainer from "../../components/LeadsContainer/LeadDetails/TradeInTab/TradeInTabContainer";
import TestDriveTabContainer from "../../components/LeadsContainer/LeadDetails/TestDriveTab/TestDriveTabContainer";
import BookServiceTabContainer from "../../components/LeadsContainer/LeadDetails/BookServiceTab/BookServiceTabContainer";
import FinanceTabContainer from "../../components/LeadsContainer/LeadDetails/FinanceTab/FinanceTabContainer";
import ApplicationTabContainer
    from "../../components/LeadsContainer/LeadDetails/ApplicationTab/ApplicationTabContainer";

export const TabsContext = createContext();

export const TabsProvider = ({ children }) => {

    const [selectedTab, setSelectedTab] = React.useState(0);

    const [tabNameIndex, setTabNameIndex] = useState({
        0: "actions",
        1: "vehicle",
        2: "tradein",
        3: "management",
        4: "application", 
        5: "documents",
        6: "finance_offers",
        7: "otp",
        8: "invoice",
        9: "emails",
        10: "voicemails",
        11: "sms",
        12: "notes",
        13: "events",
    });

    const [tabNames, setTabNames] = useState([
        "Actions",
        "Vehicle",
        "Trade In",
        "Management",
        "Application",
        "Documents",
        "Finance Offers",
        "OTP",
        "Invoice",
        "Emails",
        "Voicemails",
        "SMS",
        "Notes",
        "Events"
    ]);

    const [tabContainerList, setTabContainerList] = useState([
        "ActionTabContainer",
        "VehicleTabContainer",
        "TradeInTabContainer",
        "ManagementTabContainer",
        "ApplicationTabContainer",
        "DocumentsTabContainer",
        "FinanceOfferTabContainer",
        "OTPTabContainer",
        "InvoiceTabContainer",
        "EmailTabContainer",
        "VoiceMailTabContainer",
        "SMSTabContainer",
        "NotesTabContainer",
        "EventsTabContainer",
    ]);

    const adjustTabName = (tabName, removeWhiteSpace, camelCase) => {

        if (removeWhiteSpace) {
            tabName = tabName.replace(/ /g, '');
        }

        if (camelCase) {
            tabName = tabName[0].toLowerCase() + tabName.substr(1);
        }

        return tabName;
    }

    const getLeadTypeTabName = (leadTypeId) => {
        switch (leadTypeId) {
            // 2021-08-26 Requested to remove the Finance tab as it's not really being used
            // case 1: 
            // case 2: return "Finance";
            case 5: return "Service";
            case 8: return "Test Drive";
            case 22: return "Trade In";
            default: return "null";
        }
    }

    const getCommonContainerList = (isStartingSet) => {
        if (isStartingSet) {
            return [
                "ActionTabContainer",
                "VehicleTabContainer",
                "TradeInTabContainer",
                "ManagementTabContainer",
            ];
        } else {
            return [
                "EmailTabContainer",
                "VoiceMailTabContainer",
                "SMSTabContainer",
                "NotesTabContainer",
                "EventsTabContainer"
            ];
        }
    }

    const getCommonTabNames = (isStartingSet) => {
        if (isStartingSet) {
            return [
                "Actions",
                "Vehicle",
                "Trade In",
                "Management",
            ];
        } else {
            return [
                "Emails",
                "Voicemails",
                "SMS",
                "Notes",
                "Events"
            ];
        }
    }

    const getCommanTabNameIndices = (isStartingSet, startingIndexForEndingSet) => {
        if (isStartingSet) {
            return [
                "actions",
                "vehicle",
                "tradein",
                "management"
            ];
        } else {
            return [
                "emails",
                "voicemails",
                "sms",
                "notes",
                "events"
            ];
        }
    }

    const removeNulls = (list) => {
        return list.filter(item => !item.includes("null"));
    }

    const getPaidOutState = (leadTypeId) => {
        var tabName = getLeadTypeTabName(leadTypeId);

        var containerlist = [
            ...getCommonContainerList(true),
            (adjustTabName(tabName, true) + "TabContainer"),
            "ApplicationTabContainer",
            "DocumentsTabContainer",
            "FinanceOfferTabContainer",
            "OTPTabContainer",
            "InvoiceTabContainer",
            ...getCommonContainerList(false)
        ];

        var tabnames = [
            ...getCommonTabNames(true),
            tabName,
            "Application",
            "Documents",
            "Finance Offers",
            "OTP",
            "Invoice",
            ...getCommonTabNames(false)
        ];

        var tabnameindex = [
            ...getCommanTabNameIndices(true),
            adjustTabName(tabName, true, true),
            "application",
            "documents",
            "finance_offers",
            "otp",
            "invoice",
            ...getCommanTabNameIndices(false)
        ];

        setTabContainerList(removeNulls(containerlist));
        setTabNames(removeNulls(tabnames));
        setTabNameIndex(removeNulls(tabnameindex));
    }

    const getPrequalifiedSubmitted = (leadTypeId) => {       
        var tabName = getLeadTypeTabName(leadTypeId);

        var containerlist = [
            ...getCommonContainerList(true),
            (adjustTabName(tabName, true) + "TabContainer"),
            "ApplicationTabContainer",
            ...getCommonContainerList(false)
        ];

        var tabnames = [
            ...getCommonTabNames(true),
            tabName,
            "Application",
            ...getCommonTabNames(false)
        ];

        var tabnameindex = [
            ...getCommanTabNameIndices(true),
            adjustTabName(tabName, true, true),
            "application",
            ...getCommanTabNameIndices(false)
        ];

        setTabContainerList(removeNulls(containerlist));
        setTabNames(removeNulls(tabnames));
        setTabNameIndex(removeNulls(tabnameindex));
    }

    const getPrequalifiedApproved = (leadTypeId) => {
        var tabName = getLeadTypeTabName(leadTypeId);

        var containerlist = [
            ...getCommonContainerList(true),
            (adjustTabName(tabName, true) + "TabContainer"),
            "ApplicationTabContainer",
            ...getCommonContainerList(false)
        ];

        var tabnames = [
            ...getCommonTabNames(true),
            tabName,
            "Application",
            ...getCommonTabNames(false)
        ];

        var tabnameindex = [
            ...getCommanTabNameIndices(true),
            adjustTabName(tabName, true, true),
            "application",
            ...getCommanTabNameIndices(false)
        ];

        setTabContainerList(removeNulls(containerlist));
        setTabNames(removeNulls(tabnames));
        setTabNameIndex(removeNulls(tabnameindex));
    }

    const getTabs = (name) => {
            switch(name){
                case("ActionTabContainer"):
                    return <ActionTabContainer />
                    break;
                case("VehicleTabContainer"):
                    return <VehicleTabContainer />
                    break;
                case("ManagementTabContainer"):
                    return <ManagementTabContainer />
                    break;
                case("ApplicationTabContainer"):
                    return <ApplicationTabContainer />
                    break;
                case("TradeInTabContainer"):
                    return <TradeInTabContainer />
                    break;
                case("TestDriveTabContainer"):
                    return <TestDriveTabContainer />
                    break;
                case("ServiceTabContainer"):
                    return <BookServiceTabContainer />
                    break;
                case("FinanceTabContainer"):
                    return <FinanceTabContainer />
                    break;
                case("EmailTabContainer"):
                    return <EmailTabContainer />
                    break;
                case("VoiceMailTabContainer"):
                    return <VoiceMailTabContainer />
                    break;
                case("NotesTabContainer"):
                    return <NotesTabContainer />
                    break;
                case("EventsTabContainer"):
                    return <EventsTabContainer />
                    break;
                case("SMSTabContainer"):
                    return <SMSTabContainer />
                    break;
                case("InvoiceTabContainer"):
                    return <InvoiceTabContainer />
                    break;
                case("OTPTabContainer"):
                    return <OTPTabContainer />
                    break;
                case("FinanceOfferTabContainer"):
                    return <FinanceOfferTabContainer />
                    break;
                case("DocumentsTabContainer"):
                    return <DocumentsTabContainer />
                    break;
                default:
                    return <ActionTabContainer />
            }
    }

    const getPrequalifiedDeclined = (leadTypeId) => {
        var tabName = getLeadTypeTabName(leadTypeId);

        var containerlist = [
            ...getCommonContainerList(true),
            (adjustTabName(tabName, true) + "TabContainer"),
            ...getCommonContainerList(false)
        ];

        var tabnames = [
            ...getCommonTabNames(true),
            tabName,
            ...getCommonTabNames(false)
        ];

        var tabnameindex = [
            ...getCommanTabNameIndices(true),
            adjustTabName(tabName, true, true),
            ...getCommanTabNameIndices(false)
        ];

        setTabContainerList(removeNulls(containerlist));
        setTabNames(removeNulls(tabnames));
        setTabNameIndex(removeNulls(tabnameindex));
    }

    const getApplicationSubmitted = (leadTypeId) => {
        var tabName = getLeadTypeTabName(leadTypeId);

        var containerlist = [
            ...getCommonContainerList(true),
            (adjustTabName(tabName, true) + "TabContainer"),
            "ApplicationTabContainer",
            "FinanceOfferTabContainer",
            ...getCommonContainerList(false)
        ];

        var tabnames = [
            ...getCommonTabNames(true),
            tabName,
            "Application",
            "Finance Offers",
            ...getCommonTabNames(false)
        ];

        var tabnameindex = [
            ...getCommanTabNameIndices(true),
            adjustTabName(tabName, true, true),
            "application",
            "financeOffer",
            ...getCommanTabNameIndices(false)
        ];

        setTabContainerList(removeNulls(containerlist));
        setTabNames(removeNulls(tabnames));
        setTabNameIndex(removeNulls(tabnameindex));
    }

    const getNewLead = (leadTypeId) => {
        var tabName = getLeadTypeTabName(leadTypeId);

        var containerlist = [
            ...getCommonContainerList(true),
            (adjustTabName(tabName, true) + "TabContainer"),
            ...getCommonContainerList(false)
        ];

        var tabnames = [
            ...getCommonTabNames(true),
            tabName,
            ...getCommonTabNames(false)
        ];

        var tabnameindex = [
            ...getCommanTabNameIndices(true),
            adjustTabName(tabName, true, true),
            ...getCommanTabNameIndices(false)
        ];

        setTabContainerList(removeNulls(containerlist));
        setTabNames(removeNulls(tabnames));
        setTabNameIndex(removeNulls(tabnameindex));
    }

    const getNotTaken = (leadTypeId) => {
        var tabName = getLeadTypeTabName(leadTypeId);

        var containerlist = [
            ...getCommonContainerList(true),
            (adjustTabName(tabName, true) + "TabContainer"),
            "ApplicationTabContainer",
            "DocumentTabContainer",
            ...getCommonContainerList(false)
        ];

        var tabnames = [
            ...getCommonTabNames(true),
            tabName,
            "Application",
            "Documents",
            ...getCommonTabNames(false)
        ];

        var tabnameindex = [
            ...getCommanTabNameIndices(true),
            adjustTabName(tabName, true, true),
            "application",
            "document",
            ...getCommanTabNameIndices(false)
        ];

        setTabContainerList(removeNulls(containerlist));
        setTabNames(removeNulls(tabnames));
        setTabNameIndex(removeNulls(tabnameindex));
    }

    return (
        <TabsContext.Provider value={{tabNames,tabNameIndex ,tabContainerList,
            getPaidOutState, getPrequalifiedSubmitted, getPrequalifiedApproved,getTabs,getPrequalifiedDeclined,
            getApplicationSubmitted, getNotTaken, getNewLead, selectedTab, setSelectedTab}}>
            {children}
        </TabsContext.Provider>
    )
};
