import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from "@material-ui/icons/Folder";

const useStyles = makeStyles({
  btnTxt: {
    color: "white !important",
    outline: "0 !important",
  },
  errorText: {
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: "#f44336",
  },
  dropzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
    // padding: props => props ? '1rem' : '3rem',
    outline: "0 !important",
  },
  container: {
    position: "relative",
    width: "100%",
  },
});

const dropzoneMessage = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const deleteButton = {
  backgroundColor: "#d4411e",
  borderRadius: 0,
  position: "absolute",
  zIndex: "100",
  right: 10,
  top: 10,
  outline: "0 !important",
};

export default function FileUploader({
  title,
  file,
  id,
  onDropFile,
  clear,
  error,
  className,
  required,
  small,
  disabled,
  errorMsg,
  reset,
}) {
  const [files, setFiles] = useState([]);
  const [isDragAccept, setDragAccept] = useState(false);
  const classes = useStyles(small);
  const [loaded, setLoaded] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setDragAccept(true);
      setFiles(acceptedFiles);
      onDropFile(Object.assign(acceptedFiles[0]), id);
    },
  });

  const clearfile = () => {
    clear(id);
    setDragAccept(false);
    setFiles([]);
  };

  useEffect(() => {

    if (file?.name?.length > 0) {

      setLoaded(true);
      setDragAccept(true);

      if (file.preview && file instanceof File) {
        
        let reader = new FileReader();
        
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          setFiles([ e.target.result ]);
          setLoaded(false);
          setDragAccept(false);
        };

      } else {

        setFiles([ file ]);
        setLoaded(false);
        setDragAccept(false);

      }  

    } else {

      clearfile();

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <section className={className}>
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
        component="p"
      >
        {title} {required && " *"}
      </Typography>
      <div className={classes.container}>
        <div
          className={classes.dropzone}
          style={{ border: "2px dashed lightgrey" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} maxFiles={1} multiple={false} />
          <div style={dropzoneMessage}>
            {loaded && <CircularProgress />}
            {!loaded && files.length === 0 && (
              <React.Fragment>
                <CloudUploadOutlinedIcon color="disabled" fontSize="large" />
                {!small && (
                  <Typography
                    variant="body2"
                    className="mt-2"
                    color="textSecondary"
                    component="p"
                  >
                    Click or drag file here to upload
                  </Typography>
                )}
              </React.Fragment>
            )}
          </div>
          {files.length > 0 && (
            <React.Fragment>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={files[0].path || files[0].name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={clearfile}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </React.Fragment>
          )}
        </div>
      </div>
      {error && (
        <p className={classes.errorText}>
          {errorMsg ? errorMsg : `A ${title} is required`}
        </p>
      )}
    </section>
  );
}
