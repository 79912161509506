import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportingController from "./ReportingController";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import DatePickHelper from "./DatePickHelper";
import { Card } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {},
    step: {
        marginTop: "2rem",
        marginLeft: "2rem",
    },
    buttonMake: {
        marginTop: "2rem",
        backgroundColor: "#0b7d95",
        color: "white"
    },
    cellText: {
        background: "#ebd852"
    },
}));
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: "auto"
        },
    },
    expanded: {
        backgroundColor: "#f3efef"
    },
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
     
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        color: "white"
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey
    },

}))(MuiAccordionDetails);

export default function AgentMonitorContainer() {
    const classes = useStyles();
    const history = useHistory();

    let { loading, ranges, total } = ReportingController(
        "agentMonitor" // TODO change this to the specific api endpoint
    );

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: 0.1
        },
        body: {
            fontSize: 14,
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: 0.1
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    return (
        <div className={classes.root}>
            <Grid
                container
                justify={"space-around"}
                alignItems="flex-start"
            >
                <Grid item xs={10} md={8} lg={12} className={classes.step}>
                    <DatePickHelper />
                </Grid>
                <Grid item xs={10} md={8} lg={12} className={classes.step}>
                    <Divider />
                </Grid>
                {loading && <CircularProgress />}
                {ranges.map((row) => (
                    <Grid item xs={10} md={12} lg={3} className={classes.step}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography>{row.name} <Typography
                                    variant="caption" display="flex"
                                    gutterBottom>id: {row.id}</Typography></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ width: "100%" }}>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                <Card elvation={4}>
                                                    {row.reports.map((rowItem) => (
                                                        <StyledTableRow key={row.id}>
                                                            <StyledTableCell style={{ width: "100%" }}>
                                                                {rowItem.name}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align={"right"}
                                                                className={classes.cellText}
                                                            >
                                                                {rowItem.total}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </Card>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Button variant="contained" color="primary"
                                        style={{ marginTop: "2rem" }}
                                        onClick={() => {
                                            let path = `/leadLogs/${row.id}`;
                                            history.push(path);
                                        }}
                                        type="submit"
                                    >
                                        Details
                                        </Button>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
