import React, { useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SMSController from "../SMSTab/SMSController";
import Divider from "@material-ui/core/Divider";
import EmailIcon from "@material-ui/icons/Email";
import ClearIcon from "@material-ui/icons/Clear";
import SmsIcon from "@material-ui/icons/Sms";
import VoiceMailController from "./VoiceMailController";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    marginTop: "1rem",
  },
  stepPadding: {
    marginTop: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  delivered: {
    color: "#8bc34a",
    marginRight: 10,
  },
  failed: {
    color: "#ff5722",
    marginRight: 10,
  },
  card: {
    marginBottom: 10,
  },
}));

export default function VoiceMailTabContainer() {
  const classes = useStyles();
  const { state, tab, id } = useParams();
  const { data } = VoiceMailController(id);

  return (
    <React.Fragment>
      <Card className={classes.stepPadding}>
        <CardContent>
          {data.map((el) => (
            <div>
              <Card className={classes.heading}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.heading}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                    <div className={el.status == "DELIVRD" ? classes.delivered : classes.failed}>
                    {el.type === "EMAIL" ? <EmailIcon color="inherit" /> : <SmsIcon color="inherit" />}
                    </div>

                      {el.heading}
                    </div>

                    <Typography variant="subtitle1" gutterBottom>
                      <Divider variant="inset" />
                                  {el.body} - {el.date}
                    </Typography>
                  </Typography>
                </CardContent>
              </Card>
              <Divider variant="inset" />
            </div>
          ))}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
