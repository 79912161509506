import React, { useState, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import axios from 'axios'
import { NavLink as RouterLink } from 'react-router-dom';
import { BASE_URI, DEFAULT_ERROR_MESSAGE } from '../../shared/Constants'
import { AuthContext } from '../../shared/context/AuthContext'
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext'
import LoginImage from '../../shared/assets/Login.png'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                MarketDealer
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(' + LoginImage + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loginContainer: {
        backgroundColor: 'transparent',
        overflow: 'hidden',
        display: 'flex',
        paddingLeft: '30px',
        paddingRight: '30px',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down("md")]: {
            padding: '20px'
        }
    },
    link: {
        color: '#3f51b5 !important',
        '&:hover': {
            textDecoration: 'underline !important'
        }
    }
}));

export default function LoginComponent(props) {
    const classes = useStyles();
    const { login, setSession } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const { showLoading, hideLoading } = useContext(LoadingContext)
    const [credentials, setValues] = useState({
        username: '',
        password: ''
    });
    const [errorUsername, setErrorUsername] = useState(false)
    const [errorPassword, setErrorPassword] = useState(false)
    const [loginAnimation, setLoginAnimation] = useState(true)
    const [resetAnimation, setResetAnimation] = useState(false)

    const handleChange = (prop) => (event) => {
        if (prop === 'username') {
            setErrorUsername(false)
        }
        if (prop === 'password') {
            setErrorPassword(false)
        }
        setValues({ ...credentials, [prop]: event.target.value });
    };

    const determineLastAccessedDealer = (dealers) => {
        if (dealers.length === 0) {
            return null
        }
        if (dealers.length === 1) {
            return dealers[0].dealerId
        }

        let mostRecentDate = dealers[0].lastAccessed
        let mostRecentDealerId = dealers[0].dealerId
        dealers.forEach((dealer) => {
            if (dealer.lastAccessed > mostRecentDate) {
                mostRecentDate = dealer.lastAccessed
                mostRecentDealerId = dealer.dealerId
            }
        })

        return mostRecentDealerId
    }

    const loginUser = (event) => {
        event.preventDefault();
        showLoading()
        login(credentials.username, credentials.password).then((response) => {
            hideLoading()
            let res = response.data
            if (!res.isAuthenticated || !res.token) {
                enqueueSnackbar(res.message, { variant: 'error' });
                return
            }
            //let dealerId = determineLastAccessedDealer(res.dealers)
            let dealerIDs = res.dealers.map(d => d.dealerId);
            setSession(credentials.username, response.data.token, response.data.refreshToken, dealerIDs, response.data.permissions)
            enqueueSnackbar('Logged In', { variant: 'success' });
        }).catch(() => {
            hideLoading()
            enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: 'error' });
        })
    }

    const validateUsername = () => {
        return setErrorUsername(credentials.username === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(credentials.username))
    }

    const validatePassword = () => {
        return setErrorPassword(credentials.password === '' || credentials.password === null)
    }

    const resetPasswordRequest = (event) => {
        event.preventDefault();
        if (!errorUsername) {
            showLoading()
            processResetPasswordRequest().then(() => {
                hideLoading()
                enqueueSnackbar('Password request sent, please check email.', { variant: 'success' });
                setResetAnimation((prev) => !prev)
            }).catch((error) => {
                hideLoading()
                if (error?.response?.data) {
                    let errorMsg =
                        (error.response.data.title ? `${error.response.data.title}. ` : '') + 
                        (error.response.data.detail ?? error);

                    enqueueSnackbar(errorMsg, { variant: 'error' });
                } else {
                    enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: 'error' });
                }
            })
        } else {
            enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: 'error' });
        }
    }

    const processResetPasswordRequest = () => {
        return axios.get(`${BASE_URI}/User/ResetPassword/${credentials.username}`)
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={9} className={classes.image} />
            <Grid item xs={12} sm={8} md={3} component={Paper} className={classes.loginContainer} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" style={{ fontWeight: '400 !important' }} variant="h5">
                        Sign in
          </Typography>
                    <Grid container style={{ minHeight: 240, width: '100%' }}>
                        <Slide direction="left" in={loginAnimation} mountOnEnter unmountOnExit
                            onExited={() => setResetAnimation((prev) => !prev)}>
                            <form className={classes.form} onSubmit={loginUser} noValidate>
                                <TextField
                                    fullWidth
                                    required
                                    margin="normal"
                                    id="username"
                                    autoFocus
                                    label="Email Address"
                                    value={credentials.username}
                                    onChange={handleChange('username')}
                                    onBlur={validateUsername}
                                    error={errorUsername}
                                    helperText={errorUsername ? 'Requires valid email address' : ''}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    margin="normal"
                                    type='password'
                                    id="password"
                                    label="Password"
                                    value={credentials.password}
                                    onChange={handleChange('password')}
                                    onBlur={validatePassword}
                                    error={errorPassword}
                                    helperText={errorPassword ? 'Requires password' : ''}
                                />
                                <Button disabled={errorUsername || errorPassword || credentials.username === '' || credentials.password === ''}
                                    fullWidth
                                    className={classes.submit}
                                    type="submit" variant="contained" color="primary">
                                    Login
                </Button>
                            </form>
                        </Slide>

                        <Slide direction="left" in={resetAnimation}
                            onExited={() => setLoginAnimation((prev) => !prev)}
                            mountOnEnter unmountOnExit>
                            <form className={classes.form} onSubmit={resetPasswordRequest} noValidate>
                                <TextField
                                    fullWidth
                                    required
                                    margin="normal"
                                    id="username"
                                    autoFocus
                                    label="Email Address"
                                    value={credentials.username}
                                    onChange={handleChange('username')}
                                    onBlur={validateUsername}
                                    error={errorUsername}
                                    helperText={errorUsername ? 'Requires email address' : ''}
                                />
                                <Button disabled={errorUsername || credentials.username === ''}
                                    fullWidth
                                    className={classes.submit}
                                    type="submit" variant="contained" color="primary">
                                    Reset Password
                </Button>
                            </form>
                        </Slide>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <Link
                                className={classes.link}
                                style={{ display: loginAnimation ? 'block' : 'none' }}
                                onClick={() => setLoginAnimation((prev) => !prev)} variant="body2">
                                Forgot Password?
              </Link>
                            <Link
                                className={classes.link}
                                style={{ display: resetAnimation ? 'block' : 'none' }}
                                onClick={() => setResetAnimation((prev) => !prev)} variant="body2">
                                Back to Login
              </Link>
                        </Grid>
                        <Grid item xs={6} container justify="flex-end">
                            <Link to="/sell-car"
                                component={RouterLink}
                                variant="body2">
                                Sign Up?
              </Link>
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <Copyright />
                    </Box>
                </div>
            </Grid>
        </Grid>
    );
}