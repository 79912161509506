import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {BASE_URI} from '../../../shared/Constants'
import {DealerContext} from '../../../shared/DealerContext'

export default function RangeFilter(pageNumber, pageSize, order, orderBy, forceReload, searchString, makeId) {
  
  const { dealerId } = useContext(DealerContext);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [ranges, setRanges] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setRanges([])
  }, [pageNumber, pageSize, order, orderBy, dealerId, forceReload])

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)
    const params = {
      dealerId,
      pageNumber: pageNumber + 1,
      pageSize,
      isDash: true,
      makeId: makeId || null,
      searchString: searchString?.trim()?.length > 0 ? searchString.trim() : null
    }
    if (order) {params.order = order.toUpperCase()}
    if (orderBy) {params.orderBy = orderBy}
    axios({
      method: 'GET',
      url: `${BASE_URI}/dealermodels`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      setRanges(response.data?.list)
      setTotal(response.data?.total)
      setLoading(false)
    }).catch( error => {
      if(axios.isCancel(error)) return
      if (error.response) {
        setError(true)
      }
      setLoading(false)
    })
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize, order, orderBy, dealerId, forceReload, searchString, makeId])

  return {loading, error, ranges, total}
}