import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {useHistory, useParams} from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort'
import {DEFAULT_PAGE_SIZE} from '../../shared/Constants'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExportContainer from '../../shared/ExportContainer'
import OfferFilter from './OfferFilter'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import {CurrencyValue} from '../../shared/TextMask'
import moment from "moment";
import {ImageContainer} from '../../shared/ImageContainer'
import { DealerContext } from '../../shared/DealerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function OfferTable({tabStatus}) {
  const classes = useStyles();
  let history = useHistory();
  const {status} = useParams();
  const {dealerArr} = useContext(DealerContext)
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('headline');
  const [searchString, setSearchString] = useState('');
  const [offerStatus, setOfferStatus] = useState('');
  const{
    loading,
    offers,
    total
  }= OfferFilter(searchString, offerStatus, page, pageSize, order, orderBy)

  useEffect(() => {
    setOfferStatus(status ? status:tabStatus ? tabStatus: '')
  },[status,tabStatus])

  const headCells = [
    { id: 'headline', label: 'Details', sort: true, width: '50%' },
    { id: 'leadType', label: 'Type', sort: true },
    { id: 'price', label: 'Price', sort: true, width: '20%' },
    { id: 'term', label: 'Term', sort: true },
    { id: 'rate', label: 'Rate', sort: true },
    { id: 'deposit', label: 'Deposit', sort: true },
    { id: 'balloon', label: 'Balloon', sort: true },
    { id: 'variant', label: 'Url', sort: true },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleChange = (event) => {
    setPage(0)
    setSearchString(event.target.value)
  };

  const dealerLookup = (data) => {
    let filter = dealerArr?.filter((d)=> d.id == data?.dealerId )
    let url = filter[0]?.websiteUrl
    var lastChar = url.substr(url.length - 1); 
    if(lastChar !== "/"){
      url = url + "/"
    }else(
      url = url
    )
    data.url = `${url}special-deals/${data?.model?.replace(/\s/g, '-').replace(/\//g, '-').toLowerCase()}/${data?.ownedModelId}/${data?.id}`

  };

  return (
    <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Toolbar disableGutters style={{marginBottom: '2rem'}}>

              <div style={{flex: '1 1'}}>
                <ExportContainer values={offers}
                                 useDataHeaders
                                 fileName='Offers'
                                 headers={headCells}/>
              </div>

              <TextField
                name="offerFilter"
                id="offerFilter"
                label="Search Offers"
                value={searchString}
                type="text"
                InputProps={{
                  endAdornment: searchString ?  (
                    <InputAdornment position="end">
                      <IconButton size="small" disableFocusRipple
                                  disableRipple onClick={()=> {
                        setSearchString('')
                        setPage(0)
                      }}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ): '',
                }}
                onChange={handleChange}
              />
            </Toolbar>

            <TableContainer>
              <Table aria-label="offers table" size="small">
                <TableHeadersSort
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {
                    offers.map((row) =>(
                      <Tooltip title={`Edit ${row.active ? 'Active': 'Inactive'} ${row.headline}`} key={row.id} placement="left">
                        <TableRow hover
                                  className={classes.hoverTableCell}
                                  onClick={() => history.push(`/offers/edit/${row.id}`)}>
                          <TableCell>
                            <List dense>
                             <Typography variant="subtitle2" color="textSecondary">{row.dateFrom ? moment(row.dateFrom).format('l'): ''} - {row.dateTo ? moment(row.dateTo).format('l'): ''}</Typography>
                              <ListItem alignItems='center' >
                             
                                <ListItemAvatar>
                                  <Avatar src={row.imageUrl}>
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={<Typography variant="subtitle1"><strong>{row.headline}</strong></Typography>}
                                />
                              </ListItem>
                            </List>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.leadType || "N/A"}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography><CurrencyValue value={row.price}/></Typography>
                            <Typography><CurrencyValue value={row.monthlyInstallment}/> pm</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.term}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.rate}% linked</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.deposit}%</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.balloonPayment}%</Typography>
                          </TableCell>
                          <TableCell>
                            {dealerLookup(row)}
                            <Typography><a href={row?.url} target="_blank">{row?.url}</a></Typography>
                          </TableCell>
                        </TableRow>
                      </Tooltip>
                    ))
                  }
                  {
                    ((!offers.length || offers.length === 0) && !loading) &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}
                                 className="py-3">
                        <Typography variant="h6" color="textSecondary">
                          No Offers Added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
                  {
                    loading &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={total}
                page={page}
                rowsPerPageOptions={[5,10,25]}
                onChangePage={handleChangePage}
                rowsPerPage={pageSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
    </div>
  );
}