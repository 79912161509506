import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import { BASE_URI } from "../../shared/Constants";
import { DealerContext } from '../../shared/DealerContext'

export default function SlidesFilter(searchString, slideStatus, pageNumber, pageSize, order, orderBy) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [slides, setSlides] = useState([])
    const [total, setTotal] = useState(0)
    const { dealerId } = useContext(DealerContext);

    useEffect(() => {
        setSlides([])
    }, [searchString, slideStatus, pageNumber, pageSize, order, orderBy, dealerId])

    useEffect(() => {
        let cancel
        setLoading(true)
        setError(false)
        const params = {
            pageNumber: pageNumber + 1,
            pageSize,
            dealerId: dealerId
        }
        if (searchString) { params.searchName = searchString }
        if (slideStatus) { params.status = slideStatus }
        //if (order) {params.order = order.toUpperCase()}
        //if (orderBy) {params.orderBy = orderBy}
        axios({
            method: 'GET',
            url: `${BASE_URI}/Slide`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setSlides(response.data?.list)
                setTotal(response.data?.total)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })
        setLoading(false)
        // return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, slideStatus, pageNumber, pageSize, order, orderBy, dealerId])

    return { loading, error, slides, total }
}