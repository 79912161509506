import React, { useContext } from "react";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
//import {AuthContext} from '../shared/Contexts/AuthContext'
//import {DEALER_ROLE, VEHICLE_ADDONS} from '../shared/Constants'
//import Reports from './Reports'
import { ImageContainer } from '../shared/ImageContainer'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  labelAndValue: {
    fontSize: '1.2rem'
  }
}));

export default function VehicleDetails({ vehicleDetails, images,lead }) {
  const { role } = { role: 'tmpUser' };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = useStyles();

  return (
    <React.Fragment style={{ display: 'flex', justtifyContent: 'space-between' }}>
      {
        images.length > 0 &&
        <GridList cols={matches ? 3 : 1} cellHeight={200} style={{ width: '50%' }}>
          {
            images.map((tile, index) => (
              <GridListTile key={index}>
                <ImageContainer
                  src={tile.imageUrl}
                  alt={tile.imageType} />
              </GridListTile>
            ))
          }
        </GridList>
      }
      { /*  {
          role === DEALER_ROLE && <Reports />
        }*/}
      {
        Object.keys(vehicleDetails).length > 0 &&
        <Grid container style={{ width: '50%', display: 'flex', justifyContent: 'center', font: 'inherit' }}>
          <Grid item xs={12} sm={4}>
            <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p" >
                Make
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails?.model?.make?.name}</strong>
              </Typography>
            </div>
            <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p">
                Model
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails?.model?.name}</strong>
              </Typography>
            </div>
            <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p">
                Variant
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails?.variant?.name}</strong>
              </Typography>
            </div>
            <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p">
                Year
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails.year}</strong>
              </Typography>
            </div>
            <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p">
                Odometer reading
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails.odometer}</strong>
              </Typography>
            </div>
            <div>
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p">
                Color
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails.color}</strong>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p">
                VIN Number
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails?.vinNumber}</strong>
              </Typography>
            </div>
            <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p">
                Registration Number
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails.registration}</strong>
              </Typography>
            </div>
            <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p">
                Transmission
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{vehicleDetails.transmission === 0 ? 'Automatic' : 'Manual'}</strong>
              </Typography>
            </div>
            
           {lead?.availability &&  <div className="pb-5">
              <Typography className={classes.labelAndValue} variant="caption" color="textSecondary" component="p" >
                Availability
              </Typography>
              <Typography className={classes.labelAndValue} variant="body2" component="p">
                <strong>{lead?.availability}</strong>
              </Typography>
            </div>}
          </Grid>
        </Grid>
      }
      {
        !Object.keys(vehicleDetails).length &&
        <Grid container className="p-5">
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="textSecondary">
              No vehicle information available
            </Typography>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
}
