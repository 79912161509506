import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    MenuItem
} from '@material-ui/core'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { BASE_URI } from '../../shared/Constants'
import axios from 'axios'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import { useSnackbar } from 'notistack';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    title: {
        fontSize: 16
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


export default function MessagingSettingsForm({ history, match }) {
    const classes = useStyles();
    const { id } = match.params;
    const isAddMode = !id;
    const mountedRef = useRef(true)
    //const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const initialValues = {
        messageTypeId: 0,
        leadStatusId: 0,
        messageActionId: 0,
        subject: '',
        body: ''
    }
    const [formFields, setFormFields] = useState(initialValues)
    const [types, setTypes] = useState([])
    const [states, setStates] = useState([])
    const [actions, setActions] = useState([])

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getMessageSettings = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/messageTemplate/${id}`, {
                    cancelToken: source.token
                })
                let formData = result.data
                setFormFields(formData)
            } catch (error) {
                // addAlert('Unables to load vehicle categories')
                history.push('..')
            }
        }

        if (!isAddMode) {
            getMessageSettings().then(() => {
                if (!mountedRef.current) return null
            })
        }

        axios.get(`${BASE_URI}/messageType`, {
            cancelToken: source.token
        }).then((response) => {
            console.log(response.data);
            setTypes(response.data)
        })

        axios.get(`${BASE_URI}/leadStatus`, {
            cancelToken: source.token
        }).then((response) => {
            console.log(response.data);
            setStates(response.data)
        })

        axios.get(`${BASE_URI}/messageAction`, {
            cancelToken: source.token
        }).then((response) => {
            console.log(response.data);
            setActions(response.data)
        })


        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mailMergeFieldArr = [
        {
            variable: '[[LEAD_NAME]]', value: 'Leads Name'
        },
        {
            variable: '[[LEAD_PHONE]]', value: 'Leads Phone'
        },
        {
            variable: '[[LEAD_EMAIL]]', value: 'Leads Email'
        },
        {
            variable: '[[PREQUAL_URL]]', value: 'Link to pre-qualification form'
        },
        {
            variable: '[[SUBJECT]]', value: 'Subject of the enquiry'
        },
        {
            variable: '[[NOW]]', value: 'Current date and time'
        },
        {
            variable: '[[DEALER]]', value: 'Dealer Name'
        },
        {
            variable: '[[MOTORGROUP]]', value: 'Motogroup Name'
        },
        {
            variable: '[[VEHICLE]]', value: 'Vehicle make and model'
        },
        {
            variable: '[[APPOINTMENT]]', value: 'Appointment start date and time'
        },
        {
            variable: '[[AGENT_NAME]]', value: 'Agents Name'
        },
        {
            variable: '[[AGENT_PHONE]]', value: 'Agents Phone'
        },
        {
            variable: '[[AGENT_EMAIL]]', value: 'Agent Email'
        },
        {
            variable: '[[FINANCE_URL]]', value: 'Link to application form'
        }
    ]

    const updateMessageSettings = async (id, fields, setSubmitting, resetForm) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.put(`${BASE_URI}/messageTemplate/${id}`, fields, {
                params: {
                },
                cancelToken: source.token
            })
            //enqueueSnackbar(result.data.message, {variant: 'success'});
            //history.push('/admin/states')
            resetForm()
            history.push('..');
            return result;
        } catch (error) {
            //enqueueSnackbar(error.data.message, {variant: 'error'});
        } finally {
            setSubmitting(false)
        }
    }

    const createMessageSettings = async (fields, setSubmitting, resetForm) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.post(`${BASE_URI}/messageTemplate`, fields, {
                cancelToken: source.token
            })
            //enqueueSnackbar(result.data.message, {variant: 'success'});
            //history.push('/admin/states')
            resetForm()
            history.push('/admin/messaging/');
            return result;
        } catch (error) {
            //enqueueSnackbar(error.data.message, {variant: 'error'});
        } finally {
            setSubmitting(false)
        }
    }

    function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
        setStatus();
        console.log(fields)
        if (isAddMode) {
            createMessageSettings(fields, setSubmitting, resetForm);
        } else {
            updateMessageSettings(id, fields, setSubmitting, resetForm);
        }
    }


    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">
                <Grid item xs={12}>
                    <Formik
                        initialValues={formFields}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                            // name: Yup.string().required('State name is required'),
                            // dateField: Yup.string().required('Select a Model for the vehicle'),
                            // handledBy: Yup.string().required('Enter a title for the Range option'),
                            // reworkField: Yup.string().required('Vehicle image is required')
                        })}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                handleReset
                            } = props

                            return (
                                <Form>
                                    <Typography variant="h6" gutterBottom>
                                        {isAddMode ? 'Add' : 'Edit'} Communications Content
                  </Typography>
                                    <Grid spacing={4} container style={{ marginTop: '0.5rem' }} direction="row">
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                name="messageTypeId"
                                                id="messageTypeId"
                                                required
                                                label="Type"
                                                select
                                                value={values.messageTypeId}
                                                helperText={errors.messageTypeId && touched.messageTypeId ? errors.messageTypeId : ''}
                                                error={errors.messageTypeId && touched.messageTypeId}
                                                onBlur={handleBlur("messageTypeId")}
                                                onChange={handleChange("messageTypeId")}
                                            >
                                                {types && types.map((option, index) => (
                                                    <MenuItem key={index} id={`${option.id}-${index}`} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                name="leadStatusId"
                                                id="leadStatusId"
                                                required
                                                label="State"
                                                select
                                                value={values.leadStatusId}
                                                helperText={errors.leadStatusId && touched.leadStatusId ? errors.leadStatusId : ''}
                                                error={errors.leadStatusId && touched.leadStatusId}
                                                onBlur={handleBlur("leadStatusId")}
                                                onChange={handleChange("leadStatusId")}
                                            >
                                                {states.map((option, index) => (
                                                    <MenuItem key={index} id={`${option.id}-${index}`} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                name="messageActionId"
                                                id="messageActionId"
                                                required
                                                label="actions"
                                                select
                                                value={values.messageActionId}
                                                helperText={errors.messageActionId && touched.messageActionId ? errors.messageActionId : ''}
                                                error={errors.messageActionId && touched.messageActionId}
                                                onBlur={handleBlur("messageActionId")}
                                                onChange={handleChange("messageActionId")}
                                            >
                                                {actions.map((option, index) => (
                                                    <MenuItem key={index} id={`${option.id}-${index}`} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>


                                        <Grid item container xs={12} md={7}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    name="subject"
                                                    required
                                                    fullWidth
                                                    id="subject"
                                                    label="Subject / Short Text (e.g.SMS / WhatsApp)"
                                                    value={values.subject}
                                                    helperText={errors.subject && touched.subject ? errors.subject : ''}
                                                    error={errors.subject && touched.subject}
                                                    onBlur={handleBlur("handledBy")}
                                                    type="text"
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    name="body"
                                                    id="body"
                                                    required
                                                    variant="outlined"
                                                    multiline
                                                    rows={18}
                                                    label="Body"
                                                    value={values.body}
                                                    helperText={errors.body && touched.body ? errors.body : ''}
                                                    error={errors.body && touched.body}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} md={5}>
                                            <Card>
                                                <CardContent>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                        Mail Merge Fields
                          </Typography>
                                                    <TableContainer>
                                                        <Table className={classes.table} size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Variable</TableCell>
                                                                    <TableCell>Value</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {mailMergeFieldArr.map((row, index) => (
                                                                    <StyledTableRow key={index}>
                                                                        <TableCell>{row.variable}</TableCell>
                                                                        <TableCell>{row.value}</TableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item container
                                            justify='flex-end' xs={12}>
                                            <Box mr={3}>
                                                <Button
                                                    onClick={() => {
                                                        handleReset()
                                                        history.push('/admin/messaging')
                                                    }}>
                                                    Cancel
                        </Button>
                                            </Box>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                      </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </Paper>
    )
}