import { useState, useEffect } from "react";
import axios from 'axios';
import { BASE_URI } from "../../../../shared/Constants";
// import moment from 'moment'

export default function FinanceOfferController(id, order, orderBy) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [total, setTotal] = useState(0);


    const getPivotTable = (dataArray) => {

        var result = [];

        for (const [key, value] of Object.entries(dataArray[0])) {
            result.push({ key: key, items: [] });
        }

        for (var idx2 in dataArray) {
            for (var idx in result) {
                result[idx].items.push(dataArray[idx2][result[idx].key]);
            }
        }

        return result;
    }

  useEffect(() => {
    let cancel;
    setLoading(true);
    setError(false);
    const params = {
      };

     axios({
       method: 'GET',
       url: `${BASE_URI}/dealernet/results/${id}`,
       params,
       cancelToken: new axios.CancelToken(c => cancel = c)
     }).then((response) => {
       if (response.data) {
           var headers = response.data.map(itm => { return { id: itm.bankName, numeric: false, label: itm.bankName, sort: true } });
           headers.splice(0, 0, { id: '', numeric: false, label: '', sort: true });

           setHeaders(headers);

           setData(getPivotTable(response.data));

         }

       setLoading(false)
     }).catch( error => {
       if(axios.isCancel(error)) return
       if (error.response) {
         setError(true)
       }
       setLoading(false)
     })

    // return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, order, orderBy]);

  return { loading, error, data, headers, total };
}
