import React, {useContext} from 'react';
import './App.css';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import DashboardContainer from './components/dashboardContainer/DashboardContainer'
import LoginComponent from './components/login/LoginComponent'
import ResetPasswordComponent from './components/login/ResetPasswordComponent'
import {AuthContext} from './shared/context/AuthContext'
import {SnackbarProvider} from 'notistack';
import LoadingProvider from './shared/context/loadingContext'
import {DealerContextProvider} from './shared/DealerContext'
import {AUTH_USER} from './shared/Constants'
import {LeadMainProvider} from "./shared/contexts/LeadMainContext";
import {TabsProvider} from "./shared/contexts/TabsContext";
import {ActionProvider} from "./shared/contexts/ActionTabContext";
import {ReportingProvider} from "./shared/contexts/ReportingContext";

const theme = createMuiTheme({
    overrides: {
        MuiTextField: {
            root: {
                marginBottom: '1rem'
            }
        },
        MuiTypography: {
            h5: {
                textTransform: 'capitalize'
            },
            subtitle2: {
                fontSize: '1rem'
            }
        },
        MuiDialogActions: {
            root: {
                padding: '1.5rem 1rem'
            }
        }
    }
});


function App() {
    let authenticatedUser = localStorage.getItem(AUTH_USER);
    const {isAuthenticated} = useContext(AuthContext);

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}>
                <LoadingProvider>
                    <Router>
                        <DealerContextProvider>
                            <LeadMainProvider>
                                <TabsProvider>
                                    <ReportingProvider>
                                        <ActionProvider>
                                            <Route path="/" render={() => {
                                                if (authenticatedUser || isAuthenticated) return <DashboardContainer />
                                                if (window.location.href.indexOf('resetPassword') <= 0) return <LoginComponent/>
                                            }} />
                                            <Route path="/resetPassword/:token" render={() => {
                                                return <ResetPasswordComponent />
                                            }} />
                                        </ActionProvider>
                                    </ReportingProvider>
                                </TabsProvider>
                            </LeadMainProvider>
                        </DealerContextProvider>
                    </Router>
                </LoadingProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
