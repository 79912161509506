import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    card: {
        background: "lightblue",
    },
}));

export default function TestDriveTabContainer() {
    
    const classes = useStyles();

    let { lead } = useContext(LeadMainContext);

    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <Typography variant="h6">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    Current Selected Date:  

                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography variant="caption">
                                                {new Date(lead.serviceDate).toDateString()}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
