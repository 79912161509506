import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, TextField, Button, Box, Paper, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Formik, Form } from "formik";
import axios from "axios";
import { useSnackbar } from "notistack";
import { BASE_URI } from "../../shared/Constants";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiCardHeader-title": {
			fontSize: "20px",
		},
	},
	flexCenter: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
	},
	stepPadding: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	errorText: {
		fontSize: "0.75rem",
		marginTop: "3px",
		textAlign: "left",
		fontWeight: 400,
		lineHeight: "1.66",
		letterSpacing: "0.03333em",
		color: "#f44336",
	},
	featureInputContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
}));

export default function PackageForm() {

	const { id } = useParams();
	const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const defaultFormValues = {
		dealerId: parseInt(id),
        packageId: 0,
        packageName: 'None'
    }

	const [ loading, setLoading ] = useState(true);
	const [ packages, setPackages ] = useState([]);
    const [ isFormSubmit, setIsFormSubmit ] = useState(false);
	const [ initialFormValues, setInitialFormValues ] = useState(defaultFormValues);

	useEffect(() => {

		let cancel;
		var dealerPackage = null;
		var errors = [];

        const getPackages = () => {
			return axios({
				method: 'GET',
				url: `${BASE_URI}/Package`,
				cancelToken: new axios.CancelToken(c => cancel = c)
			}).then((response) => {
				
				var data = [
					{ id: 0, name: "None" },
					...response?.data
				];
				
				setPackages(data);

			}).catch(error => {
				
				if (axios.isCancel(error)) {
					return;
				}

				errors.push(`Unable to load dealer package options: ${error}`)
				
			});
		}

        const getDealerPackage = () => {
			return axios({
				method: 'GET',
				url: `${BASE_URI}/DealerPackage/GetForDealer/${id}`,
				cancelToken: new axios.CancelToken(c => cancel = c)
			}).then((response) => {
				
				dealerPackage = response?.data;

			}).catch(error => {
				
				if (axios.isCancel(error)) {
					return;
				}

				errors.push(`Unable to load dealer selected package: ${error}`)
				
			});
		}

		Promise.allSettled([
			getPackages(),
			getDealerPackage()
		]).then(() => {
			
			setLoading(false);

			if (dealerPackage != null && dealerPackage.id > 0) {
				setInitialFormValues(dealerPackage)
			}

			if (errors.length > 0) {
				enqueueSnackbar(`Some errors occurred while loading the form data: ${errors.map(e => e)}`, { variant: 'error' });	
			}

		}).catch((error) => {
			
			setLoading(false);
			enqueueSnackbar(`An unexpected error occurred while loading the form data: ${error}`, { variant: 'error' });

		});

	}, []);

	const onFormSubmit = (values) => {

		var promise = values?.id > 0 ? updateDealerPackage(values) : createDealerPackage(values);

		Promise.allSettled([
			promise
		]).then(() => {
			
			setIsFormSubmit(false);

		}).catch((error) => {
			
			setIsFormSubmit(false);
			enqueueSnackbar(`An unexpected error occurred while submitting the form data: ${error}`, { variant: 'error' });

		});

	};

	const createDealerPackage = (values) => {

		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

        return axios({
            method: 'POST',
            url: `${BASE_URI}/DealerPackage`,
            data: values,
            cancelToken: source.token
        }).then((response) => {
			
            setInitialFormValues(response?.data || values);

            enqueueSnackbar("Successfully saved the dealer package", { variant: "success" });

        }).catch((error) => {

            if (axios.isCancel(error)) {
				return;
			}

            enqueueSnackbar(`Failed to save the dealer package: ${error}`, { variant: 'error' });
			
        })

	};

	const updateDealerPackage = (values) => {
		
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

        return axios({
            method: 'PUT',
            url: `${BASE_URI}/DealerPackage`,
            data: values,
            cancelToken: source.token
        }).then((response) => {
			
            setInitialFormValues(response?.data || values);

            enqueueSnackbar("Successfully updated the dealer package", { variant: "success" });

        }).catch((error) => {

            if (axios.isCancel(error)) {
				return;
			}

            enqueueSnackbar(`Failed to update the dealer package: ${error}`, { variant: 'error' });
			
        })

	};

    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)
    }

	return (
		<Paper className={classes.paper}>
			<Grid container spacing={4} className={classes.stepPadding} direction="column">
				<Grid item xs={12}>

					<Formik
						initialValues={initialFormValues}
						enableReinitialize={true}
						onSubmit={async (values, actions) => {						
							setIsFormSubmit(true);
							onFormSubmit(values);
						}}
					>
						{(props) => {
							const { 
								values,
								touched, 
								errors, 
								handleBlur, 
								handleChange, 
								handleReset,
								isSubmitting,
								setFieldValue 
							} = props;
							return (
								<Form>

									<Typography variant="h6" gutterBottom>
										Package
									</Typography>

									{
										loading && 
										<Grid container spacing={4} style={{ marginTop: "1rem" }} justify="center" direction="row">
											<Grid item xs={12} md={1}>
												<CircularProgress />
											</Grid>
										</Grid>
									}

									{
										!loading &&
										<Grid container spacing={4} style={{ marginTop: "1rem" }} justify="space-around" direction="row">
											<Grid item xs={12} md={6}>
												<TextField
													id="packageName"
													label="Select Dealer Package"
													value={values.packageName}
													select
													fullWidth
													helperText={errors.packageName && touched.packageName ? errors.packageName : ""}
													error={errors.packageName && touched.packageName}
													onBlur={handleBlur}
													onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'packageName', 'packageId')}
												>
													{packages.map((option) => (
														<MenuItem key={option.id} id={option.id} value={option.name}>
															{option.name}
														</MenuItem>
													))}
												</TextField>
											</Grid>
	
											<Grid item container justify='flex-end' xs={12}>
												<Box mr={3}>
													<Button
														disabled={isSubmitting || isFormSubmit}
														onClick={() => {
															handleReset();
															window.history.back();
														}}>
														Cancel
													</Button>
												</Box>
												<Button type="submit" variant="contained" color="primary" disabled={isSubmitting || isFormSubmit}>
													Submit
												</Button>
											</Grid>
										</Grid>
									}

								</Form>
							);
						}}
					</Formik>

				</Grid>
			</Grid>
		</Paper>
	);
}
