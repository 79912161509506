import {useState, useEffect, useContext} from "react";
import axios from 'axios';
import {BASE_URI} from "../../shared/Constants";
import {LeadMainContext} from "../../shared/contexts/LeadMainContext";

export default function Leads(id, pageNumber, pageSize, order, orderBy, state, date, source, agent, leadLike, motorgroupId, dealerId, dealerIDs) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [ranges, setRanges] = useState([]);
    const [fullRange, setFullRange] = useState([]);
    const [total, setTotal] = useState(0);

    let {...context} = useContext(LeadMainContext);
    let axiosSource;

    useEffect(() => {
        
        if (typeof axiosSource != typeof undefined) {
            source.cancel("Operation canceled due to new request.");
        }

        axiosSource = axios.CancelToken.source();

        setLoading(true);
        setError(false);

        const params = {};

        if (pageNumber) {
            params.pageNumber = pageNumber;
        }

        if (pageSize) {
            params.pageSize = pageSize
        }

        if (order) {
            params.order = order.toUpperCase();
        }

        if (orderBy) {
            params.orderBy = orderBy;
        }

        if (date) {
            let dateTemp = "";
            params.since = dateTemp.concat(date.getFullYear(), '/', (date.getMonth() + 1), '/', date.getDate());
        } else {
            let dateTemp = "";
            params.since = dateTemp.concat(context.dates.getFullYear(), '/', (context.dates.getMonth() + 1),
                '/', context.dates.getDate());
        }

        if (state) {
            params.state = state;
        } else {
            params.state = context.leadState.id;
        }

        if (source) {
            params.sourceId = source;
        }

        if (leadLike) {
            params.leadName = leadLike;
        }

        if (agent) {
            params.user = agent;
        }

        if (motorgroupId) {
            params.motorgroupId = motorgroupId;
        }

        if (dealerId) {
            params.dealerId = dealerId;
        }

        if (dealerIDs?.length > 0) {
            params.dealerIDs = dealerIDs.join();
        }
        
        // Get the range for the current page number
        axios({
            method: 'GET',
            url: `${BASE_URI}/leads`,
            params,
            cancelToken: axiosSource.token
        }).then((response) => {
            if (response.data?.list) {
                setRanges(response.data?.list)
            }
            setTotal(response.data?.total != null ? response.data.total : 0);

            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })

        params.pageSize = 0;

        // Get the full range
        axios({
            method: 'GET',
            url: `${BASE_URI}/leads`,
            params,
            cancelToken: axiosSource.token
        }).then((response) => {
            
            if (response.data?.list) {
                setFullRange(response.data?.list)
            }

            setLoading(false)

        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })

    }, [id, pageNumber, pageSize, order, orderBy, date, state, source, agent, leadLike, motorgroupId, dealerId, dealerIDs]);

    return {loading, error, ranges, fullRange, total};
}