import MaskedInput, {conformToMask} from 'react-text-mask'
import React from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/[0-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default TextMaskCustom;


const CurrencyMask = {
  prefix: 'R ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

export const CurrencyValue = ({ value, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...CurrencyMask
  })

  const roundedNumber = (value ?? 0).toFixed()
  const convertedCurrency = conformToMask(
    roundedNumber.toString(),
    currencyMask(roundedNumber.toString()),
    {}
  )

  return convertedCurrency.conformedValue;
}


export const CurrencyInput = (props) => {
  const { inputRef, ...other } = props;
  const currencyMask = createNumberMask({
    ...CurrencyMask
  })

  return <MaskedInput mask={currencyMask}
                      ref={(ref) => {
                        inputRef(ref ? ref.inputElement : null);
                      }}
                      {...other} />

}

export const CurrencyConverter = (amount) => {
  if (amount && typeof amount === 'string') {
    let removeCurrency = amount.replace(/R/g, '')
    let removeSpaces = removeCurrency.replace(/\s+/g, '')
    return parseFloat(removeSpaces)
  } else {
    return amount
  }
}
