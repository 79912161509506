import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from '@material-ui/core/AppBar';
import { Box } from '@material-ui/core'
import DealerAdminForm from './DealerAdminForm'
import DealerGetScore from './DealerGetScore'
import DealerWebsiteAdminForm from './DealerWebsiteAdminForm'
import DealerStaff from './DealerStaff'
import DealerDepartments from './DealerDepartments'
import DealerPrivateNotes from './DealerPrivateNotes'
import DealerSettingForm from './DealerSettingForm'
import DealerFacebookLeads from './DealerFacebookLeads'
import DealerImportersForm from './DealerImportersForm'
import DealerActivatedServiceForm from './DealerActivatedServiceForm'
import DealerSocialMedia from './DealerSocialMedia'
import DealerSettingsAndFunctions from './DealerSettingsAndFunctions'
import PackageForm from './PackageForm'
import RoundRobinServiceTab from './RoundRobinServiceTab'
import axios from 'axios'
import { useParams } from "react-router-dom";
import { BASE_URI } from '../../shared/Constants'
import { AuthContext } from '../../shared/context/AuthContext'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2rem',
        width: '100%',
        overflow: 'auto'
    }
}));


export default function DealerContainer({ match }) {
    const classes = useStyles();
    let history = useHistory();
    const [tabIndex, setTabIndex] = useState(0);
    const [dealer, setDealer] = useState();
    const { id } = useParams();
    const { role } = useContext(AuthContext);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const hideForSelectionDealerRole = () => {

        const parsedData = window.location.pathname.split("/");

        if (parsedData?.length > 1 && parsedData[1] == "SelectionDealer") {
            return hasRole('SelectionDealer');
        } else {
            return false;
        }

    };

    function hideForMasterDealer() {
        
        let isMaster = dealer?.name?.toLowerCase().includes("master");

        return isMaster;

    }
    const hasRole = (roleToCheck) => {

        if (!role) {
            return false;
        }

        return role.filter(itm => itm == roleToCheck).length > 0;
    }

    const tabs = [
        { id: 0, label: 'Dealer Info', comp: <DealerAdminForm history={history} match={match} /> },
        { id: 1, label: 'Dealer Staff', comp: <DealerStaff />, hide: !hasRole('Admin') },
        { id: 2, label: 'Website', comp: <DealerWebsiteAdminForm />, hide: hideForSelectionDealerRole() },
        { id: 3, label: 'Private Notes', comp: <DealerPrivateNotes />, hide: hideForSelectionDealerRole() },
        { id: 4, label: 'Lead Routing', comp: <DealerSettingForm /> },
        { id: 5, label: 'Stock Importer', comp: <DealerImportersForm /> },
        { id: 6, label: 'Social Media', comp: <DealerSocialMedia />, hide: hideForSelectionDealerRole() },
        { id: 7, label: 'Colors & Icons', comp: <DealerSettingsAndFunctions />, hide: hideForSelectionDealerRole() },
        { id: 8, label: 'Activated Services', comp: <DealerActivatedServiceForm />, hide: hideForSelectionDealerRole() },
        { id: 9, label: 'Facebook Leads', comp: <DealerFacebookLeads />, hide: hideForSelectionDealerRole() },
        { id: 10, label: 'Get Score', comp: <DealerGetScore match={match} />, hide: hideForSelectionDealerRole() },
        { id: 11, label: 'Package', comp: <PackageForm />, hide: !hideForSelectionDealerRole() },
        { id: 12, label: 'Round Robin', comp: <RoundRobinServiceTab />, hide: !hideForMasterDealer() || hideForSelectionDealerRole() }
    ];

    const getDealer = async () => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.get(`${BASE_URI}/dealers/${id}`, {
                cancelToken: source.token,
            });

            setDealer(result.data);

            return result;
        } catch (error) {
            //enqueueSnackbar(error.data.message, {variant: 'error'});
        } finally {
        }
    };

    useEffect(() => {

        getDealer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root} >
            {dealer &&
                <h2>Editing Dealer: {dealer.name}</h2>
            }

            <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleChange} aria-label="dealer tabs" >
                    {
                        tabs.filter(tab => !tab.hide).map((step, index) => {
                            return <Tab disableRipple label={step.label} />
                        })
                    }
                </Tabs>
            </AppBar>
            <Box mt={4}>
                {
                    tabs.filter(tab => !tab.hide).map((step, index) => {
                        return <React.Fragment key={step.id}> {tabIndex === index ? step.comp : null} </React.Fragment>
                    })
                }
            </Box>
        </div>
    );
}