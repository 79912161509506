import React, {useState} from 'react';
import {StyledTab, StyledTabs} from '../../../shared/StyledTabs'
import ColorListContainer from './ColourListContainer'
import {
  Typography,
  Box
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core/styles/index'
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
    display: 'flex',
    paddingLeft: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2.5rem'
  }
}));


export default function ColourTabs({ navigate, modelId, dealerModelId, dealerVariantId }) {
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const tabs = [
    { id: 0, label: 'Interior',  comp: <ColorListContainer isInterior={true} dealerModelId={dealerModelId} />},
    { id: 1, label: 'Exterior', comp: <ColorListContainer isInterior={false} dealerModelId={dealerModelId} />}
  ];

  return(
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h4">
          Color Options
        </Typography>
        <Button variant="contained" endIcon={<NavigateNextIcon />} color="primary" 
                onClick={() => {
                  navigate('accessories', modelId, dealerModelId, dealerVariantId);
                }}
        >
          View Accessories
        </Button>
      </Toolbar>
      <StyledTabs variant={ matches ? 'standard': 'scrollable'}
                  centered={matches}
                  scrollButtons={ matches ? 'auto' : 'on'}
                  value={tabIndex} onChange={handleTabChange}>
        {
          tabs.filter(tab => !tab.hide).map((step) =>
            <StyledTab label={`${step.label}`}
                       key={step.id} disabled={step.disabled} />
          )
        }
      </StyledTabs>
      <Box mt={4}>
        {
          tabs.filter(tab=>!tab.hide).map((step, index) => {
            return <React.Fragment key={step.id}> {tabIndex === index ? step.comp: null} </React.Fragment>
          })
        }
      </Box>
    </React.Fragment>
  )
}