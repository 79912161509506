import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
// import moment from 'moment'

export default function StateListFilter(searchString, pageNumber, pageSize, order, orderBy) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [states, setStates] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        setStates([])
    }, [searchString, pageNumber, pageSize, order, orderBy])

    const sampleData = [
        { id: 0, name: 'New Lead', handledBy: 'Qualifier' },
        { id: 1, name: 'Prequalified Approved', handledBy: 'Agent' },
        { id: 2, name: 'Prequalified Declined', handledBy: 'Finance' },
        { id: 3, name: 'Application Completed', handledBy: 'Qualifier' },
        { id: 4, name: 'Application Submitted', handledBy: 'Sales' },
        { id: 5, name: 'Application Approved', handledBy: 'brand expert' },
    ];

    useEffect(() => {
        let cancel
        setLoading(true)
        setError(false)
        const params = {
            pageNumber: pageNumber + 1,
            pageSize
        }
        if (searchString) { params.searchString = searchString }
        if (order) { params.order = order.toUpperCase() }
        if (orderBy) { params.orderBy = orderBy }
        axios({
            method: 'GET',
            url: `${BASE_URI}/LeadStatus`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                setStates(response.data)
            }
            setTotal(response.data?.length)
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })

        // return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, pageNumber, pageSize, order, orderBy])

    return { loading, error, states, total }
}