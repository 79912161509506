import React, { useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form, Field} from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {Typography} from "@material-ui/core";
import VehicleInnerTabContainer from "./VehicleInnerTabContainer";
import { useParams } from "react-router-dom";
import axios from 'axios'
import { BASE_URI } from '../../../../shared/Constants'

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    stepPaddingButton: {
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "2rem",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    card: {
        background: "lightblue",
    },
    cardBottom: {
        marginBottom: "1rem",
    },
}));

export default function VehicleTabContainer() {
    const classes = useStyles();
    const { state, tab, id } = useParams();
    const [vehicle, setVehicle] = useState({})

    React.useEffect(() => {
        axios.get(`${BASE_URI}/LeadVehicles/lead/${id}`).then((result) => {
            setVehicle(result.data);
        });
    }
    ,[]);

    return (
        <div className={classes.root}>
            {state != "prequalifiedDecl" && (
                <Formik
                    initialValues={vehicle}
                    enableReinitialize={true}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            console.log(values);
                            actions.setSubmitting(true);
                        }, 100);
                    }}
                >
                    {(props) => {
                        return (
                            <Form>
                                <Grid container direction="row">
                                    <Grid item className={classes.stepPadding} xs={12}>
                                        <Card className={classes.cardBottom}>
                                            <CardContent>
                                                <Typography variant="h6">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        Current Selected Vehicle:
                                                        <Card className={classes.card}>
                                                            <CardContent>
                                                                <Typography variant="caption">
                                                                    {vehicle.displayName}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </div>
                                                </Typography>
                                                <Grid container direction="row">
                                                    <Grid
                                                        item
                                                        className={classes.stepPadding}
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <TextField
                                                            id="vinNumber"
                                                            name="vinNumber"
                                                            label="VIN Number"
                                                            value={props.values.vinNumber}
                                                            helperText={
                                                                props.touched.vinNumber
                                                                    ? props.errors.vinNumber
                                                                    : ""
                                                            }
                                                            error={
                                                                props.touched.vinNumber &&
                                                                Boolean(props.errors.vinNumber)
                                                            }
                                                            onChange={(event) => {
                                                                props.handleChange(event);
                                                                props.setFieldTouched("vinNumber", true, false);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item className={classes.stepPaddingButton}>
                                                    <Button
                                                        variant="contained"
                                                        style={{margin: "1rem"}}
                                                        color="primary"
                                                        type="submit"
                                                        disabled={!props.isValid}
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        style={{margin: "1rem"}}
                                                        color="primary"
                                                        onClick={props.resetForm}
                                                    >
                                                        Reset
                                                    </Button>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>

                            </Form>
                        );
                    }}
                </Formik>
            )}
            <Grid container direction="row">
                <Grid item xs={12}>
                    <VehicleInnerTabContainer/>
                </Grid>
            </Grid>
        </div>
    );
}
