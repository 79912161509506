import { useState, useEffect } from "react";
import axios from 'axios';
import { BASE_URI } from "../../../../shared/Constants";
// import moment from 'moment'

export default function EventsController(id) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setData([]);
    }, [id]);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);
        const params = {
            leadId : id
        };

        axios({
            method: 'GET',
            url: `${BASE_URI}/leadAction`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data && response.data.list) {
                setData(response.data.list)
            }
            setTotal(response.data.total)
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })

        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { loading, error, data, total };
}
