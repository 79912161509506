import React, { useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../../../shared/TableHeadersSort";
import FinanceOfferController from "./FinanceOfferController";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "45ch",
    },
  },
  stepPadding: {
    marginTop: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  export: {
    width: "45ch",
  },
}));

export default function FinanceOfferTabContainer() {
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("title");
  const [elementRef, setElementRef] = useState("");
  const { id } = useParams();
  const { loading, error, data, headers, total } = FinanceOfferController(id);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  return (
    <React.Fragment>
      <Card className={classes.stepPadding}>
        <CardContent>
          <TableContainer ref={(el) => setElementRef(el)}>
            <Table aria-label="ranges table">
              <TableHeadersSort
                headCells={headers}
              />
              <TableBody>
                {data.map((row) => (
                                  
                    <StyledTableRow key={row.key}>
                        <StyledTableCell>{row.key}</StyledTableCell>
                        {row.items.map((itm) => (
                            <StyledTableCell>{itm}</StyledTableCell>
                            ))
                        }
                    </StyledTableRow>
                ))}
                              {(!data.length || data.length === 0) && !loading && (
                  <StyledTableRow>
                    <TableCell
                                          colSpan={headers.length}
                      align="center"
                      style={{ borderBottom: 0 }}
                      className="py-3"
                    >
                      <Typography variant="h6" color="textSecondary">
                        No finance offers yet
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                )}
                {loading && (
                  <StyledTableRow>
                    <TableCell
                      colSpan={headers.length}
                      align="center"
                      style={{ borderBottom: 0 }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
