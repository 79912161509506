import React, { useEffect, useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form, Field} from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import {BASE_URI} from "../../../../shared/Constants";
import { NEWUSED } from "../../../../shared/SelectorValues";
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "1rem",
    },
    stepPaddingButton: {
        marginTop: "1rem",
        marginLeft: "1rem",
    },
    card: {
        background: "lightblue",
    },
}));

export default function OtherTabContainer() {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [makeData, setMakeData] = useState([]);
    const [modelData, setModelData] = useState([]);
    const [variantData, setVariantData] = useState([]);
    const [vehicle, setVehicle] = useState({})
    const [makeId, setMakeId] = useState({})
    const [modelId, setModelId] = useState({})
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        axios.get(`${BASE_URI}/LeadVehicles/lead/${id}`).then((result) => {
            setVehicle(result.data);
        });

        let cancel;
        axios({
            method: 'GET',
            url: `${BASE_URI}/makes`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setMakeData(response.data?.list)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })
    }, []);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);
        const params = {};
        params.makeId = makeId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/models`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            console.log(response.data);
            if (response.data?.list) {
                setModelData(response.data?.list)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })

    }, [makeId]);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);
        const params = {};
        params.modelId = modelId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/variants`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            console.log(response.data);
            if (response.data?.list) {
                setVariantData(response.data?.list)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })

    }, [modelId]);

    const values = {
        vehiclePrice: "",
        make: "",
        makeId: "",
        model: "",
        modelId: "",
        variant: "",
        variantId: "",
        price: "",
        newUsed: "Used",
    };

    const handleMakeChange = (e) => {
        setModelData([]);

        if (e != null)
            setMakeId(e.id);
    };

    const handleModelChange = (e) => {
        setVariantData([]);

        if (e != null)
            setModelId(e.id);
    };

    const createVehicleLead = (values) => {

        let vehicleParam = {}
        vehicleParam.LeadId = parseInt(values.leadId)
        vehicleParam.VariantId = values.variantId
        vehicleParam.IsNew = values.newUsed === "NEW";
        vehicleParam.Price = parseInt(values.price);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leadVehicles`,
            data: vehicleParam,
            cancelToken: source.token
        }).then((responseSecond) => {

            enqueueSnackbar(`Successfully added a vehicle`, { variant: 'success' });

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get complete the request", { variant: 'error' });
            //setLoader(false);
        })
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={values}
                isInitialValid={false}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        createVehicleLead({ ...values, leadId: id });

                        actions.setSubmitting(true);
                    }, 100);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Card className={classes.stepPadding}>
                                <CardContent>
                                    <Typography variant="h6" style={{marginBottom: "1rem"}}>
                                        Select any vehicle
                                    </Typography>
                                    <Typography variant="body1" style={{marginBottom: "1rem"}}>
                                        The current selected vehicle is saved with a price{" "}
                                        <b>R{vehicle.price}</b>. Change it by selecting
                                        the same Make and Variant here and stating the price
                                    </Typography>
                                    <Grid container direction="row">
                                        <Grid item className={classes.stepPadding} xs={12} md={3} lg={3}>
                                            <Autocomplete
                                                id="make"
                                                name="make"
                                                value={{
                                                    id: props.values.makeId.toString(),
                                                    name: props.values.make.toString(),
                                                }}
                                                options={makeData}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => {
                                                    return option.name === value.name;
                                                }}
                                                onChange={(event, value) => {
                                                    props.setFieldValue("make", value ? value.name : "");
                                                    props.setFieldValue("makeId", value ? value.id : "");
                                                    props.setFieldTouched("make", true, false);
                                                    props.setFieldTouched("makeId", true, false);
                                                    handleMakeChange(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={
                                                            props.touched.make && Boolean(props.errors.make)
                                                        }
                                                        helperText={
                                                            props.touched.make ? props.errors.make : ""
                                                        }
                                                        label="Select A Make"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item className={classes.stepPadding} xs={12} md={3} lg={3}>
                                            <Autocomplete
                                                id="model"
                                                name="model"
                                                value={
                                                {
                                                    id: props.values.modelId.toString(),
                                                    title: props.values.model.toString(),
                                                }}
                                                options={modelData}
                                                disabled={modelData.length <= 0}
                                                getOptionLabel={(option) => { return option.title }}
                                                getOptionSelected={(option, value) => {
                                                    return option.title === value.title;
                                                }}
                                                onChange={(event, value) => {
                                                    props.setFieldValue("model", value ? value.title : "");
                                                    props.setFieldValue("modelId", value ? value.id : "");
                                                    props.setFieldTouched("model", true, false);
                                                    props.setFieldTouched("modelId", true, false);

                                                    handleModelChange(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={
                                                            props.touched.model &&
                                                            Boolean(props.errors.model)
                                                        }
                                                        helperText={
                                                            props.touched.model ? props.errors.model : ""
                                                        }
                                                        label="Select A Model"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item className={classes.stepPadding} xs={12} md={3} lg={3}>
                                            <Autocomplete
                                                id="variant"
                                                name="variant"
                                                value={{
                                                    id: props.values.variantId.toString(),
                                                    variantName: props.values.variant.toString(),
                                                }}
                                                options={variantData}
                                                disabled={variantData.length <= 0}
                                                getOptionLabel={(option) => option.variantName}
                                                getOptionSelected={(option, value) => {
                                                    return option.variantName === value.variantName;
                                                }}
                                                onChange={(event, value) => {
                                                    props.handleChange(event);
                                                    props.setFieldValue("variant", value ? value.variantName : "");
                                                    props.setFieldValue("variantId", value ? value.id : "");
                                                    props.setFieldTouched("variant", true, false);
                                                    props.setFieldTouched("variantId", true, false);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={
                                                            props.touched.variant &&
                                                            Boolean(props.errors.variant)
                                                        }
                                                        helperText={
                                                            props.touched.variant ? props.errors.variant : ""
                                                        }
                                                        label="Select A Variant"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item className={classes.stepPadding} xs={12} md={2} lg={2}>
                                            <TextField
                                                id="price"
                                                name="price"
                                                label="Price"
                                                value={props.values.price}
                                                helperText={
                                                    props.touched.price ? props.errors.price : ""
                                                }
                                                error={
                                                    props.touched.price && Boolean(props.errors.price)
                                                }
                                                onChange={(event) => {
                                                    props.handleChange(event);

                                                    props.setFieldTouched("price", true, false);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item className={classes.stepPadding} xs={12} md={2} lg={2}>
                                            <Autocomplete
                                                id="newUsed"
                                                name="newUsed"
                                                value={{
                                                    name: props.values.newUsed.toString(),
                                                }}
                                                options={NEWUSED}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => {
                                                    return option.name === value.name;
                                                }}
                                                onChange={(event, value) => {
                                                    props.handleChange(event);
                                                    props.setFieldValue("newUsed", value ? value.name : "");

                                                    props.setFieldTouched("newUsed", true, false);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={
                                                            props.touched.newUsed &&
                                                            Boolean(props.errors.newUsed)
                                                        }
                                                        helperText={
                                                            props.touched.newUsed ? props.errors.newUsed : ""
                                                        }
                                                        label="New / Used"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                            <Button
                                                variant="contained"
                                                style={{margin: "1rem"}}
                                                color="primary"
                                                type="submit"
                                                disabled={!props.isValid}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={props.resetForm}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
