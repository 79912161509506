import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI } from '../Constants'
import { useSnackbar } from 'notistack'

export const LeadMainContext = createContext();

export const LeadMainProvider = ({ children }) => {

    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);

    const [dates, setDates] = useState(todayDate)
    const [leadState, setLeadState] = useState({ name: "", id: 0, acronym: "" })
    const [lead, setLead] = useState({})
    const [leadStates, setLeadStates] = useState([]);
    const [stateLoader, setStateLoader] = useState(false)

    const [stateParam, setStateParam] = useState('')
    const [leadIdParam, setLeadIdParam] = useState(0);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const updateDate = (date) => {
        setDates(new Date(date))
    }

    useEffect(() => {
        reloadState();
        
    }, [leadIdParam]);

    const updateState = (state) => {
        setStateLoader(true);
        axios.get(`${BASE_URI}/leads/${lead.id}/state/${state}`).then(() => {
            reloadState();
            enqueueSnackbar("Updated state", { variant: 'success' });
            setStateLoader(false)
        });
    }

    const reloadState = () => {
        if (leadIdParam == 0)
            return;

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'GET',
            url: `${BASE_URI}/leadStatus`,
            cancelToken: source.token
        }).then((statesResponse) => {
            if (statesResponse.data) {
                setLeadStates(statesResponse.data);

                axios({
                    method: 'GET',
                    url: `${BASE_URI}/leads/${leadIdParam}`,
                    cancelToken: source.token
                }).then((response) => {
                    if (response.data) {
                        setLead(response.data);
                        updateLocalState(statesResponse.data, response.data.leadStatusId);

                    }
                });
            }
        });
    }

    const updateLocalState = (states, newStateId) => {
        let temp = states.find(x => x.id == newStateId);

        if (temp != null) {
            setLeadState(temp)
        }
    }

    return (
        <LeadMainContext.Provider value={{ updateDate, dates, leadState, lead, setLeadIdParam, setStateParam, setLeadState, reloadState, updateState, stateLoader}}>
            {children}
        </LeadMainContext.Provider>
    );
}
