import React, { useState, useEffect, useContext } from "react";
import { 
    Button, 
    CircularProgress, 
    Grid, 
    IconButton, 
    InputAdornment, 
    Paper, 
    TextField, 
    Typography 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableContainer, TableBody, TablePagination } from "@material-ui/core";
import { Clear, Edit, OpenInBrowser } from '@material-ui/icons';
import PreviewIcon from '@mui/icons-material/Preview';
import axios from "axios";
import { useSnackbar } from "notistack";
import { BASE_URI } from "../../shared/Constants";
import { DealerContext } from '../../shared/DealerContext';
import { useStyles, StyledTableRow, StyledTableCell } from '../../shared/CommonStyles';
import { GetErrorMessage } from '../../shared/CommonFunctions';
import TableHeadersSort from "../../shared/TableHeadersSort";
import ManageVehiclesDetailsPopup from "./ManageVehiclesDetailsPopup";

const useStylesLocal = makeStyles((theme) => ({
    thumbImg: {
        maxWidth: "100px",
        padding: "10px 0"
    }
}));

export default function ManageVehiclesTable({ isNew }) {
    
    const classes = useStyles({ stickyHeaderTableHeight: window.innerHeight });
    const localClasses = useStylesLocal();
	const { selectedMotorGroup: selectedDealer } = useContext(DealerContext);
    const { enqueueSnackbar } = useSnackbar();

    const [ loading, setLoading ] = useState(true);
    const [ loadingDetails, setLoadingDetails ] = useState(false);
    const [ vehicles, setVehicles ] = useState([]);
    const [ searchString, setSearchString ] = useState("");
    const [ popUp, setPopUp ] = useState(false);
    const [ vehicleDetailPairs, setVehicleDetailPairs ] = useState([]);
    const [ vehicleDetailImages, setVehicleDetailImages ] = useState([]);

    const [ order, setOrder ] = useState("asc");
    const [ orderBy, setOrderBy ] = useState("modelName");
    const [ page, setPage ] = useState(0);
    const [ pageSize, setPageSize ] = useState(25);
    
    const headCellsNew = [
        { id: "image", label: "Preview", align: "center" },
        { id: "mmCode", label: "M&M Code", sort: true, align: "center" },
        { id: "makeName", label: "Make", sort: true },
        { id: "modelName", label: "Model", sort: true },
        { id: "variantName", label: "Details", sort: true },
        { id: "dealerName", label: "Dealer", sort: true }
    ];

    const headCellsUsed = [
        { id: "image", label: "Preview", align: "center" },
        { id: "stockNo", label: "Stock No.", sort: true, align: "center" },
        { id: "brand", label: "Make", sort: true },
        { id: "model", label: "Model", sort: true },
        { id: "modelRange", label: "Details", sort: true },
        { id: "dealershipName", label: "Dealer", sort: true }
    ];

    const headCells = isNew ? headCellsNew : headCellsUsed;

    useEffect(() => {

        if (!selectedDealer.motorgroupID) {
            return;
        }

        setLoading(true);

        Promise.allSettled([
            isNew ? loadNewVehicles() : loadUsedVehicles()
        ])
        .catch((error) => {

			enqueueSnackbar(GetErrorMessage(error, "An unexpected error occurred while loading the vehicles"), { variant: 'error' });

        }).finally(() => {

            setLoading(false);

        });
        
    }, [selectedDealer]);

    function loadNewVehicles() {

        return new Promise((res, rej) => {

            let params = {};

            if (selectedDealer.motorgroupID > 0) {
                params.motorGroupId = parseInt(selectedDealer.motorgroupID);
            } else {
                params.dealerId = parseInt(selectedDealer.id);
            }

            axios({
                method: 'GET',
                url: `${BASE_URI}/DealerVariants/FullDisplayDetails`,
                params,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                let data = [
                    ...response?.data
                ];
                
                setVehicles(data);
                res(data);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the vehicles list"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function loadNewVehicleDetails(dealerVariantId) {

        let images = [];
        let params = {
            dealerVariantId: dealerVariantId
        };

        const loadInteriors = () => {

            return new Promise((res, rej) => {

                axios({
                    method: 'GET',
                    url: `${BASE_URI}/colours/interiors`,
                    params,
                    cancelToken: axios.CancelToken.source().token
                }).then((response) => {
                    
                    let data = [
                        ...response?.data?.list
                    ];
                    
                    images = [
                        ...images,
                        ...data?.map(c => c.image)
                    ];
                    
                    res(data);
        
                }).catch(error => {
                    
                    if (axios.isCancel(error)) {
                        return;
                    }
        
                    enqueueSnackbar(GetErrorMessage(error, "Unable to load the vehicle interior details"), { variant: 'error' });
                    rej(null);

                });

            });
        
        };
        
        const loadExteriors = () => {

            return new Promise((res, rej) => {

                axios({
                    method: 'GET',
                    url: `${BASE_URI}/colours/exteriors`,
                    params,
                    cancelToken: axios.CancelToken.source().token
                }).then((response) => {

                    let data = [
                        ...response?.data?.list
                    ];
                    
                    images = [
                        ...images,
                        ...data?.map(c => c.image)
                    ];
                    
                    res(data);
        
                }).catch(error => {
                    
                    if (axios.isCancel(error)) {
                        return;
                    }
        
                    enqueueSnackbar(GetErrorMessage(error, "Unable to load the vehicle exterior details"), { variant: 'error' });
                    rej(null);

                });

            });
        
        };
        
        setLoadingDetails(true);

        Promise.allSettled([
            loadInteriors(),
            loadExteriors()
        ])
        .catch((error) => {

			enqueueSnackbar(GetErrorMessage(error, "An unexpected error occurred while loading the vehicle details"), { variant: 'error' });

        }).finally(() => {

            setVehicleDetailImages(images);
            setLoadingDetails(false);

        });
        
        // Populate the vehicle information into lable-value pairs
        let vehicle = vehicles.find(v => v.id == dealerVariantId);
        let detailPairs = [
            { label: "M&M Code", value: vehicle.mmCode },
            { label: "Variant", value: vehicle.variantName },
            { label: "Model", value: vehicle.modelName },
            { label: "Make", value: vehicle.makeName },
            { label: "Price Range", value: vehicle.priceRange },
        ];

        setVehicleDetailPairs(detailPairs);

    }

    function loadUsedVehicles() {

        return new Promise((res, rej) => {

            let params = {};

            if (selectedDealer.motorgroupID > 0) {
                params.motorGroupId = parseInt(selectedDealer.motorgroupID);
            } else {
                params.dealerId = parseInt(selectedDealer.id);
            }

            axios({
                method: 'GET',
                url: `${BASE_URI}/Stock`,
                params,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                let data = [
                    ...response?.data?.list
                ];
                
                setVehicles(data);
                res(data);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the vehicles list"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function loadUsedVehicleDetails(stockId) {

        return new Promise((res, rej) => {

            setLoadingDetails(true);

            axios({
                method: 'GET',
                url: `${BASE_URI}/Stock/${stockId}`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                let data = response?.data;

                if (!data) {
                    enqueueSnackbar("No vehicle details available", { variant: 'warning' });
                    res(null);
                    return;
                }
                
                // Populate the vehicle information into lable-value pairs
                let detailPairs = [
                    { label: "Stock Number", value: data.stockNo },
                    { label: "Variant", value: data.modelRange },
                    { label: "Model", value: data.model },
                    { label: "Make", value: data.brand },
                    { label: "Model Year", value: data.year || "Not provided" },
                    { label: "Mileage", value: data.mileage || "Not provided" },
                    { label: "Fuel Type", value: data.fuelType || "Not provided" },
                    { label: "Transmission", value: data.transmission || "Not provided" },
                    { label: "Colour", value: data.colour || "Not provided" },
                    { label: "Price Range", value: data.price || "Not provided" },
                ];
                
                setVehicleDetailPairs(detailPairs);
                setVehicleDetailImages(data.mainUrls);
                res(data);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }

                enqueueSnackbar(GetErrorMessage(error, "Unable to load the vehicles details"), { variant: 'error' });
                rej(null);

            }).finally(() => {

                setLoadingDetails(false);
    
            });

        });

    }

    function getDisplayVehicles() {

        try {
            
            // Filter, slice and sort the vehicles list
            let filteredVehicles = !searchString 
                ? [ ...vehicles ]
                : vehicles.filter(v => 
                    (isNew ? v.mmCode : v.stockNo)?.trim().toLowerCase().includes(searchString?.trim().toLowerCase()) ||
                    (isNew ? v.makeName : v.brand)?.trim().toLowerCase().includes(searchString?.trim().toLowerCase()) ||
                    (isNew ? v.modelName : v.model)?.trim().toLowerCase().includes(searchString?.trim().toLowerCase()) ||
                    (isNew ? v.variantName : v.modelRange)?.trim().toLowerCase().includes(searchString?.trim().toLowerCase()) ||
                    (isNew ? v.dealerName : v.dealershipName)?.trim().toLowerCase().includes(searchString?.trim().toLowerCase())
                );

            let sortedVehicles = filteredVehicles.sort(getComparator());

            let startIndex = page * pageSize;
            let slicedVehicles = sortedVehicles.slice(startIndex, startIndex + pageSize);

            return slicedVehicles;

        } catch (error) {
            
            enqueueSnackbar(GetErrorMessage(error, "Failed to filter and sort the list"), { variant: 'error' });
            return vehicles;

        }   

    }

    function selectVehicle(id) {

        setPopUp(true);

        if (isNew) {
            loadNewVehicleDetails(id);
        } else {
            loadUsedVehicleDetails(id);
        }

    }

    function getComparator() {

        return order === "asc"
            ? (a, b) => ascendingComparator(a, b)
            : (a, b) => -ascendingComparator(a, b);

    }

    function ascendingComparator(a, b) {

        return (a[orderBy] > b[orderBy]) ? 1 : -1;

    }

    function clearSearchString() {

        setSearchString("");

    }

    function handleChangeSearchString(event) {
        
        setSearchString(event?.target?.value);

    }

    function handleRequestSort(event, property) {

        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);

    }

    function handleChangePage(event, newPage) {

        setPage(newPage);

    }

    function handleChangeRowsPerPage(event) {

        setPageSize(parseInt(event?.target?.value || 25, 10));
        setPage(0);

    }

    return (
        <Paper className={classes.paper}>
            {
                popUp &&
                <ManageVehiclesDetailsPopup 
                    closePopUp={() => { setPopUp(false) }}
                    loading={loadingDetails}
                    vehicleDetailPairs={vehicleDetailPairs}
                    vehicleDetailImages={vehicleDetailImages}
                />
            }
            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                <Grid item xs={12} container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h4">
                            Manage {isNew ? "New" : "Used"} Vehicles
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            id="seachString"
                            label={`Search ${isNew ? "New" : "Used"} Vehicles`}
                            disabled={loading}
                            value={searchString}
                            onChange={handleChangeSearchString}
                            InputProps={{
                                endAdornment: 
                                    searchString?.length > 0 &&
                                    <InputAdornment position="end">
                                        <IconButton size="small" disableFocusRipple disableRipple onClick={() => clearSearchString()}>
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>
                
                <Grid item xs={12}>
                    <TableContainer className={classes.stickyHeaderTable}>
                        <Table stickyHeader>
                            <TableHeadersSort
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {
                                    loading &&
                                    <StyledTableRow hover>
                                        <StyledTableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{ borderBottom: 0 }}
                                            className="py-3"
                                        >
                                            <CircularProgress/>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                }
                                {
                                    !loading && (!vehicles?.length || vehicles.length === 0) && 
                                    <StyledTableRow hover>
                                        <StyledTableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{ borderBottom: 0 }}
                                            className="py-3"
                                        >
                                            <Typography variant="h6" color="textSecondary">
                                                No {isNew ? "New" : "Used"} Vehicles Available
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                }
                                {
                                    !loading && getDisplayVehicles().map((row) => (
                                        <StyledTableRow 
                                            key={row.id} 
                                            style={{ cursor: "pointer" }}
                                            onClick={() => selectVehicle(row.id ?? row.stockId)}
                                        >
                                            <StyledTableCell>
                                                <img className={localClasses.thumbImg} src={row.image} alt="Vehicle thumbnail" />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row[headCells[1].id]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row[headCells[2].id]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row[headCells[3].id]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row[headCells[4].id]}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row[headCells[5].id]}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>

                        <TablePagination
                            component="div"
                            count={vehicles?.length}
                            page={page}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            onChangePage={handleChangePage}
                            rowsPerPage={pageSize}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>

            </Grid>
        </Paper>
    )
}
