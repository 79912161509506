import {useState, useEffect} from 'react';
import axios from 'axios';
import {BASE_URI} from '../../shared/Constants'

export default function UserListFilter(searchString, pageNumber, pageSize, order, orderBy) {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);

  let cancel;

  useEffect(() => {

    setUsers([]);
    getUsers();

    return () => cancel()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, pageNumber, pageSize, order, orderBy])

  function getUsers() {

    setLoading(true);
    setError(false);

    const params = {
      pageNumber: pageNumber + 1,
      pageSize
    };

    if (searchString) {params.firstName = searchString}
    if (order) {params.order = order.toUpperCase()}
    if (orderBy) {params.orderBy = orderBy}

    axios({
      method: 'GET',
      url: `${BASE_URI}/Admin/Users`,
      params,
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {

      if (response.data?.list) {
        setUsers(response.data?.list)
        setTotal(response.data?.total)
      }

    }).catch( error => {

      if(axios.isCancel(error)) return

      if (error.response) {
        setError(true)
      }

    }).finally(() => {

      setLoading(false);

    });
  }

  function reload() {

    getUsers();

  }

  return {loading, error, users, total, reload}
}