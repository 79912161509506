import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form} from "formik";
// import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider, DateTimePicker} from "@material-ui/pickers";
import { Typography } from "@material-ui/core";
import axios from 'axios'
import { BASE_URI } from '../../../../shared/Constants'
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    root: {width: "97.5%"},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
    },
    button: {
        marginTop: "2rem",
    },
    buttonMake: {
        marginTop: "2rem",
        backgroundColor: "#0b7d95",
        color: "white"
    },
}));

export default function AppointmentContainer() {
    const classes = useStyles();
    const { state, tab, id } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const values = {
        startDate: new Date(),
        endDate: new Date(),
    };

    return (
        <div className={classes.root}>
            <Formik
                initialValues={values}
                isInitialValid={false}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        values.leadId = parseInt(id);
                        axios.post(`${BASE_URI}/appointment`, values).then(() => {
                            enqueueSnackbar("Appointment sent!", { variant: 'success' });
                        });;

                        console.log(values);
                        actions.setSubmitting(true);
                    }, 100);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Grid container direction="row">
                                <Grid item xs={12} md={12}
                                      lg={12}>
                                    <Card className={classes.stepPadding}>
                                        <CardContent>
                                            <Typography variant="h6">
                                                Schedule an appointment:
                                            </Typography>
                                            <Grid container direction="row">
                                                <Grid
                                                    item
                                                    className={classes.stepPadding}
                                                    xs={12}
                                                    md={2}
                                                    lg={2}
                                                >
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <DateTimePicker
                                                            label="From"
                                                            name="startDate"
                                                            id="startDate"
                                                            clearable
                                                            ampm={false}
                                                            minutesStep={10}
                                                            disablePast
                                                            value={props.values.startDate}
                                                            onChange={(event) => {
                                                                props.setFieldValue(
                                                                    "startDate",
                                                                    new Date(event)
                                                                );

                                                                props.setFieldTouched("startDate", true, false);
                                                            }}
                                                            animateYearScrolling
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid
                                                    item
                                                    className={classes.stepPadding}
                                                    xs={12}
                                                    md={3}
                                                    lg={3}
                                                >
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <DateTimePicker
                                                            label="To"
                                                            clearable
                                                            name="endDate"
                                                            id="endDate"
                                                            ampm={false}
                                                            minutesStep={10}
                                                            disablePast
                                                            value={props.values.endDate}
                                                            onChange={(event) => {
                                                                props.setFieldValue("endDate", new Date(event));
                                                                props.setFieldTouched("endDate", true, false);
                                                            }}
                                                            animateYearScrolling
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={12} md={3} lg={3}>
                                                    <Button
                                                        className={classes.buttonMake}
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={!props.isValid}
                                                    >
                                                        Make appointment
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
