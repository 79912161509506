import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomerTabContainer from "./CustomerTabContainer";
import PassportTabContainer from "./PassportTabContainer";
import WorkTabContainer from "./WorkTabContainer";
import SpouseTabContainer from "./SpouseTabContainer";
import FinancialTabContainer from "./FinancialTabContainer";
import BankTabContainer from "./BankTabContainer";
import { useParams, useHistory } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "10px",
        backgroundColor: "#3378af",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export default function ApplicationTabContainer(props) {
    const classes = useStyles();
    const { state, tab, id } = useParams();
    let history = useHistory();
    const { match } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const tabNameToIndex = {
        0: "customer",
        1: "work",
        2: "spouse",
        3: "financial",
        4: "bank",
    };

    const indexToTabName = {
        customer: 0,
        work: 1,
        spouse: 2,
        financial: 3,
        bank: 4,
    };

    const [selectedTab, setSelectedTab] = React.useState(indexToTabName[tab]);
    const handleChange = (event, newValue) => {
        history.push(
            `/leadDetailsApplication/${state}/${tabNameToIndex[newValue]}/${id}`
        );
        setSelectedTab(newValue);
    };

    return (
        <>
            <AppBar position="static" className={classes.root}>
                <Tabs
                    centered={true}
                    value={selectedTab != null ? selectedTab : setSelectedTab(0)}
                    onChange={handleChange}
                    variant={matches ? 'standard' : 'scrollable'}
                    scrollButtons={matches ? 'auto' : 'on'}
                    textColor="inherit"
                >
                    <Tab label="Customer" />
                    <Tab label="Work" />
                    <Tab label="Spouse & Relative" />
                    <Tab label="Financial" />
                    <Tab label="Bank" />
                </Tabs>
            </AppBar>
            {selectedTab === 0 && <CustomerTabContainer />}
            {/*{selectedTab === 1 && <PassportTabContainer />}*/}
            {selectedTab === 1 && <WorkTabContainer />}
            {selectedTab === 2 && <SpouseTabContainer />}
            {selectedTab === 3 && <FinancialTabContainer />}
            {selectedTab === 4 && <BankTabContainer />}
        </>
    );
}
