import {useState, useEffect} from 'react';
import axios from 'axios';
import {BASE_URI} from '../../shared/Constants'
// import moment from 'moment'

export default function MessagingSettingsFilter(searchString, pageNumber, pageSize, order, orderBy) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setData([])
  }, [searchString, pageNumber, pageSize, order, orderBy])

  const sampleData = [
    {
      id: 0,
      type: 'sms',
      action: "State Change",
      state: 'New Lead',
      motorgroup: 'Click Car',
      subject: '[[LEAD_NAME]], welcome to [[MOTORGROUP]], [[AGENT_NAME]] will contact you shortly. You can do a instant Pre-Qualification here [[PREQUAL_URL]]'
    },
    {
      id: 1,
      type: 'email',
      action: "VoiceMessage",
      state: 'Prequalified Approved',
      motorgroup: '',
      subject: '[[MOTORGROUP]]: We could not get hold of you.'
    },
    {
      id: 2,
      type: 'email',
      action: "Appointment",
      state: 'Application Completed',
      motorgroup: 'Click Car',
      subject: '[[MOTORGROUP]]: Confirming our appointment.'
    },
    {
      id: 3,
      type: 'sms',
      action: "VoiceMessage",
      state: 'Application Completed',
      motorgroup: 'Click Car',
      subject: 'Hi [[LEAD_NAME]], I tried contacting you to complete your finance. You can click here to complete your application online. https://bit.ly/2CkzePR [[AGENT_NAME]] [[DEALER]]'
    },
    {
      id: 4,
      type: 'sms',
      action: "StateChange",
      state: 'Delivered',
      motorgroup: '',
      subject: 'CONTRACT - Step 7 of 8'
    }
  ];

  useEffect(() => {
    let cancel
    setLoading(true)
    setError(false)
    const params = {
      pageNumber: pageNumber + 1,
      pageSize
      }

    if (searchString) {params.searchString = searchString}
    if (order) {params.order = order.toUpperCase()}
      if (orderBy) { params.orderBy = orderBy }

     axios({
       method: 'GET',
       url: `${BASE_URI}/messageTemplate`,
       params,
       cancelToken: new axios.CancelToken(c => cancel = c)
     }).then((response) => {
       if (response.data.list) {
           setData(response.data.list)
       }
       setTotal(response.data.total)
       setLoading(false)
     }).catch( error => {
       if(axios.isCancel(error)) return
       if (error.response) {
         setError(true)
       }
       setLoading(false)
     })
    setLoading(false)
    //setData(sampleData)
    //setTotal(sampleData.length)

      return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, pageNumber, pageSize, order, orderBy])

  return {loading, error, data, total}
}