import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../../shared/CommonStyles';

const useStylesLocal = makeStyles((theme) => ({
    galleryImg: {
        maxWidth: "100px",
        transition: "transform .2s",
        "&:hover": {
            transform: "scale(2.5)"
        }
    }
}));

export default function ManageVehiclesDetailsPopup({ closePopUp, loading, vehicleDetailPairs, vehicleDetailImages }) {

    const classes = useStyles();
    const localClasses = useStylesLocal();

    return (
        <div className={classes.popUpOuter} >
            <div className={classes.popUpInner}>

                <p className={classes.popUpClose} onClick={closePopUp}>
                    <CloseIcon style={{ fontSize: '35px' }} />
                </p>

                <div className={classes.popUpTitleHolder}>
                    <Typography variant="h5">
                        Vehicle Details
                    </Typography>
                </div>

                <div className={classes.popUpContentHolder}>
                    <Grid container spacing={4} justify="center">
                        {
                            loading &&
                            <CircularProgress />
                        }
                        {
                            !loading &&
                            <>
                                <Grid item xs={12} container spacing={4}>
                                    {
                                        !(vehicleDetailPairs?.length > 0) &&
                                        <Typography variant="h6" gutterBottom>No details available</Typography>
                                    }
                                    {
                                        vehicleDetailPairs?.length > 0 && vehicleDetailPairs.map((pair, index) => (
                                            <Grid key={`v${index}`} item xs={12} container justify="space-between">
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><strong>{pair.label}</strong></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography variant="body1">{pair.value}</Typography>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>

                                <Grid item={12} container justify="center">
                                    <Typography variant="subtitle1" align="center" gutterBottom><strong>Gallery</strong></Typography>
                                </Grid>

                                <Grid item xs={12} container spacing={2}>
                                    {
                                        !(vehicleDetailImages?.length > 0) &&
                                        <Typography variant="h6" gutterBottom>No gallery images available</Typography>
                                    }
                                    {
                                        vehicleDetailImages?.length > 0 && vehicleDetailImages.map((image, index) => (
                                            <Grid key={`i${index}`} item xs={6} sm={4} md={3} lg={2}>
                                                <img className={localClasses.galleryImg} alt={`Vehicle image ${index + 1}`} src={image}/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </>
                        }
                    </Grid>
                </div>
                
            </div>
        </div>
    );
}