import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    Divider,
    Paper,
    Fab
} from '@material-ui/core'
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from "react-router-dom";
import { BASE_URI, LeadTypeEnum } from '../../shared/Constants'
import { useSnackbar } from "notistack";
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    featureInputContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    title: {
        background: '#bdbfc1',
        color: 'white',
        padding: "10px",
    }
}));

export default function DealerImportersForm({ history, props }) {

    const initialValues = {
        importerMappingId: 0,
        importType: 0,
        dealerId: ''
    }

    const { id } = useParams();
    const [formFields, setFormFields] = useState(initialValues);
    const [importerArr, setImporterArr] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();

    function onSubmit(fields) {
        console.log(fields);

         try {
             const CancelToken = axios.CancelToken;
             const source = CancelToken.source();

             axios.post(
                 `${BASE_URI}/importer`,
                 {
                     importerMappingId: formFields.importerMappingId,
                     dealerId: parseInt(id),
                     foreignDealerId: fields.dealerId,
                     importerId: fields.importType
                 },
                 {
                     cancelToken: source.token,
                 }
             ).then(result => {
                 enqueueSnackbar("Updated importer", { variant: 'success' });
             });

         } catch (error) {
             enqueueSnackbar("Updated failed", {variant: 'error'});
         }
     }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const getImporterMapping = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/importer/${id}`, {
                    cancelToken: source.token,
                });

                if (result.data) {
                    setFormFields(
                        {
                            ...result.data,
                            dealerId: result.data.foreignDealerId,
                            importType: result.data.importerId
                        });
                }

            } catch (error) {
                // addAlert('Unables to load vehicle categories')
                //history.push('..')
            }
        };

        const getImporters = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/lookup/importers`, {
                    cancelToken: source.token,
                });

                setImporterArr(result.data);
                getImporterMapping();
            } catch (error) {
                // addAlert('Unables to load vehicle categories')
                //history.push('..')
            }
        };

        getImporters();

        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Paper className={classes.paper}>
            <Formik
                initialValues={formFields}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    dealerId: Yup.string().required("Dealership Name is required"),
                    importType: Yup.number().required("Importer is required")
                })}>
                {(props) => {
                    const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset } = props;

                    //showErrors(touched)

                    return (
                        <Form>
                            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                                <Grid item xs={12} md={6}>
                                    <Grid item xs={12}>

                                        <Typography variant="h6" gutterBottom>
                                            IMPORTERS
					        </Typography>
                                    </Grid>
                                    <TextField
                                        fullWidth
                                        name="importType"
                                        id="importType"
                                        required
                                        label="Importer"
                                        select
                                        value={values.importType}
                                        helperText={errors.importType && touched.importType ? errors.importType : ""}
                                        error={errors.importType && touched.importType}
                                        onBlur={handleBlur("importType")}
                                        onChange={handleChange("importType")}>
                                        {importerArr.map((option, index) => (
                                            <MenuItem key={index} id={`${option.importerId}`} value={option.importerId}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        name="dealerId"
                                        id="dealerId"
                                        label="Dealership ID"
                                        value={values.dealerId}
                                        helperText={errors.dealerId && touched.dealerId ? errors.dealerId : ""}
                                        error={errors.dealerId && touched.dealerId}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>

                                <Grid item container
                                    justify='flex-end' xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                   
                                    >
                                        Save
                        </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }
                }
            </Formik>
        </Paper>
    )
}