import React, {useEffect, useState, useRef, useContext} from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Toolbar
} from '@material-ui/core'
import { Formik, Form, Field  } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import { Switch } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {BASE_URI} from '../../shared/Constants'
import axios from 'axios'
import {useParams, useHistory} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {LoadingContext} from '../../shared/context/loadingContext'


const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(3),
  }
}));


export default function SMSSettingsForm() {
  const classes = useStyles();
  let history = useHistory();
  const { id } = useParams();
  const [isAddMode, setIsAddMode] = useState(true)
  const mountedRef = useRef(true)
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useContext(LoadingContext)
  const initialValues = {
    userId: parseInt(id, 10),
    smsUser: '',
    smsPassword: '',
    active: false
  }
  const [formFields, setFormFields] = useState(initialValues)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getSMSSettings = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/SmsConfig/${id}`, {
          cancelToken : source.token
        })
        let formData = result.data
        if (result.data) {
          setIsAddMode(false)
          setFormFields(formData)
        }
      } catch (error) {
      }
    }

    getSMSSettings().then(() => {
      if (!mountedRef.current) return null
    })

    return () => {
      mountedRef.current = false
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const editSMSSettings = async (fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.put(`${BASE_URI}/SmsConfig`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully edited SMS settings', {variant: 'success'});
      return result
    } catch (error) {
      if (error.response && error.response.data.errors) {
        let errorObj= error.response.data.errors
        enqueueSnackbar(`${errorObj[Object.keys(errorObj)[0]]}`, {variant: 'error'});
      } else {
        enqueueSnackbar(`Unable to edit SMS Settings`, {variant: 'error'});
      }
    } finally {
      hideLoading()
      setSubmitting(false)
    }
  }

  const createSMSSettings = async (fields, setSubmitting) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.post(`${BASE_URI}/SmsConfig`, fields, {
        cancelToken : source.token
      })
      enqueueSnackbar('Successfully added SMS settings', {variant: 'success'});
      return result
    } catch (error) {
      if (error.response && error.response.data.errors) {
        let errorObj= error.response.data.errors
        enqueueSnackbar(`${errorObj[Object.keys(errorObj)[0]]}`, {variant: 'error'});
      } else {
        enqueueSnackbar(`Unable to create SMS Settings`, {variant: 'error'});
      }
    } finally {
      hideLoading()
      setSubmitting(false)
    }
  }

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    showLoading()
    if (isAddMode) {
      createSMSSettings(fields, setSubmitting);
    } else {
      editSMSSettings(fields, setSubmitting);
    }
  }


  return(
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction="column">
        <Grid item xs={12}>
          <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              smsUser: Yup.string().required('SMS User is required'),
              smsPassword: Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  'Password requires at least one lowercase, one uppercase letter and one non-alphanumeric letter'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                handleReset
              } = props

              return (
                <Form>
                  <Toolbar disableGutters>
                    <Typography variant="h6" style={{flex: '1 1'}} component="div" gutterBottom>
                      Edit SMS Settings
                    </Typography>
                    <FormControlLabel
                      control={
                        <Field component={Switch}
                               color="primary"
                               type="checkbox"
                               name="active" />
                      }
                      label="SMS Active"
                    />
                  </Toolbar>
                  <Grid spacing={4} container direction="row">
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name="smsUser"
                        id="smsUser"
                        required
                        label="SMS User"
                        value={values.smsUser}
                        type="text"
                        helperText={errors.smsUser && touched.smsUser ? errors.smsUser: ''}
                        error={errors.smsUser && touched.smsUser}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name="smsPassword"
                        id="smsPassword"
                        required
                        label="SMS Password"
                        value={values.smsPassword}
                        type="password"
                        helperText={errors.smsPassword && touched.smsPassword ? errors.smsPassword: ''}
                        error={errors.smsPassword && touched.smsPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item container
                          justify='flex-end' xs={12}>
                      <Box mr={3}>
                        <Button
                          onClick={() => {
                            handleReset()
                            history.push('/admin/users')
                          }}>
                          Cancel
                        </Button>
                      </Box>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  )
}