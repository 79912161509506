import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Typography } from "@material-ui/core";
import axios from 'axios'
import { BASE_URI } from '../../../../shared/Constants'
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    button: {
        marginTop: "2rem",
    },
}));

export default function VoiceMessageContainer() {
    const classes = useStyles();
    const { state, tab, id } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const values = {
        subject: "",
    };

    return (
        <div className={classes.root}>
            <Formik
                initialValues={values}
                isInitialValid={false}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        values.leadId = parseInt(id);
                        axios.post(`${BASE_URI}/voiceMails`, values).then(() => {
                            enqueueSnackbar("VoiceMail logged", { variant: 'success' });
                        });;

                        actions.setSubmitting(true);
                    }, 100);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Grid container direction="row">
                                <Grid item xs={12}>
                                    <Card className={classes.stepPadding}>
                                        <CardContent>
                                            <Typography variant="h6">
                                                I left a voice message:
                      </Typography>
                                            <Grid container direction="row">

                                                <Grid item xs={12} md={4} lg={4}>
                                                    <Button
                                                        className={classes.button}
                                                        variant="contained"
                                                        startIcon={<AddIcon />}
                                                        color="primary"
                                                        type="submit"

                                                    >
                                                        Log it
                          </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
