import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from '@material-ui/core/AppBar';
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2rem',
        width: '100%',
        overflow: 'auto'
    },
    iframe:{
        border:'0px #ffffff none',
    }
}));


export default function Dashboard({ match }) {
    const classes = useStyles();
    let history = useHistory();
 
    return (
        <div className={classes.root} >
            <iframe src="https://hyperboliq.clicdata.com/v/dashleads" className={classes.iframe} name="Dash Leads" scrolling="no" width="100%" height="100%" ></iframe>
       
        </div>
    );
}
