import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuItems from './MenuItems';
import { Route, NavLink, Redirect } from 'react-router-dom'
import DASHContainer from '../DASHController/DASHContainer'
import LeadsContainer from "../LeadsContainer/LeadsContainer";
import AddLeadContainer from "../LeadsContainer/AddLead/AddLeadContainer";
import DealerLeadsImport from "../LeadsContainer/DealerLeadsImport";
import LeadDetailsContainer from "../LeadsContainer/LeadDetails/LeadDetailsContainer";
import LeadMainContainer from "../LeadsContainer/LeadMainScreen/LeadMainContainer";
import SettingsIcon from '@material-ui/icons/Settings';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ListItemText from '@material-ui/core/ListItemText';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EmailIcon from '@material-ui/icons/Email';
import DealerDepartments from '../../components/dealerAdmin/DealerDepartments'
import Link from '@mui/material/Link';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import StateAdminTable from '../stateAdmin/StateAdminTable'
import StateAdminForm from '../stateAdmin/StateAdminForm'
import Menu from '@material-ui/core/Menu';
import UserAdminTable from '../userAdmin/UserAdminTable'
import UserAdminForm from '../userAdmin/UserAdminForm'
import AdminManufacturers from '../userAdmin/AdminManufacturers'
import UserEditContainer from '../userAdmin/UserEditContainer'
import StatePermissionsMatrix from '../statePermissions/StatePermissionsMatrix'
import MessagingSettingsTable from '../messagingAdmin/MessagingSettingsTable'
import MessagingSettingsForm from '../messagingAdmin/MessagingSettingsForm'
import SlidesTable from '../slides/SlidesTable'
import SlidesForm from '../slides/SlidesForm'
import { DealerSelect } from '../../shared/DealerSelect'
import { AuthContext } from '../../shared/context/AuthContext'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DealerAdminTable from '../dealerAdmin/DealerAdminTable'
import DealerContainer from '../dealerAdmin/DealerContainer'
import Dashboard from '../dealerAdmin/Dashboard'
import VehicleLibraryMakes from '../VehicleLibrary/VehicleLibraryMakes'
import VehicleLibraryModels from '../VehicleLibrary/VehicleLibraryModels'
import VehicleLibraryVariants from '../VehicleLibrary/VehicleLibraryVariants'
import VehicleLibraryMakeForm from '../VehicleLibrary/Forms/VehicleLibraryMakeForm'
import VehicleLibraryModelForm from '../VehicleLibrary/Forms/VehicleLibraryModelForm'
import VehicleLibraryVariantForm from '../VehicleLibrary/Forms/VehicleLibraryVariantForm'
import ManageVehiclesContainer from '../ManageVehicles/ManageVehiclesContainer'

import BulkLeadActionsContainer from '../bulkLeadActions/BulkLeadActionsContainer'
import { useLocation, useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReportingContainer from "../Reporting/ReportingContainer";
import LeadLogsContainer from "../Reporting/LeadLogsContainer";
import ExportContainer from "../export/ExportContainer";
import OfferContainer from '../offers/OfferContainer'
import OfferForm from '../offers/OfferForm'
import DashLogo from '../../shared/assets/DashLogo.png'
import { DealerContext } from "../../shared/DealerContext";
import TextField from '@material-ui/core/TextField'



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '20px 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,

        color: 'white',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    dealerSelect: {
        minWidth: '200px',
        marginTop: '1rem'
    },
    logo: {
        maxWidth: 110,
    },
    linkButton: {
        color: '#6f7276',
        textDecoration: 'none'
    },
    linkContainer: {
        color: 'white',
        height: '55px',
        display: 'flex',
        justifyContent: 'center',
        marginRight: '25px',
        alignItems: 'center',
        fontSize: '16px',
        background: 'white',
        borderRadius: '10px',
        padding: '10px',
    },
    rateContainer: {
        color: "white !important",
        width: '100px',
        marginTop: '17px',
        marginRight: '15px',
        '&$MuiFormLabel-root ': {
            marginRight: '40px',
            width: '100px !important',
            color: "white !important",
            textAlign: 'center',
            display: 'flex',
        },
        '&$notchedOutline': {
            marginRight: '40px',
            width: '100px !important',
            color: 'white',
            textAlign: 'center',
            display: 'flex',
        }
    },
}));

export default function DashboardContainer() {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { logout, role } = useContext(AuthContext);
    const [showBack, setShowBack] = React.useState(false)
    let { ...dealerContext } = useContext(DealerContext);

    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        if (location) {
            const path = location.pathname
            setShowBack(path.includes('admin') && (path.includes('add') || path.includes('edit')))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const handleSettingsOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const hasRole = (roleToCheck) => {

        if (!role) {
            return false;
        }

        return role.filter(itm => itm == roleToCheck).length > 0;
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/*<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>*/}
                        {/*DASH*/}
                        {/*</Typography>*/}
                        <div className={classes.title}>
                            <img src={DashLogo} alt="logo" className={classes.logo} />
                        </div>

                        <div className={classes.linkContainer} onClick={() => window.open(`https://${dealerContext?.selectedMotorGroup?.websiteUrl}`)}><Link className={classes.linkButton} /*href={`${dealerContext?.selectedMotorGroup?.websiteUrl}`}*/ target="_blank">Go to
                            Website</Link></div>
                        <React.Fragment>
                            <TextField
                                className={classes.rateContainer}
                                name="dealerSelect"
                                id="dealerSelect"
                                variant={"outlined"}
                                label="Global Interest Rate"
                                value={`${dealerContext?.selectedMotorGroup?.globalInterestRate} %`}
                            />
                        </React.Fragment>
                        <DealerSelect
                            secondary
                            className={classes.dealerSelect} />
                        {(hasRole('Admin') || hasRole('Manager')) &&
                            <IconButton onClick={handleSettingsOpen} color="inherit">
                                <SettingsIcon />
                            </IconButton>
                        }

                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleSettingsClose}
                        >
                            {(hasRole('Admin') || hasRole('Manager')) &&
                                <ListItem button onClick={handleSettingsClose} component={NavLink} to="/admin/export">
                                    <ListItemIcon>
                                        <ImportExportIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Export" />
                                </ListItem>
                            }

                            {hasRole('Admin') && <>
                                <ListItem button onClick={handleSettingsClose} component={NavLink} to="/admin/dealer">
                                    <ListItemIcon>
                                        <BusinessCenterIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Dealers" />
                                </ListItem>

                                <ListItem button onClick={handleSettingsClose} component={NavLink} to="/admin/users">
                                    <ListItemIcon>
                                        <PeopleAltIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Users" />
                                </ListItem>

                                <ListItem button onClick={handleSettingsClose} component={NavLink} to="/admin/messaging">
                                    <ListItemIcon>
                                        <EmailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Email & SMS Wording" />
                                </ListItem>

                                <ListItem button onClick={handleSettingsClose} component={NavLink} to="/admin/roles">
                                    <ListItemIcon>
                                        <AssignmentIndIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Roles" />
                                </ListItem>

                                <ListItem button onClick={handleSettingsClose} component={NavLink} to="/departments">
                                    <ListItemIcon>
                                        <ApartmentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Dealer Departments" />
                                </ListItem>

                                <ListItem button onClick={handleSettingsClose} component={NavLink} to="/admin/states">
                                    <ListItemIcon>
                                        <LocalOfferIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Lead States" />
                                </ListItem>

                                <ListItem button onClick={handleSettingsClose} component={NavLink}
                                    to="/admin/leads/bulkactions">
                                    <ListItemIcon>
                                        <AccountTreeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Bulk Actions" />

                                </ListItem>
                                <ListItem button onClick={handleSettingsClose} component={NavLink}
                                    to="/admin/Manufacturers">
                                    <ListItemIcon>
                                        <PrecisionManufacturingIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Manufacturers" />

                                </ListItem>
                            </>}
                        </Menu>
                        <IconButton onClick={logout} color="inherit">
                            <Tooltip title="Logout" placement="bottom">
                                <ExitToAppIcon />
                            </Tooltip>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}

                >
                    <div className={classes.toolbarIcon} >
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List><MenuItems /></List>
                    <Divider />
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth={(location.pathname == "/leadList" || location.pathname.split('/')[1] == "leadDetailsApplication" || location.pathname.split('/')[1] == "reporting" ? false : "lg")} className={classes.container}>
                        <Grid container spacing={3}>
                            {/* Routes*/}
                            <Grid item xs={12}>
                                <Route exact path="/" render={() => {
                                    return (<Redirect to="/DASH" />)
                                }} />
                                <Route path="/DASH/:step?/:modelId?/:dealerModelId?/:dealerVariantId?" component={DASHContainer} />
                                <Route path="/leads/:state?" component={LeadsContainer} />
                                <Route path="/admin/export" component={ExportContainer} />
                                <Route path="/addlead" component={AddLeadContainer} />
                                <Route path="/importLeads/dealer/:id" component={DealerLeadsImport} />
                                <Route path={"/leadList"} component={LeadMainContainer} />
                                <Route path={"/departments"} component={DealerDepartments} />
                                <Route path={"/reporting/:tab?"} component={ReportingContainer} />
                                <Route path={"/leadLogs/:id?"} component={LeadLogsContainer} />
                                <Route path="/leadDetailsApplication/:state?/:tab?/:id?"
                                    component={LeadDetailsContainer} />
                                <Route exact path="/admin/states" component={StateAdminTable} />
                                <Route path="/admin/states/add" component={StateAdminForm} />
                                <Route path="/admin/states/edit/:id?" component={StateAdminForm} />
                                <Route exact path="/admin/users" component={UserAdminTable} />
                                <Route path="/admin/users/add" component={UserAdminForm} />
                                <Route path="/admin/users/edit/:id?" component={UserEditContainer} />
                                <Route path="/admin/roles" component={StatePermissionsMatrix} />
                                <Route path="/admin/manufacturers" component={AdminManufacturers} />
                                <Route exact path="/slides" component={SlidesTable} />
                                <Route exact path="/slides/add" component={SlidesForm} />
                                <Route exact path="/slides/edit/:id?" component={SlidesForm} />
                                <Route exact path="/admin/messaging" component={MessagingSettingsTable} />
                                <Route path="/admin/messaging/add" component={MessagingSettingsForm} />
                                <Route path="/admin/messaging/edit/:id?" component={MessagingSettingsForm} />
                                <Route exact path="/admin/leads/bulkactions" component={BulkLeadActionsContainer} />
                                <Route exact path="/admin/dealer" component={DealerAdminTable} />
                                <Route path="/admin/dealer/add" component={DealerContainer} />
                                <Route path="/admin/dealer/edit/:id?" component={DealerContainer} />
                                <Route exact path="/SelectionDealer/dealers" component={DealerAdminTable} />
                                <Route path="/SelectionDealer/dealer/edit/:id?" component={DealerContainer} />
                                <Route exact path="/offers" component={OfferContainer} />
                                <Route exact path="/dashboard" component={Dashboard} />
                                <Route path="/offers/add" component={OfferForm} />
                                <Route path="/offers/edit/:id?" component={OfferForm} />
                                <Route exact path="/VehicleLibrary/Makes" component={VehicleLibraryMakes} />
                                <Route path="/VehicleLibrary/Make/:id/:action?" component={VehicleLibraryMakeForm} />
                                <Route exact path="/VehicleLibrary/Makes/:makeId/Models" component={VehicleLibraryModels} />
                                <Route path="/VehicleLibrary/Makes/:makeId/Model/:id/:action?" component={VehicleLibraryModelForm} />
                                <Route exact path="/VehicleLibrary/Makes/:makeId/Models/:modelId/Variants" component={VehicleLibraryVariants} />
                                <Route path="/VehicleLibrary/Makes/:makeId/Models/:modelId/Variant/:id/:action?" component={VehicleLibraryVariantForm} />
                                <Route path="/ManageVehicles" component={ManageVehiclesContainer} />
                            </Grid>
                        </Grid>
                    </Container>
                </main>
            </div>
        </React.Fragment>
    );
}
