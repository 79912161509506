import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { createChainedFunction, Typography } from "@material-ui/core";
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"
import EventsController from "./EventsController";
import { useContext, useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../../../shared/TableHeadersSort";
import { useParams } from "react-router-dom";
import {DEFAULT_PAGE_SIZE} from "../../../../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  stepPadding: {
    marginTop: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  stepPaddingButton: {
    marginLeft: "2rem",
  },
  card: {
    background: "lightblue",
    marginTop: "1rem",
  },
}));

export default function EventsTabContainer() {
    const classes = useStyles();
    let { lead } = useContext(LeadMainContext);
    const { state, tab, id } = useParams();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("event");
    const [elementRef, setElementRef] = useState("");
    const { loading, error, data, total } = EventsController(id);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const headCells = [
        {id: "event", numeric: false, label: "Event", sort: true},
        {id: "actioned_by", numeric: false, label: "Actioned By", sort: true},
        {id: "actioned_on", numeric: false, label: "Actioned On", sort: true},
        {id: "properties", numeric: false, label: "Properties", sort: true},
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Card className={classes.stepPadding}>
            <CardContent>
              <Typography variant="h6" style={{ marginBottom: "1rem" }}>
                Lead Events
              </Typography>
              <Grid container direction="row">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableContainer width="100%" ref={(el) => setElementRef(el)}>
                    <Table aria-label="ranges table">
                        <TableHeadersSort
                            order={order}
                            orderBy={orderBy}
                            headCells={headCells}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                        {data.map((row) => (
                              <TableRow key={row.id}>
                                  <TableCell>{row.action}</TableCell>
                                  <TableCell>{row.createdBy}</TableCell>
                                  <TableCell>{moment(row.createDate).format('Y-MM-DD h:mm')}</TableCell>
                                  <TableCell>{row.properties}</TableCell>
                              </TableRow>
                          ))}
                          {(total === 0) && !loading && (
                            <TableRow>
                              <TableCell
                                colSpan={headCells.length}
                                align="center"
                                style={{ borderBottom: 0 }}
                                className="py-3"
                              >
                                <Typography variant="h6" color="textSecondary">
                                  No Events Found
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {loading && (
                            <TableRow>
                              <TableCell
                                colSpan={headCells.length}
                                align="center"
                                style={{ borderBottom: 0 }}
                              >
                                <CircularProgress />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
