import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer'
    }
  },
});

export const StyledDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" className="text-capitalize">{children}</Typography>
      <CloseIcon className={classes.closeButton} onClick={onClose} />
    </DialogTitle>
  );
});