import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form, Field} from "formik";
import AddLeadForm from "./AddLeadForm";
import * as Yup from "yup";
import axios from "axios";
import {BASE_URI} from "../../../shared/Constants";
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
            width: "45ch",
        },
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(5),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
}));

export default function AddLeadContainer() {

    const classes = useStyles();
    //const [newCardDisabled, setNewCardDisabled] = useState(true);     // additional feature implementation that was halted
    const [error, setError] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

    const validationSchema = Yup.object({
        name: Yup.string("Enter a name and surname").required("Name and surname are required"),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        phone: Yup.string("Enter your phone number")
            .required("Phone number is required")
            .matches(phoneRegExp, "Phone number is not valid"),
        dealerName: Yup.string("Select a dealer").required("Dealer is required").nullable(),
        sourceSelector: Yup.string("Select a source").required("Source is required").nullable(),
        typeSelector: Yup.string("Select a type").required("Type is required").nullable(),
        // newCar: Yup.string().when("sourceSelector", {
        //     is: !newCardDisabled,
        //     then: Yup.string("Select a car").required("Car is required")
        // })
    });

    const values = {
        name: "",
        phone: "",
        email: "",
        idNumber: "",
        notes: "",
        dealerId: "",
        dealerName: "",
        sourceSelector: "",
        agentId: 0,
        leadSourceId: "",
        typeSelector: "",
        selectedMake: "",
        selectedMakeId: "",
        selectedModel: "",
        selectedModelId: "",
        selectedVariant: "",
        selectedVariantId: "",
    };

    const addLead = (values) => {

        //setLoader(true);

        let params = {}

        params.leadStatusId = 1
        params.dealerId = values.dealerId
        params.name = values.name
        params.phoneNumber = values.phone
        params.leadSourceId = values.leadSourceId
        params.leadTypeId = values.leadTypeId
        params.TypeCode = "NEW"
        params.notes = values.notes
        params.idNumber = values.idNumber;
        params.triggeredByAgent = true;
        params.agentId = values.agentId;
        params.variantId = values?.selectedVariantId

        let m = new Date()
        m = m.getUTCFullYear() + "-" + (m.getUTCMonth() + 1) + "-" + m.getUTCDate() + " " + (('0' + m.getHours()).slice(-2)) + ":" + (('0' + m.getMinutes()).slice(-2)) + ":" + (('0' + m.getSeconds()).slice(-2));
        params.dateUpdated = new Date().toISOString();

        params.emailAddress = values.email

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'POST',
            url: `${BASE_URI}/leads`,
            data: params,
            cancelToken: source.token
        }).then((response) => {
            values.leadId = response.data.id;

            enqueueSnackbar(`Successfully added a lead`, { variant: 'success' });

        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to complete the request", { variant: 'error' });
            //setLoader(false);

        })
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Formik
                    initialValues={values}
                    enableReinitialize={true}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            addLead(values);
                            actions.setSubmitting(true);
                            actions.resetForm();
                        }, 100);
                    }}
                    validationSchema={validationSchema}
                >
                    {(props) => {
                        return (
                            <Form noValidate>
                                <Field
                                    component={AddLeadForm}
                                    props={props}
                                    label="Add Lead Form"
                                    name="AddLeadForm"
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Paper>
        </div>
    );
}
