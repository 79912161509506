import React, { useState } from 'react';
import { 
    AppBar, 
    Box, 
    Tabs, 
    Tab 
} from '@material-ui/core';
import { useStyles, StyledTableRow, StyledTableCell } from '../../shared/CommonStyles';
import ManageVehiclesTable from './ManageVehiclesTable';

export default function ManageVehiclesContainer() {
    
    const classes = useStyles({ stickyHeaderTableHeight: window.innerHeight });
    
    const [tabIndex, setTabIndex] = useState(0);

    const tabs = [
        { id: 1, label: "New Vehicles", comp: (<ManageVehiclesTable isNew={true} />)},
        { id: 2, label: "Used Vehicles", comp: (<ManageVehiclesTable isNew={false} />)}
    ];
    
    function handleTabChange(event, newValue) {

        setTabIndex(newValue);

    }

    return (
        <div className={classes.root} >
            
            <AppBar position="static">
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    {
                        tabs.filter(tab => !tab.hide).map((step, index) => {
                            return <Tab disableRipple label={step.label} />
                        })
                    }
                </Tabs>
            </AppBar>
            <Box mt={4}>
                {
                    tabs.map((step, index) => (
                        <React.Fragment key={step.id}>
                            {tabIndex === index ? step.comp : null}
                        </React.Fragment>
                    ))
                }
            </Box>

        </div>
    )
}