import React, {useEffect, useState} from 'react';
import {
  Grid,
  TextField
} from '@material-ui/core'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import ImageUploadInput from '../../../shared/ImageUploadFormInput'
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {DEFAULT_COLOR} from '../../../shared/Constants'
import {ColorPicker} from '../../../shared/ColorPicker'
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {CurrencyInput, CurrencyValue} from '../../../shared/TextMask'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  height: {
    height: '250px'
  }
}));

export default function ColourForm({onSubmit, id, data, small, deleteColour}) {
  const classes = useStyles();
  const initialValues = {
    title: '',
    colour: DEFAULT_COLOR,
    image: '',
    price: ''
  };
  const [colorValues, setColorValues] = useState(data ? data: initialValues)
  const [editMode, setEditMode] = useState(id !== undefined)

  useEffect(() => {
    if(data) {
      setColorValues(data)
    }
  },[data])

  return(
    <Formik
      initialValues={colorValues}
      onSubmit={(values, actions) => {
        onSubmit(values, actions.resetForm)
        setTimeout(() => {
          actions.setSubmitting(false)
        }, 500)
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('Title is required'),
        colour: Yup.string().required('Colour is required'),
        price: Yup.string().required('Enter a valid amount'),
        image: Yup.string().required('Vehicle image is required')
      })}
    >

      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          handleReset
        } = props;

        return (
          <Form>
            <Grid
              spacing={4}
              container
              justify="space-around"
              direction="row"
            >
              <Grid item container xs={12} style={{paddingBottom: 0}}>
                <ColorPicker
                  currentColor={values.colour}
                  hideLabel
                  disabled={editMode}
                  label={'Colour'}
                  onChange={colorChoice => {
                    values.colour = colorChoice;
                  }}
                />
                {
                  editMode &&
                    <div>
                      <Typography variant="subtitle2">
                        {values.title}
                      </Typography>
                      <Typography variant="caption">
                        <CurrencyValue value={values.price ? values.price: 0} />
                      </Typography>
                    </div>
                }
                {
                  !editMode &&
                    <React.Fragment>
                      <TextField
                        name="title"
                        id="title"
                        required
                        label="Colour Name"
                        value={values.title}
                        type="text"
                        disabled={editMode}
                        helperText={errors.title && touched.title ? errors.title: ''}
                        error={errors.title && touched.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        style={{marginLeft: '1.5rem'}}
                        required
                        id="price"
                        label="Color Price (eg. 1500)"
                        value={values.price}
                        onChange={handleChange}
                        placeholder={'eg. 1500'}
                        error={ errors.price && touched.price}
                        helperText={errors.price && touched.price ? errors.price : ''}
                        InputProps={{
                          inputComponent: CurrencyInput
                        }}
                      />
                    </React.Fragment>
                }
              </Grid>
              <Grid item xs={12} md={small ? 5: 10}>
                <ImageUploadInput title='Vehicle preview Image'
                                  className={classes.height}
                                  disabled={editMode}
                                  required
                                  fieldName={'image'}
                                  error={errors.image && touched.image}/>
              </Grid>
              <Grid item
                    style={{paddingLeft: 0}}
                    alignItems="flex-start"
                    justify="center"
                    container direction="column"
                    xs={12} md={small ? 7:2}>
                  {
                    (!editMode && values.id === undefined) &&
                    <Fab variant="extended" size="small"
                      color="primary" aria-label="add"
                         type="submit"
                         disabled={isSubmitting}
                      className={classes.margin}>
                      <AddIcon />
                      Submit Colour
                    </Fab>
                  }
                  {
                    (!editMode && values.id !== undefined) &&
                    <React.Fragment>
                      <IconButton
                        type="submit"
                        disabled={isSubmitting}
                        aria-label={`Confirm Edit colour option`} >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        disabled={isSubmitting}
                        onClick={() =>{
                          setEditMode(prevState => !prevState)
                          setColorValues(data)
                          handleReset(data)
                        }}
                        aria-label={`Cancel edit colour option`}>
                        <ClearIcon />
                      </IconButton>
                    </React.Fragment>
                  }
                  {
                    editMode &&
                      <React.Fragment>
                        <IconButton
                          onClick={() => setEditMode(prevState => !prevState)}
                          disabled={isSubmitting}
                          aria-label={`Edit colour option`} >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={deleteColour}
                          aria-label={`Delete colour option`}>
                          <DeleteIcon />
                        </IconButton>
                      </React.Fragment>
                  }
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}