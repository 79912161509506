import React, { useState, useRef, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    TextField,
    Button,
    Box,
    MenuItem,
    Toolbar,
    Card
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import { BASE_URI } from '../../shared/Constants'
import * as Yup from 'yup'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { DatePicker } from 'formik-material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { DealerContext } from "../../shared/DealerContext";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    }
}));

export default function ChangeStateOfLeadsForm() {
    const classes = useStyles();
    const mountedRef = useRef(true)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const initialValues = {
        fromStatusId: '',
        toStatusId: '',
        userId: 0,
        beforeDate: ''
    }
    const [formFields, setFormFields] = useState(initialValues)
    const [fromStateArr, setFromStateArr] = useState([
        {
            id: 0,
            name: 'Application Submitted'
        },
        {
            id: 1,
            name: 'Application Rejected'
        },
        {
            id: 2,
            name: 'Application Declined'
        }
    ])
    const [toStateArr, setToStateArr] = useState([
        {
            id: 0,
            name: 'Application Submitted'
        },
        {
            id: 1,
            name: 'Application Rejected'
        },
        {
            id: 2,
            name: 'Application Declined'
        }
    ])
    const [userArr, setUserArr] = useState([
        {
            id: 0,
            name: 'Steven'
        },
        {
            id: 1,
            name: 'Jeffrey'
        },
        {
            id: 2,
            name: 'Thabo'
        }
    ])

    let { ...dealerContext } = useContext(DealerContext);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getState = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/state`, {
                    cancelToken: source.token
                })
                let formData = result.data
                setFormFields(formData)
            } catch (error) {
                // addAlert('Unables to load vehicle categories')
            }
        }

        getState().then(() => {
            if (!mountedRef.current) return null
        })

        const params = {
            pageNumber: 1,
            pageSize: 1000
        };

        let cancel;

        axios({
            method: 'GET',
            url: `${BASE_URI}/Admin/Users`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setUserArr(response.data?.list);
            }
        });

        axios({
            method: 'GET',
            url: `${BASE_URI}/LeadStatus`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                setFromStateArr(response.data)
                setToStateArr(response.data)
            }
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
            }
        })

        return () => {
            mountedRef.current = false
            source.cancel();
        };
    }, []);



    const updateStateOfLeads = async (fields, setSubmitting, resetForm) => {
        try {
            fields.dealerId = dealerContext.dealerId;

            var data = { ...fields };

            if (data.beforeDate.length <= 0) {
                data.beforeDate = new Date('01-01-2030')
            }

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.post(`${BASE_URI}/bulkAction/state`, data, {
                cancelToken: source.token
            })
            enqueueSnackbar('Updated state', {variant: 'success'});
            resetForm()
        } catch (error) {
            enqueueSnackbar('Could not update state', {variant: 'error'});
        } finally {
            setSubmitting(false)
        }
    }

    function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
        setStatus();
        updateStateOfLeads(fields, setSubmitting, resetForm)
    }


    return (
        <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            // onSubmit={(values, actions) => {
            //   updateState(values, actions.resetForm)
            //   setTimeout(() => {
            //     actions.setSubmitting(false)
            //   }, 500)
            // }}
            validationSchema={Yup.object().shape({
                // name: Yup.string().required('State name is required'),
                // dateField: Yup.string().required('Select a Model for the vehicle'),
                // handledBy: Yup.string().required('Enter a title for the Range option'),
                // reworkField: Yup.string().required('Vehicle image is required')
            })}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                    handleReset
                } = props

                return (
                    <Form>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Card style={{ marginBottom: '1rem' }}>
                                <Grid spacing={4} className={classes.stepPadding} container direction="row">
                                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                        <Toolbar disableGutters>
                                            <Typography style={{ flex: '1 1' }} variant="h6" id="tableTitle" component="div">
                                                Change the State of Leads
                    </Typography>
                                        </Toolbar>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            name="fromStatusId"
                                            id="fromStatusId"
                                            required
                                            label="State to change"
                                            select
                                            value={values.fromStatusId}
                                            helperText={errors.fromStatusId && touched.fromStatusId ? errors.fromStatusId : ''}
                                            error={errors.fromStatusId && touched.fromStatusId}
                                            onBlur={handleBlur("fromStatusId")}
                                            onChange={handleChange("fromStatusId")}
                                        >
                                            {fromStateArr.map((option) => (
                                                <MenuItem key={option.id} id={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            name="toStatusId"
                                            id="toStatusId"
                                            required
                                            label="State to set the leads to"
                                            select
                                            value={values.toStatusId}
                                            helperText={errors.toStatusId && touched.toStatusId ? errors.toStatusId : ''}
                                            error={errors.toStatusId && touched.toStatusId}
                                            onBlur={handleBlur("toStatusId")}
                                            onChange={handleChange("toStatusId")}
                                        >
                                            {toStateArr.map((option) => (
                                                <MenuItem key={option.id} id={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            name="userId"
                                            id="userId"
                                            label="Only for User"
                                            select
                                            value={values.userId}
                                            helperText={errors.userId && touched.userId ? errors.userId : 'Optional'}
                                            error={errors.userId && touched.userId}
                                            onBlur={handleBlur("userId")}
                                            onChange={handleChange("userId")}
                                        >
                                            {userArr.map((option) => (
                                                <MenuItem key={option.userId} id={option.userId} value={option.userId}>
                                                    {option.fullName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Field component={DatePicker}
                                            autoOk
                                            format="dd/MM/yyyy"
                                            helperText={"Optional"}
                                            label="Only for leads older than"
                                            value={values.beforeDate}
                                            maxDate={values.beforeDate}
                                            name="beforeDate" />
                                    </Grid>


                                    <Grid item container
                                        justify='flex-end' xs={12}>
                                        <Box mr={3}>
                                            <Button
                                                onClick={() => {
                                                    handleReset()
                                                }}>
                                                Reset
                    </Button>
                                        </Box>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                        >
                                            Submit
                  </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </MuiPickersUtilsProvider>
                    </Form>
                )
            }}
        </Formik>
    )
}