import React, { useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ReportingController from "./ReportingController";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DatePickHelper from "./DatePickHelper";
import TableHead from "@material-ui/core/TableHead";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "2rem",
        marginLeft: "0.5rem",
    },
    stepPaddingTable: {
        marginTop: "2rem",
        marginLeft: "3rem",
    },
    button: {
        marginTop: "2rem",
    },
    buttonMake: {
        marginTop: "2rem",
        backgroundColor: "#0b7d95",
        color: "white"
    },
}));

export default function LeadSourcesContainer() {
    const classes = useStyles();

    let { loading, sources } = ReportingController(
        "leadSources" // TODO change this to the specific api endpoint
    );

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#20c997",
            color: theme.palette.common.white,
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: 0.1
        },
        body: {
            fontSize: 14,
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: 0.1
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },

        },

    }))(TableRow);

    return (
        <div className={classes.root}>
            <Grid
                container
                className={classes.stepPadding}
                justify={"space-around"}
            >
                <Grid item xs={12} md={12} lg={12} className={classes.stepPadding}>
                    <DatePickHelper />
                </Grid>
                <Grid item xs={10} md={8} lg={12} className={classes.stepPadding}>
                    <Divider />
                </Grid>
                <Grid item xs={10} md={8} lg={12} style={{ textAlign: "center" }}>
                    {loading && <CircularProgress />}
                </Grid>
                <Grid item xs={12} md={12} lg={5} className={classes.stepPadding}>
                    <Typography>
                        Lead Sources (based on created within date range)
                    </Typography>
                    <TableContainer>
                        <Table aria-label="Lead Sources (based on created within date range)">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>State</StyledTableCell>
                                    <StyledTableCell>Count</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sources.leadSources.map((row) => (
                                    <StyledTableRow>
                                        <StyledTableCell>
                                            {row.source == "Total" ? <b>{row.source}</b> : row.source}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.count}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} md={12} lg={5} className={classes.stepPaddingTable}>
                    <Typography>
                        Delivered Lead Sources (based on delivered within date range)
                    </Typography>
                    <TableContainer>
                        <Table aria-label="Delivered Lead Sources (based on delivered within date range)">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>State</StyledTableCell>
                                    <StyledTableCell>Count</StyledTableCell>
                                    <StyledTableCell>Percentage</StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sources.deliveredLeadSources.map((row) => (

                                    <StyledTableRow>
                                        <StyledTableCell>
                                            {row.source == "Total" ? <b>{row.source}</b> : row.source}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.count}</StyledTableCell>
                                        <StyledTableCell>{row.percentage.toFixed(2) + '%'}</StyledTableCell>

                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
}