import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import jwt from 'jwt-decode'
import { useHistory } from 'react-router-dom'
import { AUTH_USER, BASE_URI, AUTH_PERMISSION } from '../Constants'


export const AuthContext = createContext({ role: [], permissions: [] });

export const AuthContextProvider = (props) => {
    let history = useHistory();
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [role, setRole] = useState([])
    const [permissions, setPermissions] = useState([])
    const [userDealerIDs, setUserDealerIDs] = useState([])
    const [email, setEmail] = useState('')
    const [hasAdminRights,setHasAdminRights] = useState(false)

    useEffect(()=>{
        if(role?.includes('Admin')){
            setHasAdminRights(true);
        }
    },[role])

    useEffect(() => {
        const session = JSON.parse(localStorage.getItem(AUTH_USER));
        if (session && !isAuthenticated) {
            setUserDealerIDs(session.userDealerIDs)

            if (Array.isArray(session.role)) {
                setRole([...session.role]);
            }
            else {
                setRole([session.role]);
            }

            setEmail(session.email);

            if (session.token) {
                setAuthenticated(true);
            }
        } else if (!localStorage && isAuthenticated) {
            setAuthenticated(false);
        }

        const permission = JSON.parse(localStorage.getItem(AUTH_PERMISSION));

        if (permission) {
            setPermissions([...permission])
        }

        if (!session || !session.userDealerIDs) {
            localStorage.removeItem(AUTH_USER);
            setAuthenticated(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    axios.interceptors.request.use(
        async config => {
            let session = JSON.parse(localStorage.getItem(AUTH_USER))
            if (session && session.token) {
                let token = createJWTToken(session.token)
                config.headers.authorization = token
            }
            return config
        },
        error => {
            Promise.reject(error)
        })

    axios.interceptors.response.use((response) => {
        return response
    }, function (error) {
        const originalRequest = error.config;
        // if (error?.response?.status === 401 && originalRequest.url ===
        //   `${BASE_URI}/Identity/Login`) {
        //   history.push('/login');
        //   return Promise.reject(error);
        // }

        if (error?.response?.status === 401 && originalRequest.url ===
            `${BASE_URI}/User/Refresh`) {
            logout()
            window.location.href = '/login';
            return Promise.reject(error);
        }

        if (error?.response?.status === 401 && !originalRequest._retry) {
            let session = JSON.parse(localStorage.getItem(AUTH_USER))
            if (!session) {
                return Promise.reject(error)
            }
            const refreshToken = session.refreshToken;
            return axios.post(`${BASE_URI}/User/Refresh`, null,
                {
                    headers: {
                        'Refresh-Token': refreshToken
                    }
                })
                .then(res => {
                    if (res.status === 201 || res.status === 200) {
                        setSession(null, res.data.token, res.data.refreshToken)
                        originalRequest._retry = true;
                        axios.defaults.headers.common['authorization'] = 'Bearer ' + res.data.token;
                        return axios(originalRequest);
                    }
                }).catch((error) => {
                    originalRequest._retry = false;
                    logout()
                    window.location.href = '/login';

                    return Promise.reject(error);
                })
        }
        return Promise.reject(error);
    });

    const setSession = (username, token, refreshToken, dealerIDs, permissions) => {
        const jwtHeader = jwt(token);
        const roles = jwtHeader['roles']
        const user = {
            email: username ? username : '',
            token: token,
            refreshToken: refreshToken,
            role: roles,
            userDealerIDs: dealerIDs
        }

        setUserDealerIDs(user.userDealerIDs)

        if (Array.isArray(roles)) {
            setRole([...roles]);
        }
        else {
            setRole([roles])
        }

        setEmail(user.email);
        setPermissions([...permissions]);
        setAuthenticated(true);
        localStorage.setItem(AUTH_USER, JSON.stringify(user))
        localStorage.setItem(AUTH_PERMISSION, JSON.stringify(permissions))
    }

    const createJWTToken = (token) => {
        return 'Bearer ' + token;
    }

    const logout = () => {
        localStorage.removeItem(AUTH_USER);
        setRole('')
        setPermissions('')
        setAuthenticated(false);
    }

    const login = (username, password) => {
        return axios.post(`${BASE_URI}/User/Login`, {
            email: username, password
        })
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, setSession, role, userDealerIDs, permissions, email, hasAdminRights }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
