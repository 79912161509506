import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    button: {
        marginTop: "2rem",
    },
}));

export default function NewFinanceHouseContainer() {
    const classes = useStyles();
    const [financeHouseData, setFinanceHouseData] = useState([]);

    const values = {
        financeHouse: "",
        financeHouseId:"",
    };

    return (
        <div className={classes.root}>
            <Formik
                initialValues={values}
                isInitialValid={false}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        console.log(values);
                        actions.setSubmitting(true);
                    }, 100);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Grid container direction="row">
                                <Grid item xs={12}>
                                    <Card className={classes.stepPadding}>
                                        <CardContent>
                                            <Typography variant="h6">
                                                New Application - Finance House
                                            </Typography>
                                            <Grid container direction="row">
                                                <Grid
                                                    item
                                                    className={classes.stepPadding}
                                                    xs={12}
                                                    md={4}
                                                    lg={4}
                                                >
                                                    <Autocomplete
                                                        id="financeHouse"
                                                        name="financeHouse"
                                                        value={{
                                                            id: props.values.financeHouseId.toString(),
                                                            financeHouseName: props.values.financeHouse.toString(),
                                                        }}
                                                        options={financeHouseData}
                                                        getOptionLabel={(option) => option.financeHouseName}
                                                        getOptionSelected={(option, value) => {
                                                            return option.financeHouseName === value.financeHouseName;
                                                        }}
                                                        onChange={(event, value) => {
                                                            props.handleChange(event);
                                                            props.setFieldValue("financeHouse", value ? value.financeHouseName : "");
                                                            props.setFieldValue("financeHouseId", value ? value.id : "");
                                                            props.setFieldTouched("financeHouse", true, false);
                                                            props.setFieldTouched("financeHouseId", true, false);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={
                                                                    props.touched.financeHouse &&
                                                                    Boolean(props.errors.financeHouse)
                                                                }
                                                                helperText={
                                                                    props.touched.financeHouse ? props.errors.financeHouse : ""
                                                                }
                                                                label="Select the Finance House"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={1} lg={1}>
                                                    <Button
                                                        className={classes.button}
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        disabled={!props.isValid}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} md={1} lg={1}>
                                                    <Button
                                                        className={classes.button}
                                                        variant="contained"
                                                        color="secondary"
                                                        type="submit"
                                                    >
                                                        Reset
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
