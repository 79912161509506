import React, {useState, createContext, useEffect, useRef, useContext} from 'react';
import axios from 'axios';
import {BASE_URI} from './Constants'
import {useSnackbar} from 'notistack'
import {AuthContext} from './context/AuthContext'

export const DealerContext = createContext();

export const DealerContextProvider = (props) => {
    const [selectedMotorGroup, setSelectedMotorGroup] = useState({})
    const [motorGroupId, setMotorGroupId] = useState('')
    const [isMasterDealer, setIsMasterDealer] = useState(false);
    const [dealerId, setDealerId] = useState('')
    const [dealerName, setDealerName] = useState('')
    const [dealerArr, setDealerArr] = useState([])
    const mountedRef = useRef(true)
    const {enqueueSnackbar} = useSnackbar();
    const {userDealerIDs, role} = useContext(AuthContext);

    const hasRole = (roleToCheck) => {
        if (!role) {
            return false;
        }

        return role.filter(itm => itm === roleToCheck).length > 0;
    }

    const filterDealerOnMotorGroup = (dealer, dealers) => {

        if (!dealers || dealers.length === 0 || hasRole('Admin'))
            return;
        var dealerArr = dealers.filter(itm => itm.motorgroupID == dealer?.motorgroupID);
        setDealerArr(dealerArr);
    }

    const filterDealersForUser = (dealers) => {

        if (!dealers || dealers.length === 0 || hasRole('Admin'))
            return;

        var dealerArr = dealers.filter(d => userDealerIDs.includes(d.id));
        setDealerArr(dealerArr);
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getDealers = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/dealers`, {
                    cancelToken: source.token
                })
                setDealerArr(result.data.list)
                return result.data.list
            } catch (error) {
                if (axios.isCancel(error)) return
                //enqueueSnackbar("Unable to load dealerships", {variant: 'error'});
            }
        }

        getDealers().then((dealers) => {
            var thisDealer = findDealer((userDealerIDs && userDealerIDs.length > 0 ? userDealerIDs[0] : 0), dealers)
            //filterDealerOnMotorGroup(thisDealer, dealers)
            filterDealersForUser(dealers);
            if (!mountedRef.current) return null
        })
        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDealerIDs])

    const updateSelectedMotorGroup = (motorGroup, dealerId) => {
        setSelectedMotorGroup(motorGroup)
        setDealerId(dealerId)
        setIsMasterDealer(getIsMasterDealer(motorGroup));
    }

    const findDealer = (id, dealers) => {
        if (dealers && dealers.length > 0 && id) {
            let dealer = dealers.find(dealer => dealer.id === id)
            setSelectedMotorGroup(dealer)
            setDealerId(dealer.id)
            setDealerName(dealer.name)

            return dealer
        }
    }

    const updateMotorGroupId = (id) => {
        setMotorGroupId(id)
    }

    const getIsMasterDealer = (dealer = selectedMotorGroup) => {
        // A dealer is a master when its name contains the word 'master' or when its name is the same as the motorgroup's name
        return (dealer?.name?.toLowerCase().includes("master"));// || dealer?.name?.trim().toLowerCase() == dealer?.motorGroupName?.trim().toLowerCase());
    };

    return (
        <DealerContext.Provider value={{
            dealerArr,
            selectedMotorGroup,
            motorGroupId,
            dealerId,
            dealerName,
            isMasterDealer,
            updateMotorGroupId,
            updateSelectedMotorGroup
        }}>
            {props.children}
        </DealerContext.Provider>
    );
}

export default DealerContextProvider;
