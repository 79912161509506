import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import { Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Toolbar from "@material-ui/core/Toolbar";
import { useSnackbar } from 'notistack'
import { useParams } from "react-router-dom";
import { Switch } from 'formik-material-ui'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from 'formik-material-ui-pickers';
import FormActions from '../../../../shared/FormActions'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BASE_URI, LOOKUP_URI } from '../../../../shared/Constants'
import axios from 'axios'
import SelectInput from '../../../../shared/AutoCompleteComponent'
import LoadingCardComponent from '../../../../shared/LoadingCardComponent'

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
}));

export default function BankTabContainer({ back, next }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const initialValues = {
        bankBranchName: '', //DONE
        bankAccountHolderName: '', //DONE
        accountNumber: '', //DONE
        bankAccountType: '', //DONE
        bankAccountTypeId: '', //DONE
        bank: '', //DONE
        bankId: '', //DONE
        branchCode: '', //DONE
        firstDebitDate: new Date(),
        requestedInterestRate: '', //DONE
        factualDeclaration: true, //DONE
    }
    const [formFields, setFormFields] = useState(initialValues)
    const mountedRef = useRef(true)
    const { id: leadId } = useParams();
    const [isAddMode, setIsAddMode] = useState(true);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [banks, setBanks] = useState([])
    const [accountTypes, setAccountTypes] = useState([])


    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)
        const getCustomerBankData = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/Applications/Bank/${leadId}`, {
                    cancelToken: source.token
                })

                setLoading(false)
        
                if (!result?.data)
                  return;
                  
                setFormFields(result.data)
                setIsAddMode(false)
                
            } catch (error) {
                setLoading(false)
                if (axios.isCancel(error)) return
            }
        }
        getCustomerBankData().then(() => {
            if (!mountedRef.current) return null
        })

        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId]);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getBankList = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/bank`, {
                    cancelToken: source.token
                })
                setBanks(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
            }
        }

        const getAccountTypes = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/accountType`, {
                    cancelToken: source.token
                })
                setAccountTypes(result.data)
            } catch (error) {
                if (axios.isCancel(error)) return
            }
        }

        getBankList().then(() => {
            if (!mountedRef.current) return null
        })

        getAccountTypes().then(() => {
            if (!mountedRef.current) return null
        })

        return () => {
            mountedRef.current = false
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
        setStatus();
        if (isAddMode) {
            createBankApplication(leadId, fields, setSubmitting);
        } else {
            editBankApplication(leadId, fields, setSubmitting);
        }
    }

    const createBankApplication = async (id, fields, setSubmitting) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.post(`${BASE_URI}/Applications/Bank/${id}`, fields, {
                cancelToken: source.token
            })
            enqueueSnackbar('Successfully uploaded bank details', { variant: 'success' });
            
            return result;
        } catch (error) {
            enqueueSnackbar('Error uploading bank details, please try again later.', { variant: 'error' });
        } finally {
            setSubmitting(false)
        }
    }


    const editBankApplication = async (id, fields, setSubmitting) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.put(`${BASE_URI}/Applications/Bank/${id}`, fields, {
                cancelToken: source.token
            })
            enqueueSnackbar('Successfully edited bank details', { variant: 'success' });
            
            return result;
        } catch (error) {
            enqueueSnackbar('Error updating bank details, please try again later.', { variant: 'error' });
        } finally {
            setSubmitting(false)
        }
    }

    const handleSelectChange = (e, value, setFieldValue, fieldName, fieldId) => {
        setFieldValue(fieldName, e.target.value)
        setFieldValue(fieldId, value.props.id)
    }

    return (
        <div className={classes.root}>
            <Formik
                initialValues={formFields}
                isInitialValid={false}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        handleReset,
                        setFieldValue
                    } = props
                    return (
                        <React.Fragment>
                            {
                                loading && <LoadingCardComponent />
                            }
                            {
                                !loading &&
                                <Form>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Card style={{ marginTop: '1.5rem' }} elevation={5}>
                                            <Grid spacing={4} className={classes.stepPadding} container direction="row">
                                                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                                    <Toolbar disableGutters>
                                                        <Grid item container>
                                                            <Grid item xs={12} md={4}>
                                                                <Typography variant="h6" component="div" gutterBottom>
                                                                    Banking Details
                                </Typography>
                                                            </Grid>
                                                            <Grid item container justify={matches ? `flex-end` : 'flex-start'} xs={12} md={8}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Field component={Switch}
                                                                            color="primary"
                                                                            type="checkbox"
                                                                            name="factualDeclaration" />
                                                                    }
                                                                    label="Factual Declaration"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Toolbar>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        fullWidth
                                                        Label={"Bank"}
                                                        fieldName={"bank"}
                                                        fieldNameID={"bankId"}
                                                        data={banks}
                                                        onBlur={handleBlur("bank")}
                                                        helperText={errors.bank && touched.bank ? errors.bank : 'Start Typing'}
                                                        error={errors.bank && touched.bank}
                                                        value={{
                                                            name: values.bank ? values.bank.toString() : '',
                                                            id: values.bankId ? values.bankId.toString() : '',
                                                        }}
                                                    />

                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id="bankAccountHolderName"
                                                        fullWidth
                                                        name="bankAccountHolderName"
                                                        // required
                                                        label="Account Holder Name"
                                                        value={values.bankAccountHolderName}
                                                        helperText={errors.bankAccountHolderName && touched.bankAccountHolderName ? errors.bankAccountHolderName : ''}
                                                        error={errors.bankAccountHolderName && touched.bankAccountHolderName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id="accountNumber"
                                                        fullWidth
                                                        name="accountNumber"
                                                        // required
                                                        label="Account Number"
                                                        value={values.accountNumber}
                                                        helperText={errors.accountNumber && touched.accountNumber ? errors.accountNumber : ''}
                                                        error={errors.accountNumber && touched.accountNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        id="bankAccountType"
                                                        select
                                                        //required
                                                        label="Account Type"
                                                        value={values.bankAccountType}
                                                        helperText={errors.bankAccountType && touched.bankAccountType ? errors.bankAccountType : ''}
                                                        error={errors.bankAccountType && touched.bankAccountType}
                                                        onBlur={handleBlur("bankAccountType")}
                                                        onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'bankAccountType', 'bankAccountTypeId')}
                                                    >
                                                        {accountTypes.map((option) => (
                                                            <MenuItem key={option.id} id={option.id} value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        id="bankBranchName"
                                                        fullWidth
                                                        name="bankBranchName"
                                                        // required
                                                        label="Branch Name"
                                                        value={values.bankBranchName}
                                                        helperText={errors.bankBranchName && touched.bankBranchName ? errors.bankBranchName : ''}
                                                        error={errors.bankBranchName && touched.bankBranchName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField
                                                        id="branchCode"
                                                        fullWidth
                                                        name="branchCode"
                                                        // required
                                                        label="Branch Code"
                                                        value={values.branchCode}
                                                        helperText={errors.branchCode && touched.branchCode ? errors.branchCode : ''}
                                                        error={errors.branchCode && touched.branchCode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>

                                                <Grid item md={3}></Grid>

                                                <Grid item xs={12} md={3}>
                                                    <TextField
                                                        id="requestedInterestRate"
                                                        fullWidth
                                                        type="number"
                                                        name="requestedInterestRate"
                                                        // required
                                                        label="Requested Interest Rate"
                                                        value={values.requestedInterestRate}
                                                        helperText={errors.requestedInterestRate && touched.requestedInterestRate ? errors.requestedInterestRate : ''}
                                                        error={errors.requestedInterestRate && touched.requestedInterestRate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Field component={DatePicker}
                                                        autoOk
                                                        format="dd/MM/yyyy"
                                                        label="First Debit Date"
                                                        name="firstDebitDate" />
                                                </Grid>
                                            </Grid>
                                        </Card>

                                        <FormActions back={back} reset={() => handleReset()} />
                                    </MuiPickersUtilsProvider>
                                </Form>
                            }
                        </React.Fragment>
                    );
                }}
            </Formik>
        </div>
    );
}
