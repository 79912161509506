import React, {useState, Fragment, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import axios from "axios";
import {BASE_URI} from "../../shared/Constants";
import Leads from "./LeadsFilter";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTextField-root": {
            marginRight: theme.spacing(5),
            marginLeft: theme.spacing(5),
            width: "40ch",
        },
    },
}));

export default function FilterContainer(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [names, setNames] = useState("");
    const [stateOfRequest, setStateOfRequest] = useState("");
    const [dealer, setDealer] = useState("");
    const [source, setSource] = useState("");
    const [leadName, setLeadName] = useState("");
    const [namesList, setNamesList] = useState([]);
    const [stateOfRequestList, setStateOfRequestList] = useState([]);
    const [dealerList, setDealerList] = useState([]);
    const [sourceList, setSourceList] = useState([]);

    const users = [
        {
            value: "Abigail Oosthuizen",
            label: "Abigail Oosthuizen",
        },
        {
            value: "Vernon Smith",
            label: "Vernon Smith",
        },
        {
            value: "James Small",
            label: "James Small",
        },
        {
            value: "Jaco Kriel",
            label: "Jaco Kriel",
        },
    ];
    const {ranges, total} = Leads(
        null,
        null,
        null,
        null,
        null,
        null,
        selectedDate,
        source,
        names,
        leadName,
        dealer);
    useEffect(() => {
        getDealers();
        getStates();
        getSources();
    }, []);
    // const getUsers = () => {
    //   let cancel;
    //   axios({
    //     method: 'GET',
    //     url: `${BASE_URI}/dealers`,
    //     cancelToken: new axios.CancelToken(c => cancel = c)
    //   }).then((response) => {
    //     if (response.data?.list) {
    //       setDealerList(response.data.list);
    //       return dealers;
    //       return dealerList;
    //     }
    //   });
    // }
    const getDealers = () => {
        let cancel;
        axios({
            method: 'GET',
            url: `${BASE_URI}/dealers`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setDealerList(response.data.list);
                return dealerList;
            }
        });
    }
    const getStates = () => {
        let cancel;
        axios({
            method: 'GET',
            url: `${BASE_URI}/leadstatus`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setStateOfRequestList(response.data.list);
                return stateOfRequestList;
            }
        });
    }
    const getSources = () => {
        let cancel;
        axios({
            method: 'GET',
            url: `${BASE_URI}/leadsources`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                setSourceList(response.data);
                setLoading(false);
                return sourceList;
            }
        });
    }
    const handleNameChange = (event) => {
        setNames(event.target.value);
    };
    const handleStateChange = (event) => {
        setStateOfRequest(event.target.value);
    };
    const handleDealerChange = (event) => {
        setDealer(event.target.value);
    };
    const handleDateChange = (date) => {
        setSelectedDate(new Date(date));
    };
    const handleSourceChange = (event) => {
        setSource(event.target.value);
    };
    const handleLeadNameChange = (event) => {
        setLeadName(event.target.value);
    };

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
                className={classes.stepPadding}
                direction="column"
            >
                <Grid item xs={12}>
                    <TextField
                        id="outlined-select-name"
                        select
                        value={names}
                        label="Filter By User:"
                        onChange={handleNameChange}
                        helperText="Please select user"
                    >
                        {/* TODO DO THE MAPPING */}
                        {users.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {/* TODO Get the correct label value based on user selection */}
                    <TextField
                        id="outlined-select-state"
                        select
                        value={stateOfRequest}
                        label="Filter By State:"
                        onChange={handleStateChange}
                        helperText="Please select the state"
                    >
                        {/* TODO DO THE MAPPING */}
                        {stateOfRequestList.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        id="outlined-select-state"
                        select
                        value={dealer}
                        label="Filter By Dealer:"
                        onChange={handleDealerChange}
                        helperText="Please select the dealer"
                    >
                        {/* TODO DO THE MAPPING */}
                        {dealerList.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField id="outlined-basic"
                               name="leadName" value={leadName}
                               onChange={handleLeadNameChange}
                               label="Lead name like"/>

                    <TextField
                        id="outlined-select-state"
                        select
                        value={source}
                        label="Filter By Source:"
                        onChange={handleSourceChange}
                        helperText="Please select the source"
                    >
                        {/* TODO DO THE MAPPING */}
                        {sourceList.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                            label="Only show Leads (updated) since"
                            clearable
                            disableFuture
                            value={selectedDate}
                            onChange={handleDateChange}
                            animateYearScrolling
                        />
                    </MuiPickersUtilsProvider>
                    <Divider
                        variant="fullWidth"
                        style={{marginTop: "1rem", marginBottom: "1rem"}}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
