import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    loadConainer:{
      height: '60em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position:'absolute',
      width: '100%',
      left:'0',
      margin: '0',
      zIndex:'100'
    }
}));
export default function LoaderComponent() {
  const classes = useStyles();
  
  return (
        <Grid className={classes.loadConainer} container spacing={4} justify="center" alignContent='center'>
            <Grid item>
                <CircularProgress size={80} />
            </Grid>
        </Grid>
  );
}
