import {useState, useEffect, useContext} from "react";
import axios from 'axios';
import {BASE_URI} from "../../../../shared/Constants";
import { DealerContext } from "../../../../shared/DealerContext";
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext";
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack'

export default function Management() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [state, setState] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [dealer, setDealer] = useState([]);
    let { ...dealerContext } = useContext(DealerContext);
    let { ...leadContext } = useContext(LeadMainContext);
    const { id } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);

        axios({
            method: 'GET',
            url: `${BASE_URI}/leadstatus`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                setState(response.data)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
        })
        axios({
            method: 'GET',
            url: `${BASE_URI}/dealers`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setDealer(response.data?.list)
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
        })
        let params = {}
        params.dealerId = dealerContext.dealerId;
        axios({
            method: 'GET',
            url: `${BASE_URI}/dealerAgent`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                let res = response.data.map(r => {return {"id":r.userId, "name": r.fullName}})
                setAgentsList(res);
            }
        });
    }, []);

    console.log(leadContext.lead);

    const save = (values) => {
        axios.put(`${BASE_URI}/leads/${id}`, { ...leadContext.lead, ...values }).then(() => {
            leadContext.reloadState();
            enqueueSnackbar("Saved lead!", { variant: 'success' });
        });
    };

    return { loading, error, state, agentsList, dealer, save};
}