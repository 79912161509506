import React, {useState} from 'react';
import {
  Typography,
  Box,
  Paper,
  Grid
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core/styles/index'
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {useParams, useHistory} from 'react-router-dom'
import OfferTable from './OfferTable'
import {StyledTab, StyledTabs} from '../../shared/StyledTabs'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  toolbar: {
    paddingRight: 24,
    display: 'flex',
    paddingLeft: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2.5rem'
  }
}));


export default function OfferContainer() {
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  let history = useHistory();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const tabs = [
    { id: 0, label: 'Active',  comp: <OfferTable tabStatus={'active'}/>},
    { id: 1, label: 'Inactive', comp: <OfferTable tabStatus={'inactive'}/>}
  ];

  return(
    <React.Fragment>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container spacing={4} className={classes.stepPadding} direction="column">
            <Grid item xs={12}>
              <Toolbar disableGutters style={{marginBottom: '2rem'}}>
                <Typography style={{flex: '1 1'}} variant="h4" id="tableTitle" component="div">
                  Offers
                </Typography>
                <Button onClick={() => history.push('/offers/add')}
                        variant="contained"
                        style={{marginLeft: '2rem'}}
                        startIcon={<AddIcon />}
                        color="primary">
                  Offer
                </Button>
              </Toolbar>
              <StyledTabs variant={ matches ? 'standard': 'scrollable'}
                          centered={matches}
                          scrollButtons={ matches ? 'auto' : 'on'}
                          value={tabIndex} onChange={handleTabChange}>
                {
                  tabs.filter(tab => !tab.hide).map((step) =>
                    <StyledTab label={`${step.label}`}
                               key={step.id} disabled={step.disabled} />
                  )
                }
              </StyledTabs>
              <Box mt={4}>
                {
                  tabs.filter(tab=>!tab.hide).map((step, index) => {
                    return <React.Fragment key={step.id}> {tabIndex === index ? step.comp: null} </React.Fragment>
                  })
                }
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </React.Fragment>
  )
}