import React, {useState, createContext, useEffect} from 'react';
import axios from 'axios';
import {BASE_URI} from '../Constants'

export const ReportingContext = createContext();

export const ReportingProvider = ({ children }) => {

    var today = new Date();
    var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const getDatePart = (date) => {
        return new Date(date.toDateString());
    }

    const [fromDate, setFromDate] = React.useState(firstDayOfMonth);
    const [toDate, setToDate] = React.useState(getDatePart(new Date()));
    const [responseData, setResponseData] = React.useState({});

    const updateTo = (to) => {
        setToDate(getDatePart(new Date(to)))
    }

    const updateFrom = (from) => {
        setFromDate(getDatePart(new Date(from)))
    }

    const updateResponseData = (data) => {
        setResponseData(data);
    }

    return (
        <ReportingContext.Provider
            value={{
                fromDate,
                toDate,
                updateTo,
                updateFrom,
                updateResponseData,
                responseData,
            }}>
            {children}
        </ReportingContext.Provider>
    );
}
