import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TableRow, TableCell } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2rem',
        width: '100%',
        overflow: 'auto'
    },
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	stepPadding: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
	},
	stickyHeaderTable: {
		minHeight: "250px",
		maxHeight: ({ stickyHeaderTableHeight }) => `${stickyHeaderTableHeight * 0.68}px`,
		"& th": {
			backgroundColor: theme.palette.common.white
		}
	},
	backArrowIcon: {
		paddingRight: "15px",
		cursor: "pointer"
	},
    popUpOuter: {
        paddingLeft: "240px",      // hard-coded width of the drawer
        left: "0",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: "fixed",
        alignItems: "center",
        background: "#1717179c",
        minHeight: "100vh",
        top: "0",
        bottom: "0",
        backdropFilter: "blur(1px)",
        zIndex: '5'
    },
    popUpInner: {
        position: 'relative',
        background: "white",
        borderRadius: "10px",
        textAlign: 'center !important',
        margin: '15px',
        minWidth: "400px",
        maxWidth: "90vw",
        '@media (min-width:0px) and (max-width:768px)': {
            width: "100%"
        },
        "@media (min-width: 769px)": {
          width: "800px",
        },
    },
    popUpClose: {
        position: 'absolute',
        right: '20px',
        top: '20px',
        cursor: "pointer",
        "&:hover": {
            color: "red",
        }
    },
    popUpTitleHolder: {
        padding: "20px 20px",
        borderBottom: '2px solid #001e4c1c'
    },
    popUpContentHolder: {
        padding: "15px 20px",
        minHeight: "20vh",
        maxHeight: "75vh",
        overflowY: "auto"
    }
}));

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        paddingBottom: "15px"
    },
    body: {
        fontSize: 14,
        padding: "0 15px"
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
