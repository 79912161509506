import React, {useContext} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider, DateTimePicker, DatePicker} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {ReportingContext} from "../../shared/contexts/ReportingContext";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginLeft: "2rem",
    },
    button: {
        marginTop: "2rem",
    },
    buttonMake: {
        marginTop: "2rem",
        backgroundColor: "#0b7d95",
        color: "white"
    },
}));

export default function DatePickHelper() {
    const classes = useStyles();
    let {...context} = useContext(ReportingContext);

    const [fromDate, setFromDate] = React.useState(new Date(context.fromDate));
    const [toDate, setToDate] = React.useState(new Date(context.toDate));

    const handleToDateChange = (date) => {
        setToDate(new Date(date));
        context.updateTo(date);
    };

    const handleFromDateChange = (date) => {
        setFromDate(new Date(date));
        context.updateFrom(date);
    };

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    return (
        <div className={classes.root}>
            <Grid
                container
                className={classes.stepPadding}
                justify="center"
            >
                <Grid item xs={12} md={4} lg={5}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                            label="From:"
                            clearable
                            fullWidth
                            disableFuture
                            value={fromDate}
                            onChange={handleFromDateChange}
                            animateYearScrolling
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={4} lg={5} className={classes.stepPadding}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                            label="To:"
                            clearable
                            fullWidth
                            disableFuture
                            value={toDate}
                            onChange={handleToDateChange}
                            animateYearScrolling
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </div>
    );
}
