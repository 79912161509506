import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, TextField, Button, Box, Paper, CircularProgress, Toolbar, Divider, Checkbox } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from 'yup'
import { useSnackbar } from "notistack";
import { BASE_URI, LeadTypeEnum } from "../../shared/Constants";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiCardHeader-title": {
			fontSize: "20px",
		},
	},
	flexCenter: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
	},
	stepPadding: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	errorText: {
		fontSize: "0.75rem",
		marginTop: "3px",
		textAlign: "left",
		fontWeight: 400,
		lineHeight: "1.66",
		letterSpacing: "0.03333em",
		color: "#f44336",
	},
	featureInputContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
    tr: {
        padding: "5px 30px",
    },
    th: {
        padding: "5px 10px",
        textAlign: "center"
    },
    td: {
        padding: "2px 10px",
    },
    tdCenter: {
        padding: "2px 10px",
        textAlign: "center"
    }
}));

export default function RoundRobinServiceTab() {

	const { id } = useParams();
	const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

	const [ loading, setLoading ] = useState(true);
	const [ loadingRoutes, setLoadingRoutes ] = useState(false);
	const [ savingRoutes, setSavingRoutes ] = useState(false);
    const [ motorGroupDealers, setMotorGroupDealers ] = useState([]);
    const [ roundRobinCampaigns, setRoundRobinCampaigns ] = useState([]);
    const [ selectedCampaign, setSelectedCampaign ] = useState(null);
    const [ isAddingNewCampaign, setIsAddingNewCampaign ] = useState(false);
    const [ leadSources, setLeadSources ] = useState([]);
    const [ leadTypes, setLeadTypes ] = useState([]);
    const [ roundRobinRoutes, setRoundRobinRoutes ] = useState([]);

    const newCampaignFormFields = {
        name: '',
        leadSourceId: 0,
        leadSource: '',
        leadTypeId: 0,
        leadType: '',
        facebookPageId: '',
        facebookAdId: ''
    };

    const newCampaignValidationSchema = Yup.object({
        name: Yup.string().required('Campaign name is required'),
        leadSource: Yup.string().required('Lead source is required'),
        leadType: Yup.string().required('Lead type is required'),
        facebookPageId: Yup.string().required('Facebook Page ID is required')
    });

	useEffect(() => {

		Promise.allSettled([
            getMotorGroupDealers(),
			getRounRobinCampaigns(),
			getLeadSources(),
            getLeadTypes()
		]).then(() => {
			
			setLoading(false);

		}).catch((error) => {
			
			setLoading(false);
			enqueueSnackbar(getErrorMessage(error, "An unexpected error occurred while loading the form data"), { variant: 'error' });

		});

	}, []);

    useEffect(() => {

        if (selectedCampaign?.id > 0) {

            getRoundRobinRoutes();

        } else {

            setRoundRobinRoutes([]);

        }

    }, [selectedCampaign]);

    function getErrorMessage(axiosError, defaultMsg) {

        let errorTitle = defaultMsg;
        let errorDetails = "No additional details are available";

        if (axiosError?.request?.message || axiosError?.request?.statusText)  {

            errorTitle = "Failed to send the request";
            errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;

        } else if (axiosError?.response?.data) {

            errorTitle = axiosError?.response?.data?.title ?? errorTitle;
            errorDetails = axiosError?.response?.data?.details ?? errorDetails;
        }

        return `${errorTitle}: ${errorDetails}`;
    }

    function getMotorGroupDealers() {

        return new Promise((res, rej) => {

            // Need to get the current/master dealer's details first
            const getDealerDetails = async () => {
                try {
                    const dealerResponse = await axios.get(`${BASE_URI}/Dealers/${id}`, {
                        cancelToken: axios.CancelToken.source().token,
                    });

                    let dealer = dealerResponse?.data;

                    if (!dealer) {
                        enqueueSnackbar(`No data returned for the current dealer`, { variant: 'error' });
                        return;
                    }
                    
                    return dealer;
        
                } catch (error) {

                    if (axios.isCancel(error)) {
                        return;
                    }
        
                    enqueueSnackbar(getErrorMessage(error, "Unable to load the current dealer's details"), { variant: 'error' });
                    return null;
                    
                }
            };

            getDealerDetails().then(dealer => {

                const params = {
                    motorgroupId: dealer?.motorgroupId
                };
    
                axios({
                    method: 'GET',
                    url: `${BASE_URI}/Dealers`,
                    params,
                    cancelToken: axios.CancelToken.source().token
                }).then((response) => {
                    
                    let dealers = response?.data?.list;
    
                    if (!dealers || dealers.length <= 0) {
                        enqueueSnackbar(`No motorgroup dealers available for the current dealer`, { variant: 'error' });
                        return;
                    }
                    
                    setMotorGroupDealers(dealers);
                    res(dealers)
    
                }).catch(error => {
                    
                    if (axios.isCancel(error)) {
                        return;
                    }
    
                    enqueueSnackbar(getErrorMessage(error, "Unable to load the motorgroup's dealers"), { variant: 'error' });
                    rej(null);
                    
                });
            });

        });

    }

    function getRounRobinCampaigns() {

        return new Promise((res, rej) => {

            axios({
                method: 'GET',
                url: `${BASE_URI}/RoundRobinCampaign`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                let data = [
                    {
                        id: 0, 
                        name: "None",
                        leadSourceId: 0,
                        leadSource: { id: 0, name: "Default" },
                        leadTypeId: 0,
                        leadType: { id: 0, name: "Default" },
                        facebookPageId: "0"
                    },
                    ...response?.data
                ];
                
                setRoundRobinCampaigns(data);
                res(data)
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(getErrorMessage(error, "Unable to load round robin campaign options"), { variant: 'error' });
                rej(null);

            });

        });

    }

    function getLeadSources() {

        return axios({
            method: 'GET',
            url: `${BASE_URI}/LeadSources`,
            cancelToken: axios.CancelToken.source().token
        }).then((response) => {
            
            let data = [
                { id: 0, name: "Default" },
                ...response?.data
            ];
            
            setLeadSources(data);

        }).catch(error => {
            
            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar(getErrorMessage(error, "Unable to load lead source options"), { variant: 'error' });
            
        });

    }

    function getLeadTypes() {

        let allLeadTypes = [
            { id: 0, name: "Default" },
            ...Object.entries(LeadTypeEnum).map(([key, value]) => { return { id: value, name: key }; })
        ];

        setLeadTypes(allLeadTypes);

    }

    function getRoundRobinRoutes() {
        
        setLoadingRoutes(true);

        return new Promise((res, rej) => {

            axios({
                method: 'GET',
                url: `${BASE_URI}/RoundRobinRoute/Campaign/${selectedCampaign.id}`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                let allRoutes = [];
                let campaignRoutes = response?.data || [];
                
                for (let i = 0; i < motorGroupDealers.length; i++) {
                    const dealer = motorGroupDealers[i];
                    
                    let existingRoute = campaignRoutes.find(r => r.dealerId == dealer.id);

                    if (!existingRoute) {

                        allRoutes.push({
                            roundRobinId: selectedCampaign.id,
                            dealerId: dealer.id,
                            name: dealer.name,
                            isTicked: false
                        });

                    } else {
                        
                        allRoutes.push({
                            name: dealer.name,
                            isTicked: true,
                            ...existingRoute
                        });
                        
                    }
                }

                // Ensure the current (master) dealer's "isExcluded" field is true otherwise the master dealer will be included in the round-robin queue
                let masterRoute = allRoutes.find(r => r.dealerId == id);

                masterRoute.isTicked = true;
                masterRoute.isExcluded = true;

                setRoundRobinRoutes(allRoutes);
                setLoadingRoutes(false);
                res(allRoutes);
    
            }).catch(error => {
                
                setLoadingRoutes(false);

                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(getErrorMessage(error, "Unable to load round robin route options"), { variant: 'error' });
                rej(null);
                
            });

        });
        
    }

    function onSubmitNewCampaign(formValues, formActions) {

        let campaign = {
            name: formValues.name,
            leadSourceId: formValues.leadSourceId,
            leadTypeId: formValues.leadTypeId,
            facebookPageId: formValues.facebookPageId,
            facebookAdId: formValues.facebookAdId
        };

        axios({
            method: 'POST',
            url: `${BASE_URI}/RoundRobinCampaign`,
            data: campaign,
            cancelToken: axios.CancelToken.source().token
        }).then(newCampaignResponse => {

            enqueueSnackbar(`Successfully saved the new campaign. Reloading all campaigns`, { variant: 'success' });

            getRounRobinCampaigns();
            setIsAddingNewCampaign(false);
            formActions.setSubmitting(false);

        }).catch(error => {

            formActions.setSubmitting(false);

            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar(getErrorMessage(error, "Failed to save the new campaign"), { variant: 'error' });
            
        });

    }

    function saveRoutes() {

        let newRoutes = roundRobinRoutes.filter(r => !r.id && r.isTicked);
        let deleteRoutes = roundRobinRoutes.filter(r => r.id > 0 && !r.isTicked);

        setSavingRoutes(true);

        Promise.allSettled([
            saveNewRoutes(newRoutes),
            deleteUntickedRoutes(deleteRoutes)
        ]).then((response) => {
            
            setSavingRoutes(false);

            if (response.some(r => r.status == "rejected")) {
                enqueueSnackbar("An unexpected error occurred while saving the routes", { variant: 'error' });
            }

            getRoundRobinRoutes();

        });

    }

    function saveNewRoutes(routes) {

        if (!routes || routes.length <= 0)
            return;

        return axios({
            method: 'POST',
            url: `${BASE_URI}/RoundRobinRoute/Batch`,
            data: routes,
            cancelToken: axios.CancelToken.source().token
        }).catch(error => {

            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar(getErrorMessage(error, "Failed to save the newly added routes"), { variant: 'error' });
            
        });

    }
    
    function deleteUntickedRoutes(routes) {

        if (!routes || routes.length <= 0)
            return;
            
        let routeIDs = routes.map(r => r.id);

        return axios({
            method: 'DELETE',
            url: `${BASE_URI}/RoundRobinRoute/Batch`,
            data: routeIDs,
            cancelToken: axios.CancelToken.source().token
        }).catch(error => {

            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar(getErrorMessage(error, "Failed to remove the unchecked routes"), { variant: 'error' });
            
        });

    }
    
    function addNewCampaign() {

        setSelectedCampaign(null);
        setIsAddingNewCampaign(true);

    }

    function selectRoundRobinCampaign(e, c) {

        setSelectedCampaign(e?.target?.value);

    }

    function toggleRoundRobinDealer(dealerId) {
        
        // This round-about way is to ensure that the checkbox is rendered after its value is updated
        let list = [...roundRobinRoutes];
        let dealer = list.find(d => d.dealerId == dealerId);
        
        dealer.isTicked = !dealer.isTicked;

        setRoundRobinRoutes(list);

    }

    function handleSelectChange(e, value, setFieldValue, fieldName, fieldId) {

        setFieldValue(fieldName, e.target.value);
        setFieldValue(fieldId, value.props.id);

    }

	return (
		<Paper className={classes.paper}>
            {
                loading &&
                <Grid container direction="row" justify="center">
                    <CircularProgress/>
                </Grid>
            }
            {
                !loading &&
                <Grid container spacing={4} className={classes.stepPadding} direction="column">

                    {/* Round robin campaign section*/}
                    <Grid item xs={12} container spacing={2}>

                        <Grid item xs={12}>
                            <Toolbar disableGutters>
                                <Typography variant="h6" style={{ flex: "1 1" }} component="div" gutterBottom>
                                    Round Robin Campaign
                                </Typography>

                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    disabled={isAddingNewCampaign || savingRoutes}
                                    onClick={addNewCampaign}
                                >
                                    New Campaign
                                </Button>
                            </Toolbar>
                        </Grid>

                        {
                            isAddingNewCampaign &&
                            <Grid item xs={12}>
                                <Formik
                                    initialValues={newCampaignFormFields}
                                    isInitialValid={false}
                                    enableReinitialize={true}
                                    onSubmit={onSubmitNewCampaign}
                                    validationSchema={newCampaignValidationSchema}
                                >
                                    {(props) => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            isSubmitting,
                                            setFieldValue
                                        } = props;
                                        return (
                                            <Form noValidate>
                                                <Grid container spacing={2} direction="row">
                        
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            id="name"
                                                            label="Campaign Name"
                                                            value={values.name}
                                                            helperText={errors.name && touched.name ? errors.name : ''}
                                                            error={errors.name && touched.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange} />
                                                    </Grid>
                        
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <TextField
                                                            select
                                                            fullWidth
                                                            required
                                                            id="leadSource"
                                                            label="Lead Source"
                                                            value={values.leadSource}
                                                            helperText={errors.leadSource && touched.leadSource ? errors.leadSource : ''}
                                                            error={errors.leadSource && touched.leadSource}
                                                            onBlur={handleBlur}
                                                            onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'leadSource', 'leadSourceId')}>
                                                            {
                                                                leadSources.map((option, index) => (
                                                                    <MenuItem key={index} id={option.id} value={option.name}>
                                                                        {option.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </TextField>
                                                    </Grid>
                        
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <TextField
                                                            select
                                                            fullWidth
                                                            required
                                                            id="leadType"
                                                            label="Lead Type"
                                                            value={values.leadType}
                                                            helperText={errors.leadType && touched.leadType ? errors.leadType : ''}
                                                            error={errors.leadType && touched.leadType}
                                                            onBlur={handleBlur}
                                                            onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'leadType', 'leadTypeId')}>
                                                            {
                                                                leadTypes.map((option, index) => (
                                                                    <MenuItem key={index} id={option.id} value={option.name}>
                                                                        {option.name}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </TextField>
                                                    </Grid>

                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            id="facebookPageId"
                                                            label="Facebook Page ID"
                                                            value={values.facebookPageId}
                                                            helperText={errors.facebookPageId && touched.facebookPageId ? errors.facebookPageId : ''}
                                                            error={errors.facebookPageId && touched.facebookPageId}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange} />
                                                    </Grid>

                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <TextField
                                                            fullWidth
                                                            id="facebookAdId"
                                                            label="Facebook Ad ID"
                                                            value={values.facebookAdId}
                                                            helperText={errors.facebookAdId && touched.facebookAdId ? errors.facebookAdId : ''}
                                                            error={errors.facebookAdId && touched.facebookAdId}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange} />
                                                    </Grid>
                        
                                                    <Grid item xs={12} container spacing={2} direction="row" justify='flex-end'>
                                                        <Grid item xs={12} sm={2}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                                onClick={() => setIsAddingNewCampaign(false)}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>

                                                        <Grid item xs={12} sm={2}>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={isSubmitting}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </Grid>
                        }
                        {
                            !isAddingNewCampaign && 
                            <Grid item xs={12} container spacing={2} direction="row">

                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        fullWidth
                                        id="selectedCampaign"
                                        label="Select a Campaign"
                                        disabled={savingRoutes}
                                        onChange={(e, c) => selectRoundRobinCampaign(e, c)}>
                                        {
                                            roundRobinCampaigns.map((option, index) => (
                                                <MenuItem key={index} id={option.id} value={option}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>

                                {
                                    selectedCampaign?.id > 0 &&
                                    <>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <Typography variant="caption" color="textSecondary" component="p">
                                                Lead Source
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                <strong>{selectedCampaign?.leadSource?.name || "Default"}</strong>
                                            </Typography>
                                        </Grid>
            
                                        <Grid item xs={12} md={6} lg={3}>
                                            <Typography variant="caption" color="textSecondary" component="p">
                                                Lead Type
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                <strong>{selectedCampaign?.leadType?.name || "Default"}</strong>
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item xs={12} md={6} lg={3}>
                                            <Typography variant="caption" color="textSecondary" component="p">
                                                Facebook Page ID
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                <strong>{selectedCampaign?.facebookPageId || "None"}</strong>
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item xs={12} md={6} lg={3}>
                                            <Typography variant="caption" color="textSecondary" component="p">
                                                Facebook Ad ID
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                <strong>{selectedCampaign?.facebookAdId || "None"}</strong>
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                            </Grid>
                        }

                    </Grid>
                    
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    
                    {/* Round robin routes section */}
                    {
                        selectedCampaign?.id > 0 && loadingRoutes &&
                        <Grid item xs={12} container direction="row" justify="center">
                            <CircularProgress/>
                        </Grid>
                    }
                    {
                        selectedCampaign?.id > 0 && !loadingRoutes && roundRobinRoutes.length <= 0 &&
                        <Grid item xs={12}>
                            <Typography variant="caption" align="center" gutterBottom>
                                There are no dealers available for routing this campaign
                            </Typography>
                        </Grid>
                    }
                    {
                        selectedCampaign?.id > 0 && !loadingRoutes && roundRobinRoutes.length > 0 &&
                        <Grid item xs={12} container spacing={2}>
                            
                            <Grid item xs={12}>
                                <Toolbar disableGutters>
                                    <Typography variant="h6" style={{ flex: "1 1" }} component="div" gutterBottom>
                                        Round Robin Routes
                                    </Typography>

                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        disabled={savingRoutes}
                                        onClick={saveRoutes}
                                    >
                                        Save
                                    </Button>
                                </Toolbar>
                            </Grid>
        
                            <Grid item xs={12}>
                                <Typography variant="caption" gutterBottom>
                                    Select the dealers you want to add to the round robin queue
                                </Typography>
                            </Grid>
        
                            <Grid item xs={12}>
                                <table width="100%" border="1">
                                    <thead>
                                        <tr className={classes.tr}>
                                            <th className={classes.th}>
                                                Dealer Name
                                            </th>
                                            <th className={classes.th}>
                                                Enqueued
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {roundRobinRoutes.filter(r => !r.isExcluded).map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className={classes.td}>
                                                        <div key={`${index}-1`}>
                                                            {item.name}
                                                        </div>
                                                    </td>
                                                    <td className={classes.tdCenter}>
                                                        <div key={`${index}-2`}>
                                                            <Checkbox
                                                                name={item.name}
                                                                color="primary"
                                                                checked={item.isTicked}
                                                                disabled={savingRoutes}
                                                                onChange={() => {
                                                                    toggleRoundRobinDealer(item.dealerId);
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Grid>
        
                        </Grid>
                    }
                                                
                </Grid>
            }
		</Paper>
	);
}
