import React from "react";
import { Field } from "formik";
import FileUploader from "./FileUploader";
// import ImageUploader from './ImageUploader'
import Resizer from "react-image-file-resizer"
import { IMAGE_UPLOADER_CONFIG } from './Constants'

const FileUploadInput = ({ title, fieldName, error, required, disabled, errorMsg, className, setFileUpoload }) => (
    <Field name={fieldName} id={fieldName} type="text">
        {({ field: { value }, form: { setFieldValue } }) => (
            <FileUploader
                file={value}
                title={title}
                disabled={disabled}
                required={required}
                className={className}
                onDropFile={(file) => {
                    let isImage = file.type.includes('image')
                    if (isImage) {
                        Resizer.imageFileResizer(
                            file,
                            IMAGE_UPLOADER_CONFIG.maxWidth,
                            IMAGE_UPLOADER_CONFIG.maxHeight,
                            IMAGE_UPLOADER_CONFIG.compressFormat,
                            IMAGE_UPLOADER_CONFIG.quality,
                            IMAGE_UPLOADER_CONFIG.rotation,
                            uri => {
                                setFieldValue(fieldName, uri)
                            },
                            IMAGE_UPLOADER_CONFIG.outputType
                        );
                    } else {
                        setFieldValue(fieldName, file)
                        if (setFileUpoload) setFileUpoload(file);
                    }
                }}
                id={fieldName}
                image={value}
                error={error}
                errorMsg={errorMsg}
                clear={() => setFieldValue(fieldName, '')} />
        )}
    </Field>
);

export default FileUploadInput;