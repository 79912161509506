import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import { Box } from '@material-ui/core'
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { ReportingContext } from "../../shared/contexts/ReportingContext";
import { DealerContext } from "../../shared/DealerContext";
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
import { CSVLink } from "react-csv";
import DatePickHelper from "../Reporting/DatePickHelper";

const useStyles = makeStyles((theme) => ({
    root: {},
    step: {
        marginTop: "2rem",
        marginLeft: "2rem",
    },
    buttonMake: {
        marginTop: "2rem",
        backgroundColor: "#0b7d95",
        color: "white"
    },
    cellText: {
        background: "#ebd852"
    },
}));

export default function ExportContainer() {
    const classes = useStyles();
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState('');
    const [ranges, setRanges] = useState([]);
    let { ...context } = useContext(ReportingContext);
    let { ...dealerContext } = useContext(DealerContext);

    useEffect(() => {
        setRanges([]);
    }, [context.toDate, context.fromDate]);

    const exportCSV = () => {
        setIsSubmitting(true);

        let cancel;

        const params = {};
        params.toDate = context.toDate;
        params.fromDate = context.fromDate;
        params.dealerId = dealerContext.dealerId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/leads/export`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {

            setData(response.data);
            setIsSubmitting(false);
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                //setError(true)
            }
            setIsSubmitting(false);
        })


    }

    return (
        <div className={classes.root}>
            <Grid
                container
                justify={"space-around"}
                alignItems="flex-start"
            >
                <Grid item xs={10} md={8} lg={12} className={classes.step}>
                    <DatePickHelper />
                </Grid>
                <Grid item xs={10} md={8} lg={12} className={classes.step}>
                    <Box mr={3}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={exportCSV}
                        >
                            Generate CSV
                        </Button>
                    </Box>
                    <Box mr={3}>
                        {data.length > 0 && <>
                            <CSVLink
                                style={{ textDecoration: 'none' }}
                                data={data}
                                // I also tried adding the onClick event on the link itself
                                filename={'export.csv'}
                                target="_blank"
                            >
                                Download CSV
                        </CSVLink></>
                        }
                    </Box>
                </Grid>
                <Grid item container
                    justify='flex-end' xs={12}>

                </Grid>
            </Grid>
        </div>
    );
}
