import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Typography} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    stepPaddingButton: {
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "2rem",
    },
    card: {
        background: "red",
    },
}));

export default function LeadClosedContainer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid item className={classes.stepPadding} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="caption">
                                This Lead has been closed for further action.
                                (A Manager can re-open it if required)
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
