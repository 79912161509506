import React, {useEffect, useState, useRef} from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  MenuItem
} from '@material-ui/core'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {BASE_URI} from '../../shared/Constants'
import axios from 'axios'
// import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

function getStyles(arrayItem, selectArray, theme) {
  return {
    fontWeight:
      selectArray.indexOf(arrayItem) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function StateAdminForm({history, match}) {
  const classes = useStyles();
  const { id } = match.params;
  const theme = useTheme();
  const isAddMode = !id;
  const mountedRef = useRef(true)
  //const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const initialValues = {
    name: '',
    dateField: '',
    handledBy: '',
    reworkField: '',
    reworkDays: '',
    reworkTo: '',
    actionsStateArr: [],
    nextStateArr: [],
  }
  const [formFields, setFormFields] = useState(initialValues)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getState = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/leadStatus`, {
          params: {
            id
          },
          cancelToken : source.token
        })
        let formData = result.data
        setFormFields(formData)
      } catch (error) {
        // addAlert('Unables to load vehicle categories')
        history.push('..')
      }
    }

    if (!isAddMode) {
      getState().then(() => {
        if (!mountedRef.current) return null
      })
    }
    return () => {
      mountedRef.current = false
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const stateArr = [
    'Application Submitted', 'Application Rejected', 'Application Declined', 'Application Updated', 'Bank Validated'
  ]

  const handledByArr = [
    'Agent', 'Qualifier', 'Documenter', 'Finance', 'Manager'
  ]

  const updateState = async (id, fields, setSubmitting, resetForm) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.put(`${BASE_URI}/state`, fields, {
        params: {
          id
        },
        cancelToken : source.token
      })
      //enqueueSnackbar(result.data.message, {variant: 'success'});
      //history.push('/admin/states')
      resetForm()
      history.push('..');
      return result;
    } catch (error) {
      //enqueueSnackbar(error.data.message, {variant: 'error'});
    } finally {
      setSubmitting(false)
    }
  }

  const createState = async (fields, setSubmitting, resetForm) => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const result = await axios.post(`${BASE_URI}/state`, fields, {
        cancelToken : source.token
      })
      //enqueueSnackbar(result.data.message, {variant: 'success'});
      //history.push('/admin/states')
      resetForm()
      history.push('..');
      return result;
    } catch (error) {
      //enqueueSnackbar(error.data.message, {variant: 'error'});
    } finally {
      setSubmitting(false)
    }
  }

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    console.log(id)
    if (isAddMode) {
      createState(fields, setSubmitting, resetForm);
    } else {
      updateState(id, fields, setSubmitting, resetForm);
    }
  }


  return(
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction="column">
        <Grid item xs={12}>
          <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            enableReinitialize={true}
            // onSubmit={(values, actions) => {
            //   updateState(values, actions.resetForm)
            //   setTimeout(() => {
            //     actions.setSubmitting(false)
            //   }, 500)
            // }}
            validationSchema={Yup.object().shape({
              // name: Yup.string().required('State name is required'),
              // dateField: Yup.string().required('Select a Model for the vehicle'),
              // handledBy: Yup.string().required('Enter a title for the Range option'),
              // reworkField: Yup.string().required('Vehicle image is required')
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isSubmitting,
                handleReset
              } = props

              return (
                <Form>
                  <Typography variant="h6" gutterBottom>
                    {isAddMode ? 'Add': 'Edit'} State
                  </Typography>
                  <Grid spacing={4} container direction="row">
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name="name"
                        id="name"
                        required
                        label="State Name"
                        value={values.name}
                        type="text"
                        helperText={errors.name && touched.name ? errors.name: ''}
                        error={errors.name && touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name="dateField"
                        id="dateField"
                        required
                        label="Date Field"
                        value={values.dateField}
                        type="text"
                        helperText={errors.dateField && touched.dateField ? errors.dateField: '(e.g. created_at)'}
                        error={errors.dateField && touched.dateField}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={false} md={4}></Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="handledBy"
                        id="handledBy"
                        required
                        label="Once its reached this state, who is it handled by?"
                        select
                        value={values.handledBy}
                        helperText={errors.handledBy && touched.handledBy ? errors.handledBy: ''}
                        error={errors.handledBy && touched.handledBy}
                        onBlur={handleBlur("handledBy")}
                        onChange={handleChange("handledBy")}
                      >
                        {handledByArr.map((option, index) => (
                          <MenuItem key={index} id={`${option}-${index}`} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={false} md={6}></Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="nextStateArr">Next State(s)</InputLabel>
                        <Select
                          labelId="nextStateArr-label"
                          id="nextStateArr"
                          multiple
                          required
                          value={values.nextStateArr}
                          onChange={handleChange("nextStateArr")}
                          input={<Input id="nextStateArr" />}
                          renderValue={(selected) => (
                            <div  className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value}  className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {stateArr.map((state) => (
                            <MenuItem key={state} value={state} style={getStyles(state, stateArr, theme)}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="actionsStateArr">Actions on this State</InputLabel>
                        <Select
                          labelId="actionsStateArr-label"
                          id="actionsStateArr"
                          multiple
                          required
                          value={values.actionsStateArr}
                          onChange={handleChange("actionsStateArr")}
                          input={<Input id="selectStateArr" />}
                          renderValue={(selected) => (
                            <div  className={classes.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={value}  className={classes.chip} />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {stateArr.map((state) => (
                            <MenuItem key={state} value={state} style={getStyles(state, stateArr, theme)}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        name="reworkField"
                        required
                        fullWidth
                        id="reworkField"
                        label="Rework Field"
                        helperText={errors.reworkField && touched.reworkField ? errors.reworkField: '(e.g. pq_declined_rework_at)'}
                        value={values.reworkField}
                        type="text"
                        onChange={handleChange}
                      />

                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="reworkDays"
                        required
                        style={{width: 200}}
                        id="reworkDays"
                        label="Rework After"
                        value={values.reworkDays}
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <Typography>
                              Days
                            </Typography>
                          )
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="reworkTo"
                        required
                        fullWidth
                        id="reworkTo"
                        label="Rework To"
                        value={values.reworkTo}
                        type="text"
                        onChange={handleChange}
                      />

                    </Grid>

                    <Grid item container
                          justify='flex-end' xs={12}>
                      <Box mr={3}>
                        <Button
                          onClick={() => {
                            handleReset()
                            history.push('/admin/states')
                          }}>
                          Cancel
                        </Button>
                      </Box>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  )
}