import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, CircularProgress, Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from 'yup';
import { useSnackbar } from "notistack";
import { BASE_URI } from "../../../shared/Constants";
import { useStyles } from '../../../shared/CommonStyles';
import { GetErrorMessage, AddDateStringDelimiter } from '../../../shared/CommonFunctions';

const fuelTypes = [
    "Petrol",
    "Diesel",
    "Electric",
    "Hybrid"
];

const transmissionTypes = [
    "Automatic",
    "Manual"
];

export default function VehicleLibraryVariantForm() {
    
    const classes = useStyles();
    const history = useHistory();
    const { makeId, modelId, id, action } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const isNewForm = (id <= 0 || action.trim().toLowerCase() === "add");
    const newFormValues = {
        name: "",
        mmCode: "",
        price: 0,
        introYear: new Date(),
        discontinueYear: new Date(moment(new Date()).add(1, "year")),
        fuelType: "",
        transmission: ""
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Variant name is required"),
        mmCode: Yup.string().required("M&M code is required"),
        price: Yup.number().min(0, "Price cannot be negative").required("Price is required"),
        introYear: Yup.string().required("Introduction year is required"),
        discontinueYear: Yup.string().required("Discontinue year is required"),
        fuelType: Yup.string().required("Fuel type selection is required"),
        transmission: Yup.string().required("Transmission type selection is required")
    });

    const [ loading, setLoading ] = useState(true);
    const [ initialFormValues, setInitialFormValues ] = useState(newFormValues);

    useEffect(() => {
        
        Promise.allSettled([
            LoadVariantDetails()
        ])
        .catch((error) => {

			enqueueSnackbar(GetErrorMessage(error, "An unexpected error occurred while loading the master variant details"), { variant: 'error' });

        }).finally(() => {

            setLoading(false);

        });
        
        
    }, []);

    function LoadVariantDetails() {

        if (isNewForm) {
            return;
        }

        return new Promise((res, rej) => {

            axios({
                method: 'GET',
                url: `${BASE_URI}/MasterVariant/${id}`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                console.log("variant response = ", response)
                if (!response?.data) {

                    enqueueSnackbar("No master variant details could be found", { variant: 'warning' });
                    res(null);
                    return;

                }

                let variantDetails = {
                    ...response?.data,
                    introYear: new Date(AddDateStringDelimiter(response?.data?.introYear)),
                    discontinueYear: new Date(AddDateStringDelimiter(response?.data?.discontinueYear))
                };
                
                setInitialFormValues(variantDetails);
                res(variantDetails);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the master variant details"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function GoBack() {

        history.replace(`/VehicleLibrary/Makes/${makeId}/Models/${modelId}/Variants`);

    }

    function SubmitForm(formValues, formActions) {
        
        try {

            let variant = {
                ...formValues,
                introYear: `${formValues.introYear.getFullYear().toString()}/${(formValues.introYear.getMonth() + 1).toString().padStart(2, "0")}`,
                discontinueYear: `${formValues.discontinueYear.getFullYear().toString()}/${(formValues.discontinueYear.getMonth() + 1).toString().padStart(2, "0")}`,
                masterModelId: parseInt(modelId)
            }
            
            if (isNewForm) {
                AddNewVariant(variant, formActions.setSubmitting);
            } else {
                EditVariant(variant, formActions.setSubmitting);
            }

        } catch (error) {

            formActions.setSubmitting(false);
            enqueueSnackbar(GetErrorMessage(error, "Unable to save the master variant"), { variant: 'error' });

        }

    }

    function AddNewVariant(variant, setSubmitting) {
        
        axios({
            method: 'POST',
            url: `${BASE_URI}/MasterVariant`,
            data: variant,
            cancelToken: axios.CancelToken.source().token
        }).then(newVariantResponse => {
            
            let newVariant = newVariantResponse?.data;

            if (!newVariant?.id || newVariant.id <= 0) {

                enqueueSnackbar(`The new variant did not save correctly, please try again`, { variant: 'error' });
                DelayNavigation(GoBack);

            } else {

                enqueueSnackbar(`Successfully saved the new variant`, { variant: 'success' });
                DelayNavigation(GoBack);

            }

        }).catch(error => {

            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar(GetErrorMessage(error, "Failed to save the new variant"), { variant: 'error' });
            
        }).finally(() => {

            setSubmitting(false);

        });

    }
    
    function EditVariant(variant, setSubmitting) {
        
        axios({
            method: 'PUT',
            url: `${BASE_URI}/MasterVariant/${id}`,
            data: variant,
            cancelToken: axios.CancelToken.source().token
        }).then(variantResponse => {

            let variant = variantResponse?.data;

            if (!variant || CompareProps(variant, initialFormValues)) {

                enqueueSnackbar(`The variant did not save correctly, please try again`, { variant: 'error' });
                DelayNavigation(GoBack);

            } else {

                enqueueSnackbar(`Successfully saved the variant`, { variant: 'success' });
                DelayNavigation(GoBack);

            }

        }).catch(error => {

            if (axios.isCancel(error)) {
                return;
            }

            enqueueSnackbar(GetErrorMessage(error, "Failed to save the variant"), { variant: 'error' });
            
        }).finally(() => {

            setSubmitting(false);

        });

    }
    
    function DelayNavigation(callback) {

        setTimeout(() => {
            callback();
        }, 1000);

    }

    function CompareProps(obj1, obj2) {

        let allEqual = true;
        let keys = Object.keys(obj1);
        
        for (let k = 0; k < keys.length; k++) {
            const key = keys[k];
            
            if (obj1[key] != obj2[key]) {
                allEqual = false;
                break;
            }   
        }

        return allEqual;

    }

    function HandleSelectChange(e, value, setFieldValue, fieldName, fieldId) {
        
        setFieldValue(fieldName, e.target.value);
        setFieldValue(fieldId, value.props.id);

    }

    function HandleDateChange(date, setFieldValue, fieldName) {
        
        setFieldValue(fieldName, new Date(date));

    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                <Grid item xs={12}>
                    <Typography variant="h4">
                        {isNewForm ? "New" : "Edit"} Master Variant
                    </Typography>
                </Grid>

                {
                    loading &&
                    <Grid item xs={12} container direction="row" justify="center">
                        <CircularProgress/>
                    </Grid>
                }
                {
                    !loading &&
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initialFormValues}
                            isInitialValid={false}
                            enableReinitialize={true}
                            onSubmit={SubmitForm}
                            validationSchema={validationSchema}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    isSubmitting,
                                    setFieldValue
                                } = props;
                                return (
                                    <Form noValidate>
                                        <Grid container spacing={2} direction="row">
                
                                            <Grid item xs={12} md={6} lg={3}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="name"
                                                    label="Variant Name"
                                                    disabled={isSubmitting}
                                                    value={values.name}
                                                    helperText={errors.name && touched.name ? errors.name : ''}
                                                    error={errors.name && touched.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Grid>
                
                                            <Grid item xs={12} md={6} lg={3}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="mmCode"
                                                    label="M&M Code"
                                                    disabled={isSubmitting || !isNewForm}
                                                    value={values.mmCode}
                                                    helperText={errors.mmCode && touched.mmCode ? errors.mmCode : ''}
                                                    error={errors.mmCode && touched.mmCode}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    inputProps={ !isNewForm ? { readonly: "true" } : {} } />
                                            </Grid>
                                            
                                            <Grid item xs={12} md={6} lg={3}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    type="number"
                                                    id="price"
                                                    label="Price"
                                                    disabled={isSubmitting}
                                                    value={values.price}
                                                    helperText={errors.price && touched.price ? errors.price : ''}
                                                    error={errors.price && touched.price}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange} />
                                            </Grid>
                                            
                                            <Grid item xs={12} md={6} lg={3}>
                                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                    <DatePicker
                                                        fullWidth
                                                        required
                                                        id="introYear"
                                                        label="Introduction Year"
                                                        openTo="year"
                                                        views={[ "year", "month" ]}
                                                        disabled={isSubmitting}
                                                        value={values.introYear}
                                                        helperText={errors.introYear && touched.introYear ? errors.introYear : ''}
                                                        error={errors.introYear && touched.introYear}
                                                        onBlur={handleBlur}
                                                        onChange={(date) => HandleDateChange(date, setFieldValue, "introYear")}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            
                                            <Grid item xs={12} md={6} lg={3}>
                                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                    <DatePicker
                                                        fullWidth
                                                        required
                                                        id="discontinueYear"
                                                        label="Discontinue Year"
                                                        openTo="year"
                                                        views={[ "year", "month" ]}
                                                        disabled={isSubmitting}
                                                        value={values.discontinueYear}
                                                        helperText={errors.discontinueYear && touched.discontinueYear ? errors.discontinueYear : ''}
                                                        error={errors.discontinueYear && touched.discontinueYear}
                                                        onBlur={handleBlur}
                                                        onChange={(date) => HandleDateChange(date, setFieldValue, "discontinueYear")}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            
                                            <Grid item xs={12} md={6} lg={3}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="fuelType"
                                                    label="Fuel Type"
                                                    disabled={isSubmitting}
                                                    value={values.fuelType}
                                                    helperText={errors.fuelType && touched.fuelType ? errors.fuelType : ''}
                                                    error={errors.fuelType && touched.fuelType}
                                                    onBlur={handleBlur}
                                                    onChange={(e, child) => HandleSelectChange(e, child, setFieldValue, 'fuelType', 'fuelTypeId')}>
                                                    {
                                                        fuelTypes.map((option, index) => (
                                                            <MenuItem key={index} id={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            </Grid>
                                            
                                            <Grid item xs={12} md={6} lg={3}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    required
                                                    id="transmission"
                                                    label="Transmission"
                                                    disabled={isSubmitting}
                                                    value={values.transmission}
                                                    helperText={errors.transmission && touched.transmission ? errors.transmission : ''}
                                                    error={errors.transmission && touched.transmission}
                                                    onBlur={handleBlur}
                                                    onChange={(e, child) => HandleSelectChange(e, child, setFieldValue, 'transmission', 'transmissionId')}>
                                                    {
                                                        transmissionTypes.map((option, index) => (
                                                            <MenuItem key={index} id={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={12} container spacing={2} direction="row" justify='flex-end'>
                                                <Grid item xs={12} sm={2} container justify="flex-end">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={GoBack}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>

                                                <Grid item xs={12} sm={2} container justify="flex-end">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Grid>
                }

            </Grid>
        </Paper>
    );
}