import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { BASE_URI } from "../../shared/Constants";
import { ReportingContext } from "../../shared/contexts/ReportingContext";
import { useSnackbar } from 'notistack';
import { DealerContext } from "../../shared/DealerContext";

export default function ReportingController(name) {
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const [error, setError] = useState(false);
    const [ranges, setRanges] = useState([]);
    const [analysis, setAnalysis] = useState({
        "pipeline": [], "extrapolation": [], "forecast": []
    });
    const [sources, setSources] = useState({
        "leadSources": [], "deliveredLeadSources": []
    });
    const [appsSubmitted, setAppsSubmitted] = useState([]);

    let { ...context } = useContext(ReportingContext);

    let { ...dealerContext } = useContext(DealerContext);

    useEffect(() => {
        setRanges([]);
    }, [context.toDate, context.fromDate, name]);

    useEffect(() => {
        switch (name) {
            case "agentWorkBreakDown":
                getAgentWorkBreakdown();
                break;
            case "agentMonitor":
                getAgentMonitor();
                break;
            case "appsSubmitted":
                getAgentAppsSubmitted();
                break;
            case "leadSources":
                getLeadSources();
                break;
            case "leadAnalysis":
                getLeadAnalysis();
                break;
            default:
                getAgentWorkBreakdown();
                break;
        }
    }, [context.toDate, context.fromDate, name, dealerContext.dealerId]);

    const getAgentWorkBreakdown = () => {

        if (!dealerContext.dealerId) {
            return;
        }

        setLoading(true)

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let params = {};
        params.toDate = context.toDate.toDateString();
        params.fromDate = context.fromDate.toDateString();
        params.dealerId = dealerContext.dealerId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/reporting/agentWorkBreakdown`,
            params,
            cancelToken: source.token
        }).then((response) => {
            if (response.data) {
                setRanges(response.data)
                context.updateResponseData(response.data);
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the agent Work breakdown list, please refresh!", { variant: 'error' });
        })
    }

    const getAgentMonitor = () => {

        if (!dealerContext.dealerId) {
            return;
        }

        setLoading(true)

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let params = {};
        params.toDate = context.toDate;
        params.fromDate = context.fromDate;
        params.dealerId = dealerContext.dealerId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/reporting/monitor`,
            params,
            cancelToken: source.token
        }).then((response) => {
            if (response.data) {
                setRanges(response.data)
                context.updateResponseData(response.data);
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the monitor data, please refresh!", { variant: 'error' });
        })
    }

    const getLeadAnalysis = () => {

        if (!dealerContext.dealerId) {
            return;
        }

        setLoading(true);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let params = {};
        params.to = context.toDate;
        params.from = context.fromDate;
        params.dealerId = dealerContext.dealerId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/reporting/leadAnalysis`,
            params,
            cancelToken: source.token
        }).then((response) => {
            if (response.data) {
                setAnalysis(response.data)
                context.updateResponseData(response.data);
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the lead analysis data, please refresh!", { variant: 'error' });
        })
    }

    const getLeadSources = () => {

        if (!dealerContext.dealerId) {
            return;
        }

        setLoading(true);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let params = {};
        params.to = context.toDate;
        params.fromDate = context.fromDate;
        params.dealerId = dealerContext.dealerId;
        axios({
            method: 'GET',
            url: `${BASE_URI}/reporting/leadSources`,
            params,
            cancelToken: source.token
        }).then((response) => {
            if (response.data) {
                setSources(response.data)
                context.updateResponseData(response.data);
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the lead source data, please refresh!", { variant: 'error' });
        })
    }

    const getAgentAppsSubmitted = () => {

        if (!dealerContext.dealerId) {
            return;
        }

        setLoading(true);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let params = {};
        params.to = context.toDate;
        params.fromDate = context.fromDate;
        params.dealerId = dealerContext.dealerId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/reporting/appsSubmitted`,
            params,
            cancelToken: source.token
        }).then((response) => {
            if (response.data) {
                setAppsSubmitted(response.data)
                context.updateResponseData(response.data);
            }
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the agent application submitted data, please refresh!", { variant: 'error' });
        })
    }

    return { loading, error, ranges, analysis, sources, appsSubmitted };
}
