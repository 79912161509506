import React from "react";
import { Field } from "formik";
import ImageUploader from './ImageUploader'
import Resizer from "react-image-file-resizer"
import { IMAGE_UPLOADER_CONFIG } from './Constants'
import ImageService from "./ImageService";

const ImageUploadInput = ({ title, fieldName, error, required, disabled, errorMsg, className, setImage }) => (
    <Field name={fieldName} id={fieldName} type="text">
        {({ field: { value }, form: { setFieldValue } }) => (
            <ImageUploader title={title}
                disabled={disabled}
                required={required}
                className={className}
                onDropFile={async (file) => {

                    if (file.type === "image/gif") {

                        // Cannot compress gif
                        let uri = await ImageService.getBase64(file);
                        
                        setFieldValue(fieldName, uri);
                        if (setImage) setImage(fieldName, uri);

                        return;
                    }

                    let compressFormat = IMAGE_UPLOADER_CONFIG.compressFormat;

                    if (file.type === "image/png") {
                        compressFormat = 'PNG';
                    }

                    Resizer.imageFileResizer(
                        file,
                        IMAGE_UPLOADER_CONFIG.maxWidth,
                        IMAGE_UPLOADER_CONFIG.maxHeight,
                        compressFormat,
                        IMAGE_UPLOADER_CONFIG.quality,
                        IMAGE_UPLOADER_CONFIG.rotation,
                        uri => {
                            setFieldValue(fieldName, uri)
                            if (setImage) setImage(fieldName, uri)
                        },
                        IMAGE_UPLOADER_CONFIG.outputType
                    );
                }}
                id={fieldName}
                image={value}
                error={error}
                errorMsg={errorMsg}
                clear={() => setFieldValue(fieldName, '')} />
        )}
    </Field>
);

export default ImageUploadInput;