import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import NewMotorGroupTabContainer from "./NewMotorGroupTabContainer";
import OtherTabContainer from "./OthertabContainer";
import UsedStockContainer from "./UsedStockTabContainer";
import {useParams, useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#3378af",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export default function VehicleInnerTabContainer() {
    const classes = useStyles();
    const {state, tab, id} = useParams();
    const history = useHistory();

    const tabNameToIndex = {
        0: "newMotorgroupVehicles",
        1: "usedStock",
        2: "other",
    };

    const indexToTabName = {
        newMotorgroupVehicles: 0,
        usedStock: 1,
        other: 2,
    };

    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        history.push(
            `/leadDetailsApplication/${state}/vehicle_${tabNameToIndex[newValue]}/${id}`
        );

        setSelectedTab(newValue);
    };

    return (
        <>
            <AppBar position="relative" className={classes.root}>
                <Tabs
                    variant="scrollable"
                    value={selectedTab != null ? selectedTab : setSelectedTab(0)}
                    onChange={handleChange}
                    textColor="inherit"
                >
                    <Tab label="Other" />
                    
                </Tabs>
            </AppBar>
            <OtherTabContainer />
        </>
    );
}
