import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Typography } from "@material-ui/core";
import axios from 'axios'
import { BASE_URI } from '../../../../shared/Constants'
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"
import { LeadStatusEnum } from "../../../../shared/Constants"

const useStyles = makeStyles((theme) => ({
  root: {},
  stepPadding: {
    marginTop: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  button: {
    marginTop: "2rem",
    marginLeft: "2rem",
  },
  buttonMarkApproved: {
    marginTop: "2rem",
    marginLeft: "2rem",
    backgroundColor:"#369312",
    color:"white"
  },
  buttonMarkDeclined: {
    marginTop: "2rem",
    marginLeft: "2rem",
    backgroundColor:"#f8cf01",
    color:"black"
  },
}));

export default function MarkAsContainer() {
  const classes = useStyles();
  let { updateState } = useContext(LeadMainContext);

  const values = {
    state: "",
    };


  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Card className={classes.stepPadding}>
            <CardContent>
              <Typography variant="h6"> Mark As or Review</Typography>
              <Grid container direction="row">
                <Grid item xs={12} md={4} lg={4}>
                  <Button
                                      className={classes.button}
                                      variant="contained"
                                      startIcon={<AddIcon />}
                                      color="primary"
                                      type="submit"
                                      onClick={() => updateState(LeadStatusEnum["Application Approved"])}
                  >
                    Mark as Approved
                  </Button>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Button
                    className={classes.buttonMarkDeclined}
                    variant="contained"
                    color="primary"
                                      type="submit"
                                      onClick={() => updateState(LeadStatusEnum["Application Declined"])}
                  >
                    Mark as Declined
                  </Button>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                                      type="submit"
                                      
                  >
                    Review Application
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
