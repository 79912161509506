import React, { useContext } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Typography} from "@material-ui/core";
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"
import { LeadStatusEnum } from "../../../../shared/Constants"
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
    },
    button: {
        backgroundColor: "#0b7d95",
        color: "white"
    },
    buttonMark: {
        marginLeft:"1rem",
        backgroundColor: "#0cc124",
        color: "white"
    },
}));

export default function PreQualifiedEditContainer() {
    let { updateState, stateLoader } = useContext(LeadMainContext);
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                                    
                            {!stateLoader && <Button
                                className={classes.buttonMark}
                                variant="contained"
                                color="secondary"
                                onClick={() => updateState(LeadStatusEnum["Application Submitted"])}
                            >
                                Mark the application as complete and pass it to the F&I
                                    </Button>}

                            {stateLoader && (
                                <Button className={classes.buttonMark} variant={"contained"}
                                    color="secondary"
                                    disabled={true}
                                >
                                    <CircularProgress />
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

//<Button
//    className={classes.button}
//    variant="contained"
//    color="primary"
//>
//    Edit the Application
//                                    </Button>