import React, { useState, createContext } from 'react';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const LoadingContext = createContext()

export default function LoadingProvider({ children }) {
  const [isLoading, setOpen] = useState(false)

  const showLoading = () => {
    setOpen(true)
  }

  const hideLoading = () => {
    setOpen(false);
  };

  return (
    <LoadingContext.Provider value={{showLoading, hideLoading, isLoading}}>
      {children}
      <Dialog
        TransitionComponent={Transition}
        open={isLoading}

        aria-labelledby="loading"
      >
        <DialogContent className="d-flex flex-column align-items-center py-4 px-5">
          <CircularProgress size={80} />
          <Typography variant="h6" className="mt-4" color="textSecondary">
            Loading ...
          </Typography>
        </DialogContent>
      </Dialog>
    </LoadingContext.Provider>
  )
}