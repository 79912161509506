import React, {useState, useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../shared/TableHeadersSort";
import {BASE_URI, DEFAULT_PAGE_SIZE} from "../../shared/Constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import ExportContainer from "../../shared/ExportContainer";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LeadLogFilter from "./LeadLogFilter";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
            width: "45ch",
        },
    },
    export: {
        width: "45ch",
    },
    inlineDisplay: {
        display: "flex",
        justifyContent: "space-between",
    },
    stepPadding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function LeadLogsContainer() {
    const classes = useStyles();
    const {id} = useParams();

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("ID");
    const [search, setSearch] = useState("")

    const history = useHistory();

    let {loading, ranges, total} = LeadLogFilter(
        id,
        page,
        pageSize,
        order,
        orderBy,
        search
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    const headCells = [
        {id: "lead", numeric: false, label: "Lead", sort: true},
        {id: "user", numeric: false, label: "User", sort: true},
        {id: "owner", numeric: false, label: "Owner", sort: true},
        {id: "action", numeric: false, label: "Action", sort: true},
        {id: "state", numeric: false, label: "State", sort: true},
        {id: "currentState", numeric: false, label: "Current State", sort: true},
        {id: "at", numeric: false, label: "At", sort: true},
    ];

    useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            <Grid
                container
                className={classes.stepPadding}
                direction="column"
            >
                <Grid item xs={12}>
                    <Grid item xs={12} className={classes.inlineDisplay}>
                        <div>
                            <ExportContainer values={ranges}
                                             fileName='LeadsLog'
                                             headers={headCells}/>
                        </div>
                        <TextField onChange={handleSearchChange} label="Search"/>
                    </Grid>
                    <Divider
                        variant="fullWidth"
                        style={{marginTop: "1rem", marginBottom: "1rem"}}
                    />
                    <TableContainer>
                        <Table aria-label="Agent Work Breakdown">
                            <TableHeadersSort
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {ranges.slice(page * pageSize, (page * pageSize) + pageSize).map((row) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell>
                                            <Button
                                                variant="contained"
                                                style={{margin: "1rem",backgroundColor: "#3378af", color:"white"}}
                                                onClick={() => {
                                                    let path = `/leadDetailsApplication/${row.currentState}/actions/${row.leadId}`;
                                                    history.push(path);
                                                }}
                                                type="submit"

                                            >
                                                {row.leadId}
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>{row.user}</StyledTableCell>
                                        <StyledTableCell>{row.owner}</StyledTableCell>
                                        <StyledTableCell>{row.action}</StyledTableCell>
                                        <StyledTableCell>{row.state}</StyledTableCell>
                                        <StyledTableCell>{row.currentState}</StyledTableCell>
                                        <StyledTableCell>{row.at}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                {(!ranges.length || ranges.length === 0) && !loading && (
                                    <StyledTableRow>
                                        <StyledTableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{borderBottom: 0}}
                                            className="py-3"
                                        >
                                            <Typography variant="h6" color="textSecondary">
                                                No Information
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                                {loading && (
                                    <StyledTableRow>
                                        <StyledTableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{borderBottom: 0}}
                                        >
                                            <CircularProgress/>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={total}
                            page={page}
                            rowsPerPageOptions={[5, 10, 25]}
                            onChangePage={handleChangePage}
                            rowsPerPage={pageSize}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
                <Grid item xs={12} className={classes.inlineDisplay}>
                    <Button
                        variant="contained"
                        color={"primary"}
                        style={{margin: "1rem"}}
                        onClick={() => {
                            let path = `/reporting/`;
                            history.push(path);
                        }}
                        type="submit"
                    >
                        Back
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
