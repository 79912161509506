import {useState, useEffect, useContext} from "react";
import axios from 'axios';
import {BASE_URI} from "../../../shared/Constants";
import {DealerContext} from "../../../shared/DealerContext";

export default function LeadMain(date) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [ranges, setRanges] = useState([]);
    const [total, setTotal] = useState(0);

    let {...context} = useContext(DealerContext);

    useEffect(() => {
        setRanges([]);
    }, [date]);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);
        const params = {};
        if (date) {
            let dateTemp = "";
            params.since = dateTemp.concat(date.getFullYear(), '/', (date.getMonth() + 1), '/', date.getDate());
        }

        params.motorgroupId = context.selectedMotorGroup.motorgroupID;

        axios({
            method: 'GET',
            url: `${BASE_URI}/leads`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setRanges(response.data?.list)
            }
            setTotal(response.data?.total != null ? response.data.total : 0);

            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })
    }, [date, context.selectedMotorGroup]);

    return {loading, error, ranges, total};
}