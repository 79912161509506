import React, { useState, useContext, useRef } from "react";
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHeadersSort from '../../../shared/TableHeadersSort'
import { BASE_URI, DEFAULT_PAGE_SIZE } from '../../../shared/Constants'
import RangeFilter from './RangeFilter'
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { CurrencyConverter, CurrencyValue } from '../../../shared/TextMask'
import Zoom from '@material-ui/core/Zoom';
import RangeForm from './RangeForm'
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import ConfirmationDialog from '../../../shared/ConfirmationDialog';
import axios from 'axios';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import { DealerContext } from '../../../shared/DealerContext'
import { useSnackbar } from 'notistack'
import ImageService from "../../../shared/ImageService";
import { borderBottom } from "@material-ui/system";

export default function RangeListContainer({ navigate }) {

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('title');
  const [tableView, setTableView] = useState(true)
  const [formView, setFormView] = useState(false)
  const [anchorRef, setAnchorRef] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeRange, setActiveRange] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false)
  const { dealerId } = useContext(DealerContext);
  const [forceReload, setForceReload] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [makeId, setMakeId] = useState();

  const searchStringRef = useRef("");
  
  const { enqueueSnackbar } = useSnackbar();

  const {
    loading,
    ranges,
    total
  } = RangeFilter(page, pageSize, order, orderBy, forceReload, searchString, makeId)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setOpen(false);
    if (anchorRef) {
      setAnchorRef(null);
    }
  }

  const handleMenuToggle = (event, range) => {
    setActiveRange(range)
    setAnchorRef(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  }

  const headCells = [
    { id: 'title', numeric: false, label: 'Title', sort: true },
    { id: 'make', numeric: false, label: 'Make', sort: true },
    { id: 'model', numeric: false, label: 'Model', sort: true },
    { id: 'price', numeric: false, label: 'Price', sort: true },
    { id: 'actions', numeric: false, label: ' ', sort: false }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleDeleteRange = () => {
    setOpenConfirm(false)
    if (activeRange) {
      deleteRange(activeRange.id).then((res) => {
        setForceReload(prev => !prev)
        enqueueSnackbar("Successfully deleted vehicle model", { variant: 'success' });
      }).catch((err) => {
        enqueueSnackbar("Unable to delete vehicle model", { variant: 'error' });
      })
    }
  }

  const viewRange = () => {
    if (activeRange) {
      navigate('variants', activeRange.modelId, activeRange.id)
    }
    handleMenuClose()
  }

  const handleModelFormAction = (data) => {
    if (data && dealerId) {
      if (data?.id > 0) {
        editRange(data).then((res) => {
          cancelForm()
          navigate('variants', activeRange.modelId, res.data.id)
          enqueueSnackbar("Successfully edited vehicle model", { variant: 'success' });
        }).catch((err) => {
          enqueueSnackbar("Unable to edit vehicle model", { variant: 'error' });
        })
      } else {
        createRange(data).then((res) => {
          cancelForm()
          navigate('variants', activeRange.modelId, res.data.id)
          enqueueSnackbar("Successfully added vehicle model", { variant: 'success' });
        }).catch((err) => {
          enqueueSnackbar("Unable to create vehicle model", { variant: 'error' });
        })
      }
    } else {
      enqueueSnackbar("Dealer ID is missing", { variant: 'error' });
    }
  }

  const handleInformationFormAction = async (values) => {
    
    let filePath1 = values.brochure1 && values.brochure1.path ? await getFilePath(values.brochure1) : null;
    let filePath2 = values.brochure2 && values.brochure2.path ? await getFilePath(values.brochure2) : null;

    let mainCI = !values?.main ? null : {
      ...activeRange?.ownedModelInformation?.contentImages?.find(i => { return i.imageName === 'main' }),
      imageName: 'main',
      ownedModelInformationId: activeRange?.ownedModelInformation?.id,
      image: {
        imageUrl: values.main
      }      
    };

    let smallCI = !values?.small ? null : {
      ...activeRange?.ownedModelInformation?.contentImages?.find(i => { return i.imageName === 'small' }),
      imageName: 'small',
      ownedModelInformationId: activeRange?.ownedModelInformation?.id,
      image: {
        imageUrl: values.small
      }
    };

    let medium1CI = !values?.medium1 ? null : {
      ...activeRange?.ownedModelInformation?.contentImages?.find(i => { return i.imageName === 'medium1' }),
      imageName: 'medium1',
      ownedModelInformationId: activeRange?.ownedModelInformation?.id,
      image: {
        imageUrl: values.medium1
      }
    };

    let medium2CI = !values?.medium2 ? null : {
      ...activeRange?.ownedModelInformation?.contentImages?.find(i => { return i.imageName === 'medium2' }),
      imageName: 'medium2',
      ownedModelInformationId: activeRange?.ownedModelInformation?.id,
      image: {
        imageUrl: values.medium2
      }
    };

    let medium3CI = !values?.medium3 ? null : {
      ...activeRange?.ownedModelInformation?.contentImages?.find(i => { return i.imageName === 'medium3' }),
      imageName: 'medium3',
      ownedModelInformationId: activeRange?.ownedModelInformation?.id,
      image: {
        imageUrl: values.medium3
      }
    };

    let medium4CI = !values?.medium4 ? null : {
      ...activeRange?.ownedModelInformation?.contentImages?.find(i => { return i.imageName === '4' }),
      imageName: 'medium4',
      ownedModelInformationId: activeRange?.ownedModelInformation?.id,
      image: {
        imageUrl: values.medium4
      }
    };

    let brochure1 = !values.brochure1 ? null : {
      ...activeRange.ownedModelInformation?.brochures[0],
      ownedModelInformationId: activeRange?.ownedModelInformation?.id,
      document: {
        ...activeRange.ownedModelInformation?.brochures[0]?.document,
        fileName: values.brochure1?.name || activeRange.ownedModelInformation?.brochures[0]?.document?.fileName,
        filePath: filePath1 || activeRange.ownedModelInformation?.brochures[0]?.document?.filePath
      }
    };

    let brochure2 = !values.brochure2 ? null : {
      ...activeRange.ownedModelInformation?.brochures[1],
      ownedModelInformationId: activeRange?.ownedModelInformation?.id,
      document: {
        ...activeRange.ownedModelInformation?.brochures[1]?.document,
        fileName: values.brochure2?.name || activeRange.ownedModelInformation?.brochures[1]?.document?.fileName,
        filePath: filePath2 || activeRange.ownedModelInformation?.brochures[1]?.document?.filePath
      }
    };
    
    let modelInfo = {
      ...activeRange.ownedModelInformation,
      ...values,
      deal: values?.deal ? { ...values?.deal, ownedModelInformationId: activeRange?.ownedModelInformation?.id } : null,
      OwnedModelId: activeRange.id,
      videoLink: values.videoLink,
      contentImages: [ mainCI, smallCI, medium1CI, medium2CI, medium3CI, medium4CI ].filter(ci => ci != null),
      brochures: [ brochure1, brochure2]
    };
    
    if (modelInfo && dealerId) {

      if (modelInfo.id !== undefined) {

        editInformation(modelInfo).then((res) => {
          
          activeRange.ownedModelInformation = res.data;

          cancelForm();
          enqueueSnackbar("Successfully edited model brochure", { variant: 'success' });


        }).catch((err) => {

          enqueueSnackbar("Failed to edit model brochure: " + err, { variant: 'error' });

        })

      } else {

        createInformation(modelInfo).then((res) => {

          activeRange.ownedModelInformation = res.data;

          cancelForm();
          enqueueSnackbar("Successfully added model brochure", { variant: 'success' });

        }).catch((err) => {

          enqueueSnackbar("Failed to create model brochure: " + err, { variant: 'error' });

        })

      }

    } else {

      enqueueSnackbar("Dealer ID is missing", { variant: 'error' });

    }
  };

  const getFilePath = async (doc) => {
    var filePath = null;

    try {

      var base64Image = (doc instanceof File) ? await ImageService.getBase64(doc) : doc;

      filePath = ImageService.parseBase64(base64Image);

    } catch (error) {
      enqueueSnackbar("Failed to get brochure file path. Brochure did not save: " + error, { variant: 'error' });
    }

    return filePath;
  };

  const createInformation = (formData) => {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    return axios.post(`${BASE_URI}/DealerModelInformation`, formData, {
      cancelToken: source.token
    })
  }

  const editInformation = (formData) => {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    return axios.put(`${BASE_URI}/DealerModelInformation`, formData, {
      cancelToken: source.token
    })

  }

  const createRange = (formData) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let data = {
      image: formData.image,
      dealerId: dealerId,
      modelId: formData.isComingSoon === true ? null : formData.modelId,
      makeId: formData.makeId,
      price: formData.isPOA === true || formData.isComingSoon === true ? 0 : CurrencyConverter(formData.price),
      title: formData.title,
      isComingSoon: formData.isComingSoon
    }
    return axios.post(`${BASE_URI}/dealermodels`, data, {
      cancelToken: source.token
    })
  }

  const editRange = (formData) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let data = {
      image: formData.image,
      dealerId: dealerId,
      modelId: formData.isComingSoon === true ? null : formData.modelId,
      makeId: formData.makeId,
      price: formData.isPOA === true || formData.isComingSoon === true ? 0 : CurrencyConverter(formData.price),
      title: formData.title,
      isComingSoon: formData.isComingSoon
    }

    return axios.put(`${BASE_URI}/dealermodels/${formData.id}`, data, {
      cancelToken: source.token
    })
  }

  const deleteRange = (id) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return axios.delete(`${BASE_URI}/dealermodels/${id}`, {
      cancelToken: source.token
    })
  }

  const cancelForm = () => {
    setActiveRange(null)
    setFormView((prev) => !prev)
  }

  

  const handleMakeChange = (val)=>{
    if (makeId > 0) {
      setMakeId()
    } else {
      setMakeId(val)
    }
  }

  const handleSearchString = (val) => {
    
    if (val.length > 0 && val.length <= 2) return;

    searchStringRef.current = val;

    setTimeout(() => {
      setSearchString(searchStringRef.current);
    }, 500);

  }
        
                function removeDuplicates(data,key){
                    return[
                        ...new Map(
                            data.map(x => [key(x),x])
                        ).values()
                    ].sort((a, b) => a?.make > b?.make ? 1 : -1)
                }
  return (
    <React.Fragment>
      <ConfirmationDialog secondaryInfo={activeRange ? activeRange.title : ''}
        action='delete'
        open={openConfirm}
        confirm={handleDeleteRange}
        closeDialog={() => setOpenConfirm(false)} />
      <Zoom in={tableView} mountOnEnter unmountOnExit
        onExited={() => setFormView((prev) => !prev)}>
        <div>
          <Toolbar disableGutters>
            <Typography style={{ flex: '1 1' }} variant="h4" id="tableTitle" component="div">
              Model
            </Typography>
            <Button onClick={() => {
              setTableView((prev) => !prev)
              setActiveRange(null)
            }}
              variant="contained"
              style={{ marginRight: '1rem' }}
              startIcon={<AddIcon />}
              color="primary">
              Model
            </Button>
            {/*<Button onClick={() => console.log('Add')}*/}
            {/*variant="contained"*/}
            {/*startIcon={<DeleteIcon />}*/}
            {/*color="primary">*/}
            {/*Clear All*/}
            {/*</Button>*/}
          </Toolbar>
          <input type='text' placeholder="Search Model" style={{ width: '30%',padding:'10px',border:'0px',borderBottom:'1px solid' }} onChange={(e)=>{handleSearchString(e.target.value)}}></input>
          <div style={{ width: '100%',display:'flex',border:'0px',marginTop:'15px'}}>
          {removeDuplicates(ranges, it =>it.make).map((r)=>{
           return(
            <div style={{ background: '#3f51b5',marginRight:"10px",padding:'5px 10px',cursor:'pointer',color:'white',borderRadius:'5px' }} onClick={(e)=>{handleMakeChange(r.makeId)}}>
              {r.make}{makeId > 0 && r.makeId === makeId ? " X" : ""}
            </div>
          )
          })}
          </div>
        
          <TableContainer>
            <Table aria-label="ranges table">
              <TableHeadersSort
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
             
                {
                  ranges?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell >
                        <List >
                          <ListItem alignItems='center' >
                            <ListItemAvatar>
                              <Avatar src={row.image}>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={
                              <p>{row.title}</p>
                            }
                            />
                          </ListItem>
                        </List>
                      </TableCell>
                      <TableCell>
                        {row.make}
                      </TableCell>
                      <TableCell>
                        {row.model}
                      </TableCell>
                      <TableCell>
                        <CurrencyValue value={row.price} />
                      </TableCell>
                      <TableCell className="pr-0" style={{ width: '5%' }}>
                        <IconButton aria-label="options"
                          onClick={(e) => handleMenuToggle(e, row)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
                {
                  ((!ranges.length || ranges.length === 0) && !loading) &&
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center"
                      style={{ borderBottom: 0 }}
                      className="py-3">
                      <Typography variant="h6" color="textSecondary">
                        No Models created
                      </Typography>
                    </TableCell>
                  </TableRow>
                }
                {
                  loading &&
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center"
                      style={{ borderBottom: 0 }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
            <Popper open={open} anchorEl={anchorRef} role={undefined} transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleMenuClose}>
                      <MenuList id="split-button-menu">
                        <MenuItem onClick={viewRange}>
                          Select
                        </MenuItem>
                        <MenuItem onClick={() => {
                          setTableView((prev) => !prev)
                          handleMenuClose()
                        }}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => {
                          setOpenConfirm(true)
                          handleMenuClose()
                        }}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <TablePagination
              component="div"
              count={total}
              page={page}
              rowsPerPageOptions={[5, 10, 25]}
              onChangePage={handleChangePage}
              rowsPerPage={pageSize}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </Zoom>
      <Zoom in={formView}
        onExited={() => setTableView((prev) => !prev)}
        mountOnEnter unmountOnExit>
        <div>
          <RangeForm
            data={activeRange}
            onSubmitModelForm={handleModelFormAction}
            onSubmitInformationForm={handleInformationFormAction}
            cancel={cancelForm} />
        </div>
      </Zoom>
    </React.Fragment>
  )
}