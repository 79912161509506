import { useState, useEffect } from "react";
import axios from 'axios';
import { BASE_URI } from '../../../../shared/Constants'
import moment from 'moment'

export default function VoiceMailController(id) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        let cancel;
        setLoading(true);
        setError(false);

        axios({
           method: 'GET',
            url: `${BASE_URI}/voiceMails/lead/${id}`,
           cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {

            let data = response.data.map(itm => {

                return {
                    status: itm.isSuccess ? "DELIVRD" : "FAILED",
                    type: itm.messageTypeId === 1 ? "SMS" : "EMAIL",
                    date: moment(itm.createDate).format("l"),
                    heading: itm.subject,
                    body: itm.body
                };

            });

            setData(data);
            setTotal(response.length)

           setLoading(false)
         }).catch( error => {
           if(axios.isCancel(error)) return
           if (error.response) {
             setError(true)
           }
           setLoading(false)
         })

        setLoading(false);

        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { loading, error, data, total };
}
