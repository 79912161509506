import React, { useContext } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Divider, Typography } from "@material-ui/core";
import { TabsContext } from "../../../../shared/contexts/TabsContext"
import { LeadStatusEnum } from "../../../../shared/Constants"
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    button: {
        marginTop: "2rem",
        marginLeft: "2rem",
    },
    buttonSendBack:{
        marginTop: "2rem",
        marginLeft: "2rem",
        backgroundColor:"#f8cf01",
        color:"black"
    },
    buttonSent:{
        marginTop: "2rem",
        marginLeft: "2rem",
        backgroundColor:"#04872d",
        color:"white"
    },
    line: {
        marginTop: "3rem",
        marginBottom: "1rem",
        height: 2
    },
}));

export default function BankSubmittedContainer() {
    const classes = useStyles();
    let { updateState } = useContext(LeadMainContext);
    let { ...tabContext } = useContext(TabsContext);

    return (
        <div className={classes.root}>

            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <Typography variant="h6">Mark, review or upload</Typography>

                            <Grid container direction="row" justify="center">
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        onClick={() => updateState(LeadStatusEnum["Not Taken Up"])}
                                    >
                                        Mark this lead as Not Taken Up
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider className={classes.line} variant="middle"/>
                            <Grid container direction="row">
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => tabContext.setSelectedTab(3)}
                                    >
                                        Review the Application
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.buttonSendBack}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => updateState(LeadStatusEnum["Documents In"])}
                                    >
                                        Send it back - incomplete!
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
