import React, {useContext} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import ReportingController from "./ReportingController";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DatePickHelper from "./DatePickHelper";
import TableHead from "@material-ui/core/TableHead";
import Divider from "@material-ui/core/Divider";
import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "2rem",
        marginLeft: "0.5rem",
    },
    stepPaddingTable: {
        marginTop: "2rem",
        marginLeft: "3rem",
    },
    button: {
        marginTop: "2rem",
    },
    buttonMake: {
        marginTop: "2rem",
        backgroundColor: "#0b7d95",
        color: "white"
    },
}));

export default function LeadAnalysisContainer() {
    const classes = useStyles();

    let {loading, analysis} = ReportingController(
        "leadAnalysis" // TODO change this to the specific api endpoint
    );

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#20c997",
            color: theme.palette.common.white,
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: 0.1
        },
        body: {
            fontSize: 14,
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: 0.1
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    const StyledTableRowForecast = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
            "&:nth-of-type(1)": {
                backgroundColor: theme.palette.action.disabled,
            },
        },
    }))(TableRow);

    return (
        <div className={classes.root}>
            <Grid
                container
                className={classes.stepPadding}
                justify={"space-around"}
            >
                <Grid item xs={12} md={12} lg={12} className={classes.stepPadding}>
                    <DatePickHelper/>
                </Grid>
                <Grid item xs={10} md={8} lg={12} className={classes.stepPadding}>
                    <Divider/>
                </Grid>
                <Grid item xs={10} md={8} lg={12} style={{ textAlign: "center" }}>
                    {loading && <CircularProgress />}
                </Grid>
                <Grid item xs={12} md={12} lg={5} className={classes.stepPadding}>
                    <Typography>
                        Pipeline Processing
                    </Typography>
                    <TableContainer>
                        <Table aria-label="Pipeline Processing">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>State</StyledTableCell>
                                    <StyledTableCell>Count</StyledTableCell>
                                    <StyledTableCell>Percentage</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {analysis.pipeline.map((row) => (
                                    <StyledTableRow>
                                        <StyledTableCell>
                                            {row.state}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.count}</StyledTableCell>
                                        <StyledTableCell>{row.percentage.toFixed(2) + '%'}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} md={12} lg={5} className={classes.stepPaddingTable}>
                    <Typography>
                        Extrapolation to the End of the Month (based on time)
                    </Typography>
                    <TableContainer>
                        <Table aria-label="Extrapolation to the End of the Month (based on time)">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>State</StyledTableCell>
                                    <StyledTableCell>Expected Count</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {analysis.extrapolation.map((row) => (

                                    <StyledTableRow>
                                        <StyledTableCell>
                                            {row.state}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.expectedCount}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={10} md={8} lg={12} className={classes.stepPadding}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.stepPadding}>
                    <Typography>
                        Forecast on Leads currently in each State
                    </Typography>
                    <TableContainer>
                        <Table aria-label="Forecast on Leads currently in each State">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>New Lead</StyledTableCell>
                                    <StyledTableCell>Prequalified Approved</StyledTableCell>
                                    <StyledTableCell>Application Submitted</StyledTableCell>
                                    <StyledTableCell>Application Approved</StyledTableCell>
                                    <StyledTableCell>Documents In</StyledTableCell>
                                    <StyledTableCell>Bank Submitted</StyledTableCell>
                                    <StyledTableCell>Bank Validated</StyledTableCell>
                                    <StyledTableCell>Offer To Purchase</StyledTableCell>
                                    <StyledTableCell>Invoiced</StyledTableCell>
                                    <StyledTableCell>Delivered</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {analysis.forecast.slice(0,1).map((row) => (
                                    <StyledTableRowForecast key={row.id}>
                                        <StyledTableCell>
                                            {row.state}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.newLead == -1 ? "" : row.newLead.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.prequalifiedApproved == -1 ? "" : row.prequalifiedApproved.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.applicationSubmitted == -1 ? "" : row.applicationSubmitted.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.applicationApproved == -1 ? "" : row.applicationApproved.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.documentsIn == -1 ? "" : row.documentsIn.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.bankSubmitted == -1 ? "" : row.bankSubmitted.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.bankValidated == -1 ? "" : row.bankValidated.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.offerToPurchase == -1 ? "" : row.offerToPurchase.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.invoiced == -1 ? "" : row.invoiced.toFixed(2) + '%'}</StyledTableCell>
                                        <StyledTableCell>{row.delivered == -1 ? "" : row.delivered.toFixed(2) + '%'}</StyledTableCell>
                                    </StyledTableRowForecast>
                                ))}
                                {analysis.forecast.slice(1).map((row) => (
                                    <StyledTableRowForecast key={row.id}>
                                        <StyledTableCell>
                                            {row.state}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.newLead == -1 ? "" : row.newLead.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.prequalifiedApproved == -1 ? "" : row.prequalifiedApproved.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.applicationSubmitted == -1 ? "" : row.applicationSubmitted.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.applicationApproved == -1 ? "" : row.applicationApproved.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.documentsIn == -1 ? "" : row.documentsIn.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.bankSubmitted == -1 ? "" : row.bankSubmitted.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.bankValidated == -1 ? "" : row.bankValidated.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.offerToPurchase == -1 ? "" : row.offerToPurchase.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.invoiced == -1 ? "" : row.invoiced.toFixed(1)}</StyledTableCell>
                                        <StyledTableCell>{row.delivered == -1 ? "" : row.delivered.toFixed(1)}</StyledTableCell>
                                    </StyledTableRowForecast>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
}
