import React, {useState} from "react";
import {Fade, Modal, Grid, Button} from "@material-ui/core";
import {ChromePicker} from "react-color";
import {makeStyles} from "@material-ui/core/styles";
import {DEFAULT_COLOR} from './Constants'
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[7],
    // minHeight: 400,
    // margin: 10,
    // padding: theme.spacing(2, 4, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

export const ColorPicker = ({label, onChange, currentColor, hideLabel, disabled}) => {
  const classes = useStyles();
  const [color, setColor] = useState(currentColor || DEFAULT_COLOR);
  const [showPicker, setShowPicker] = useState(false);
  const handleChange = color => {
    setColor(color.hex);
  };

  const openModal = () => {
    if(!disabled) setShowPicker(true)
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: disabled ? 'default':'pointer',
        paddingRight: 20}}>
      {
        !hideLabel && <label>{label}</label>
      }
      <div
        style={{
          padding: 15,
          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 3,
          backgroundColor: color
        }}
        onClick={openModal}></div>
      {showPicker && (
        <Modal
          className={classes.modal}
          open={showPicker}
          closeAfterTransition
          BackdropProps={{
            timeout: 500
          }}>
          <Fade in={true}>
            <div className={classes.modalContent}>
              <div
                style={{
                  backgroundColor: color,
                  // marginTop: 10,
                  // marginBottom: 10,
                  padding: 40,
                  // borderRadius: 3
                }}>
                <ChromePicker color={color} onChange={handleChange} />
              </div>
              <Grid container style={{padding: '15px 0 15px 0'}} justify="space-evenly">
                  <Grid item xs={6} align="center">
                    <Button
                      color="inherit"
                      onClick={() => {
                        setShowPicker(false);
                      }}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} align="center">
                    <Button
                      color="inherit"
                      type="submit"
                      onClick={() => {
                        onChange(color);
                        setShowPicker(false);
                      }}>
                      Save
                    </Button>
                  </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
};