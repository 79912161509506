import React, { useContext, useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext'
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink, useHistory, useParams } from 'react-router-dom'
import { BASE_URI } from '../../shared/Constants'
import axios from 'axios'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" component={RouterLink} to='/'>
                MarketDealer
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        overflow: 'hidden'
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/collection/786933/900x900)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'right bottom',
    },
    paper: {
        margin: theme.spacing(5, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export default function ResetPasswordComponent() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { showLoading, hideLoading } = useContext(LoadingContext)
    const [password, setPassword] = useState('')
    const { token } = useParams();
    let history = useHistory();
    const [confirmPassword, setConfirmPassword] = useState('')

    const [errors, setError] = useState({
        password: false,
        confirmPassword: false
    })

    useEffect(() => {
        if (token === '' || token === undefined) {
            history.push(`/login`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const validatePassword = () => {
        setError({ ...errors, password: password === '' || !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(password) })
    }

    useEffect(() => {
        if (confirmPassword !== '') {
            validateConfirmPassword()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password])

    const validateConfirmPassword = () => {
        setError({ ...errors, confirmPassword: password !== confirmPassword })
    }

    const resetPassword = (event) => {
        event.preventDefault()
        showLoading()
        processResetPassword().then((res) => {
            hideLoading()
            enqueueSnackbar('Successful reset', { variant: 'success' });
            history.push(`/login`)
        }).catch(() => {
            hideLoading()
            enqueueSnackbar('Something went wrong', { variant: 'success' });
            history.push(`/login`)
        })
    }


    const processResetPassword = () => {
        return axios.post(`${BASE_URI}/User/ResetPassword`, {
            token: token,
            newPassword: password
        })
    }

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5}
                style={{ overflow: 'hidden' }}
                component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Link variant="button"
                        component={RouterLink} to='/'
                        className="flex-grow-1">
                    </Link>
                    <Typography component="h1" variant="h5" style={{ fontWeight: '400 !important' }} className="py-3">
                        Reset Password
          </Typography>
                    

                    <form className={classes.form} onSubmit={resetPassword} noValidate>
                        <TextField
                            fullWidth
                            required
                            autoFocus
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className='mb-2'
                            type='password'
                            id="password"
                            label="Password"
                            name="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onBlur={validatePassword}
                            error={errors.password}
                            helperText={errors.password ? 'Please enter 10 or more characters. Password should contain both numeric, alphabetic and special characters. Atleast one uppercase and one lowercase letter' : ' '}
                        />

                        <TextField
                            fullWidth
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className='mb-2'
                            type='password'
                            id="confirmPassword"
                            label="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            onBlur={validateConfirmPassword}
                            error={errors.confirmPassword}
                            helperText={errors.confirmPassword ? 'Passwords do not match' : ' '}
                        />
                        <Button disabled={errors.password || errors.confirmPassword || confirmPassword === '' || password === '' || password !== confirmPassword}
                            fullWidth
                            className={classes.submit}
                            type="submit" variant="contained" color="primary">
                            Reset Password
              </Button>
                    </form>
                    <Box mt={5}>
                        <Copyright />
                    </Box>
                </div>
            </Grid>
        </Grid>
    )
}
