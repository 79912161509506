import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SelectInput from "../../../AutocompleteContainer/AutoCompleteComponent";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import Management from "./MangementController";
import { AuthContext } from '../../../../shared/context/AuthContext'

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    stepPaddingButton: {
        marginTop: "1rem",
        marginLeft: "1rem",
    },
    card: {
        background: "lightblue",
    },
}));

export default function ManagementTabContainer() {
    const classes = useStyles();
    const { role } = useContext(AuthContext);

    const values = {
        subject: "",
        leadState: "",
        leadStatusId: 0,
        handledBy: "",
        handledById: 0,
        dealerId: 0,
        dealer: "",
    };
    const { loading, error, state, agentsList, dealer, save } = Management();

    const hasRole = (roleToCheck) => {

        if (!role) {
            return false;
        }

        return role.filter(itm => itm == roleToCheck).length > 0;
    }

    return <>
        {
            (hasRole('Admin') || hasRole('Manager')) &&
            <div className={classes.root}>
                <Formik
                    initialValues={values}
                    isInitialValid={false}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            save(values);
                            console.log(values);
                            actions.setSubmitting(true);
                        }, 100);
                    }}
                >
                    {(props) => {
                        return (
                            <Form>
                                <Card className={classes.stepPadding}>
                                    <CardContent>
                                        <Grid container direction="row">
                                            <Grid item className={classes.stepPadding} xs={12} md={4}>
                                                <TextField
                                                    id="subject"
                                                    name="subject"
                                                    label="Message"
                                                    fullWidth
                                                    value={props.values.subject}
                                                    helperText={
                                                        props.touched.subject ? props.errors.subject : ""
                                                    }
                                                    error={
                                                        props.touched.subject && Boolean(props.errors.subject)
                                                    }
                                                    onChange={(event) => {
                                                        props.handleChange(event);
                                                        props.setFieldTouched("subject", true, false);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={7}>
                                                <Card className={classes.card}>
                                                    <CardContent>
                                                        <Typography variant="h6">
                                                            The client will not be emailed regarding this change
                                                    </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            <Grid item className={classes.stepPadding} xs={12} md={3}>
                                                <Field
                                                    component={SelectInput}
                                                    props={props}
                                                    Label={"Lead State"}
                                                    fieldName={"leadState"}
                                                    fieldNameID={"leadStatusId"}
                                                    data={state}
                                                    helperText={
                                                        props.touched.leadState ? props.errors.leadState : ""
                                                    }
                                                    error={
                                                        props.touched.leadState &&
                                                        Boolean(props.errors.leadState)
                                                    }
                                                    value={{
                                                        name: props.values.leadState.toString(),
                                                        id: props.values.leadStatusId.toString(),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item className={classes.stepPadding} xs={12} md={3}>
                                                <Field
                                                    component={SelectInput}
                                                    props={props}
                                                    Label={"Agents"}
                                                    fieldName={"handledBy"}
                                                    fieldNameID={"handledById"}
                                                    data={agentsList}
                                                    helperText={
                                                        props.touched.handledBy ? props.errors.handledBy : ""
                                                    }
                                                    error={
                                                        props.touched.handledBy && Boolean(props.errors.handledBy)
                                                    }
                                                    value={{
                                                        name: props.values.handledBy.toString(),
                                                        id: props.values.handledById.toString(),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item className={classes.stepPadding} xs={12} md={3}>
                                                <Field
                                                    component={SelectInput}
                                                    props={props}
                                                    Label={"Dealer"}
                                                    fieldName={"dealer"}
                                                    fieldNameID={"dealerId"}
                                                    data={dealer}
                                                    helperText={
                                                        props.touched.dealer ? props.errors.dealer : ""
                                                    }
                                                    error={
                                                        props.touched.dealer && Boolean(props.errors.dealer)
                                                    }
                                                    value={{
                                                        name: props.values.dealer.toString(),
                                                        id: props.values.dealerId.toString(),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                                                <Button
                                                    variant="contained"
                                                    style={{ margin: "1rem" }}
                                                    color="primary"
                                                    type="submit"
                                                    disabled={!props.isValid}
                                                >
                                                    Save
                                            </Button>
                                                <Button
                                                    variant="contained"
                                                    style={{ margin: "1rem" }}
                                                    color="primary"
                                                    onClick={props.resetForm}
                                                >
                                                    Reset
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                            </Form>
                        );
                    }}
                </Formik>
            </div>
        }
        {
            !(hasRole('Admin') || hasRole('Manager')) && <Grid container direction="row">
                <Grid item className={classes.stepPadding} xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="caption">
                                Your role does not have access to this tab
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        }
    </>
}
