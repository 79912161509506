import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { TabsContext } from "../../../../shared/contexts/TabsContext"
import { LeadStatusEnum } from "../../../../shared/Constants"
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    button: {
        marginTop: "2rem",
        marginLeft: "2rem",
    },
    buttonSendBack: {
        marginTop: "2rem",
        marginLeft: "2rem",
        backgroundColor: "#f8cf01",
        color: "black"
    },
    buttonSent: {
        marginTop: "2rem",
        marginLeft: "2rem",
        backgroundColor: "#04872d",
        color: "white"
    },
}));

export default function BankValidatedContainer() {
    const classes = useStyles();
    let { updateState } = useContext(LeadMainContext);
    let { ...tabContext } = useContext(TabsContext);

    const values = {
        message: "",
    };

    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <Typography variant="h6">Upload, complete, not taken up or documents in</Typography>
                            <Grid container direction="row">
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.buttonSendBack}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => updateState(LeadStatusEnum["Not Taken Up"])}
                                    >
                                        Not Taken Up
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Upload the OTP
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.buttonSent}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        OTP Complete ...
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.buttonSendBack}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => updateState(LeadStatusEnum["Documents In"])}
                                    >
                                        Documents In
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
