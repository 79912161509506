import React, { useContext, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import CarRentalIcon from '@mui/icons-material/CarRental';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import { NavLink as RouterLink } from "react-router-dom";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { AuthContext } from '../../shared/context/AuthContext'


export default function MenuItems(props) {
 
    const { role } = useContext(AuthContext);

    const hasRole = (roleToCheck) => {

        if (!role) {
            return false;
        }

        return role.filter(itm => itm == roleToCheck).length > 0;
    }

    return <div  style={{color:'black'}}>

        {hasRole('Admin') && <ListItem button key="DASH" component={RouterLink} to="/DASH" >
            <ListItemIcon >
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Configurator" style={{color:''}}/>
        </ListItem>
        }
        {hasRole('Admin') && <ListItem button key="VehicleLibrary" component={RouterLink} to="/VehicleLibrary/Makes" >
            <ListItemIcon >
                <DirectionsCar />
            </ListItemIcon>
            <ListItemText primary="Vehicle Library" style={{color:''}}/>
        </ListItem>
        }
        <ListItem button key="ManageVehicles" component={RouterLink} to="/ManageVehicles" >
            <ListItemIcon >
                <CarRentalIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Vehicles" style={{color:''}}/>
        </ListItem>
        <ListItem button key="Leads" component={RouterLink} to="/leadList">
            <ListItemIcon>
                <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Dash Virtual" />
        </ListItem>
        {hasRole('SelectionDealer') && 
            <ListItem button key="Dealers" component={RouterLink} to="/SelectionDealer/dealers">
                <ListItemIcon>
                    <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="Dealers" />
            </ListItem>
        }
        {(hasRole('Admin') || hasRole('SelectionDealer')) && <ListItem button key="Slides" component={RouterLink} to="/slides">
            <ListItemIcon>
                <AddToQueueIcon />
            </ListItemIcon>
            <ListItemText primary="Slides" />
        </ListItem>
        }
        {(hasRole('Admin') || hasRole('Manager')) && <ListItem button key="Reporting" component={RouterLink} to="/reporting">
            <ListItemIcon>
                <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reporting" />
        </ListItem>
        }
        {(hasRole('Admin') || hasRole('SelectionDealer')) && <ListItem button key="Offers" component={RouterLink} to="/offers">
            <ListItemIcon>
                <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Offers" />
        </ListItem>}
        {(hasRole('Admin') || hasRole('SelectionDealer')) && <ListItem button key="Dashboard" component={RouterLink} to="/dashboard">
            <ListItemIcon>
                <QueryStatsIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItem>}
    </div>
};