import { useContext } from "react";
import axios from 'axios';
import { BASE_URI } from "../../../../shared/Constants";
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext";
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack'

export default function NotesController() {
    let { ...leadContext } = useContext(LeadMainContext);
    const { id } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const save = (values) => {
        axios.put(`${BASE_URI}/leads/${id}`, { ...leadContext.lead, ...values }).then(() => {
            leadContext.reloadState();
            enqueueSnackbar("Saved lead!", { variant: 'success' });
        });
    };

    return { save };
}