import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {
    Grid,
    TextField,
    Button,
    Box,
    CircularProgress,
    Typography,
    Divider,
    Link,
    Card,
    CardContent,
    CardActions,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Modal,
    Switch
} from "@material-ui/core";
import { Formik } from "formik";
import axios from "axios";
import { useSnackbar } from "notistack";
import { makeStyles, styled } from "@material-ui/core/styles";
import { alpha } from "@mui/material";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { BASE_URI, FACEBOOK_BUSINESS_ID } from "../../shared/Constants";
import { AuthContext } from "../../shared/context/AuthContext";
import TableHeadersSort from "../../shared/TableHeadersSort";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiCardHeader-title": {
            fontSize: "20px",
        },
    },
    flexCenter: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        display: "inline-block",
        width: "100%",
    },
    stepPaddingButtons: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    modalContainer: {
        justifyContent: "center",
        display: "flex",
        height: "fit-content",
        textAlign: "center",
        marginTop: "150px",
    },
    modalContent: {
        backgroundColor: "white",
        textAlign: "center",
        padding: "3%",
        borderRadius: "10px",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    paperHeading: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        marginBottom: "1rem",
        height: "fit-content",
        paddingBottom: "0",
    },
    errorText: {
        fontSize: "0.75rem",
        marginTop: "3px",
        textAlign: "left",
        fontWeight: 400,
        lineHeight: "1.66",
        letterSpacing: "0.03333em",
        color: "#f44336",
    },
    featureInputContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    switchContainer: {
        width: "fit-content",
        display: "inline-flex",
        minWidth: "fit-content",
        alignItems: "center",
        marginLeft: "35px",
    },
    swichMain: {
        display: "grid",
    },
    optionsMenu: {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
    optionsContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "right",
    },
    campaignsDevider: {
        margin: 'auto',
        marginTop: '50px',
        marginBottom: '50px',
        width: '90%',
    },
    customCampaignHeader: {
        display: 'inline-flex',
        justifyContent: 'space-between'
    },
    campaignButton: {
        width: 'fit-content',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    tablebody: {
        maxHeight: '500px',
        overflowY: 'overlay',
        overflowX: 'none',
        marginRight: '20px'
    },
    customFormContainer: {
        //:'50vh',
        // minWidth:'fit-content'
    }
}));

const integrationStatus = {
    //Successs
    Success: 10,
    SubscriptionSuccessCRMAssaignRequired: 11,

    //Errors
    Error: 20,
    PageAccessTokenErrorPageAssaignRequired: 21,
};

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function DealerFacebookLeads({ onSubmit, cancel, data }) {

    const { hasAdminRights } = useContext(AuthContext);
    const { id } = useParams();
    const [integrationState, setIntegrationState] = useState({ showModal: false });
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [allPagesAccounts, setMarketDearlerAllPagesAccounts] = useState([]);
    const [allAdsAcounts, setMarketDealAllAdsAccounts] = useState([]);
    const [activeAdAccount, setActiveAdAccount] = useState(null);
    const [activeUserPageAccount, setActiveUserPageAccount] = useState(null);
    const [dealer, setDealer] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [dealerCampaigns, setDealerCampaigns] = useState([]);
    const [activeCampaign, setActiveCampaign] = useState(null);
    const [dealerCampaign, setDealerCampaign] = useState(null);
    const [makeData, setMakeData] = useState([]);
    const [modelData, setModelData] = useState([]);
    const [variantData, setVariantData] = useState([]);
    const [makeId, setMakeId] = useState(0);
    const [modelId, setModelId] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [leadAccess, setLeadAccess] = useState(null);
    const [showError, setShowError] = useState(false);
    const [isFacebookDataLoading, setFacebookDataLoading] = useState(false);
    const preventDefault = (event) => { event.preventDefault() };
    const [stockNo, setstockNo] = useState(null);
    const [usedVehicle, setUsedVehicle] = useState(null);
    const [isNew, setIsNew] = useState(true);
    const [integrationVersion, setIntegrationVersion] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showCustomForm, setShowCustomForm] = useState(null);
    const [newCampaignInput, setNewCampaignInput] = useState({ campaignName: '', adName: '' })
    const open = Boolean(anchorEl);

    const [values, setValues] = useState({
        make: "",
        makeId: "",
        model: "",
        modelId: "",
        variant: "",
        variantId: "",
        pageId: "",
        adId: "",
        formId: "",
        campaignId: "",
        campaignName: "",
        dealerId: "",
        leadTypeId: "",
        leadTypeName: "",
        crmSourceOverride: "",
        campaignCode: "",
    });

    const LeadTypes = [
        { id: 1, name: "New" },
        { id: 2, name: "Used" },
        { id: 3, name: "DealerContract" },
        { id: 4, name: "StaffContact" },
        { id: 5, name: "Service" },
        { id: 6, name: "Part" },
        { id: 7, name: "PersonalLoad" },
        { id: 8, name: "TestDrive" },
    ];

    useEffect(() => {
        if (allAdsAcounts.length > 0 && allPagesAccounts === 0) {
            setShowError(true);
        }
    }, [allAdsAcounts]);

    useEffect(() => {
        setIntegrationVersion(dealer?.integrationVersion)
    }, [dealer?.integrationVersion])

    useEffect(() => {
        if (dealerCampaign) {
            setValues({
                ...values,
                pageId: dealerCampaign?.pageId
                    ? dealerCampaign?.pageId
                    : dealer?.facebookPageId,
                adId: dealerCampaign?.adId
                    ? dealerCampaign?.adId
                    : dealer?.facebookAdAccountId,
                formId: dealerCampaign?.formId ? dealerCampaign?.formId : "",
                id: dealerCampaign?.id,
                make: dealerCampaign?.make?.name,
                makeId: dealerCampaign?.make?.id,
                model: dealerCampaign?.model?.name,
                modelId: dealerCampaign?.model?.id,
                variant: dealerCampaign?.variant?.name,
                variantId: dealerCampaign?.variant?.id,
                campaignName: dealerCampaign?.campaignName,
                crmSourceOverride: dealerCampaign?.crmSourceOverride,
                leadTypeName: dealerCampaign?.leadType?.name,
                leadTypeId: dealerCampaign?.leadType?.id,
                campaignCode: dealerCampaign?.campaignCode,
            });
        }
    }, [dealerCampaign]);

    const handleCustomFormOpen = () => {
        setShowCustomForm(true);
    };

    const handleCustomFormClose = () => {
        setShowCustomForm(false);
    };
    const handleSubmitCampaign = () => {
        SubmitCustomCampaign();
        handleCustomFormClose();
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMakeChange = (e) => {
        setModelData([]);

        if (e != null) setMakeId(e.id);
    };

    const handleModelChange = (e) => {
        setVariantData([]);

        if (e != null) setModelId(e.id);
    };

    useEffect(() => {
        if (activeCampaign) {
            setValues({
                ...values,
                campaignId: activeCampaign?.id,
                campaignName: activeCampaign?.name,
            });
            setLoading(true);
            axios({
                method: "GET",
                url: `${BASE_URI}/dealers/GetDealerCampaign?campaignId=${activeCampaign?.id}`,
                cancelToken: new axios.CancelToken((c) => (cancel = c)),
            })
                .then((response) => {
                    if (response?.data) {
                        const dealerCampaign = response?.data;

                        setDealerCampaign(dealerCampaign);
                        if (dealerCampaign?.vehicleSource == 1 || dealerCampaign?.stock) {
                            //Dash Used Car
                            setIsNew(false);
                            setUsedVehicle(dealerCampaign.stock);
                            setstockNo(dealerCampaign?.stock?.stockNo);
                        } else {
                            setIsNew(true);
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) return;
                    if (error.response) {
                        //setError(true)
                    }
                    setLoading(false);
                });
            axios({
                method: "GET",
                url: `${BASE_URI}/makes`,
                cancelToken: new axios.CancelToken((c) => (cancel = c)),
            })
                .then((response) => {
                    if (response.data?.list) {
                        setMakeData(response.data?.list);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) return;
                    if (error.response) {
                        //setError(true)
                    }
                    setLoading(false);
                });
        }
    }, [activeCampaign]);

    useEffect(() => {

        if (!makeId) return;

        let cancel;
        setLoading(true);
        const params = {};
        params.makeId = makeId;

        axios({
            method: "GET",
            url: `${BASE_URI}/models`,
            params,
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
            .then((response) => {
                if (response.data?.list) {
                    setModelData(response.data?.list);
                }

                setLoading(false);
            })
            .catch((error) => {
                if (axios.isCancel(error)) return;

                if (error.response) {
                    // setError(true)
                }

                setLoading(false);
            });
    }, [makeId]);

    useEffect(() => {

        if (!modelId) return;

        let cancel;
        setLoading(true);
        const params = {};
        params.modelId = modelId;

        axios({
            method: "GET",
            url: `${BASE_URI}/variants`,
            params,
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
            .then((response) => {
                if (response.data?.list) {
                    setVariantData(response.data?.list);
                }

                setLoading(false);
            })
            .catch((error) => {
                if (axios.isCancel(error)) return;

                if (error.response) {
                    //setError(true)
                }

                setLoading(false);
            });
    }, [modelId]);

    const headCells = [
        { id: "1", label: "Campaign", sort: false },
        { id: "2", label: "Ad", sort: false },
        { id: "3", label: "Status", sort: false },
        { id: "6", label: "Ad ID", sort: false },
        { id: "7", label: "Page ID", sort: false },
        { id: "8", label: "Model", sort: false },
        { id: "9", label: "Form Name/Subject", sort: false },
        { id: "10", label: "LeadType", sort: false },
        { id: "11", label: "ID", sort: false },
    ];

    const SubmitChanges = () => {
        const SaveDealerCampaign = async () => {
            setLoading(true);

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            var params = values;
            if (!isNew) {
                params.makeId = 0;
                params.modelId = 0;
                params.variantId = 0;
                params.leadTypeId = 2;
                params.stockNo = stockNo;
                params.vehicleSource = 1;
            } else {
                params.leadTypeId = values?.leadTypeId;
                params.stockNo = stockNo;
                params.vehicleSource = 2;
            }

            try {
                const result = await axios.post(
                    `${BASE_URI}/dealers/AddDealerCampain`,
                    {
                        cancelToken: source.token,
                        ...params,
                    }
                );

                if (result?.data != null) {
                    showResponse(result?.data);
                }

                const dealerCampaignsResult = await axios.get(
                    `${BASE_URI}/dealers/GetDealerCampaigns?dealerId=${id}`,
                    {
                        cancelToken: source.token,
                    }
                );

                if (dealerCampaignsResult?.data) {
                    const dealerCampaigns = dealerCampaignsResult?.data;
                    setDealerCampaigns(dealerCampaigns);
                }

                setActiveCampaign(null);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        SaveDealerCampaign();
    };

    const SubmitCustomCampaign = () => {
        const SaveDealerCampaign = async () => {
            setLoading(true);

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            var params = {
                dealerId: dealer?.dealerId,
                campaignName: newCampaignInput?.campaignName,
                adName: newCampaignInput?.adName
            }

            try {
                const result = await axios.post(
                    `${BASE_URI}/dealers/AddDealerCustomCampaign`,
                    {
                        cancelToken: source.token,
                        ...params,
                    }
                );

                if (result?.data != null) {
                    showResponse(result?.data);
                }

                const dealerCampaignsResult = await axios.get(
                    `${BASE_URI}/dealers/GetDealerCampaigns?dealerId=${id}`,
                    {
                        cancelToken: source.token,
                    }
                );

                if (dealerCampaignsResult?.data) {
                    const dealerCampaigns = dealerCampaignsResult?.data;
                    setDealerCampaigns(dealerCampaigns);
                }

                setActiveCampaign(null);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        SaveDealerCampaign();
    };


    const UpdateDealerCampaign = () => {
        const Update = async () => {
            setLoading(true);

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            var params = values;
            if (!isNew) {
                params.makeId = 0;
                params.modelId = 0;
                params.variantId = 0;
                params.leadTypeId = 2;
                params.stockNo = stockNo;
                params.vehicleSource = 1;
            } else {
                params.leadTypeId = values?.leadTypeId;
                params.stockNo = "";
                params.vehicleSource = 2;
            }
            params.id = dealerCampaign?.id;

            try {
                const result = await axios.put(
                    `${BASE_URI}/dealers/UpdateDealerCampaign/${id}`,
                    {
                        cancelToken: source.token,
                        ...params,
                    }
                );
                if (result?.data != null) {
                    showResponse(result?.data);
                }
                const dealerCampaignsResult = await axios.get(
                    `${BASE_URI}/dealers/GetDealerCampaigns?dealerId=${id}`,
                    {
                        cancelToken: source.token,
                    }
                );
                if (dealerCampaignsResult?.data) {
                    const dealerCampaign = dealerCampaignsResult?.data;
                    setDealerCampaigns(dealerCampaign);
                }
                setActiveCampaign(null);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        Update();
    };

    const showResponse = (response) => {
        enqueueSnackbar(response?.message, { variant: response?.status >= 10 && response?.status <= 19 ? "success" : "error" });

        if (response.status !== 10 && response.status !== 20) {
            setIntegrationState({ showModal: true, status: response?.status });
        }
    };

    const onSubscribe = () => {
        const getData = async () => {
            setLoading(true);
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            try {
                const result = await axios.post(`${BASE_URI}/dealers/Subscribe`, {
                    cancelToken: source.token,
                    pageId: activeUserPageAccount?.id,
                    adAccountId: activeAdAccount?.account_Id,
                    dealerId: parseInt(id),
                    leadAccessId: leadAccess?.id ? leadAccess?.id : null,
                    integrationVersion: integrationVersion
                });

                showResponse(result?.data);
                const dealerResults = await axios.get(`${BASE_URI}/dealers/${id}`, {
                    cancelToken: source.token,
                });
                if (dealerResults?.data != null) {
                    setDealer(dealerResults?.data);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        getData();
    };

    const getDealer = () => {
        const getDealerRequest = async () => {
            try {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                const dealerResults = await axios.get(`${BASE_URI}/dealers/${id}`, {
                    cancelToken: source.token,
                });

                if (dealerResults?.data != null) {
                    setDealer(dealerResults?.data);
                }
            } catch (error) {
                setLoading(false);
            }
        };
        getDealerRequest();
    };

    useEffect(() => {
        if (!dealer) {
            getDealer();
        }
    }, []);

    const getVehicleDetails = () => {
        const getData = async () => {
            setFacebookDataLoading(false);
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const params = {};
            params.stockNo = stockNo;
            params.dealerId = id;

            try {
                const result = await axios.get(`${BASE_URI}/stock/stockNumber`, {
                    cancelToken: source.token,
                    params,
                });

                if (result?.data) {
                    setUsedVehicle(result?.data);
                } else {
                }

                setFacebookDataLoading(false);
            } catch (error) {
                setFacebookDataLoading(false);
            }
        };

        getData();
    };

    //step 2
    useEffect(() => {

        if (!dealer) return;

        let promises = [];

        if (
            !dealer.facebookPageId &&
            !isFacebookDataLoading &&
            allPagesAccounts.length === 0 &&
            allAdsAcounts.length === 0
        ) {
            const getData = async () => {

                setFacebookDataLoading(true);

                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();

                try {
                    const result = await axios.get(
                        `${BASE_URI}/dealers/facebookPagesAndAdsAccounts`,
                        {
                            cancelToken: source.token,
                        }
                    );

                    if (result?.data?.ads) {
                        setMarketDealAllAdsAccounts(result?.data?.ads);
                    }
                    if (result?.data?.pages) {
                        setMarketDearlerAllPagesAccounts(result?.data?.pages);
                    }

                    setFacebookDataLoading(false);
                } catch (error) {
                    setFacebookDataLoading(false);
                }
            };

            promises.push(getData());
        }

        if (true) {
            const getCampaigns = async () => {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();

                try {
                    const result = await axios.post(`${BASE_URI}/dealers/Campaigns`, {
                        cancelToken: source.token,
                        pageId: activeUserPageAccount?.id,
                        adAccountId: dealer?.facebookAdAccountId,
                        dealerId: parseInt(id),
                        integrationVersion: dealer?.integrationVersion
                    });

                    if (result?.data?.campaigns != null) {
                        setCampaigns(result?.data?.campaigns);
                    }

                    const dealerCampaignsResult = await axios.get(
                        `${BASE_URI}/dealers/GetDealerCampaigns?dealerId=${id}`,
                        {
                            cancelToken: source.token,
                        }
                    );

                    if (dealerCampaignsResult?.data) {
                        setDealerCampaigns(dealerCampaignsResult?.data);
                    }

                } catch (error) {
                    setLoading(false);
                }
            };

            promises.push(getCampaigns());
        }

        setValues({
            ...values,
            pageId: `${dealer?.facebookPageId}`,
            adId: dealer?.facebookAdAccountId,
            dealerId: parseInt(id),
        });

        Promise.allSettled(promises).then(() => { setLoading(false) });

    }, [dealer]);

    useEffect(() => {
        if (activeUserPageAccount?.leads_Access?.id) {
            setLeadAccess(activeUserPageAccount?.leads_Access);
        } else {
            setLeadAccess(null);
        }
    }, [activeUserPageAccount, activeAdAccount, leadAccess]);

    const GetDBDealerCampaign = (id) => {
        return dealerCampaigns.find((d) => d?.campaignId === id);
    };

    const UnlinkFacebookPages = () => {
        handleClose();
        const dealerID = parseInt(id);

        if (dealerID && hasAdminRights) {
            const UnlinkRequest = async () => {
                setLoading(true);
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();

                const params = {};
                params.passCode = "123qwe";
                params.dealerId = dealerID;

                try {
                    const result = await axios.get(`${BASE_URI}/dealers/ClearTestData`, {
                        cancelToken: source.token,
                        params,
                    });

                    if (result?.data != null) {
                        enqueueSnackbar("Facebook Page has been successfully unlinked.", {
                            variant: "success",
                        });
                        window.location.reload();
                    }

                    setLoading(false);
                } catch (error) {
                    enqueueSnackbar("An unexpected Error Occured.", { variant: "error" });
                    setLoading(false);
                }
            };

            UnlinkRequest();
        } else {
            enqueueSnackbar("You are not allowed to perform this action.", {
                variant: "warning",
            });
        }
    };

    const UpdateFacebookPages = () => {
        handleClose();
        const dealerID = parseInt(id);

        if (dealerID && hasAdminRights) {
            const UnlinkRequest = async () => {
                setLoading(true);
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();

                const params = {};
                params.passCode = "123qwe";

                try {
                    const result = await axios.get(
                        `${BASE_URI}/dealers/UpdatePagesAndAds`,
                        {
                            cancelToken: source.token,
                            params,
                        }
                    );

                    if (result?.data != null) {
                        enqueueSnackbar("Facebook Pages have been successfully updated.", {
                            variant: "success",
                        });
                        window.location.reload();
                    }

                    setLoading(false);
                } catch (error) {
                    enqueueSnackbar("An unexpected Error Occured.", { variant: "error" });
                    setLoading(false);
                }
            };

            UnlinkRequest();
        } else {
            enqueueSnackbar("You are not allowed to perform this action.", {
                variant: "warning",
            });
        }
    };

    return (
        <Card className="d-flex justify-content-between flex-column">
            <CardContent style={{ width: "100%", display: "block" }}>
                <Dialog open={showCustomForm} onClose={handleClose} maxWidth={'md'} fullWidth={true} aria-labelledby="form-dialog-title" >
                    <DialogTitle id="form-dialog-title">Add Custom Campaign</DialogTitle>
                    <DialogContent >
                        <DialogContentText>
                            Please fill the Campaign details below.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="campaignName"
                            label="Campaign Name"
                            type="text"
                            fullWidth
                            onChange={(e) => { setNewCampaignInput({ ...newCampaignInput, campaignName: e?.target?.value }) }}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="adName"
                            label="Ad Name"
                            type="text"
                            fullWidth
                            onChange={(e) => { setNewCampaignInput({ ...newCampaignInput, adName: e?.target?.value }) }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCustomFormClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmitCampaign} color="primary" disabled={!newCampaignInput?.campaignName || !newCampaignInput?.adName}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    open={integrationState?.showModal}
                    className={classes.modalContainer}
                >
                    <div className={classes.modalContent}>
                        {
                            integrationState?.status === integrationStatus?.PageAccessTokenErrorPageAssaignRequired &&
                            <>
                                <Alert severity="error">{`You do not have access to ${activeUserPageAccount?.name} : ${activeUserPageAccount?.id} Page`}</Alert>
                                <Link
                                    href={`https://business.facebook.com/settings/pages/${activeUserPageAccount?.id}?business_id=${FACEBOOK_BUSINESS_ID}`}
                                    onClick={preventDefault}
                                >
                                    Please click here to go to facebook business and add the user (Sibusiso Malatji) access to the page.
                                </Link>
                            </>
                        }
                        {
                            integrationState?.status === integrationStatus?.SubscriptionSuccessCRMAssaignRequired &&
                            <>
                                <Alert severity="success">{`You have successfully linked ${activeUserPageAccount?.name} to LeadGen`}</Alert>
                                <Link
                                    href={`https://business.facebook.com/settings/leads-accesses/`}
                                    onClick={preventDefault}
                                >
                                    Please Assaign DashLead CRM Manualy on Facebook Business
                                    Intergration to the page And Add (Sibusiso Malaji Under People
                                    ), to complete the process. (leads wont be received unless
                                    this step is completed)
                                </Link>
                            </>
                        }
                        <Grid item className={classes.stepPaddingButtons}>
                            <Button
                                variant="contained"
                                style={{ margin: "1rem" }}
                                color="default"
                                onClick={() => setIntegrationState({ showModal: false })}
                            >
                                Close
                            </Button>
                        </Grid>
                    </div>
                </Modal>
                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    open={showError}
                    className={classes.modalContainer}
                >
                    <div className={classes.modalContent}>
                        <h2 id="server-modal-title">Sorry server side issue occured, feature temporary blocked by facebook. Please try again later</h2>
                        <Grid item className={classes.stepPaddingButtons}>
                            <Button
                                variant="contained"
                                style={{ margin: "1rem" }}
                                color="default"
                                onClick={() => setShowError(false)}
                            >
                                Close
                            </Button>
                        </Grid>
                    </div>
                </Modal>

                {
                    hasAdminRights &&
                    <div className={classes.optionsContainer}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? "demo-customized-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            More Options
                            {loading && <CircularProgress />}
                        </Button>
                        <Menu
                            className={classes.optionsMenu}
                            id="demo-customized-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => UpdateFacebookPages()} disableRipple>
                                <SystemUpdateAltIcon style={{ fontSize: "25px" }} />
                                Update Pages And Ads Accounts
                            </MenuItem>
                            <MenuItem onClick={() => UnlinkFacebookPages()} disableRipple>
                                <LinkOffIcon style={{ fontSize: "25px" }} />
                                Unlink Facebook Page And Ad Account
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple disabled={true}>
                                <FactCheckIcon style={{ fontSize: "25px" }} />
                                Check Facebook Intergration Issues
                            </MenuItem>
                            <Divider sx={{ my: 0.5 }} />

                            <MenuItem onClick={handleClose} disableRipple disabled={true}>
                                <MoreHorizIcon style={{ fontSize: "25px" }} />
                                More
                            </MenuItem>
                        </Menu>
                    </div>
                }

                {
                    <Grid container direction="row" className={classes.swichMain}>
                        <Grid item xs={12} md={2} className={`${classes.stepPadding} ${classes.switchContainer}`}>
                            <Typography>Market Dealer Holdings (V1) </Typography>
                            <Switch
                                Label
                                {...label}
                                checked={integrationVersion === 2}
                                onChange={(e) => setIntegrationVersion(integrationVersion === 2 ? 1 : 2)}
                                disabled={dealer?.facebookAdAccountId}
                            />
                            <Typography>MDH Digital 2022 (V2)</Typography>
                        </Grid>
                    </Grid>
                }

                {
                    !loading && !dealer?.facebookAdAccountId &&
                    <React.Fragment>
                        <Grid item className={classes.stepPadding}>
                            <Autocomplete
                                id="allPages"
                                name="allPages"
                                value={activeUserPageAccount}
                                options={allPagesAccounts.filter(p => { return p?.integrationVersion === integrationVersion || p?.existsOnBothVersions }).sort((a, b) => a.name > b.name ? 1 : -1)}
                                getOptionLabel={(option) => `${option.name} : ${option.id}`}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value) => { setActiveUserPageAccount(value) }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Page" />
                                )}
                            />

                            <Autocomplete
                                id="allAds"
                                name="allAds"
                                value={activeAdAccount}
                                options={allAdsAcounts.filter(p => { return p?.integrationVersion === integrationVersion }).sort((a, b) => a.name > b.name ? 1 : -1)}
                                getOptionLabel={(option) => `${option.name} : ${option?.account_Id}`}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, value) => { setActiveAdAccount(value) }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Ad Account" />
                                )}
                            />

                            <Box mb={3} className={classes.flex}>
                                <Button
                                    gutterBottom
                                    variant="contained"
                                    color="primary"
                                    className={classes.full}
                                    onClick={() => onSubscribe()}
                                    style={{ marginLeft: "1rem" }}
                                    disabled={!activeUserPageAccount || !activeAdAccount || loading}
                                >
                                    {!loading ? "Intergrate with Dash Lead" : "please wait"}
                                    {loading && <CircularProgress />}
                                </Button>
                            </Box>
                        </Grid>
                    </React.Fragment>
                }

                {
                    !activeCampaign &&
                    <React.Fragment>
                        <Typography variant="h6" component="h2">
                            Facebook Campaigns
                        </Typography>
                        <Grid container direction="row" className={classes.swichMain}>
                            <TableContainer className={classes.tablebody}>
                                <Table aria-label="slides table" size="small">
                                    <TableHeadersSort headCells={headCells} />

                                    <TableBody >
                                        {!loading && campaigns.map((c) => {
                                            const dc = GetDBDealerCampaign(c?.id);
                                            return (!dc || !dc?.isCustomCampaign) && (
                                                <TableRow
                                                    hover={"pointer"}
                                                    onClick={() => setActiveCampaign(c)}
                                                >
                                                    <TableCell>{c?.adset?.name}</TableCell>
                                                    <TableCell>{c?.name}</TableCell>
                                                    <TableCell>{c?.status}</TableCell>
                                                    <TableCell>{dealer?.facebookAdAccountId}</TableCell>
                                                    <TableCell>{dealer?.facebookPageId}</TableCell>
                                                    <TableCell>{dc?.model?.name}</TableCell>
                                                    <TableCell>{``}</TableCell>
                                                    <TableCell>{dc?.leadType?.name}</TableCell>
                                                    <TableCell>{dc?.id}</TableCell>
                                                </TableRow>
                                            );
                                        })}

                                        {(!campaigns.length || campaigns.length === 0) && (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={headCells?.length}
                                                    align="center"
                                                    style={{ borderBottom: 0 }}
                                                    className="py-3"
                                                >
                                                    {
                                                        !loading
                                                            ? "No Campaigns Available"
                                                            : <>
                                                                <Typography variant="h6" color="textSecondary" gutterBottom>Loading Campaigns</Typography>
                                                                <br />
                                                                <CircularProgress />
                                                            </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Divider className={classes.campaignsDevider} />
                        <Grid container direction="row" className={`${classes.swichMain} ${classes.customCampaignHeader}`}>
                            <Typography variant="h6" component="h2">
                                Custom Campaigns
                            </Typography>
                            <Button variant="contained" className={classes.campaignButton} onClick={() => handleCustomFormOpen()}>Add Campaign
                                {loading && <CircularProgress />}</Button>
                        </Grid>
                        <Grid container direction="row" className={classes.swichMain}>
                            <TableContainer className={classes.tablebody}>
                                <Table aria-label="slides table" size="small">
                                    <TableHeadersSort headCells={headCells} />

                                    <TableBody>
                                        {!loading && dealerCampaigns.filter(dc => { return dc?.isCustomCampaign }).map((c) => {
                                            return (
                                                <TableRow
                                                    hover={"pointer"}
                                                    onClick={() => setActiveCampaign(c)}
                                                >
                                                    <TableCell>{c?.campaignName}</TableCell>
                                                    <TableCell>{c?.adName}</TableCell>
                                                    <TableCell>{'Active'}</TableCell>
                                                    <TableCell>{'N/a'}</TableCell>
                                                    <TableCell>{'N/a'}</TableCell>
                                                    <TableCell>{c.model?.name}</TableCell>
                                                    <TableCell>{``}</TableCell>
                                                    <TableCell>{c?.leadType?.name}</TableCell>
                                                    <TableCell>{c?.id}</TableCell>
                                                </TableRow>
                                            );
                                        })}

                                        {(!dealerCampaigns.length || dealerCampaigns.length === 0) && (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={headCells?.length}
                                                    align="center"
                                                    style={{ borderBottom: 0 }}
                                                    className="py-3"
                                                >
                                                    {
                                                        !loading
                                                            ? "No Campaigns Available"
                                                            : <>
                                                                <Typography variant="h6" color="textSecondary" gutterBottom>Loading Campaigns</Typography>
                                                                <br />
                                                                <CircularProgress />
                                                            </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </React.Fragment>
                }

                {
                    activeCampaign &&
                    <React.Fragment>
                        <Formik>
                            <div>
                                <Grid container direction="row" className={classes.swichMain}>
                                    <Grid item xs={12} md={2} className={`${classes.stepPadding} ${classes.switchContainer}`}>
                                        <Typography>Dash Used Vehicle</Typography>
                                        <Switch
                                            Label
                                            {...label}
                                            checked={isNew}
                                            onChange={(e) => setIsNew(!isNew)}
                                        />
                                        <Typography>External Vehicle</Typography>
                                    </Grid>
                                </Grid>

                                {
                                    !isNew &&
                                    <Grid container direction="row">
                                        <Grid item xs={12} md={3} className={classes.stepPadding}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="stockNo"
                                                type="text"
                                                label="Stock Number"
                                                value={stockNo}
                                                onChange={(e) => {
                                                    setstockNo(e?.target?.value);
                                                }}
                                                disabled={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3} className={classes.stepPadding}>
                                            <Button
                                                variant="contained"
                                                style={{ marginRight: "1rem" }}
                                                onClick={getVehicleDetails}
                                                color="default"
                                            >
                                                Retrieve Vehicle Details
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }

                                {
                                    usedVehicle && !isNew &&
                                    <Grid container direction="row">
                                        <Grid item xs={12} md={3} className={classes.stepPadding}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="uMake"
                                                type="text"
                                                label="Make"
                                                value={usedVehicle?.brand}
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={3} className={classes.stepPadding}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="uModel"
                                                type="text"
                                                label="Model"
                                                value={usedVehicle?.model}
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={3} className={classes.stepPadding}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="uVariant"
                                                type="text"
                                                label="Variant"
                                                value={usedVehicle?.modelRange}
                                                disabled={true}
                                            />
                                        </Grid>
                                    </Grid>
                                }

                                <Grid container direction="row">
                                    {
                                        isNew &&
                                        <>
                                            <Grid item xs={12} md={3} className={classes.stepPadding}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="stockNo"
                                                    type="text"
                                                    label="Stock Number"
                                                    value={stockNo}
                                                    onChange={(e) => {
                                                        setstockNo(e?.target?.value);
                                                    }}
                                                    disabled={false}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className={classes.stepPadding}>
                                                <Autocomplete
                                                    id="make"
                                                    name="make"
                                                    value={{
                                                        id: values?.makeId?.toString(),
                                                        name: values?.make?.toString(),
                                                    }}
                                                    options={makeData}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                    onChange={(event, value) => {
                                                        setValues({
                                                            ...values,
                                                            make: value ? value.name : "",
                                                            makeId: value ? value.id : "",
                                                        });
                                                        handleMakeChange(value);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select A Make" />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className={classes.stepPadding}>
                                                <Autocomplete
                                                    id="model"
                                                    name="model"
                                                    value={{
                                                        id: values?.modelId?.toString(),
                                                        title: values?.model?.toString(),
                                                    }}
                                                    options={modelData}
                                                    disabled={modelData.length <= 0}
                                                    getOptionLabel={(option) => option.title}
                                                    getOptionSelected={(option, value) => option.title === value.title}
                                                    onChange={(event, value) => {
                                                        setValues({
                                                            ...values,
                                                            model: value ? value.title : "",
                                                            modelId: value ? value.id : "",
                                                        });

                                                        handleModelChange(value);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select A Model" />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className={classes.stepPadding}>
                                                <Autocomplete
                                                    id="variant"
                                                    name="variant"
                                                    value={{
                                                        id: values?.variantId?.toString(),
                                                        variantName: values?.variant?.toString(),
                                                    }}
                                                    options={variantData}
                                                    disabled={variantData.length <= 0}
                                                    getOptionLabel={(option) => option.variantName}
                                                    getOptionSelected={(option, value) => option.variantName === value.variantName}
                                                    onChange={(event, value) => {
                                                        setValues({
                                                            ...values,
                                                            variant: value ? value.variantName : "",
                                                            variantId: value ? value.id : "",
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select A Variant" />
                                                    )}
                                                />
                                            </Grid>
                                        </>
                                    }
                                </Grid>

                                <Grid container direction="row">
                                    <Grid item xs={12} md={3} className={classes.stepPadding}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="crmSourceOverride"
                                            type="text"
                                            label="CRM Source Overide"
                                            value={values.crmSourceOverride}
                                            onChange={(event, value) => {
                                                setValues({
                                                    ...values,
                                                    crmSourceOverride: event.target.value,
                                                });
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} className={classes.stepPadding}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="campaignCode"
                                            type="text"
                                            label="Campaign Code (Floor)"
                                            value={values.campaignCode}
                                            onChange={(event, value) => {
                                                setValues({
                                                    ...values,
                                                    campaignCode: event.target.value,
                                                });
                                            }}
                                            disabled={false}
                                        />
                                    </Grid>

                                    {
                                        isNew &&
                                        <Grid item xs={12} md={3} className={classes.stepPadding}>
                                            <Autocomplete
                                                id="leadType"
                                                name="LeadType"
                                                value={{
                                                    id: values?.leadTypeId?.toString(),
                                                    name: values?.leadTypeName?.toString(),
                                                }}
                                                options={LeadTypes}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.name === value.leadTypeName}
                                                onChange={(event, value) => {
                                                    setValues({
                                                        ...values,
                                                        leadTypeId: value ? value.id : "",
                                                        leadTypeName: value ? value.name : "",
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Lead Type" />
                                                )}
                                            />
                                        </Grid>
                                    }
                                </Grid>

                                <Grid container direction="row">
                                    <Grid item xs={12} md={3} className={classes.stepPadding}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="formId"
                                            type="text"
                                            label="Form ID"
                                            value={values.formId}
                                            disabled={false}
                                            onChange={(event, value) => {
                                                setValues({ ...values, formId: event.target.value });
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container direction="row">
                                    <Grid item xs={12} md={6} className={classes.stepPadding} style={{ width: "100%" }}>
                                        <TableContainer>
                                            <Table aria-label="slides table" size="small">
                                                <TableBody>
                                                    <TableRow
                                                        hover={"pointer"}
                                                        style={{ border: "none" }}
                                                    >
                                                        <TableCell>{"Campaign Name:"}</TableCell>
                                                        <TableCell>{values?.campaignName}</TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        hover={"pointer"}
                                                        style={{ border: "none" }}
                                                    >
                                                        <TableCell>{"Campaign ID:"}</TableCell>
                                                        <TableCell>{values?.campaignId}</TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        hover={"pointer"}
                                                        style={{ border: "none" }}
                                                    >
                                                        <TableCell>{"Page ID:"}</TableCell>
                                                        <TableCell>{values?.pageId}</TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        hover={"pointer"}
                                                        style={{ border: "none" }}
                                                    >
                                                        <TableCell>{"Ad ID:"}</TableCell>
                                                        <TableCell>{values?.adId}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </div>
                        </Formik>
                    </React.Fragment>
                }
            </CardContent>

            <CardActions>
                {
                    activeCampaign &&
                    <Grid container justify="flex-end" alignItems="center">
                        <Button
                            variant="contained"
                            style={{ marginRight: "1rem" }}
                            onClick={() => setActiveCampaign(null)}
                            color="default"
                        >
                            Back to Campaigns
                        </Button>
                        <Button
                            variant="contained"
                            onClick={
                                !dealerCampaign
                                    ? () => SubmitChanges()
                                    : () => UpdateDealerCampaign()
                            }
                            color="primary"
                        >
                            {`${dealerCampaign ? "Update" : "Save to Dealer Net"}`}
                        </Button>
                    </Grid>
                }
            </CardActions>
        </Card>
    );
}
