import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    Divider,
    Paper,
    Fab
} from '@material-ui/core'
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from "react-router-dom";
import { BASE_URI, LeadTypeEnum } from '../../shared/Constants'
import { useSnackbar } from "notistack";
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    featureInputContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    title:{
        background:'#bdbfc1',
        color:'white',
        padding:"5px 30px",

        width:"fit-content",
        borderBottom: '2px red solid',
  
    }
}));

export default function DealerSettingForm({ history }) {
    const initialValues = {
        defaultDealerEmails: '',
        selectedLeadSource: 0,
        dealerOverrideEmails: [],
        cmsSettings: [],
        ixSettings: [],
        overrideEmails: '',
        floor: '',
        source: '',
        dealershipID: '',
        ixDealerId: 0,
        ref: '',
        values: ''
    }

    const { id } = useParams();
    const isAddMode = !id;
    const [ loading, setLoading ] = useState(true);
    const [ crmSettings, setCrmSettings ] = useState([])
    const [ leadSources, setLeadSources ] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();

    const loadCrmSettings = async () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios.get(`${BASE_URI}/Dealers/settings/${id}`, {
            cancelToken: source.token
        }).then(result => {

            setCrmSettings(result.data);

        }).catch(error => {

            setLoading(false)
            enqueueSnackbar("Failed to load the dealer settings. " + error, {variant: 'error'});

        });
    };

    const loadSources = async () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios.get(`${BASE_URI}/LeadSources`, {
            cancelToken: source.token
        }).then(result => {

            setLeadSources([
                {
                    id: -1,
                    name: "Please select a lead source"
                },
                {
                    id: 0,
                    name: "Default"
                },
                ...result.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            ]);

        }).catch(error => {

            setLoading(false)
            enqueueSnackbar("Failed to load the lead sources. " + error, {variant: 'error'});

        });
    };

    useEffect(() => {
        
        setLoading(true);

        loadCrmSettings();
        loadSources();

    }, []);

    useEffect(() => {

        if (leadSources && leadSources.length > 0) {
            setLoading(false);
        }

    }, [crmSettings]);

    useEffect(() => {

        if (crmSettings && crmSettings.length > 0) {
            setLoading(false);
        }

    }, [leadSources]);

    const findByLeadType = (leadTypeId) => {

        if (!crmSettings) {
            return {
                ...initialValues,
                leadTypeId: leadTypeId
            };
        }

        var result = crmSettings.find(itm => itm.leadTypeId == leadTypeId);

        if (!result) {
            return {
                ...initialValues,
                leadTypeId: leadTypeId
            };
        } else {
            return result;
        }
    }

    function onSubmit(values) {

        const getItemForLeadSource = (items) => {
            var selectedLeadSourceId =  values.selectedLeadSource?.id ?? -1;

            return items.find(item => item.leadSourceId == selectedLeadSourceId);
        };

        // Reconstruct the CRM settings from the form values
        let changedCrmSettings = {
            dealerId: parseInt(id),
            leadTypeId: values.leadTypeId,
            defaultDealerEmails: values.defaultDealerEmails,
            dealerOverrideEmails: [ getItemForLeadSource(values.dealerOverrideEmails) ],
            cmsSettings: [ getItemForLeadSource(values.cmsSettings) ],
            ixSettings: [ getItemForLeadSource(values.ixSettings) ]
        };

        if ((values.selectedLeadSource?.id >= 0 ? values.selectedLeadSource?.id : -1) >= 0) {
            changedCrmSettings.dealerOverrideEmails[0].emails = values.overrideEmails;
            changedCrmSettings.cmsSettings[0].floor = values.floor;
            changedCrmSettings.cmsSettings[0].source = values.source;
            changedCrmSettings.cmsSettings[0].dealershipID = values.dealershipID;
            changedCrmSettings.ixSettings[0].ixDealerId = values.ixDealerId;
            changedCrmSettings.ixSettings[0].ref = values.ref;
            changedCrmSettings.ixSettings[0].value = values.value;
        }

        updateDealerSetting(changedCrmSettings)
    }

    const updateDealerSetting = async (fields) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios.put(`${BASE_URI}/dealers/settings`, fields, {
            cancelToken: source.token,
        }).then(response => {

            loadCrmSettings();
            enqueueSnackbar("Successfully updated dealer settings", { variant: "success" });

        }).catch(error => {

            enqueueSnackbar("Failed to save the dealer settings. " + error, {variant: 'error'});

        });
        try {
        } catch (error) {
            enqueueSnackbar(error.data.message, {variant: 'error'});
        } finally {
            //setSubmitting(false);
        }
    };

    const addDealerSetting = async (fields) => {
        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            console.log(fields);

            axios.post(`${BASE_URI}/dealers/settings`, fields, {
                cancelToken: source.token,
            }).then((result) => {

                axios.get(`${BASE_URI}/Dealers/settings/${id}`, {
                    cancelToken: source.token
                }).then(result => {
                    setCrmSettings(result.data);
                    enqueueSnackbar("Successfully updated Dealer Settings", { variant: "success" });
                })

            })

        } catch (error) {
            enqueueSnackbar(error.data.message, { variant: 'error' });
        } finally {
            //setSubmitting(false);
        }
    };

    const onLeadSourceChanged = (values, e, c) => {
        // When the lead source changed the CRM settings for that lead source need to be assigned to the form's "values" object
        let sourceId = c.props.id;
        if (sourceId == null || sourceId == undefined || sourceId < 0)
            return;

        values.overrideEmails = values.dealerOverrideEmails.find(itm => itm.leadSourceId == sourceId).emails;
        values.floor = values.cmsSettings.find(itm => itm.leadSourceId == sourceId).floor;
        values.source = values.cmsSettings.find(itm => itm.leadSourceId == sourceId).source;
        values.dealershipID = values.cmsSettings.find(itm => itm.leadSourceId == sourceId).dealershipID;
        values.ixDealerId = values.ixSettings.find(itm => itm.leadSourceId == sourceId).ixDealerId;
        values.ref = values.ixSettings.find(itm => itm.leadSourceId == sourceId).ref;
        values.value = values.ixSettings.find(itm => itm.leadSourceId == sourceId).value;
    };

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">
                {!loading && Object.entries(LeadTypeEnum).map(([key, value]) => (
                    <Grid item xs={12}>
                        <Formik
                            initialValues={findByLeadType(value)}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                onSubmit(values)
                                setTimeout(() => {
                                    actions.setSubmitting(false)
                                }, 500)
                            }}
                            validationSchema={Yup.object().shape({
                                // make: Yup.string().required('Select a Make for the vehicle'),
                                // model: Yup.string().required('Select a Model for the vehicle'),
                                // title: Yup.string().required('Enter a title for the Range option'),
                                // image: Yup.string().required('Vehicle image is required')
                            })}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    isSubmitting,
                                    handleReset,
                                    setFieldValue
                                } = props

                                return (
                                    <Form>                                       
                                        <Grid
                                            spacing={4}
                                            container
                                            direction="row"
                                        >
                                            <Grid item xs={12} md={6}>

                                                <Typography variant="h6" gutterBottom>
                                                    {key}
                                                </Typography>
                                       
                                                <TextField
                                                    fullWidth
                                                    name="defaultDealerEmails"
                                                    //required
                                                    id="defaultDealerEmails"
                                                    label="Emails "
                                                    value={values.defaultDealerEmails}
                                                    type="text"
                                                    helperText={errors.defaultDealerEmails && touched.defaultDealerEmails ? errors.defaultDealerEmails : ''}
                                                    error={errors.defaultDealerEmails && touched.defaultDealerEmails}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                
                                                <TextField
                                                  select
                                                  fullWidth
                                                  name="selectedLeadSource"
                                                  //required
                                                  id="selectedLeadSource"
                                                  label="Source"
                                                  value={values.selectedLeadSource}
                                                  type="text"
                                                  helperText={errors.selectedLeadSource && touched.selectedLeadSource ? errors.selectedLeadSource : ''}
                                                  error={errors.selectedLeadSource && touched.selectedLeadSource}
                                                  onChange={(event, child) => {
                                                      onLeadSourceChanged(values, event, child); 
                                                      handleChange(event)
                                                    }}
                                                  onBlur={handleBlur}
                                                >
                                                    {leadSources.map((option) => (
                                                        <MenuItem key={option.id} id={option.id} value={option}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}                                                
                                                </TextField>
                                             
                                                {values.selectedLeadSource && values.selectedLeadSource.id >= 0 &&  <>

                                                    <Grid item xs={12}>
                                                        <br/>
                                                        <Typography variant="subtitle1" style={{ flex: '1 1' }} component="div" className={classes.title}>
                                                            OVERRIDE EMAILS
                                                        </Typography>
                                                        <br/>
                                                    </Grid>

                                                    <TextField
                                                        fullWidth
                                                        name="overrideEmails"
                                                        //required
                                                        id="overrideEmails"
                                                        label="Emails "
                                                        value={values.overrideEmails}
                                                        type="text"
                                                        helperText={errors.overrideEmails && touched.overrideEmails ? errors.overrideEmails : ''}
                                                        error={errors.overrideEmails && touched.overrideEmails}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />

                                                    <Grid item xs={12}>
                                                        <br/>
                                                        <Typography variant="subtitle1" style={{ flex: '1 1' }} component="div" className={classes.title}>
                                                            CMS
                                                        </Typography>
                                                        <br/>
                                                    </Grid>

                                                    <TextField
                                                        fullWidth
                                                        name="floor"
                                                        //required
                                                        id="floor"
                                                        label="Floor"
                                                        value={values.floor}
                                                        type="text"
                                                        helperText={errors.floor && touched.floor ? errors.floor : ''}
                                                        error={errors.floor && touched.floor}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        name="source"
                                                        //required
                                                        id="source"
                                                        label="Source"
                                                        value={values.source}
                                                        type="text"
                                                        helperText={errors.source && touched.source ? errors.source : ''}
                                                        error={errors.source && touched.source}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        name="dealershipID"
                                                        //required
                                                        id="dealershipID"
                                                        label="Dealership Id"
                                                        value={values.dealershipID}
                                                        type="text"
                                                        helperText={errors.dealershipID && touched.dealershipID ? errors.dealershipID : ''}
                                                        error={errors.dealershipID && touched.dealershipID}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    
                                                    <Grid item xs={12}>
                                                        <br/>
                                                        <Typography variant="subtitle1" style={{ flex: '1 1' }} component="div" className={classes.title}>
                                                            IX
                                                        </Typography>
                                                        <br/>
                                                    </Grid>

                                                    <TextField
                                                        fullWidth
                                                        name="ixDealerId"
                                                        //required
                                                        id="ixDealerId"
                                                        label="Dealer Id"
                                                        value={values.ixDealerId}
                                                        type="number"
                                                        helperText={errors.ixDealerId && touched.ixDealerId ? errors.ixDealerId : ''}
                                                        error={errors.ixDealerId && touched.ixDealerId}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        name="ref"
                                                        //required
                                                        id="ref"
                                                        label="Ref"
                                                        value={values.ref}
                                                        type="text"
                                                        helperText={errors.ref && touched.ref ? errors.ref : ''}
                                                        error={errors.ref && touched.ref}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        name="value"
                                                        //required
                                                        id="value"
                                                        label="Value"
                                                        value={values.value}
                                                        type="text"
                                                        helperText={errors.value && touched.value ? errors.value : ''}
                                                        error={errors.value && touched.value}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />

                                                </>
                                                }

                                            </Grid>
                                            
                                            <Grid item container justify='flex-end' xs={12}>                                               
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    )
}