import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory, useParams} from 'react-router-dom';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from '@material-ui/core/AppBar';
import {Box} from '@material-ui/core'
import UserAdminForm from './UserAdminForm'
import SMTPSettingsForm from './SMTPSettingsForm'
import SMSSettingsForm from './SMSSettingsForm'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  }
}));


export default function UserEditContainer({match}) {
  const classes = useStyles();
  let history = useHistory();
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if(!id) history.push(`/admin/users`)
  }, [id])

  const tabs = [
    { id: 0, label: 'User',  comp: <UserAdminForm history={history} match={match} />},
    { id: 1, label: 'Mail Settings', comp: <SMTPSettingsForm />},
    { id: 2, label: 'SMS Settings', comp: <SMSSettingsForm />}
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={handleChange} aria-label="users tabs">
          <Tab disableRipple label="User"/>
          <Tab disableRipple label="SMTP Settings "/>
          <Tab disableRipple label="SMS Settings"/>
        </Tabs>
      </AppBar>
      <Box mt={4}>
        {
          tabs.filter(tab=>!tab.hide).map((step, index) => {
            return <React.Fragment key={step.id}> {tabIndex === index ? step.comp: null} </React.Fragment>
          })
        }
      </Box>
    </div>
  );
}