import React from 'react';
import { 
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { StyledDialogTitle } from './StyledDialog'

export default function ConfirmationDialog({open, closeDialog, confirm, action, secondaryInfo, isBusy}) {

  return (
    <Dialog maxWidth='xs' onClose={closeDialog} open={open}>
      <StyledDialogTitle onClose={closeDialog}>
        {action.toUpperCase()}
      </StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Do you wish to {action}{secondaryInfo?.length > 0 ? ` ${secondaryInfo}` : ""}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog} disabled={isBusy}>Cancel</Button>
        <Button variant="contained" onClick={confirm} color="primary">
          {
            isBusy 
            ? <CircularProgress />
            : "Confirm"
          }
        </Button>
      </DialogActions>
    </Dialog>
  )

}
