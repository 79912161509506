import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    button: {
        marginLeft: "2rem",
        backgroundColor:"#04872d"
    },
}));

export default function DeliveredContainer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <Grid container direction="row">
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Move to Paid Out
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
