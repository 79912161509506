import React, { useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Box,
    Button,
    Paper,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { BASE_URI } from '../../shared/Constants'
import { TailSpin } from 'react-loader-spinner';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    featureInputContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    ItemHolder: {
        background: '#bdbfc1',
        padding: '20px',
        borderRadius: '5px'
    },
    Item: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px'
    },
    AddDealer: {
        display: 'flex',
    },
    ItemDlt: {
        color: 'red',
        fontWeight: 'bold',
        fontSize: "18px"
    },
    PopUp: {
        position: 'absolute',
        top: '0',
        zIndex: '555',
        background: '#030303bd',
        width: '100%',
        left: '0',
        bottom: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(1px)',
    },
    PopUpCard: {
        background: '#ffffff',
        padding: '20px',
        width: '30%',
        borderRadius: '5px',
    },
    btnGroup: {
        display: 'flex',
        justifyContent: 'end',
        marginTop: '40px'
    },
    btn: {
        background: '#3f51b5',
        padding: '6px 30px',
        border: 'none',

        color: 'white',
        borderRadius: '5px',
        "&:hover": {
            boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }
    },
    btnPopUp: {
        background: '#3f51b5',
        padding: '6px 30px',
        border: 'none',
        marginLeft: '10px',
        color: 'white',
        borderRadius: '5px',
        "&:hover": {
            boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }
    },
    bold: {
        fontWeight: 'bolder'
    },
    Addbtn: {
        borderRadius: '5px',
        whiteSpace: 'nowrap',
        marginLeft: '20px',
        background: '#3f51b5',
        border: 'none',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        "&:hover": {
            boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }
    }
    , td: {
        width: 'calc(100%/4)',
        minWidth: 'calc(100%/4)',
        textAlign: 'left',
    },
    th: {
        width: 'calc(100%/4)',
        minWidth: '200px',
        textAlign: 'left',
    },

}));

export default function DealerStaff({ history, data }) {

    const [departments, setDepartments] = useState([]);
    const [departmentName, setDepartmentName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [jobTitles, setJobTitles] = useState([]);
    const [confirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {

        getData();

    }, []);

    const getData = () => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'GET',
            url: `${BASE_URI}/DealerStaff/Department`,
            cancelToken: source.token
        }).then((departmentsResponse) => {

            if (departmentsResponse?.data) {
                setDepartments(departmentsResponse?.data);
                setLoading(false)
            }

        })
            .catch((err) => {

                enqueueSnackbar("Error", err, { variant: 'error' });
            });

        axios({
            method: 'GET',
            url: `${BASE_URI}/DealerStaff/JobTitle`,
            cancelToken: source.token
        }).then((response) => {

            if (response?.data) {
                setJobTitles(response?.data);
            }

        }).catch((err) => {

            enqueueSnackbar("Error", err, { variant: 'error' });
        });
    }

    const AddDepartment = () => {

        if (departmentName) {
            setDepartmentName("")

            let department = {
                "name": departmentName
            }

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            if (!departments.filter((j) => j.name == departmentName).length >= 1) {

                axios({
                    method: 'POST',
                    url: `${BASE_URI}/DealerStaff/Department`,
                    data: department,
                    cancelToken: source.token
                }).then((response) => {

                    setDepartments([...departments, response.data])
                    enqueueSnackbar("Added" + " " + departmentName, { variant: 'success' });
                })
            } else {
                enqueueSnackbar(departmentName + " " + "Already Exists", { variant: 'Error' });
            }

        } else {

        }

    }


    const AddJobTitle = () => {
        if (jobTitle) {
            setJobTitle("")

            let Title = {
                "name": jobTitle
            }

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            if (!jobTitles.filter((j) => j.name == jobTitle).length >= 1) {

                axios({
                    method: 'POST',
                    url: `${BASE_URI}/DealerStaff/JobTitle`,
                    data: Title,
                    cancelToken: source.token
                }).then((response) => {
                    setJobTitles([...jobTitles, response.data])
                    enqueueSnackbar("Added" + " " + jobTitle, { variant: 'success' });
                })

            } else {
                enqueueSnackbar(jobTitle + " " + "Already Exists", { variant: 'Error' });
            }
        } else {

        }

    }

    const DltDepartment = (id) => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'DELETE',
            url: `${BASE_URI}/DealerStaff/Department/${id}`,
            cancelToken: source.token
        }).then((response) => {

            if (response.data == true) {
                setDepartments(departments.filter((d) => d.id !== id))
                enqueueSnackbar("Deleted" + " " + active.name, { variant: 'success' });
            }

        }).catch((err) => {

            enqueueSnackbar("Error", { variant: 'error' });
        })
    }

    const DltJobTitle = (id) => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'DELETE',
            url: `${BASE_URI}/DealerStaff/JobTitle/${id}`,
            cancelToken: source.token
        }).then((response) => {

            if (response.data == true) {
                setJobTitles(jobTitles.filter((j) => j.id !== id))
                enqueueSnackbar("Deleted" + " " + active.name, { variant: 'success' });
            }

        }).catch((err) => {

            enqueueSnackbar("Error", { variant: 'error' });
        })
    }

    const handlePopUp = (type, value) => {

        value.type = type
        setActive(value)
        setConfirmation(true)

    }


    const handlePopUpResponse = (e) => {

        let answer = e.target.value

        switch (active.type) {
            case "department":
                if (answer == 'Yes') {
                    DltDepartment(active.id)
                    setConfirmation(false)
                } else (
                    setConfirmation(false)
                )
                break;
            case "job":
                if (answer == 'Yes') {
                    DltJobTitle(active.id)
                    setConfirmation(false)

                } else (
                    setConfirmation(false)
                )
                break;
            default:
                break;
        }

    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">
                {confirmation &&
                    <div className={classes.PopUp}>
                        <div className={classes.PopUpCard}>
                            <Typography variant="body1" gutterBottom>
                                Are you sure you want to delete <span className={classes.bold}>{active.name}</span> ?
                            </Typography>

                            <div className={classes.btnGroup}>
                                <Button
                                    className={classes.btnPopUp}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => { handlePopUpResponse(e) }}
                                    value="Yes"
                                >
                                    Yes
                                </Button>
                                <Button
                                    className={classes.btnPopUp}
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => { handlePopUpResponse(e) }}
                                    value="No"
                                >
                                    No
                                </Button>

                            </div>
                        </div>
                    </div>
                }
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Departments
                    </Typography>

                    <Box mt={2} className={classes.AddDealer}>
                        <TextField
                            fullWidth
                            name="selectedOption"
                            required
                            id="selectedOption"
                            label="Add A Department"
                            value={departmentName}
                            type="text"
                            style={{ margin: '0px' }}
                            onChange={(e) => { setDepartmentName(e.target.value) }}
                        />

                        <Button

                            variant="contained"
                            color="primary"
                            type="sumbit"
                            className={classes.Addbtn}
                            onClick={(e) => { AddDepartment(e) }}
                        >
                            Add Department <span style={{ fontSize: '1.2rem', marginLeft: '5px' }}>+</span>
                        </Button>

                    </Box>

                    {!loading ?
                        <Box mt={2} style={{ overflow: 'auto' }}>
                            <table border='1' cellPadding='5px' width="100%" >
                                <tbody>

                                    <tr>
                                        <th className={classes.th} style={{ width: '95%' }}>Department Name</th>
                                        <th className={classes.th} style={{ width: '5%' }}>Delete</th>
                                    </tr>
                                    {departments?.map((department) => {
                                        return (
                                            <tr>
                                                <td >


                                                    <Typography variant="body1" gutterBottom>
                                                        {department?.name}
                                                    </Typography>
                                                </td>



                                                <td >
                                                    <div onClick={() => { handlePopUp("department", department) }}>
                                                        <Button

                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.btn}
                                                            value="No"
                                                        >
                                                            Delete
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>


                                        )
                                    })}
                                </tbody>
                            </table>
                        </Box>
                        : <div style={{ display: 'flex', justifyContent: 'center' }}><TailSpin ariaLabel="loading-indicator" color='#3f51b5' /></div>}
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Job Titles
                    </Typography>

                    <Box mt={2} className={classes.AddDealer}>
                        <TextField
                            fullWidth
                            name="selectedOption"
                            required
                            id="selectedOption"
                            label="Add A Job Title"
                            value={jobTitle}
                            type="text"
                            style={{ margin: '0px' }}
                            onChange={(e) => { setJobTitle(e.target.value) }}
                        />
                        <Button

                            variant="contained"
                            color="primary"
                            type="sumbit"
                            className={classes.Addbtn}
                            onClick={() => { AddJobTitle() }}
                        >
                            Add Job Title <span style={{ fontSize: '1.2rem', marginLeft: '5px' }}>+</span>
                        </Button>

                    </Box>
                    {!loading ?
                        <Box mt={2} >
                            <table border='1' cellPadding='5px' width="100%" >
                                <tbody>

                                    <tr>
                                        <th className={classes.th} style={{ width: '90%' }}>Job Titles</th>
                                        <th className={classes.th} >Delete</th>
                                    </tr>
                                    {jobTitles?.map((job) => {
                                        return (

                                            <tr>
                                                <td >
                                                    <Typography variant="body1" gutterBottom>

                                                        {job?.name}
                                                    </Typography>
                                                </td>



                                                <td >
                                                    <div onClick={() => { handlePopUp("job", job) }}>
                                                        <Button

                                                            variant="contained"
                                                            color="primary"

                                                            className={classes.btn}
                                                            value="No"
                                                        >
                                                            Delete
                                                        </Button>

                                                    </div>


                                                </td>
                                            </tr>


                                        )
                                    })}
                                </tbody>
                            </table>
                        </Box>
                        : <div style={{ display: 'flex', justifyContent: 'center' }}><TailSpin ariaLabel="loading-indicator" color='#3f51b5' /></div>}
                </Grid>

            </Grid>
        </Paper>
    )
}
