import { useState, useEffect } from "react";
import axios from 'axios';
import { BASE_URI } from "../../../../shared/Constants";
// import moment from 'moment'

export default function SMSController(id) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setData([]);
  }, [id]);

  const sampleData = [
    {
      status: "DELIVRD",
      heading: "Nonhlanhla Mokoena - 15/Sep/2020",
      body:
        "Nikkita Beehari, we are delighted to inform you that your application has been submitted to the banks. Your personal Finance Manager will keep you posted as we progress!",
    },
    {
      status: "FAILED",
      heading: "Nonhlanhla Mokoena - 15/Sep/2020",
      body:
        "Nikkita Beehari, we are delighted to inform you that your application has been submitted to the banks. Your personal Finance Manager will keep you posted as we progress!",
    },
  ];

  useEffect(() => {
    let cancel;
    setLoading(true);
    setError(false);
    const params = {
    };

     axios({
       method: 'GET',
         url: `${BASE_URI}/messageLog/lead/${id}/type/1`,
       params,
       cancelToken: new axios.CancelToken(c => cancel = c)
     }).then((response) => {
       if (response.data) {
           setData(response.data)
       }
       setTotal(response.data.length)
       setLoading(false)
     }).catch( error => {
       if(axios.isCancel(error)) return
       if (error.response) {
         setError(true)
       }
       setLoading(false)
     })
    
    return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { loading, error, data, total };
}
