import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    Divider,
    Paper,
    Fab
} from '@material-ui/core'
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup'
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from "react-router-dom";
import { BASE_URI } from '../../shared/Constants'
import ImageUploadInput from '../../shared/ImageUploadFormInput'
import { CurrencyInput } from '../../shared/TextMask'
import { TextField as TextFieldFormik } from "formik-material-ui";
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from "notistack";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { values } from 'lodash';


const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    featureInputContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export default function DealerWebsiteAdminForm({ history, data }) {
    const initialValues = {
        image: '',
        title: '',
        subTitle: '',
        websiteUrl: '',
        bannerTitle: '',
        bannerSubtitle: '',
        priceRange: '',
        bannerImage: '',
        copyright: '',
        links: []
    }
    const { id } = useParams();
    const isAddMode = !id;
    const [formValues, setFormValues] = useState(data ? data : initialValues)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const mountedRef = useRef(true)
    const classes = useStyles();
    let boundArrayHelpers;
    // const [disableModel, setDisabledModel] = useState(false)

    useEffect(() => {
        if (data) {
            setFormValues(data)
        }
    }, [data])


    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getWebsiteConfig = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/DealerWebsites/${id}`, {
                    cancelToken: source.token
                })

                setFormValues({ ...result.data })

            } catch (error) {
                // addAlert('Unables to load vehicle categories')
                //history.push('..')
            }
        }
        getWebsiteConfig().then(() => {
            if (!mountedRef.current) return null
        })
        return () => {
            mountedRef.current = false
            source.cancel();
        };
    }, []);

    function onSubmit(fields) {
        console.log('values', fields)
        if (id) {
            editDealer(id, fields)
        }
    }

    const editDealer = async (id, fields) => {

        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.put(`${BASE_URI}/DealerWebsites/${id}`, fields, {
                cancelToken: source.token,
            });

            enqueueSnackbar("Successfully updated Dealer Website", { variant: "success" });
            window.history.back();

            return result;
        } catch (error) {
            //enqueueSnackbar(error.data.message, {variant: 'error'});
        } finally {
            //setSubmitting(false);
        }
    };

    const bindArrayHelpers = (arrayHelpers) => {
        boundArrayHelpers = arrayHelpers
    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">
                <Grid item xs={12}>
                    <Formik
                        initialValues={formValues}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            onSubmit(values)
                            setTimeout(() => {
                                actions.setSubmitting(false)
                            }, 500)
                        }}
                        validationSchema={Yup.object().shape({
                            // make: Yup.string().required('Select a Make for the vehicle'),
                            // model: Yup.string().required('Select a Model for the vehicle'),
                            // title: Yup.string().required('Enter a title for the Range option'),
                            // image: Yup.string().required('Vehicle image is required')
                        })}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                handleReset,
                                setFieldValue
                            } = props

                            return (
                                <Form>
                                    <Typography variant="h6" gutterBottom>
                                        {isAddMode ? 'Create' : 'Edit'} Website Config
                                    </Typography>
                                    <Grid
                                        spacing={4}
                                        container
                                        justify="space-around"
                                        direction="row"
                                    >

                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ flex: '1 1' }} component="div">
                                                Navbar:
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <ImageUploadInput title='Logo'
                                                required
                                                value={values.image}
                                                fieldName={'image'}
                                                error={errors.image && touched.image} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>

                                            <TextField
                                                fullWidth
                                                name="websiteUrl"
                                                required
                                                id="websiteUrl"
                                                label="Website URL"
                                                value={values.websiteUrl}
                                                type="text"
                                                helperText={
                                                    errors.website && touched.website ? errors.website : ''
                                                }
                                                error={errors.website && touched.website}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            <TextField
                                                fullWidth
                                                name="title"
                                                required
                                                id="title"
                                                label="Title"
                                                value={values.title}
                                                type="text"
                                                helperText={
                                                    errors.title && touched.title ? errors.title : ''
                                                }
                                                error={errors.title && touched.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            <TextField
                                                fullWidth
                                                name="subTitle"
                                                required
                                                id="subTitle"
                                                label="Subtitle"
                                                value={values.subTitle}
                                                type="text"
                                                helperText={
                                                    errors.subTitle && touched.subTitle ? errors.subTitle : ''
                                                }
                                                error={errors.subTitle && touched.subTitle}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" style={{ flex: '1 1' }} component="div">
                                                Banner:
                                            </Typography>
                                        </Grid>


                                        <Grid item xs={12} md={6}>
                                            <ImageUploadInput title='Banner Image'
                                                fieldName={'bannerImage'}
                                                error={errors.bannerImage && touched.bannerImage} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="bannerTitle"
                                                required
                                                id="bannerTitle"
                                                label="Banner Title"
                                                value={values.bannerTitle}
                                                type="text"
                                                helperText={
                                                    errors.bannerTitle && touched.bannerTitle ? errors.bannerTitle : ''
                                                }
                                                error={errors.bannerTitle && touched.bannerTitle}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            <TextField
                                                fullWidth
                                                name="bannerSubtitle"
                                                required
                                                id="bannerSubtitle"
                                                label="Banner Subtitle"
                                                value={values.bannerSubtitle}
                                                type="text"
                                                helperText={
                                                    errors.bannerSubtitle && touched.bannerSubtitle ? errors.bannerSubtitle : ''
                                                }
                                                error={errors.bannerSubtitle && touched.bannerSubtitle}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            <TextField
                                                fullWidth
                                                required
                                                name="priceRange"
                                                id="priceRange"
                                                label="Price Range"
                                                value={values.priceRange}
                                                onChange={handleChange}
                                                placeholder={'eg. 200 000'}
                                                error={errors.priceRange && touched.priceRange}
                                                helperText={errors.priceRange && touched.priceRange ? errors.priceRange : ''}
                                                InputProps={{
                                                    inputComponent: CurrencyInput
                                                }}
                                            />

                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item container justify="space-between" xs={12}>
                                            <Typography variant="subtitle1" component="div">
                                                Footer:
                                            </Typography>
                                            <Fab variant="extended" size="small"
                                                color="primary" aria-label="add"
                                                disabled={isSubmitting}
                                                onClick={() => boundArrayHelpers.insert(0, {
                                                    name: '',
                                                    link: ''
                                                })}>
                                                <AddIcon />
                                                Add Link
                                            </Fab>
                                        </Grid>

                                        <Grid item style={{ paddingTop: 0 }} xs={12} md={4}>
                                            <TextField
                                                fullWidth
                                                name="copyright"
                                                required
                                                id="copyright"
                                                label="Copyright Message"
                                                value={values.copyright}
                                                type="text"
                                                helperText={
                                                    errors.copyright && touched.copyright ? errors.copyright : ''
                                                }
                                                error={errors.copyright && touched.copyright}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>

                                        <Grid item xs={false} md={8} />

                                        <Grid item xs={12}>
                                            {
                                                <FieldArray
                                                    name="links"
                                                    render={arrayHelpers => {
                                                        bindArrayHelpers(arrayHelpers)
                                                        return (
                                                            <Grid container spacing={2} direction="row">
                                                                <Grid item xs={12}>
                                                                    <Grid item
                                                                        style={{ padding: 0 }}
                                                                        xs={12}>
                                                                        <Typography variant="subtitle2">
                                                                            Links:
                                                                        </Typography>
                                                                        {(touched.links && errors.links) && <p className={classes.errorText}>{errors.links}</p>}
                                                                    </Grid>
                                                                </Grid>
                                                                {values.links && values.links.length > 0 ? (
                                                                    values.links.map((link, index) => {
                                                                        return (
                                                                            <Grid item xs={12} key={index} md={4} container direction="row">
                                                                                <Grid item xs={12} >
                                                                                    <div className={classes.featureInputContainer}>
                                                                                        <Field name={`links.${index}.name`}
                                                                                            label="Name"
                                                                                            component={TextFieldFormik} />
                                                                                        <Field name={`links.${index}.link`}
                                                                                            label="Link"
                                                                                            style={{ marginLeft: '1rem' }}
                                                                                            component={TextFieldFormik} />
                                                                                        <IconButton aria-label={`Delete link`}
                                                                                            onClick={() => arrayHelpers.remove(index)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                ) : (
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="subtitle1">
                                                                            No links added
                                                                        </Typography>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        )
                                                    }}
                                                />
                                            }
                                        </Grid>



                                        <Grid item container
                                            justify='flex-end' xs={12}>
                                            <Box mr={3}>
                                                <Button
                                                    onClick={() => {
                                                        handleReset();
                                                        window.history.back();
                                                    }}>
                                                    Cancel
                                                </Button>
                                            </Box>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </Paper>
    )
}