import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumbs, Button, CircularProgress, Grid, Link, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { Table, TableContainer, TableBody, TablePagination } from "@material-ui/core";
import { ArrowBackIos, Clear, Edit,Add } from '@material-ui/icons';
import axios from "axios";
import { useSnackbar } from "notistack";
import { BASE_URI } from "../../shared/Constants";
import { useStyles, StyledTableRow, StyledTableCell } from '../../shared/CommonStyles';
import { GetErrorMessage, AddDateStringDelimiter } from '../../shared/CommonFunctions';
import TableHeadersSort from "../../shared/TableHeadersSort";
import { CurrencyValue } from '../../shared/TextMask'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function VehicleLibraryVariants() {
    
    const classes = useStyles({ stickyHeaderTableHeight: window.innerHeight });
    const history = useHistory();
    const { makeId, modelId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [ loading, setLoading ] = useState(true);
    const [ make, setMake ] = useState({ id: makeId });
    const [ model, setModel ] = useState({ id: modelId });
    const [ variants, setVariants ] = useState([]);
    const [ searchString, setSearchString ] = useState("");

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [showCustomForm, setShowCustomForm] = useState(null);
    const [addVariantResponse, setAddVariantResponse] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const headCells = [
        { id: "name", label: "Variant Name", sort: true, width: "25%" },
        { id: "mmCode", label: "M&M Code", sort: true, align: "center" },
        { id: "price", label: "Price", sort: true, align: "center" },
        { id: "introYear", label: "Introduction Year", sort: true, align: "center" },
        { id: "discontinueYear", label: "Discontinue Year", sort: true, align: "center" },
        { id: "fuelType", label: "Fuel Type", sort: true, align: "center" },
        { id: "transmission", label: "Transmission", sort: true, align: "center" },
        { id: "actions", label: "Actions", sort: false, align: "center" }
    ];

    useEffect(() => {
    
        Promise.allSettled([
            LoadMakeDetails(),
            LoadModelDetails(),
            LoadVariants()
        ])
        .catch((error) => {

			enqueueSnackbar(GetErrorMessage(error, "An unexpected error occurred while loading the master variants"), { variant: 'error' });

        }).finally(() => {

            setLoading(false);

        });
        
    }, []);

    function LoadMakeDetails() {

        return new Promise((res, rej) => {

            axios({
                method: 'GET',
                url: `${BASE_URI}/MasterMake/${make.id}`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                if (!response?.data) {

                    enqueueSnackbar("No master make details could be found", { variant: 'warning' });
                    res(null);
                    return;

                }

                let makeDetails = {
                    id: make.id,
                    name: response?.data?.name
                };
                
                setMake(makeDetails);
                res(makeDetails);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the master make details"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function LoadModelDetails() {

        return new Promise((res, rej) => {

            axios({
                method: 'GET',
                url: `${BASE_URI}/MasterModel/${model.id}`,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                if (!response?.data) {

                    enqueueSnackbar("No master model details could be found", { variant: 'warning' });
                    res(null);
                    return;

                }

                let modelDetails = {
                    id: model.id,
                    name: response?.data?.name
                };
                
                setModel(modelDetails);
                res(modelDetails);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the master model details"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function LoadVariants() {

        return new Promise((res, rej) => {

            let params = {
                masterModelId: modelId
            };

            axios({
                method: 'GET',
                url: `${BASE_URI}/MasterVariant`,
                params,
                cancelToken: axios.CancelToken.source().token
            }).then((response) => {
                
                let data = [
                    ...response?.data
                ];
                
                setVariants(data);
                res(data);
    
            }).catch(error => {
                
                if (axios.isCancel(error)) {
                    return;
                }
    
                enqueueSnackbar(GetErrorMessage(error, "Unable to load the master variants"), { variant: 'error' });
                rej(null);

            });

        });
    
    }

    function GoBack() {

        history.goBack();

    }

    function GoToMakes() {

        history.go(-2);

    }

    function EditVariant(id) {
        
        history.push(`/VehicleLibrary/Makes/${make.id}/Models/${model.id}/Variant/${id}/edit`, { variantsSearchString: searchString });

    }

    function AddVariant(id) {
        handleOpen(true)
        const SaveDealerCampaign = async () => {
            setLoading(true)
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            var params = {
                masterMakeId: parseInt(make?.id),
                masterModelId: parseInt(model?.id),
                masterVariantId: id
            }

            try {
                const result = await axios.post(
                    `${BASE_URI}/masterVariant/AddBaseVariant`,
                    {
                        cancelToken: source.token,
                        ...params,
                    }
                );

                if (result?.data != null) {
                    setAddVariantResponse(result?.data);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        SaveDealerCampaign();
        
    }

    function GetDisplayVariants() {

        try {
            
            // Filter, slice and sort the variants list
            let filteredVariants = !searchString 
                ? [ ...variants ]
                : variants.filter(m => 
                    m.name?.trim().toLowerCase().includes(searchString?.trim().toLowerCase()) ||
                    m.mmCode?.trim().toLowerCase().includes(searchString?.trim().toLowerCase())
                );

            let sortedVariants = filteredVariants.sort(GetComparator());

            let startIndex = page * pageSize;
            let slicedVariants = sortedVariants.slice(startIndex, startIndex + pageSize);

            return slicedVariants;

        } catch (error) {
            
            enqueueSnackbar(GetErrorMessage(error, "Failed to filter and sort the list"), { variant: 'error' });
            return variants;

        }   

    }

    function ClearSearchString() {

        setSearchString("");

    }

    function HandleChangeSearchString(event) {
        
        setSearchString(event?.target?.value);

    }

    function GetComparator() {

        return order === "asc"
            ? (a, b) => AscendingComparator(a, b)
            : (a, b) => -AscendingComparator(a, b);

    }

    function AscendingComparator(a, b) {

        return (a[orderBy] > b[orderBy]) ? 1 : -1;

    }

    function HandleRequestSort(event, property) {

        const isAsc = orderBy === property && order === "asc";

        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);

    }

    function HandleChangePage(event, newPage) {

        setPage(newPage);

    }

    function HandleChangeRowsPerPage(event) {

        setPageSize(parseInt(event?.target?.value || 25, 10));
        setPage(0);

    }

    const handleOpen = () => {
        setShowCustomForm(true);
    };

    const handleCustomFormClose = () => {
        setShowCustomForm(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Paper className={classes.paper}>
            <Dialog open={showCustomForm} onClose={handleClose} maxWidth={'md'} fullWidth={true} aria-labelledby="form-dialog-title" >
                    <DialogTitle id="form-dialog-title">Import Variant</DialogTitle>
                    <DialogContent >
                        {!loading && 
                        <DialogContentText>
                            {addVariantResponse ? addVariantResponse :'No response, please contact MDH Dev support'}
                        </DialogContentText>}
                        {loading &&
                        <Grid item xs={12} container direction="row" justify="center">
                            <CircularProgress/>
                        </Grid>}
                        
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCustomFormClose} dissabled={loading} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">

                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Link underline="hover" onClick={GoToMakes}>
                            {make?.name || "makes"}
                        </Link>
                        <Link underline="hover" onClick={GoBack}>
                            {model?.name || "models"}
                        </Link>
                    </Breadcrumbs>
                </Grid>

                <Grid item xs={12} container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={12} md={8} container spacing={1} direction="row" alignItems="center">
                        <Grid item className={classes.backArrowIcon}>
                            <ArrowBackIos color="primary" onClick={GoBack}/>
                        </Grid>

                        <Grid item>
                            <Typography variant="h4">
                                Master Variants
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id="variantsFilter"
                            label="Search Variants"
                            disabled={loading}
                            value={searchString}
                            onChange={HandleChangeSearchString}
                            InputProps={{
                                endAdornment: 
                                    searchString?.length > 0 &&
                                    <InputAdornment position="end">
                                        <IconButton size="small" disableFocusRipple disableRipple onClick={() => ClearSearchString()}>
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>

                {
                    loading &&
                    <Grid item xs={12} container direction="row" justify="center">
                        <CircularProgress/>
                    </Grid>
                }
                {
                    !loading &&
                    <Grid item xs={12}>
                        <TableContainer className={classes.stickyHeaderTable}>
                            <Table stickyHeader aria-label="Master Variants">
                                <TableHeadersSort
                                    order={order}
                                    orderBy={orderBy}
                                    headCells={headCells}
                                    onRequestSort={HandleRequestSort}
                                />
                                <TableBody>
                                    {
                                        (!variants?.length || variants.length === 0) && 
                                        <StyledTableRow hover>
                                            <StyledTableCell
                                                colSpan={headCells.length}
                                                align="center"
                                                style={{ borderBottom: 0 }}
                                                className="py-3"
                                            >
                                                <Typography variant="h6" color="textSecondary">
                                                    No Master Variants Available
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }
                                    {
                                        GetDisplayVariants().map((row) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell>{row.name}</StyledTableCell>
                                                <StyledTableCell align="center">{row.mmCode}</StyledTableCell>
                                                <StyledTableCell align="center"><CurrencyValue value={row.price} /></StyledTableCell>
                                                <StyledTableCell align="center">{AddDateStringDelimiter(row.introYear)}</StyledTableCell>
                                                <StyledTableCell align="center">{AddDateStringDelimiter(row.discontinueYear)}</StyledTableCell>
                                                <StyledTableCell align="center">{row.fuelType}</StyledTableCell>
                                                <StyledTableCell align="center">{row.transmission}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Grid container spacing={2} direction="row" justify="center">
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                style={{ margin: "1rem" }}
                                                                onClick={() => EditVariant(row.id)}
                                                            >
                                                                <Edit />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell><StyledTableCell align="center">
                                                    <Grid container spacing={2} direction="row" justify="center">
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                style={{ margin: "1rem" }}
                                                                onClick={() => AddVariant(row.id)}
                                                            >
                                                                <Add />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                            <TablePagination
                                component="div"
                                count={variants?.length}
                                page={page}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onChangePage={HandleChangePage}
                                rowsPerPage={pageSize}
                                onChangeRowsPerPage={HandleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Grid>
                }

            </Grid>
        </Paper>
    );
}