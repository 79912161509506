import { useState, useEffect } from "react";
import axios from 'axios';
import { BASE_URI } from "../../../../shared/Constants";
// import moment from 'moment'

export default function EmailController(
  id,
  pageNumber,
  pageSize,
  order,
  orderBy
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [ranges, setRanges] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setRanges([]);
  }, [id, pageNumber, pageSize, order, orderBy]);

  const sampleData = [
    {
      id: 0,
      leadState: "Application Submitted",
      sentBy: "Nonhlanhla Mokoena",
      subject:
        "Pieter Goosen, we are delighted to inform you that your application has been submitted to the banks. Your personal Finance Manager will keep you posted as we progress!",
      message: "",
      to: "pietgoosenx@gmail.com",
      cc: "",
      created: "2020-09-17 12:40:08",
    },
    {
      id: 1,
      leadState: "Application Approved",
      sentBy: "Nonhlanhla Mokoena",
      subject: "Congratulations Pieter Goosen, your finance has been approved.",
      message:
        "Hello Pieter Goosen, I am delighted to inform you that your finance has been approved and we just require the following documentation: • Copy of your ID • Copy of your Drivers Licence • Latest 3 months bank statements • Latest 3 payslips You can email me the documents to docs@renaultretail.co.za or whatsapp them to 0685049549 Renault Regards, Nonhlanhla Mokoena",
      to: "pietgoosenx@gmail.com",
      cc: "",
      created: "2020-09-17 12:40:08",
    },
  ];

  useEffect(() => {
    let cancel;
    setLoading(true);
      setError(false);

    const params = {
      //id,
      //pageNumber: pageNumber + 1,
      //pageSize,
    };
    //if (order) {
    //  params.sortOrder = order.toUpperCase();
    //}
    //if (orderBy) {
    //  params.sortColumn = orderBy;
    //}
     axios({
       method: 'GET',
       url: `${BASE_URI}/messageLog/lead/${id}/type/2`,
       params,
       cancelToken: new axios.CancelToken(c => cancel = c)
     }).then((response) => {
       if (response.data) {
         setRanges(response.data)
         }

         setTotal(response.data.length)
         setLoading(false)
     }).catch( error => {
       if(axios.isCancel(error)) return
       if (error.response) {
         setError(true)
       }
       setLoading(false)
     })

     return () => cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageNumber, pageSize, order, orderBy]);

  return { loading, error, ranges, total };
}
