import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingCardComponent() {
  return (
    <Card elevation={5}>
      <CardContent>
        <Grid style={{width: '100%', paddingTop: '1rem', paddingBottom: '1rem'}} container justify='center' alignItems='center'>
          <Grid item xs={3} container justify='center' alignItems='center'>
            <CircularProgress size={90} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}