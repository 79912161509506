import React, { useState, useContext, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { Chip, Input, InputLabel, Select, FormControl, Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableHeadersSort from "../../shared/TableHeadersSort";
import { BASE_URI, DEFAULT_PAGE_SIZE } from "../../shared/Constants";
import Leads from "./LeadsFilter";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core";

import ExportContainer from "../../shared/ExportContainer";
import Divider from "@material-ui/core/Divider";
import { useHistory, useParams, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import OpenInNew from "@material-ui/icons/OpenInNew";
import FilterContainer from "./FilterContainer";
import Grid from "@material-ui/core/Grid";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import axios from "axios";
import { LeadMainContext } from "../../shared/contexts/LeadMainContext";
import { DealerContext } from "../../shared/DealerContext";
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
            width: "45ch",
        },
    },
    export: {
        width: "45ch",
    },
    addLeadButton: {
        marginLeft: "60%",
        textDecoration: 'none'
    },
    stepPadding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    buttonFlagged: {
        backgroundColor: "red",
    },
    buttonDisabled: {
        cursor: 'not-allowed'
    }, buttonAllowed: {
        cursor: 'pointer',
        backgroundColor: "light-green",
    }
}));

const getStyles = (arrayItem, selectArray, theme) => {
    return {
        fontWeight: selectArray.indexOf(arrayItem) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

export default function LeadsListContainer() {

    const classes = useStyles();
    const theme = useTheme();
    const { state } = useParams();

    let { ...context } = useContext(LeadMainContext);
    let { ...dealerContext } = useContext(DealerContext);
    const { enqueueSnackbar } = useSnackbar();

    const allMotorgroupOption = {
        id: -1,
        name: "All"
    };

    const [page, setPage] = useState(0);
    const [id, setID] = useState(state);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("DateUpdated");

    const [selectedDate, setSelectedDate] = React.useState(context.dates);
    const [names, setNames] = useState("");
    const [stateOfRequest, setStateOfRequest] = useState(state);
    const [selectedMotorgroup, setSelectedMotorgroup] = useState({});
    const [selectedDealers, setSelectedDealers] = useState([]);
    const [selectedDealerIDs, setSelectedDealerIDs] = useState([]);
    const [source, setSource] = useState("");
    const [leadName, setLeadName] = useState("");
    const [namesList, setNamesList] = useState([]);
    const [stateOfRequestList, setStateOfRequestList] = useState([]);
    const [motorgroupList, setMotorgroupList] = useState([allMotorgroupOption]);
    const [dealerList, setDealerList] = useState(dealerContext?.dealerArr);
    const [dealerListFiltered, setDealerListFiltered] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);

    let { loading, ranges, fullRange, total } = Leads(
        id,
        page,
        pageSize,
        order,
        orderBy,
        stateOfRequest,
        selectedDate,
        source,
        names,
        leadName,
        dealerContext.selectedMotorGroup.motorgroupID,
        dealerContext.isMasterDealer ? null : dealerContext.dealerId,
        selectedDealerIDs
    );

    const handleNameChange = (event) => {
        setNames(event.target.value);
    };

    const handleStateChange = (event) => {
        setStateOfRequest(event.target.value);

        let path = `/leads/${event.target.value}`;
        history.push(path);
    };

    const handleDateChange = (date) => {
        setSelectedDate(new Date(date));
    };

    const handleSourceChange = (event) => {
        setSource(event.target.value);
    };

    const handleLeadNameChange = (event) => {
        setLeadName(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSelectedDealersChange = (event) => {
        const value = event?.target?.value;
        let selectedDealerNames = typeof value === 'string' ? value.split(',') : value;

        setSelectedDealers(selectedDealerNames);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const headCells = [
        { id: "actions", numeric: false, label: "Actions", sort: true },
        { id: "actions2", numeric: false, label: "", sort: false },
        { id: "actions3", numeric: false, label: "", sort: false },
        { id: "TypeCode", numeric: false, label: "State", sort: true },
        { id: "Name", numeric: false, label: "Name", sort: true },
        { id: "PhoneNumber", numeric: false, label: "Phone", sort: true },
        { id: "VIN", numeric: false, label: "Type", sort: true },
        { id: "Source", numeric: false, label: "Source", sort: true },
        { id: "Agent", numeric: false, label: "Agent", sort: false },
        { id: "DateUpdated", numeric: false, label: "Updated", sort: true }
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    useEffect(() => {

        getUsers();
        //getDealers();
        getStates();
        getSources();

        if (dealerContext?.dealerName?.length > 0) {
            setSelectedDealers([dealerContext.dealerName]);
        }

    }, []);

    useEffect(() => {
        setDealerList(dealerContext?.dealerArr)
    }, [dealerContext?.dealerArr])

    useEffect(() => {

        let filteredDealers = (selectedMotorgroup?.id >= 0) ? dealerList.filter(d => d.motorgroupID == selectedMotorgroup.id) : dealerList;

        setDealerListFiltered(filteredDealers);

    }, [selectedMotorgroup]);

    useEffect(() => {

        setDealerListFiltered(dealerList);
        setMotorgroupList(consolidateMotorgroups(dealerList));

    }, [dealerList]);

    useEffect(() => {

        assignSelectedDealerIDs();

    }, [dealerListFiltered, selectedDealers]);

    useEffect(() => {

        getUsers();

    }, [dealerContext.dealerId]);

    const getUsers = () => {
        let cancel;

        let params = {}
        params.dealerId = dealerContext.dealerId;

        return axios({
            method: 'GET',
            url: `${BASE_URI}/dealerAgent`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                setNamesList(response.data);
                return namesList;
            }
        });
    }

    const getDealers = () => {
        let cancel;
        return axios({
            method: 'GET',
            url: `${BASE_URI}/dealers`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setDealerList(response.data.list);
                return dealerList;
            }
        });
    }

    const getStates = () => {
        let cancel;
        return axios({
            method: 'GET',
            url: `${BASE_URI}/leadStatus`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                response.data.splice(0, 0, { id: -1, name: "All" });

                setStateOfRequestList(response.data);
                let temp = response.data.find(x => x.id == state);
                if (temp != null) {
                    context.setLeadState(temp)
                }

                return stateOfRequestList;
            }
        });
    }

    const getSources = () => {
        let cancel;
        return axios({
            method: 'GET',
            url: `${BASE_URI}/leadsources`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                setSourceList(response.data);
                return sourceList;
            }
        });
    }
    const sendToCms = (leadId, path) => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        setLoading(true)

        axios({
            method: 'POST',
            url: `${BASE_URI}/Leads/SendToCms/${leadId}`,
            cancelToken: source.token
        }).then((responseSecond) => {

            enqueueSnackbar(`Request sent`, { variant: 'success' });
            setLoading(false);

            history.push(path);
        }).catch(error => {
            if (axios.isCancel(error)) return;

            enqueueSnackbar("Unable to get complete the request", { variant: 'error' });

            setLoading(false)
        })
    }


    const assignSelectedDealerIDs = () => {

        let dealerIDs = [];

        if (!selectedDealers || selectedDealers.length <= 0 || !dealerListFiltered || dealerListFiltered.length <= 0) {
            setSelectedDealerIDs(dealerIDs);
            return;
        }

        for (let i = 0; i < selectedDealers.length; i++) {
            const dealerName = selectedDealers[i];
            const dealer = dealerListFiltered.find(d => d.name == dealerName);

            if (dealer) {
                dealerIDs.push(dealer.id);
            } else {
                selectedDealers.splice(i--, 1);
            }

        }

        setSelectedDealerIDs(dealerIDs);

    };

    const consolidateMotorgroups = (dealers) => {

        let tempMotorgroups = [allMotorgroupOption];

        if (!dealers || dealers.length <= 0) {
            return tempMotorgroups;
        }

        for (let i = 1; i < dealers.length; i++) {
            const dealer = dealers[i];

            if (!tempMotorgroups.some(m => m.id == dealer.motorgroupID)) {
                tempMotorgroups.push({
                    id: dealer.motorgroupID ?? 0,
                    name: dealer.motorGroupName || "Other"
                })
            }
        }

        return tempMotorgroups.sort((a, b) => b.id == -1 ? 1 : a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1);
    };

    const LeadTypeLookUp = (data) => {
        switch (data.leadTypeId) {
            case 1:
                data.leadTypeName = "New Vehicle"
                break;
            case 2:
                data.leadTypeName = "Used Vehicle"
                break;
            case 3:
                data.leadTypeName = "Contact Us"
                break;
            case 4:
                data.leadTypeName = "Contact Us"
                break;
            case 5:
                data.leadTypeName = "Service"
                break;
            case 6:
                data.leadTypeName = "Parts"
                break;
            case 7:
                data.leadTypeName = "Personal Loan"
                break;
            case 8:
                data.leadTypeName = "Test Drive"
                break;
            case 11:
                data.leadTypeName = "Trade In"
                break;

            default:
                break;
        }
    }

    return (
        <React.Fragment>

            <Grid container >
                <Grid item xs={12} md={4} lg={4} className={classes.stepPadding}>
                    <TextField
                        id="outlined-select-name"
                        select
                        value={names}
                        fullWidth
                        label="Filter By User:"
                        onChange={handleNameChange}
                        helperText="Please select user"
                    >
                        {/* TODO DO THE MAPPING */}
                        {namesList.map((option) => (
                            <MenuItem key={option.userId} value={option.userId}>
                                {option.fullName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={4} lg={4} className={classes.stepPadding}>
                    <TextField
                        id="outlined-select-state"
                        select
                        fullWidth
                        value={stateOfRequest}
                        label="Filter By State:"
                        onChange={handleStateChange}
                        helperText="Please select the state"
                    >
                        {/* TODO DO THE MAPPING */}
                        {stateOfRequestList.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={4} lg={4} className={classes.stepPadding}>
                    <TextField id="outlined-basic"
                        name="leadName" value={leadName}
                        onChange={handleLeadNameChange}
                        fullWidth
                        label="Lead name like" />
                </Grid>
                <Grid item xs={12} md={4} lg={4} className={classes.stepPadding}>
                    <TextField
                        id="outlined-select-state"
                        select
                        value={source}
                        fullWidth
                        label="Filter By Source:"
                        onChange={handleSourceChange}
                        helperText="Please select the source"
                    >
                        {/* TODO DO THE MAPPING */}
                        {sourceList.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={4} lg={4} className={classes.stepPadding}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DatePicker
                            label="Only show Leads (updated) since"
                            clearable
                            fullWidth
                            disableFuture
                            value={selectedDate}
                            onChange={handleDateChange}
                            animateYearScrolling
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={4} lg={4} className={classes.stepPadding}>
                    <TextField
                        id="outlined-select-state"
                        select
                        value={selectedMotorgroup?.name}
                        fullWidth
                        label="Filter By Motorgroup:"
                        helperText="Filter the dealers by motorgroup"
                        onChange={(e, c) => {
                            setSelectedMotorgroup(e?.target?.value);
                        }}
                    >
                        {motorgroupList.map((option) => (
                            <MenuItem key={option.id} value={option}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={classes.stepPadding}>
                    <FormControl fullWidth style={{ marginBottom: "2rem" }}>
                        <InputLabel id="selectedDealersLabel">Selected Dealers</InputLabel>
                        <Select
                            labelId="selectedDealersLabel"
                            id="selectedDealers"
                            fullWidth
                            multiple
                            value={selectedDealers}
                            onChange={handleSelectedDealersChange}
                            input={<Input id="selectDealersChip" label="Selected Dealers" />}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4.5 + 8,
                                        width: 250,
                                    }
                                },
                                variant: "menu",
                                getContentAnchorEl: null
                            }}
                            renderValue={(selected) => (
                                <Grid container spacing={1}>
                                    {selected.map(value => (
                                        <Grid item>
                                            <Chip
                                                key={value}
                                                label={<Typography style={{ whiteSpace: 'normal' }}>{value}</Typography>}
                                                style={{ height: "100%" }}
                                                clickable
                                                onMouseDown={(event) => event.stopPropagation()}
                                                onDelete={() => {
                                                    let startPos = selectedDealers.findIndex(d => d === value);
                                                    let newSelectedDealers = selectedDealers.slice(0);

                                                    newSelectedDealers.splice(startPos, 1);
                                                    setSelectedDealers(newSelectedDealers);
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        >
                            {dealerListFiltered.map((dealer) => (
                                <MenuItem key={dealer.id} value={dealer.name} style={getStyles(dealer.name, selectedDealers, theme)}>
                                    {dealer.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Divider
                variant="fullWidth"
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />

            <Grid container className={classes.stepPadding} direction="column" >
                <Grid item xs={12}>

                    <ExportContainer values={fullRange}
                        fileName='LeadsList'
                        headers={headCells} />
                    <Button
                        component={Link}
                        to="/addlead"
                        variant="contained"
                        color="primary"
                        className={classes.addLeadButton}
                        startIcon={<AddIcon />}
                    >
                        Add Lead
                    </Button>

                    <Divider
                        variant="fullWidth"
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    />

                    <TableContainer>
                        <Table aria-label="Agent Work Breakdown">
                            <TableHeadersSort
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {ranges.map((row) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell>
                                            <Button
                                                variant="contained"
                                                className={row.hasErrorLogs ? classes.buttonFlagged : ""}
                                                style={{ margin: "1rem" }}
                                                onClick={() => {
                                                    //${row.state.toLowerCase().replace(/\s/g, '')
                                                    //TODO CHANGE to stateID
                                                    let path = `/leadDetailsApplication/${state}/actions/${row.id}`;
                                                    history.push(path);
                                                }}
                                                type="submit"
                                            >
                                                {row.id}
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button
                                                variant="contained"
                                                style={{ margin: "1rem" }}
                                                onClick={() => {
                                                    window.open(`/leadDetailsApplication/${state}/actions/${row.id}`, '_blank');
                                                }}
                                                type="submit"
                                                startIcon={<OpenInNew />}
                                            ></Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button
                                                variant="contained"
                                                className={row.hasCmsErrorLogs ? classes.buttonAllowed : classes.buttonDisabled}
                                                style={{ margin: "1rem" }}
                                                disabled={!row.hasCmsErrorLogs}
                                                onClick={() => {

                                                    //${row.state.toLowerCase().replace(/\s/g, '')
                                                    //TODO CHANGE to stateID
                                                    let path = `/leadDetailsApplication/${state}/actions/${row.id}`;
                                                    sendToCms(row.id, path);
                                                }}
                                                type="submit"
                                            >
                                                {'send to cms'}
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>{row.state}</StyledTableCell>
                                        <StyledTableCell>{row.name}</StyledTableCell>
                                        <StyledTableCell>{row.phone}</StyledTableCell>
                                        <StyledTableCell>
                                            {LeadTypeLookUp(row)}
                                            {row?.leadTypeName}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.source}</StyledTableCell>
                                        <StyledTableCell>{row.agent}</StyledTableCell>
                                        <StyledTableCell>{row.dateUpdated}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                {(!ranges.length || ranges.length === 0) && !loading && (
                                    <StyledTableRow>
                                        <StyledTableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{ borderBottom: 0 }}
                                            className="py-3"
                                        >
                                            <Typography variant="h6" color="textSecondary">
                                                No Leads
                                            </Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                                {loading && (
                                    <StyledTableRow>
                                        <StyledTableCell
                                            colSpan={headCells.length}
                                            align="center"
                                            style={{ borderBottom: 0 }}
                                        >
                                            <CircularProgress />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={fullRange.length}
                            page={page}
                            rowsPerPageOptions={[5, 10, 25]}
                            onChangePage={handleChangePage}
                            rowsPerPage={pageSize}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                
                    </TableContainer>

                </Grid>
            </Grid>

        </React.Fragment>
    );
}
