import React, {useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useParams, useHistory} from "react-router-dom";
import {TabsContext} from "../../../shared/contexts/TabsContext";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {CardContent} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import {LeadMainContext} from "../../../shared/contexts/LeadMainContext";
import {DealerContext} from "../../../shared/DealerContext";
import Grid from "@material-ui/core/Grid";
import {blue} from "@material-ui/core/colors";
import axios from "axios";
import {BASE_URI} from "../../../shared/Constants";
// import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    stepPadding: {
        paddingLeft: "1rem",
    },
    avatar:{
        backgroundColor: blue[600],
    },
    displayCenter: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor:"lightgray"
    }
}));

export default function LeadDetailsContainer() {
    const classes = useStyles();
    const { state, tab, id} = useParams();
    const history = useHistory();

    let { ...tabContext } = useContext(TabsContext);
    let { setStateParam, setLeadIdParam, lead, leadState } = useContext(LeadMainContext);
    let { ...dealer } = useContext(DealerContext);

    useEffect(() => {

        setStateParam(state);
        setLeadIdParam(id);
        //setSelectedTab(tab);

        let temp = leadState.id.toString();

        switch (temp) {
            case "1":
                tabContext.getNewLead(lead.leadTypeId);
                break;
            case "2":
                tabContext.getPrequalifiedApproved(lead.leadTypeId);
                break;
            case "4":
                tabContext.getPrequalifiedDeclined(lead.leadTypeId);
                break;
            case "16":
                tabContext.getPaidOutState(lead.leadTypeId);
                break;
            case "5":
                tabContext.getApplicationSubmitted(lead.leadTypeId);
                break;
            case "8":
                tabContext.getPrequalifiedSubmitted(lead.leadTypeId);
                break;
            case "10":
                tabContext.getNotTaken(lead.leadTypeId);
                break;
            default:
                tabContext.getPaidOutState(lead.leadTypeId);
                break;
        }

    }, [leadState])

    const handleChange = (event, newValue) => {
        history.push(`/leadDetailsApplication/${state}/${tabContext.tabNameIndex[newValue]}/${id}`);
        tabContext.setSelectedTab(newValue);
    };

    const customHeader = () => {
        return (
            <Grid container className={classes.displayCenter}>
                <Grid item xs={12} md={3} lg={3} className={classes.stepPadding}>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                {leadState.acronym}
                            </Avatar>
                        }
                        title={leadState.name}
                    />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                    <Typography>Dealer: <b>{lead.dealerName}</b></Typography>
                </Grid>
                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                    <Typography>Sales: <b>{lead.handledBy}</b></Typography>
                </Grid>
                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                    <Typography>Dealnet Reference: <b>{lead.dealnetReference}</b></Typography>
                </Grid>
            </Grid>
        )
    }

    const customPersonHeader = () => {
        return (
            <Grid container className={classes.displayCenter}>
                <Grid item xs={12} md={3} lg={3}>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                    <Typography>Name: <b>{lead.name}</b></Typography>
                </Grid>
                <Grid item xs={12} md={2} lg={2} className={classes.stepPadding}>
                    <Typography>Phone: <b>{lead.phone}</b></Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} className={classes.stepPadding}>
                    <Typography>Email Address: <b>{lead.emailAddress}</b></Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Card elevation={0}>
            <div>
                <CardHeader component={customHeader}/>
                <CardHeader component={customPersonHeader} />
            </div>
            <CardContent>
                <AppBar position="relative" className={classes.root}>
                    <Tabs
                        variant="scrollable"
                        value={tabContext.selectedTab != null ? tabContext.selectedTab : tabContext.setSelectedTab(0)}
                        onChange={handleChange}
                    >
                        {tabContext.tabNames.map((tab) => {
                            if (tab != "None")
                                return (
                                    <Tab label={tab}/>
                                )
                        })}
                    </Tabs>
                </AppBar>
                {tabContext.tabContainerList.map((container, index) => {
                    return (
                        tabContext.selectedTab === index && tabContext.getTabs(container)
                    );
                })}
            </CardContent>
        </Card>
    );
}
