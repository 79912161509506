import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import axios from 'axios'
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"
import { BASE_URI } from '../../../../shared/Constants'
import { useParams } from "react-router-dom";
import { useSnackbar } from 'notistack'
import { LeadStatusEnum } from "../../../../shared/Constants"

const useStyles = makeStyles((theme) => ({
  root: {},
  stepPadding: {
    marginTop: "1rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  button: {
    marginTop: "2rem",
  },
}));

export default function MarkAsNewContainer() {
  const classes = useStyles();
  const { state, tab, id } = useParams();
    let { updateState } = useContext(LeadMainContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const values = {
      message: "",
      state: "lost"
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={values}
        isInitialValid={false}
        onSubmit={(values, actions) => {
          setTimeout(() => {

              updateState(LeadStatusEnum["New Lead"])
            actions.setSubmitting(true);
          }, 100);
        }}
      >
        {(props) => {
          return (
            <Form>
              <Grid container direction="row">
                <Grid item xs={12}
                      md={12}
                      lg={12}>
                  <Card className={classes.stepPadding}>
                    <CardContent>
                      <Typography variant="h6">New</Typography>
                      <Grid container direction="row">
                        <Grid
                          item
                          className={classes.stepPadding}
                          xs={12}
                          md={8}
                          lg={6}
                        >
                          <TextField
                            id="message"
                            name="message"
                            label="Message"
                            fullWidth
                            multiline
                            rows={1}
                            rowsMax={5}
                            value={props.values.message}
                            helperText={
                              props.touched.message ? props.errors.message : ""
                            }
                            error={
                              props.touched.message &&
                              Boolean(props.errors.message)
                            }
                            onChange={(event) => {
                              props.handleChange(event);
                              props.setFieldTouched("message", true, false);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={!props.isValid}
                          >
                            Mark as New
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
