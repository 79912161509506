import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { BASE_URI } from "../../shared/Constants";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort'
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExportContainer from '../../shared/ExportContainer'
import { DealerContext } from '../../shared/DealerContext'
import DealerListFilter from './DealerListFilter'
import { useSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    green: {
        color: '#8bc34a'
    },
    hoverTableCell: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    removedealerbtn: {
        background: '#3f51b5',
        color: 'white',
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer'
        }
    }

}));

export default function DealerAdminTable() {
    const classes = useStyles();
    let history = useHistory();
    const [isSelectionDealerRole, setIsSelectionDealerRole] = useState(false);
    const [filteredDealers, setFilteredDealers] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('title');
    const { selectedMotorGroup } = useContext(DealerContext);
    const [searchString, setSearchString] = useState('');
    const [showDialog, setShowDialog] = useState({ show: false, activeDealer: null });
    const { enqueueSnackbar } = useSnackbar();

    const {
        loading,
        dealers,
        total
    } = DealerListFilter(searchString, page, pageSize, order, orderBy)

    const [headCells, setHeadCells] = useState([
        { id: 'dealer', label: 'Dealer', sort: true },
        { id: 'pqLow', label: 'PQ Low', sort: true },
        { id: 'pqHigh', label: 'PQ High', sort: true },
        { id: 'motorGroupName', label: 'Motor Group', sort: true }
    ]);

    useEffect(() => {

        const parsedData = window.location.pathname.split("/");

        if (parsedData?.length > 1 && parsedData[1] == "SelectionDealer") {
            setIsSelectionDealerRole(true);

            var cells = [...headCells,
            { id: 'package', label: 'Package', sort: true }
            ];

            setHeadCells(cells);
        }

    }, []);

    useEffect(() => {

        if (!selectedMotorGroup || loading) {
            return;
        }

        setIsLoading(false);

        if (!isSelectionDealerRole) {

            setFilteredDealers(dealers || []);

        } else {

            var tempFilteredDealers = dealers.filter(d => d.motorgroupID == selectedMotorGroup.motorgroupID);
            setFilteredDealers(tempFilteredDealers);

        }

    }, [dealers, selectedMotorGroup])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const handleChange = (event) => {
        setSearchString(event.target.value)
    };
    const onDeleteDealer = () => {
        
        const DeleteDealer = async () => {

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            try {
                await axios.delete(`${BASE_URI}/Dealers/RemoveDealer`, {
                    params: {
                        dealerId: showDialog?.activeDealer?.id,
                    },
                    cancelToken: source.token,
                });

                setShowDialog({ show: false })
                enqueueSnackbar("Successfully deleted the dealer", { variant: 'success' });
                window.location.reload(false);

            } catch (error) {
                enqueueSnackbar("An error occured while trying to delete the Dealer", { variant: 'error' });
                setShowDialog({ show: false })
            }
        };

        DeleteDealer();
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Dialog
                    open={showDialog?.show}
                    onClose={() => setShowDialog({ show: false, activeDealer: null })}
                    aria-labelledby="confirm-dialog"
                >
                    <DialogTitle id="confirm-dialog">Confirm</DialogTitle>
                    <DialogContent>{`Please confirm to delete '${showDialog?.activeDealer?.name}' dealer`}</DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => setShowDialog({ show: false, activeDealer: null })}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setShowDialog({ ...showDialog, show: false });
                                onDeleteDealer();
                            }}
                            color="default"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={4} className={classes.stepPadding} direction="column">
                    <Grid item xs={12}>
                        <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
                            <Typography style={{ flex: '1 1' }} variant="h4" id="tableTitle" component="div">
                                Dealers
                            </Typography>
                            <TextField
                                name="dealerFilter"
                                id="dealerFilter"
                                label="Search Dealers"
                                value={searchString}
                                type="text"
                                InputProps={{
                                    endAdornment: searchString ? (
                                        <InputAdornment position="end">
                                            <IconButton size="small" disableFocusRipple
                                                disableRipple onClick={() => setSearchString('')}>
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : '',
                                }}
                                onChange={handleChange}
                            />
                            {!isSelectionDealerRole && <>
                                <Button onClick={() => history.push('/admin/dealer/add')}
                                    variant="contained"
                                    style={{ marginLeft: '2rem' }}
                                    startIcon={<AddIcon />}
                                    color="primary">
                                    Dealer
                                </Button>
                                <Button onClick={() => history.push('/admin/dealer/add')}
                                    variant="contained"
                                    style={{ marginLeft: '2rem' }}
                                    startIcon={<GetAppIcon />}
                                    color="primary">
                                    Import
                                </Button>
                            </>}
                        </Toolbar>

                        <Grid item container xs={12}>
                            <ExportContainer values={filteredDealers}
                                useDataHeaders
                                fileName='Users'
                                headers={headCells} />
                        </Grid>

                        <TableContainer>
                            <Table aria-label="users table" size="small">
                                <TableHeadersSort
                                    order={order}
                                    orderBy={orderBy}
                                    headCells={headCells}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {
                                        filteredDealers && filteredDealers.filter((a) => {
                                            if (searchString == "") {
                                                return a
                                            } else if (a.name.toLowerCase().includes(searchString.toLowerCase())) {
                                                return a
                                            }
                                        }).map((row) => (
                                            <Tooltip title={`Edit ${row.name}`} key={row.id} placement="left">
                                                <TableRow hover
                                                    className={classes.hoverTableCell}
                                                >
                                                    <TableCell onClick={() => history.push(`/${isSelectionDealerRole ? 'SelectionDealer' : 'admin'}/dealer/edit/${row.id}`)}>
                                                        <Typography style={{ color: row.status === 'inActive' ? '#ff5722' : '' }}
                                                            variant="subtitle2">
                                                            {row.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell onClick={() => history.push(`/${isSelectionDealerRole ? 'SelectionDealer' : 'admin'}/dealer/edit/${row.id}`)}>
                                                        {row.pqLow}
                                                    </TableCell>
                                                    <TableCell onClick={() => history.push(`/${isSelectionDealerRole ? 'SelectionDealer' : 'admin'}/dealer/edit/${row.id}`)}>
                                                        {row.pqHigh}
                                                    </TableCell>
                                                    <TableCell onClick={() => history.push(`/${isSelectionDealerRole ? 'SelectionDealer' : 'admin'}/dealer/edit/${row.id}`)}>
                                                        {row.motorGroupName}
                                                    </TableCell  >
                                                    {isSelectionDealerRole && <TableCell onClick={() => history.push(`/${isSelectionDealerRole ? 'SelectionDealer' : 'admin'}/dealer/edit/${row.id}`)}>
                                                        {row.package}
                                                    </TableCell>

                                                    }
                                                    <TableCell>
                                                        <Button
                                                            className={classes.removedealerbtn}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => setShowDialog({ show: true, activeDealer: row })}>
                                                            Remove Dealer
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </Tooltip>
                                        ))
                                    }
                                    {
                                        ((!filteredDealers.length || filteredDealers.length === 0) && !isLoading) &&
                                        <TableRow>
                                            <TableCell colSpan={headCells.length} align="center"
                                                style={{ borderBottom: 0 }}
                                                className="py-3">
                                                <Typography variant="h6" color="textSecondary">
                                                    No Dealers Added
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {
                                        isLoading &&
                                        <TableRow>
                                            <TableCell colSpan={headCells.length} align="center"
                                                style={{ borderBottom: 0 }}>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={filteredDealers?.length}
                                page={page}
                                rowsPerPageOptions={[5, 10, 25]}
                                onChangePage={handleChangePage}
                                rowsPerPage={pageSize}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}