import { useState, useEffect } from "react";
import axios from 'axios';
import { BASE_URI } from '../../../../shared/Constants'
import ImageService from "../../../../shared/ImageService";

export default function DocumentTabController(id) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [types, setTypes] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {

        let cancel = reloadData();
        

        axios({
            method: 'GET',
            url: `${BASE_URI}/applicationDocument/types`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {

            setTypes(response.data);

        });

        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const reloadData = () => {
        let cancel;
        let documents = [];

        const getApplicationDocuments = axios({
            method: 'GET',
            url: `${BASE_URI}/applicationDocument/lead/${id}`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            
            let data = response.data.map(itm => {return itm});

            documents.push(...data);

        }).catch(error => {
            
            if (axios.isCancel(error)) 
                return;

            if (error.response)
                setError(true);
            
        });

        const getUserDocuments = axios({
            method: 'GET',
            url: `${BASE_URI}/userDocument/GetForLead/${id}`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            
            let data = response.data.map(itm => {return itm});

            documents.push(...data);

        }).catch(error => {
            
            if (axios.isCancel(error)) 
                return;

            if (error.response)
                setError(true);
            
        });

        setLoading(true);
        setError(false);

        Promise.allSettled([
            getApplicationDocuments,
            getUserDocuments
        ])
        .then(() => {

            setData(documents);
            setTotal(documents.length);
            setLoading(false);

            return cancel;
        });
    }

    const uploadDocument = async (values) => {

        var base64Image = (values.fileUpload instanceof File) ? await ImageService.getBase64(values.fileUpload) : values.fileUpload;

        base64Image = ImageService.parseBase64(base64Image);

        axios.post(`${BASE_URI}/ApplicationDocument`,
            {
                DocumentType: values.documentType,
                LeadId: parseInt(id),
                Base64Image: base64Image,
                FileName: (values.fileUpload instanceof File) ? values.fileUpload.name : '',
                Note: values.note
            }
        ).then(() => {
            reloadData();
        });
    }


    return { loading, error, data, total, types, reloadData, uploadDocument };
}
