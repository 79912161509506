import React, { useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { BASE_URI } from "../../shared/Constants";
import { useSnackbar } from 'notistack'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import SelectInput from "../../components/AutocompleteContainer/AutoCompleteComponent";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from "@material-ui/core";
import { DealerContext } from "../../shared/DealerContext";
import FileUploadInput from "../../shared/FileUploadInput";
import {
    Switch
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
            width: "45ch",
        },
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(5),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    sectionsDevider: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    messageSuccess: {
        color: 'green',
        fontWeight: '600'
    },
    messageWarning: {
        color: 'orange',
        fontWeight: '600'
    },
    messageError: {
        color: 'red',
        fontWeight: '600'
    },
    textBold: {
        fontWeight: '600'
    },
    tableHeader: {
        borderBottom: '2px solid darkgray',
        textAlign: 'center'
    },
    swichMain: {
        display: "grid",
    },
    stepPadding: {
        paddingLeft: '15px',
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        display: "inline-block",
        width: "100%",
    },
    switchContainer: {
        width: "fit-content",
        display: "inline-flex",
        minWidth: "fit-content",
        alignItems: "center",
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        Color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        fontWeight: '600',
        fontSize: '1.2em'
        // borderColor: 'black',
        // borderStyle: 'solid',
        // borderWidth: 0.1
    },
    body: {
        //fontSize: 14,
        // borderColor: 'black',
        // borderStyle: 'solid',
        // borderWidth: 0.1
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        }
    }
}))(TableRow);

const FACEBOOK_INTEGRATION_ERROR = "The Current Dealer is not linked to a facebook page";
const EXCEL_DOCUMENT_FORMART_ERROR = "Incorrect/Unsupported Excel Document format, Please convert document to  'Microsoft Excel 5.0/95 Workbook(*.xls)' format";
const IMPORT_SUCCESS_MSG = "SUCCESS";
const IMPORT_PARTIAL_SUCCESS_MSG = "PARTIAL SUCCESS";
const IMPORT_FAILED_MSG = "FAILED";

export default function DealerLeadsImport() {

    const { ...dealerContext } = useContext(DealerContext);

    const initialValues = {
        adID: "",
        formID: "",
        pageID: "",
        dealerId: dealerContext?.dealerId,
        file: null
    };

    const classes = useStyles();
    const [dealer, setDealer] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [importing, setImporting] = useState(false);
    const [imported, setImported] = useState(false);
    const [formFields, setFormFields] = useState(initialValues)
    const [formValid, setFormValid] = useState(false)
    const [formErrorMessage, setFormErrorMessage] = useState(EXCEL_DOCUMENT_FORMART_ERROR)
    const [resultHeading, setResultHeading] = useState(null);
    const [resultMessage, setResultMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null);
    const [leadsAddedCount, setLeadsAddedCount] = useState(null);
    const [leadsSkippedCount, setLeadsSkippedCount] = useState(null);
    const [leadsTotalCount, setLeadsTotalCount] = useState(null);
    const [skippedLeads, setSkippedLeads] = useState(null);
    const [isCustom, setIsCustom] = useState(false);

    useEffect(() => {

        if (dealerContext?.dealerId) {
            const getDealer = async () => {
                setLoading(true);

                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();

                axios({
                    method: 'GET',
                    url: `${BASE_URI}/dealers/${dealerContext?.dealerId}`,
                    cancelToken: source.token
                }).then((response) => {
                    if (response?.data) {
                        setDealer(response?.data)
                    }
                    setLoading(false)
                }).catch(error => {
                    if (axios.isCancel(error)) return
                    if (error.response) {
                        //setError(true)
                    }
                    setLoading(false)
                })
            }
            getDealer();
        }
    }, [dealerContext?.dealerId]);

    useEffect(() => {
        if (dealer) {
            setFormFields({
                ...formFields,
                adID: dealer?.facebookAdAccountId,
                formID: dealer?.facebookFormId,
                pageID: dealer?.facebookPageId,
                dealerId: dealer?.dealerId
            });
        }
    }, [dealer])

    useEffect(() => {
        validateForm()
    }, [formFields])

    const getMessageStyle = () => {
        switch (resultHeading) {
            case IMPORT_SUCCESS_MSG: return classes.messageSuccess;
            case IMPORT_PARTIAL_SUCCESS_MSG: return classes.messageWarning;
            case IMPORT_FAILED_MSG: return classes.messageError;
            default: return;
        }
    }

    const handleChangeAdId = (value) => {
        setFormFields({ ...formFields, adID: value });
    }

    const handleChangePageId = (value) => {
        setFormFields({ ...formFields, pageID: value });
    }

    const handleChangeFormId = (value) => {
        setFormFields({ ...formFields, formID: value });
    }

    const handleChangeFile = (value) => {
        setFormFields({ ...formFields, file: value });
    }

    const validateForm = () => {
        if (!formFields?.file || !formFields?.file?.name?.endsWith(".xls")) {
            setFormValid(false);
            setFormErrorMessage(EXCEL_DOCUMENT_FORMART_ERROR)
            return;
        }

        setFormValid(true);
    }

    const importLeads = () => {

        setImporting(true);
        setImported(false);
        setResultHeading(null);
        setResultMessage(null);
        setErrorMessage(null);
        setErrorDetails(null);
        setLeadsAddedCount(null);
        setLeadsSkippedCount(null);
        setLeadsTotalCount(null);
        setSkippedLeads(null);

        const formData = new FormData();

        formData.append("file", formFields?.file);
        formData.append("fileName", formFields?.file?.name);
        formData.append("dealerID", formFields?.dealerId);
        formData.append("formID", formFields?.formID);

        if (!isCustom) {
            formData.append("adID", formFields?.adID);
            formData.append("pageID", formFields?.pageID);
        }

        axios({
            method: 'POST',
            url: `${BASE_URI}/LeadsImport`,
            data: formData,
            cancelToken: axios.CancelToken.source().token
        }).then((response) => {

            setImporting(false);
            setFormValid(false)
            setFormFields({ ...formFields, file: null });

            if (!response?.data) {
                // No response received, display error message
                enqueueSnackbar("Import failed. No response or error received from server", { variant: 'error' });
            }

            var result = response?.data;

            setImported(true);
            setResultMessage(result.resultMessage);
            setErrorMessage(result.errorMsg);
            setErrorDetails(result.errorDetail);

            if (result?.leadsTotalCount == undefined) {

                // A faulty lead detail caused the import to be abandoned
                setResultHeading(IMPORT_FAILED_MSG);

            } else {

                // The lead was successful either completely or partially
                let areAllLeadsProcessed = result.leadsTotalCount - result.leadsAddedCount - result.leadsSkippedCount <= 0;

                setResultHeading(!areAllLeadsProcessed || result.leadsSkippedCount > 0 ? IMPORT_PARTIAL_SUCCESS_MSG : IMPORT_SUCCESS_MSG);
                setLeadsAddedCount(result.leadsAddedCount);
                setLeadsSkippedCount(result.leadsSkippedCount);
                setLeadsTotalCount(result.leadsTotalCount);
                setSkippedLeads(result.skippedLeads);

            }

        }).catch(error => {

            if (axios.isCancel(error)) return;

            setImporting(false);
            enqueueSnackbar(getErrorMessage(error, "Unable to complete the request"), { variant: 'error' });

        })
    }

    function getErrorMessage(axiosError, defaultMsg) {

        let errorTitle = defaultMsg;
        let errorDetails = "No additional details are available";

        if (axiosError?.request?.message || axiosError?.request?.statusText) {

            errorTitle = "Failed to send the request";
            errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;

        } else if (axiosError?.response?.data) {

            errorTitle = axiosError?.response?.data?.title ?? errorTitle;
            errorDetails = axiosError?.response?.data?.detail ?? errorDetails;
        }

        return `${errorTitle}: ${errorDetails}`;
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>

                <Grid container spacing={4} direction="column">
                    {
                        loading &&
                        <Grid item xs={12} container spacing={4}>

                            <Grid item xs={12} >
                                <Typography variant="h6" align="center">
                                    Loading
                                </Typography>
                            </Grid>

                            <Grid item xs={12} container justifyContent="center">
                                <CircularProgress />
                            </Grid>

                        </Grid>
                    }
                    {
                        importing &&
                        <Grid item xs={12} container spacing={4}>

                            <Grid item xs={12} >
                                <Typography variant="h6" align="center">
                                    Importing
                                </Typography>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography variant="body1" align="center">
                                    This might take a few minutes depending on the number of leads in the document
                                </Typography>
                            </Grid>

                            <Grid item xs={12} container justifyContent="center">
                                <CircularProgress />
                            </Grid>

                        </Grid>
                    }
                    {
                        !loading && !importing &&
                        <Grid item xs={12}>

                            <Typography variant="h6">
                                Import Setup
                            </Typography>
                            <Grid container direction="row" className={classes.swichMain}>
                                <Grid item xs={12} md={2} className={`${classes.stepPadding} ${classes.switchContainer}`}>
                                    <Typography>Facebook Campaign Leads</Typography>
                                    <Switch
                                        Label
                                        checked={isCustom}
                                        onChange={(e) => setIsCustom(!isCustom)}
                                    />
                                    <Typography>Custom Campaign Leads</Typography>
                                </Grid>
                            </Grid>

                            <Formik
                                initialValues={formFields}
                                isInitialValid={false}
                            >
                                {(props) => {
                                    const {
                                        resetForm
                                    } = props
                                    return (
                                        <Form>
                                            <Grid container direction="row">
                                                {
                                                    !isCustom &&
                                                    <Grid item xs={12} md={12} lg={4}>
                                                        <TextField
                                                            id="pageID"
                                                            name="pageID"
                                                            label="Facebook PageID"
                                                            disabled={!dealer?.isFacebookIntegrated}
                                                            value={`${formFields?.pageID}`}
                                                            type="number"
                                                            onChange={(e) => handleChangePageId(e.target.value)}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={12} md={12} lg={4}>
                                                    <TextField
                                                        id="formID"
                                                        name="formID"
                                                        label="Facebook FormID"
                                                        disabled={!dealer?.isFacebookIntegrated}
                                                        value={`${formFields?.formID}`}
                                                        type="number"
                                                        onChange={(e) => handleChangeFormId(e.target.value)}
                                                    />
                                                </Grid>
                                                {
                                                    !isCustom &&
                                                    <Grid item xs={12} md={12} lg={4}>
                                                        <TextField
                                                            id="adID"
                                                            name="adID"
                                                            label="Facebook AdID"
                                                            disabled={!dealer?.isFacebookIntegrated}
                                                            value={`${formFields?.adID}`}
                                                            type="number"
                                                            onChange={(e) => handleChangeAdId(e.target.value)}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={12} md={12} lg={4}>
                                                    <FileUploadInput
                                                        setFileUpoload={handleChangeFile}
                                                        disabled={!dealer?.isFacebookIntegrated}
                                                        title='Excel Document (format: .xls)'
                                                        fieldName={"fileUpload"}
                                                        error={
                                                            props.errors.fileUpload && props.touched.fileUpload
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Divider className={classes.sectionsDevider} />

                                            <Grid container direction="row"  >
                                                {
                                                    (!dealer?.isFacebookIntegrated || (!formValid && formFields?.file))
                                                        ?
                                                        <Alert severity="error">{!dealer?.isFacebookIntegrated ? FACEBOOK_INTEGRATION_ERROR : formErrorMessage}</Alert>
                                                        :
                                                        <Grid item xs={12} md={12} lg={4}>
                                                            <Button
                                                                variant="contained"
                                                                style={{ margin: "1rem" }}
                                                                startIcon={<AddIcon />}
                                                                color="primary"
                                                                disabled={!formValid}
                                                                onClick={() => { importLeads(); resetForm(); }}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Grid>
                                                }
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Grid>
                    }
                    {
                        imported &&
                        <Grid item xs={12} container spacing={4} direction="column">

                            <Grid item xs={12}>
                                <Divider className={classes.sectionsDevider} />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Import Results
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle1" align="center" className={getMessageStyle()}>
                                    {resultHeading}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1" align="center">
                                    {resultMessage}
                                </Typography>
                            </Grid>

                            {
                                leadsTotalCount != null &&
                                <Grid item xs={12} container direction="row" justifyContent="space-between">

                                    <Grid item xs={12} lg={4}>
                                        <Typography variant="body1" align="center" className={classes.messageSuccess}>
                                            Imported: {leadsAddedCount}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={4}>
                                        <Typography variant="body1" align="center" className={classes.messageWarning}>
                                            Skipped: {leadsSkippedCount}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} lg={4}>
                                        <Typography variant="body1" align="center" className={classes.textBold}>
                                            Total: {leadsTotalCount}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            }
                            {
                                errorMessage?.length > 0 &&
                                <Grid item xs={12} container spacing={2}>

                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            {errorMessage}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                            {errorDetails?.length > 0 ? errorDetails : "No additional details are available"}
                                        </Typography>
                                    </Grid>

                                    {
                                        skippedLeads?.length > 0 &&

                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table aria-label="Skipped lead details">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="center" style={{}}>Phone Number</StyledTableCell>
                                                            <StyledTableCell align="center" style={{}}>Email Address</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {skippedLeads.map((row) => (
                                                            <StyledTableRow>
                                                                <StyledTableCell align="center">{row.phoneNumber}</StyledTableCell>
                                                                <StyledTableCell align="center">{row.emailAddress}</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>

                                    }

                                </Grid>
                            }

                        </Grid>
                    }
                </Grid>

            </Paper>
        </div >
    );
}