import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Typography } from "@material-ui/core";
import axios from 'axios'
import { BASE_URI } from '../../../../shared/Constants'
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext"
import { LeadStatusEnum } from "../../../../shared/Constants"
import { useSnackbar } from 'notistack'
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {},
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    button: {
        marginTop: "2rem",
    }
}));

export default function FinanceLinkContainer() {
    const classes = useStyles();
 
    const { id } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const sendFinanceLink = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        axios({
            method: 'GET',
            url: `${BASE_URI}/applications/link/${id}`,
            cancelToken: source.token
        }).then((reponse) => {

            enqueueSnackbar("Sent finance link", { variant: 'success' });
        });
    }

    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Card className={classes.stepPadding}>
                        <CardContent>
                            <Typography variant="h6"> Send finance application link</Typography>
                            <Grid container direction="row">
                                <Grid item xs={12} md={4} lg={4}>
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => sendFinanceLink()}
                                    >
                                        Send finance application link
                  </Button>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
