export function GetErrorMessage(axiosError, defaultMsg) {

    let errorTitle = defaultMsg;
    let errorDetails = "No additional details are available";

    if (axiosError?.request?.message || axiosError?.request?.statusText)  {

        errorTitle = "Failed to send the request";
        errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;

    } else if (axiosError?.response?.data) {

        errorTitle = axiosError?.response?.data?.title ?? errorTitle;
        errorDetails = axiosError?.response?.data?.details ?? errorDetails;
    }

    return `${errorTitle}: ${errorDetails}`;
    
}

export function AddDateStringDelimiter(dateString, delimeter = "/") {

    /*  NOTE: only accepts Date strings in the format "yyyymmdd" */

    // Basic string validation
    if (!dateString || dateString.length <= 0) {
        return "N/A";
    }

    // Credits: 
    //  https://stackoverflow.com/questions/6981487/insert-hyphens-in-javascript
    //  https://stackoverflow.com/questions/3840600/javascript-regular-expression-remove-first-and-last-slash
    //  https://stackoverflow.com/questions/494035/how-do-you-use-a-variable-in-a-regular-expression
    
    // The regex does the following: 
    //  1) Remove any existing common delimeters
    //  2) Group the dateString into 3 groups of lenghts 4, 0-2 & 0-2 respectively
    //  3) Insert the delimeter between them
    //  4) Remove any consecutive leading or trailing delimeters

    let trimRegex = new RegExp(`(^${delimeter}+|${delimeter}+$)`, "g");

    return dateString
        .replace(/(\/+|-+)/g, "")
        .replace(/(\d{4})(\d{0,2})(\d{0,2})/, `$1${delimeter}$2${delimeter}$3`)
        .replace(trimRegex, "");

}

export function FormatDateString(date) {

    let dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    if (!(date instanceof Date))
        date = new Date(date);

    let day = date.getDate().toString().padStart(2, "0");
    let dayNum = date.getDay();
    let monthNum = date.getMonth();
    let year = date.getFullYear();

    let dayName = dayNames[dayNum];
    let monthName = monthNames[monthNum];
    let yearShort = year.toString().slice(2);

    // Increase month number by 1 for display purposes since the .getMonth() assumes a zero-base array
    monthNum++;
    monthNum = monthNum.toString().padStart(2, "0");

    return {
        ddmmyy: `${day}-${monthNum}-${yearShort}`,
        ddmmyyyy: `${day}-${monthNum}-${year}`,
        ddMMyyyy: `${day} ${monthName} ${year}`,
        DddMyy: `${dayName.slice(0, 3)} ${day} ${monthName.slice(0, 3)} ${yearShort}`,
        DddMyyyy: `${dayName.slice(0, 3)} ${day} ${monthName.slice(0, 3)} ${year}`,
        DDddMMyy: `${dayName} ${day} ${monthName} ${yearShort}`,
        MMyy: `${monthName} ${yearShort}`,
        MMyyyy: `${monthName} ${year}`,
        mmyyyy: `${monthName.slice(0, 3)} ${year}`,
        yyyymm: `${year}${monthNum}`
    };
}