import {useState, useEffect, useContext} from "react";
import axios from 'axios';
import {BASE_URI} from "../../shared/Constants";
import {DealerContext } from "../../shared/DealerContext";
import {useSnackbar} from 'notistack'

export default function LeadLogFilter(id, pageNumber, pageSize, order, orderBy, search) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [ranges, setRanges] = useState([]);
    const [total, setTotal] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    let { ...dealerContext } = useContext(DealerContext);

    useEffect(() => {
        setRanges([]);
    }, [id, pageNumber, pageSize, order, orderBy, search]);

    useEffect(() => {
        if (dealerContext.dealerId == '') {
            return;
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let params = {};
        params.userId = id;
        params.dealerId = dealerContext.dealerId;
         if (pageNumber) {
            params.pageNumber = pageNumber + 1
        }
        if (pageSize) {
            params.pageSize = pageSize
        }
        if (order) {
            params.order = order.toUpperCase();
        }
        if (orderBy) {
            params.orderBy = orderBy;
        }
        if(search !="") {
            params.search = search;
        }

        axios({
            method: 'GET',
            url: `${BASE_URI}/leadLog`,
            params,
            cancelToken: source.token
        }).then((response) => {
            if (response.data) {
                setRanges(response.data.list)
            }
            setLoading(false);
            setTotal(response.data.total)
        }).catch(error => {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to get the lead log for this user, please refresh!", {variant: 'error'});
        });

    }, [id, pageNumber, pageSize, order, orderBy, search, dealerContext.dealerId]);

    return {loading, error, ranges, total};
}