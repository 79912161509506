import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const SelectInput = (props) => {

  const {value, data, required, onBlur, fullWidth, onChange, disabled} = props

  return (<Autocomplete
      value={props.value}
      getOptionLabel={(option) => option.name ? option.name : option.title ? option.title: ''}
      getOptionSelected={(option, value) => {
        return option.name === value.name || option.id === value.id;
      }}
      disabled={disabled}
      options={[value, ...data]}
      filterSelectedOptions
      onChange={(event, value) => {
        if (onChange) {
          onChange(event, value)
        }
        // props.props.setFieldTouched(props.fieldName, true, false);
        props.props.setFieldValue(props.fieldName, value ? value.name : '');

        if (props.fieldNameID) {
          props.props.setFieldTouched(props.fieldNameID, true, false);
          props.props.setFieldValue(props.fieldNameID, value ? value.id : "");
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          onBlur={onBlur}
          required={required}
          error={props.error}
          helperText={props.helperText}
          label={props.Label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  )};

export default SelectInput;
