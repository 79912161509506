import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    Card,
    CardHeader, 
    CardContent,
    Grid,
    TextField
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { Formik, Form, Field } from "formik";
import { DatePicker } from 'formik-material-ui-pickers';
import { useSnackbar } from 'notistack'
import axios from 'axios'
import * as Yup from 'yup'
import moment from 'moment';
import TextMaskCustom from '../../../../shared/TextMask'
import FormActions from '../../../../shared/FormActions'
import { BASE_URI, LOOKUP_URI } from '../../../../shared/Constants'
import SelectInput from '../../../../shared/AutoCompleteComponent'
import LoadingCardComponent from '../../../../shared/LoadingCardComponent'

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    }
}));

export default function WorkTabContainer({ next, back, skip, saveExit }) {

    const classes = useStyles();
    const mountedRef = useRef(true);
    const { id: leadId, guidId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const initialValues = {
        employer: '',
        employmentStartDate: new Date(),
        employmentType: '',
        employmentTypeId: '',
        industryType: '',
        industryTypeId: '',
        occupation: '',
        occupationTypeId: '',
        monthsAtEmployer: "",
        employerWorkPhoneNumber: '',
        employerWorkPhoneAreaCode: '',
        employerAddress1: '',
        employerAddress2: '',
        employerCountry: '',
        employerCountryId: '',
        employerCity: "",
        employerCityId: 0,
        employerAddressCode: "",
        employerSuburb: "",
        employerProvince: "",
        employerProvinceId: "",
    };

    const [loading, setLoading] = useState(true);
    const [isAddMode, setIsAddMode] = useState(true);
    const [disableOccupationsList, setDisableOccupationsList] = useState(true);
    const [formFields, setFormFields] = useState(initialValues);
    const [employmentTypes, setEmploymentTypes] = useState([]);
    const [industryTypes, setIndustryTypes] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinceArr, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);

    const validationSchema = Yup.object({
        employer: Yup.string().required('Employer Name is required'),
        employmentStartDate: Yup.string().required('Employment Start Date is required'),
        employmentType: Yup.string().required('Employment is required'),
        industryType: Yup.string().required('Industry is required'),
        occupation: Yup.string().required('Occupation is required'),
        monthsAtEmployer: Yup.string().required('Months worked at Employer is required'),
    });

    useEffect(() => {

        setLoading(true);

        loadFormOptions().then((result) => {

            if (result === true) {

                loadWorkDetails();

            } else {

                setLoading(false);

            }

        });

    }, []);

    async function loadWorkDetails() {

        let url = !guidId 
            ? `${BASE_URI}/Applications/Work/${leadId}`
            : `${BASE_URI}/Applications/Work/guid/${guidId}`;

        try {
            
            const result = await axios.get(url, {
                cancelToken: axios.CancelToken.source().token
            })
            
            setLoading(false);

            if (!result?.data)
                return;

            setFormFields(result?.data);
            setIsAddMode(false);
            setDisableOccupationsList(false)
            
        } catch (error) {

            setLoading(false);

            if (axios.isCancel(error)) return;

        }
    }

    async function loadFormOptions() {

        let errors = [];

        const getCountries = async () => {
            try {

                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/countries`, {
                    cancelToken: axios.CancelToken.source().token
                });

                setCountries(result?.data?.sort((a, b) => a.name > b.name ? 1 : -1));

            } catch (error) {

                if (axios.isCancel(error)) return;

                errors.push(getErrorMessage(error, "Could not load country options"));

            }
        };

        const getRegions = async () => {
            try {

                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/region`, {
                    cancelToken: axios.CancelToken.source().token
                });

                setProvinces(result?.data?.sort((a, b) => a.name > b.name ? 1 : -1));

            } catch (error) {

                if (axios.isCancel(error)) return;
                
                errors.push(getErrorMessage(error, "Could not load region options"));
                
            }
        };

        const getEmploymentTypes = async () => {
            try {

                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/employmentType`, {
                    cancelToken: axios.CancelToken.source().token
                });

                setEmploymentTypes(result?.data?.sort((a, b) => a.name > b.name ? 1 : -1))

            } catch (error) {

                if (axios.isCancel(error)) return;

                errors.push(getErrorMessage(error, "Could not load employment type options"));

            }
        };

        const getIndustryTypes = async () => {
            try {

                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/industryType`, {
                    cancelToken: axios.CancelToken.source().token
                });

                setIndustryTypes(result?.data?.sort((a, b) => a.name > b.name ? 1 : -1));

            } catch (error) {

                if (axios.isCancel(error)) return;

                errors.push(getErrorMessage(error, "Could not load industry type options"));

            }
        };

        const getOccupationTypes = async () => {
            try {

                const result = await axios.get(`${BASE_URI}/${LOOKUP_URI}/occupationTypes`, {
                    cancelToken: axios.CancelToken.source().token
                });

                setDisableOccupationsList(false);
                setOccupations(result?.data?.sort((a, b) => a.name > b.name ? 1 : -1));

            } catch (error) {

                if (axios.isCancel(error)) return;

                setDisableOccupationsList(true);
                errors.push(getErrorMessage(error, "Could not load occupation type options"));

            }
        };
    
        return new Promise((res, rej) => {
            Promise.allSettled([
                getCountries(),
                getRegions(),
                getEmploymentTypes(),
                getIndustryTypes(),
                getOccupationTypes()
            ])
            .then(() => {

                if (errors.length > 0) {

                    enqueueSnackbar(errors.join(". "), { variant: 'error' });
                    res(false);

                } else {

                    res(true);

                }

            })
            .catch((error) => {
                
                rej(error);

            });
        });

    }

    function getCities(id) {

        if (!id) {
            return;
        }

        return axios.get(`${BASE_URI}/${LOOKUP_URI}/city/${id}`, {
            cancelToken: axios.CancelToken.source().token
        })

    }

    function onSubmit(fields, { setStatus, setSubmitting }) {

        let tmpFields = { 
            ...fields,
            employerWorkPhoneAreaCode: fields.employerWorkPhoneNumber.substring(0, 3),
            employerCountryId: parseInt(fields.employerCountryId, 10),
            occupationTypeId: parseInt(fields.occupationTypeId, 10),
            industryTypeId: parseInt(fields.industryTypeId, 10),
            employerProvinceId: parseInt(fields.employerProvinceId, 10),
            employerCityId: parseInt(fields.employerCityId, 10)
        };

        setStatus();

        if (isAddMode) {
            createWorkApplication(leadId, tmpFields, setSubmitting);
        } else {
            editWorkApplication(leadId, tmpFields, setSubmitting);
        }

    }

    const createWorkApplication = async (id, fields, setSubmitting) => {
        try {

            const result = await axios.post(`${BASE_URI}/Applications/Work/${id}`, fields, {
                cancelToken: axios.CancelToken.source().token
            });

            enqueueSnackbar('Successfully uploaded work details', { variant: 'success' });

            return result;

        } catch (error) {
            enqueueSnackbar(getErrorMessage(error, "Error uploading work details, please try again later"), { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    const editWorkApplication = async (id, fields, setSubmitting) => {
        try {

            const result = await axios.put(`${BASE_URI}/Applications/Work/${id}`, fields, {
                cancelToken: axios.CancelToken.source().token
            });

            enqueueSnackbar('Successfully edited work details', { variant: 'success' });

            return result;

        } catch (error) {
            enqueueSnackbar(getErrorMessage(error, "Error updating work details, please try again later"), { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    };

    const handleProvinceChange = async (e, value) => {
        if (value && value.id) {
            getCities(value.id).then((res) => {
                // setDisableOccupationsList(false)
                setCities(res.data)
            }).catch((err) => {
                enqueueSnackbar("Unable to get cities list", { variant: 'error' });
                // setDisableOccupationsList(true)
            })
        }
    };

    const handleDateChange = (e, v, values, setFieldValue) => {

        let newDate = e;
        let originalMonths = values.monthsAtEmployer;

        try {
            
            let fromMoment = moment(e);
            let toMoment = moment(new Date());
            let diffMonths = toMoment.diff(fromMoment, 'months');

            setFieldValue("monthsAtEmployer", diffMonths);

        } catch {
            setFieldValue("monthsAtEmployer", originalMonths);
        }
        finally {
            setFieldValue("employmentStartDate", newDate);
        }

    };

    const handleMonthsChange = (e, v, values, setFieldValue) => {

        let newMonths = parseInt(e?.target?.value);
        let originalDate = values.employmentStartDate;

        try {
            
            let today = moment(new Date());
            let newDate = today.subtract(newMonths, 'months').toDate();

            setFieldValue("employmentStartDate", newDate);

        } catch {
            setFieldValue("employmentStartDate", originalDate);
        }
        finally {
            setFieldValue("monthsAtEmployer", newMonths);
        }

    };

    function getErrorMessage(axiosError, defaultMsg) {

        let errorTitle = defaultMsg;
        let errorDetails = "No additional details are available";

        if (axiosError?.request?.message || axiosError?.request?.statusText)  {

            errorTitle = "Failed to send the request";
            errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;

        } else if (axiosError?.response?.data) {

            errorTitle = axiosError?.response?.data?.title ?? errorTitle;
            errorDetails = axiosError?.response?.data?.detail ?? errorDetails;
        }

        return `${errorTitle}: ${errorDetails}`;
    }

    return (
        <React.Fragment>
            {
                loading &&
                <LoadingCardComponent />
            }
            {
                !loading &&
                <Formik
                    initialValues={formFields}
                    isInitialValid={false}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleBlur,
                            handleChange,
                            handleReset,
                            setFieldValue
                        } = props;
                        return (
                            <Form noValidate>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    
                                    {/* Work details section */}
                                    <Card style={{ marginBottom: '1rem', marginTop: '1.5rem' }} elevation={5} className={classes.stepPadding}>

                                        <CardHeader title="Work Details" />

                                        <CardContent>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        id="employer"
                                                        name="employer"
                                                        label="Employer"
                                                        value={values.employer}
                                                        helperText={errors.employer && touched.employer ? errors.employer : ''}
                                                        error={errors.employer && touched.employer}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        Label={"Employment"}
                                                        fieldName={"employmentType"}
                                                        fieldNameID={"employmentTypeId"}
                                                        data={employmentTypes}
                                                        helperText={errors.employmentType && touched.employmentType ? errors.employmentType : 'Start Typing'}
                                                        error={errors.employmentType && touched.employmentType}
                                                        onBlur={handleBlur("employmentType")}
                                                        onChange={handleChange}
                                                        value={{
                                                            name: values.employmentType.toString(),
                                                            id: values.employmentTypeId.toString(),
                                                        }}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Field 
                                                        component={DatePicker}
                                                        autoOk
                                                        required
                                                        format="dd/MM/yyyy"
                                                        label="Employment Start Date"
                                                        name="employmentStartDate"
                                                        onBlur={handleBlur}
                                                        onChange={(e, v) => { handleDateChange(e, v, values, setFieldValue); }}/>
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        Label={"Industry Type"}
                                                        fieldName={"industryType"}
                                                        fieldNameID={"industryTypeId"}
                                                        data={industryTypes}
                                                        helperText={errors.industryType && touched.industryType ? errors.industryType : 'Start Typing'}
                                                        error={errors.industryType && touched.industryType}
                                                        onBlur={handleBlur("industryType")}
                                                        onChange={handleChange}
                                                        value={{
                                                            name: values.industryType.toString(),
                                                            id: values.industryTypeId.toString(),
                                                        }}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        required
                                                        fullWidth
                                                        disabled={disableOccupationsList || isSubmitting}
                                                        Label={"Occupation"}
                                                        fieldName={"occupation"}
                                                        fieldNameID={"occupationTypeId"}
                                                        data={occupations}
                                                        helperText={errors.occupation && touched.occupation ? errors.occupation : 'Start Typing'}
                                                        error={errors.occupation && touched.occupation}
                                                        onBlur={handleBlur("occupation")}
                                                        onChange={handleChange}
                                                        value={{
                                                            name: values.occupation ? values.occupation.toString() : '',
                                                            id: values.occupationTypeId ? values.occupationTypeId.toString() : '',
                                                        }}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        type="number"
                                                        id="monthsAtEmployer"
                                                        name="monthsAtEmployer"
                                                        label="Months at Employer"
                                                        value={values.monthsAtEmployer}
                                                        helperText={errors.monthsAtEmployer && touched.monthsAtEmployer ? errors.monthsAtEmployer : ''}
                                                        error={errors.monthsAtEmployer && touched.monthsAtEmployer}
                                                        onBlur={handleBlur}
                                                        onChange={(e, v) => { handleMonthsChange(e, v, values, setFieldValue); }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        id="employerWorkPhoneNumber"
                                                        name="employerWorkPhoneNumber"
                                                        label="Work Phone Number"
                                                        value={values.employerWorkPhoneNumber}
                                                        helperText={errors.employerWorkPhoneNumber && touched.employerWorkPhoneNumber ? errors.employerWorkPhoneNumber : ''}
                                                        error={errors.employerWorkPhoneNumber && touched.employerWorkPhoneNumber}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            inputComponent: TextMaskCustom,
                                                        }}
                                                    />
                                                </Grid>
                                                
                                            </Grid>
                                        </CardContent>

                                    </Card>

                                    {/* Work address section */}
                                    <Card style={{ marginBottom: '1rem' }} elevation={5} className={classes.stepPadding}>

                                        <CardHeader title="Work Address" />

                                        <CardContent>
                                            <Grid container spacing={4}>

                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        id="employerAddress1"
                                                        name="employerAddress1"
                                                        label="Address 1"
                                                        value={values.employerAddress1}
                                                        helperText={errors.employerAddress1 && touched.employerAddress1 ? errors.employerAddress1 : ''}
                                                        error={errors.employerAddress1 && touched.employerAddress1}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        id="employerAddress2"
                                                        name="employerAddress2"
                                                        label="Address 2"
                                                        value={values.employerAddress2}
                                                        helperText={errors.employerAddress2 && touched.employerAddress2 ? errors.employerAddress2 : ''}
                                                        error={errors.employerAddress2 && touched.employerAddress2}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        id="employerSuburb"
                                                        name="employerSuburb"
                                                        label="Suburb"
                                                        value={values.employerSuburb}
                                                        helperText={errors.suburb && touched.employerSuburb ? errors.employerSuburb : ''}
                                                        error={errors.employerSuburb && touched.employerSuburb}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        id="employerCity"
                                                        name="employerCity"
                                                        label="City"
                                                        value={values.employerCity}
                                                        helperText={errors.employerCity && touched.employerCity ? errors.employerCity : ''}
                                                        error={errors.employerCity && touched.employerCity}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        Label={"Province"}
                                                        fieldName={"employerProvince"}
                                                        fieldNameID={"employerProvinceId"}
                                                        data={provinceArr}
                                                        helperText={errors.employerProvince && touched.employerProvince ? errors.employerProvince : 'Start Typing'}
                                                        error={errors.employerProvince && touched.employerProvince}
                                                        onBlur={handleBlur("employerProvince")}
                                                        onChange={handleChange}
                                                        value={{
                                                            name: values.employerProvince ? values.employerProvince.toString() : '',
                                                            id: values.employerProvinceId ? values.employerProvinceId.toString() : '',
                                                        }}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <Field
                                                        component={SelectInput}
                                                        props={props}
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        Label={"Country"}
                                                        fieldName={"employerCountry"}
                                                        fieldNameID={"employerCountryId"}
                                                        data={countries}
                                                        helperText={errors.employerCountry && touched.employerCountry ? errors.employerCountry : 'Start Typing'}
                                                        error={errors.employerCountry && touched.employerCountry}
                                                        onBlur={handleBlur("employerCountry")}
                                                        onChange={handleChange}
                                                        value={{
                                                            name: values.employerCountry.toString(),
                                                            id: values.employerCountryId.toString(),
                                                        }}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        id="employerAddressCode"
                                                        name="employerAddressCode"
                                                        label="Address Code"
                                                        value={values.employerAddressCode}
                                                        helperText={errors.employerAddressCode && touched.employerAddressCode ? errors.employerAddressCode : ''}
                                                        error={errors.employerAddressCode && touched.employerAddressCode}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                
                                            </Grid>
                                        </CardContent>

                                    </Card>

                                    <FormActions back={back} reset={() => handleReset()} />

                                </MuiPickersUtilsProvider>
                            </Form>
                        );
                    }}
                </Formik>
            }
        </React.Fragment>
    );
}
