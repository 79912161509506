import { useState, useEffect } from "react";
import axios from 'axios';
import { BASE_URI } from '../../../../shared/Constants'
import ImageService from "../../../../shared/ImageService";

export default function OtpTabController(id) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {

        let cancel = reloadData();

        return () => cancel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const reloadData = () => {
        let cancel;
        setLoading(true);
        setError(false);

        axios({
            method: 'GET',
            url: `${BASE_URI}/applicationOtp/lead/${id}`,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {

            let data = response.data.map(itm => {
                return itm;
            });

            setData(data);
            setTotal(data.length)

            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                setError(true)
            }
            setLoading(false)
        })

        return cancel;
    }

    const uploadDocument = async (values) => {

        var base64Image = (values.fileUpload instanceof File) ? await ImageService.getBase64(values.fileUpload) : values.fileUpload;

        base64Image = ImageService.parseBase64(base64Image);

        axios.post(`${BASE_URI}/ApplicationOtp`,
            {
                LeadId: parseInt(id),
                Base64Image: base64Image,
                FileName: (values.fileUpload instanceof File) ? values.fileUpload.name : '',
                Note: values.otpNumber
            }
        ).then(() => {
            reloadData();
        });
    }


    return { loading, error, data, total, reloadData, uploadDocument };
}
