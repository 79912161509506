import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ActionContext } from "../../../../shared/contexts/ActionTabContext";
import { LeadMainContext } from "../../../../shared/contexts/LeadMainContext";
import { AuthContext } from "../../../../shared/context/AuthContext";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    stepPaddingButton: {
        marginTop: "1rem",
        marginLeft: "1rem",
        marginRight: "2rem",
    },
    card: {
        background: "red",
    },
}));

export default function ActionTabContainer() {
    const classes = useStyles();
    let { ...actionContext } = useContext(ActionContext);
    let { leadState } = useContext(LeadMainContext);
    let { permissions } = useContext(AuthContext);

    useEffect(() => {

        switch (leadState.id.toString()) {
            case "1":
                actionContext.getNewLead();
                break;
            case "2":
                actionContext.getPrequalifiedApproved();
                break;
            case "5":
                actionContext.getApplicationSubmitted();
                break;
            case "8":
                actionContext.getPrequalifiedSubmitted();
                break;
            case "6":
                actionContext.getApplicationApproved();
                break;
            case "3":
                actionContext.getPrequalifiedDeclined();
                break;
            case "11":
                actionContext.getDocumentsIn();
                break;
            case "12":
                actionContext.getBankSubmitted();
                break;
            case "13":
                actionContext.getBankValidated();
                break;
            case "10":
                actionContext.getOfferToPurchase();
                break;
            case "16":
                actionContext.getInvoiced();
                break;
            case "18":
                actionContext.getDelivered();
                break;
            case "19":
                actionContext.getPaidOut();
                break;
            default:
                actionContext.getPrequalifiedDeclined();
        }
    }, [leadState])

    const hasPermission = () => {
        return permissions.filter(itm => itm.id === leadState.id).length > 0
    }

    return (
        <div>
            { hasPermission &&
                actionContext.tabContainerList.map((container, index) => (
                    actionContext.getTabs(container)
                ))
            }
            {!hasPermission && <div className={classes.root}>
                <Grid container direction="row">
                    <Grid item className={classes.stepPadding} xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="caption">
                                    Your role does not have access to this lead type
                            </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            }
        </div>
    );
}
