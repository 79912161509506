import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, CardHeader, Divider } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from 'yup'
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { BASE_URI, PHONE_REGEX } from "../../shared/Constants";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useSnackbar } from 'notistack';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ImageUploadInput from '../../shared/ImageUploadFormInput'

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiCardHeader-title": {
            fontSize: "20px",
        },
    },
    flexCenter: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        display: 'inline-block',
        width: '100%'
    },
    stepPaddingButtons: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    modalContainer: {
        justifyContent: 'center',
        display: 'flex',
        height: 'fit-content',
        textAlign: 'center',
        marginTop: '150px',
    },
    modalContent: {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '3%',
        borderRadius: '10px'
    },
    modalButtons: {

    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    paperHeading: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        marginBottom: '1rem',
        height: 'fit-content',
        paddingBottom: '0'
    },
    errorText: {
        fontSize: "0.75rem",
        marginTop: "3px",
        textAlign: "left",
        fontWeight: 400,
        lineHeight: "1.66",
        letterSpacing: "0.03333em",
        color: "#f44336",
    },
    featureInputContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
}));

export default function AdminManufacturers() {
    const initialValues = {
        primaryColor: '',
        secondaryColor: '',
        accentColor: '',
        logo: '',
        heroImage: '',
        roadSideAssistTel: ''
    }
    const { id } = useParams();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [activeMake, setActiveMake] = useState(null);
    const [makes, setMakes] = useState([]);
    const [formFields, setFormFields] = useState(initialValues);

    const preventDefault = (event) => { event.preventDefault() };
    const { enqueueSnackbar } = useSnackbar();

    const handleChangebaseColor = (value) => {
        setFormFields({ ...formFields, primaryColor: value });
    }

    const handleChangesecondaryColor = (value) => {
        setFormFields({ ...formFields, secondaryColor: value });
    }
    const handleChangeaccentColor = (value) => {
        setFormFields({ ...formFields, accentColor: value });
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getMake = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/makes`, {
                    cancelToken: source.token
                })
                setMakes(result.data.list)
            } catch (error) {
                enqueueSnackbar(`Unable to retrieve vehicle makes`, { variant: 'error' });
            }
        }
        getMake();

    }, []);

    useEffect(() => {
        if (activeMake) {
            setFormFields({
                ...formFields,
                id: activeMake?.id,
                name: activeMake?.name,
                logo: activeMake?.logo,
                heroImage: activeMake?.heroImage,
                primaryColor: activeMake?.primaryColor,
                secondaryColor: activeMake?.secondaryColor,
                accentColor: activeMake?.accentColor,
                roadSideAssistTel: activeMake?.roadSideAssistTel
            })
        }
    }, [activeMake])

    const setImageUrl = (fieldName, url) => {
        setFormFields({
            ...formFields,
            [`${fieldName}`]: url,
        })
    }

    function SubmitChanges(values) {
        const update = async () => {
            setLoading(true);

            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            try {
                const result = await axios.put(`${BASE_URI}/makes/${activeMake?.id}`, {
                    cancelToken: source.token,
                    ...values,
                    roadSideAssistTel: `${values?.roadSideAssistTel}`

                })

                if (result?.data != null) {
                    const getMake = async () => {
                        enqueueSnackbar(`Manufaturer settings updated`, { variant: 'success' });
                        try {
                            const result = await axios.get(`${BASE_URI}/makes`, {
                                cancelToken: source.token
                            })
                            setMakes(result.data.list)
                        } catch (error) {
                            enqueueSnackbar(`Unable to retrieve vehicle Manufaturers `, { variant: 'error' });
                        }
                    }
                    getMake();
                }


                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        update();
    }

    return (

        <Card className="d-flex justify-content-between flex-column">
            <CardHeader title={'Manufaturers Settings'} />
            <CardContent style={{ width: '100%', display: 'block' }}>

                <React.Fragment>
                    <Grid item className={classes.stepPadding}>
                        <Autocomplete
                            id="allMakes"
                            name="allMakes"
                            value={activeMake}
                            options={makes}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => {
                                return option.id === value.id;
                            }}
                            onChange={(event, value) => {
                                setActiveMake(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Make"
                                />
                            )}
                        />


                    </Grid>
                </React.Fragment>

                <Divider />

                {formFields?.id && <React.Fragment>
                    <Formik
                        initialValues={formFields}
                        onSubmit={SubmitChanges}
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                            roadSideAssistTel: Yup.string().matches(PHONE_REGEX, 'Mobile number is not valid').notRequired()
                        })}
                    >
                        {(props) => {
                            const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset } = props;

                            return (
                                <Form>
                                    <Grid container spacing={4} className={classes.stepPadding} direction="column">
                                        <Grid item xs={12} md={6}>

                                            <Grid item xs={12}>

                                                <Typography variant="h6" gutterBottom>
                                                    {`${'Make COLORS'} (${activeMake?.name})`}
                                                </Typography>
                                            </Grid>
                                            <Typography variant="h6" gutterBottom>
                                                Contact
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                name="roadSideAssistTel"
                                                id="roadSideAssistTel"
                                                label="Raodside Assist Telephone"
                                                value={values.roadSideAssistTel}
                                                type="number"
                                                helperText={errors.roadSideAssistTel && touched.roadSideAssistTel ? errors.roadSideAssistTel : ''}
                                                error={errors.roadSideAssistTel && touched.roadSideAssistTel}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <Typography variant="h6" gutterBottom>
                                                Hex
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                name="primaryColor"
                                                //required
                                                id="primaryColor"
                                                label="Primary Color"
                                                type="text"
                                                value={`${formFields?.primaryColor}`}
                                                onChange={(e) => handleChangebaseColor(e.target.value)}
                                            >


                                            </TextField>
                                            <Typography variant="h6" gutterBottom>
                                                Hex
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                name="secondaryColor"
                                                //required
                                                id="secondaryColor"
                                                label="Secondary Color"
                                                type="text"
                                                value={`${formFields?.secondaryColor}`}
                                                onChange={(e) => handleChangesecondaryColor(e.target.value)}
                                            >


                                            </TextField>
                                            <Typography variant="h6" gutterBottom>
                                                Hex
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                name="accentColor"
                                                //required
                                                id="accentColor"
                                                label="Accent Color"
                                                type="text"
                                                value={`${formFields?.accentColor}`}
                                                onChange={(e) => handleChangeaccentColor(e.target.value)}
                                            >


                                            </TextField>


                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ImageUploadInput title='Logo'
                                                fieldName={'logo'}
                                                value={values.logo}
                                                error={errors.logo && touched.logo}
                                                setImage={setImageUrl} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ImageUploadInput title='Hero Image'
                                                fieldName={'heroImage'}
                                                value={values.heroImage}
                                                error={errors.heroImage && touched.heroImage}
                                                setImage={setImageUrl} />
                                        </Grid>


                                        <Grid item container
                                            justify='flex-end' xs={12}>

                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                            >
                                                Save
                                            </Button>
                                        </Grid>


                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                        }

                    </Formik>
                </React.Fragment>}
            </CardContent>
        </Card>
    );
}
