import React, { useState, useRef, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    TextField,
    Button,
    Box,
    MenuItem,
    Toolbar,
    Card,
    FormControlLabel
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import { BASE_URI } from '../../shared/Constants'
import * as Yup from 'yup'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { Switch } from 'formik-material-ui'
import Alert from '@material-ui/lab/Alert';
import { DealerContext } from "../../shared/DealerContext";

const useStyles = makeStyles((theme) => ({
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    }
}));

export default function AllocateLeadsForm() {
    const classes = useStyles();
    const mountedRef = useRef(true)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const initialValues = {
        statusId: '',
        motorGroup: '',
        motorGroupId: '',
        fromUserId: '',
        toUserId: '',
        roundRobin: false
    }
    const [formFields, setFormFields] = useState(initialValues)
    const [targetStateArr, setTargetStateArr] = useState([
        {
            id: 0,
            name: 'Application Submitted'
        },
        {
            id: 1,
            name: 'Application Rejected'
        },
        {
            id: 2,
            name: 'Application Declined'
        }
    ])
    
    const [fromUserArr, setFromUserArr] = useState([])
    const [toUserArr, setToUserArr] = useState([])

    let { ...dealerContext } = useContext(DealerContext);

    useEffect(() => {

        const params = {
            pageNumber: 1,
            pageSize: 1000
        };

        let cancel;
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const getState = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/state`, {
                    cancelToken: source.token
                })
                let formData = result.data
                setFormFields(formData)
            } catch (error) {
                // addAlert('Unables to load vehicle categories')
            }
        }

        getState().then(() => {
            if (!mountedRef.current) return null
        })

        axios({
            method: 'GET',
            url: `${BASE_URI}/Admin/Users`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data?.list) {
                setToUserArr(response.data?.list);
                setFromUserArr(response.data?.list);
            }
        });

        axios({
            method: 'GET',
            url: `${BASE_URI}/LeadStatus`,
            params,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then((response) => {
            if (response.data) {
                setTargetStateArr(response.data)
            }
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
            }
        })

        return () => {
            mountedRef.current = false
            source.cancel();
        };
    }, []);



    const updateBulkLeadsAllocation = async (fields, setSubmitting, resetForm) => {

        fields.dealerId = dealerContext.dealerId;

        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const result = await axios.post(`${BASE_URI }/bulkAction/allocate`, fields, {
                cancelToken: source.token
            })

            enqueueSnackbar('Allocated leads', {variant: 'success'});
            resetForm()
        } catch (error) {

            enqueueSnackbar('Could not allocate leads', {variant: 'error'});
        } finally {
            setSubmitting(false)
        }
    }

    function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
        setStatus();
        updateBulkLeadsAllocation(fields, setSubmitting, resetForm)
    }


    return (
        <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            // onSubmit={(values, actions) => {
            //   updateState(values, actions.resetForm)
            //   setTimeout(() => {
            //     actions.setSubmitting(false)
            //   }, 500)
            // }}
            validationSchema={Yup.object().shape({
                // name: Yup.string().required('State name is required'),
                // dateField: Yup.string().required('Select a Model for the vehicle'),
                // handledBy: Yup.string().required('Enter a title for the Range option'),
                // reworkField: Yup.string().required('Vehicle image is required')
            })}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                    handleReset
                } = props

                return (
                    <Form>
                        <Card style={{ marginBottom: '1rem' }}>
                            <Grid spacing={4} className={classes.stepPadding} container direction="row">
                                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                    <Toolbar disableGutters>
                                        <Typography style={{ flex: '1 1' }} variant="h6" id="tableTitle" component="div">
                                            Re-allocate Leads
                    </Typography>
                                        <FormControlLabel
                                            control={
                                                <Field component={Switch}
                                                    color="primary"
                                                    type="checkbox"
                                                    name="roundRobin" />
                                            }
                                            label="Round Robin"
                                        />
                                    </Toolbar>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert severity="warning">If you want to allocate the leads to a specific agent, don't
                                    switch the Round Robin on..... And be careful as this will re-allocate ALL
                    leads in the selected state for the selected motorgroup and 'From' user</Alert>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        name="statusId"
                                        id="statusId"
                                        required
                                        label="State to re-allocate"
                                        select
                                        value={values.statusId}
                                        helperText={errors.statusId && touched.statusId ? errors.statusId : ''}
                                        error={errors.statusId && touched.statusId}
                                        onBlur={handleBlur("statusId")}
                                        onChange={handleChange("statusId")}
                                    >
                                        {targetStateArr.map((option) => (
                                            <MenuItem key={option.id} id={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        name="fromUserId"
                                        id="fromUserId"
                                        required
                                        label="From User"
                                        select
                                        value={values.fromUserId}
                                        helperText={errors.fromUserId && touched.fromUserId ? errors.fromUserId : ''}
                                        error={errors.fromUserId && touched.fromUserId}
                                        onBlur={handleBlur("fromUserId")}
                                        onChange={handleChange("fromUserId")}
                                    >
                                        {fromUserArr.map((option) => (
                                            <MenuItem key={option.userId} id={option.userId} value={option.userId}>
                                                {option.fullName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        name="toUserId"
                                        id="toUserId"
                                        required
                                        label="To User"
                                        select
                                        value={values.toUserId}
                                        helperText={errors.toUserId && touched.toUserId ? errors.toUserId : ''}
                                        error={errors.toUserId && touched.toUserId}
                                        onBlur={handleBlur("toUserId")}
                                        onChange={handleChange("toUserId")}
                                    >
                                        {toUserArr.map((option) => (
                                            <MenuItem key={option.userId} id={option.userId} value={option.userId}>
                                                {option.fullName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>


                                <Grid item container
                                    justify='flex-end' xs={12}>
                                    <Box mr={3}>
                                        <Button
                                            onClick={() => {
                                                handleReset()
                                            }}>
                                            Reset
                    </Button>
                                    </Box>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                  </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}